import { RouteComponentProps, withRouter } from "react-router";
import { INematodeAssignment, NematodeAssignmentHelper } from "../../../types/model/nematode/nematodeAssignment";
import { IUser } from "../../../types/model/user";
import { DispatchCall, IRootState, RootAction } from "../../../@types/redux";
import React from "react";
import NematodeFunctions from "../../../store/nematode/functions";
import { createSelector } from "reselect";
import moment from "moment";
import lodash from "lodash";
import { CROP } from "../../../appConstants";
import GeneralFunctions from "../../../store/general/functions";
import NavFunctions from "../../../store/nav/functions";
import { Card, Icon, IconButton, Toolbar, Tooltip, Typography } from "@material-ui/core";
import MaterialTable from "../../customComponents/materialTable/Table";
import DeleteDialog from "../../customComponents/dialog/DeleteDialog";
import AppFunctionsService from "../../../services/appFunctionServices";
import { Transitions } from "../../customComponents/animations/Transitions";
import ScoutingAssignmentDateFilter from "../../customComponents/materialTable/ScoutingAssignmentDateFilter";
import ScoutingStartCell from "../../customComponents/table/ScoutingStartCell";
import ScoutingAssignmentEmployeeFilter from "../../customComponents/materialTable/ScoutingAssignmentEmployeeFilter";
import ScoutingAssignmentLandNameFilter from "../../customComponents/materialTable/ScoutingAssignmentLandNameFilter";
import ScoutingAssignmentBlockNameFilter from "../../customComponents/materialTable/ScoutingAssignmentBlockNameFilter";
import StandardFab from "../../customComponents/button/StandardFab";
import { Add, Delete } from "@material-ui/icons";
import NematodeActions from "../../../store/nematode/actions";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import NematodeAssignmentDistanceCell from "../../customComponents/materialTable/nematode/AssignmentDistanceCell";
import NematodeAssignmentInfoDialog from "./dialog/AssignmentInfoDialog";
import NematodeAssignmentEditDialog from "./dialog/AssignmentEditDialog";

interface INematodeAssignmentListProps extends RouteComponentProps {
    isLoading : boolean;
    assignments : Array<INematodeAssignment>;

    setLoading : DispatchCall<boolean>;
}

interface INematodeAssignmentListState {
    date ?: moment.Moment;
    startedDate ?: moment.Moment;
    finishedDate ?: moment.Moment;
    employee ?: IUser;
    landName ?: string;
    blockName ?: string;

    selectedAssignments : Array<INematodeAssignment>;
    onlyUnfinished : boolean;
}

class AssignmentList extends React.Component<INematodeAssignmentListProps, INematodeAssignmentListState> {
    constructor(props : INematodeAssignmentListProps) {
        super(props);
        this.state = {
            selectedAssignments: [],
            onlyUnfinished: false,
        };
    }

    public readonly componentDidMount = () => {
        this.loadAssignments(this.state.onlyUnfinished, true);
    }

    public readonly componentDidUpdate = (prevProps : INematodeAssignmentListProps, prevState : INematodeAssignmentListState) => {
        if (prevProps.location.search !== this.props.location.search || prevState.onlyUnfinished !== this.state.onlyUnfinished) {
            this.loadAssignments(this.state.onlyUnfinished, true);
        }
    }

    public readonly loadAssignments = (unfinishedOnly : boolean, refresh ?: boolean) => {
        const urlParams = new URLSearchParams(this.props.location.search);

        const crop = urlParams.get('crop');

        if (crop) {
            NematodeFunctions.getAssignments(crop, unfinishedOnly, refresh);
        }
    }

    private readonly getSearch = (props : INematodeAssignmentListProps) => props.location.search;
    private readonly getAssignments = (props : INematodeAssignmentListProps) => props.assignments;
    private readonly getDate = (props : INematodeAssignmentListProps, state : INematodeAssignmentListState) => state.date;
    private readonly getStartedDate = (props : INematodeAssignmentListProps, state : INematodeAssignmentListState) => state.startedDate;
    private readonly getFinishedDate = (props : INematodeAssignmentListProps, state : INematodeAssignmentListState) => state.finishedDate;
    private readonly getEmployee = (props : INematodeAssignmentListProps, state : INematodeAssignmentListState) => state.employee;
    private readonly getLandName = (props : INematodeAssignmentListProps, state : INematodeAssignmentListState) => state.landName;
    private readonly getBlockName = (props : INematodeAssignmentListProps, state : INematodeAssignmentListState) => state.blockName;
    private readonly getOnlyUnfinished = (props : INematodeAssignmentListProps, state : INematodeAssignmentListState) => state.onlyUnfinished;
    private readonly getFilteredAssignments = createSelector([
        this.getAssignments,
        this.getDate,
        this.getStartedDate,
        this.getFinishedDate,
        this.getEmployee,
        this.getLandName,
        this.getBlockName,
        this.getOnlyUnfinished,
    ], (
        assignments,
        date,
        startedDate,
        finishedDate,
        employee,
        landName,
        blockName,
        onlyUnfinished,
    ) => {
        return assignments
            .filter(n => !date || n.date === date.valueOf())
            .filter(n => !startedDate || (n.startedOn && moment.utc(n.startedOn ?? 0).startOf('day').isSame(startedDate)))
            .filter(n => !finishedDate || moment.utc(n.finishedOn).startOf('day').isSame(finishedDate))
            .filter(n => !employee || employee.employeeNumber === n.employeeNumber || employee.name === n.employeeName)
            .filter(n => !landName || landName === n.landName)
            .filter(n => !blockName || blockName === n.blockName)
            .filter(n => !onlyUnfinished || !n.finished);
    });

    private readonly getCrop = createSelector([
        this.getSearch,
    ], (
        search,
    ) => {
        const urlParams = new URLSearchParams(this.props.location.search);

        return urlParams.get('crop') as CROP;
    });

    private readonly onDelete = async (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        try {
            this.props.setLoading(true);
            await NematodeAssignmentHelper.bulkDelete([event.currentTarget.value]);
            GeneralFunctions.generalShowSuccessSnackbar('Deleted');
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error deleting assignment', ex);
        } finally {
            this.props.setLoading(false);
        }
    }

    private readonly onAddClick = () => {
        this.setState({
            onlyUnfinished: false,
        });
        NavFunctions.navPath('/nematode/assignments/create', this.props.location.search);
    }

    private readonly onDateChange = (date ?: moment.Moment) => {
        this.setState({
            date: date?.startOf('day'),
        });
    }

    private readonly onStartDateChange = (date ?: moment.Moment) => {
        this.setState({
            startedDate: date?.startOf('day'),
        });
    }

    private readonly onFinishedDateChange = (date ?: moment.Moment) => {
        this.setState({
            finishedDate: date?.startOf('day'),
        });
    }

    private readonly onEmployeeChange = (employee ?: IUser) => {
        this.setState({
            employee,
        });
    }

    private readonly onLandNameChange = (landName ?: string) => {
        this.setState({
            landName,
        });
    }

    private readonly onBlockNameChange = (blockNames : Array<string>) => {
        this.setState({
            blockName: !blockNames.length ? undefined : blockNames[0],
        });
    }

    private readonly onSelectionChange = (selectedAssignments : Array<INematodeAssignment>) => {
        this.setState({
            selectedAssignments: selectedAssignments.filter(x => !x.finished),
        });
    }

    private readonly onShowOnlyCompletedClick = () => {
        this.setState({
            onlyUnfinished: !this.state.onlyUnfinished,
        });
    }

    private readonly onDeleteSelected = async () => {
        try {
            this.props.setLoading(true);
            await NematodeAssignmentHelper.bulkDelete(this.state.selectedAssignments.map(x => x.id));
            GeneralFunctions.generalShowSuccessSnackbar('Deleted');
            this.onSelectionChange([]);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error deleting assignments', ex);
        } finally {
            this.props.setLoading(false);
        }
    }

    public readonly render = () => {
        const { isLoading, assignments } = this.props;
        const {
            date,
            startedDate,
            finishedDate,
            employee,
            selectedAssignments,
            onlyUnfinished,
            landName,
            blockName,
        } = this.state;

        const filteredAssignments = this.getFilteredAssignments(this.props, this.state);
        const crop = this.getCrop(this.props);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'fdc flx1 mh0 mw0'}>
                    <Toolbar>
                        <span className={'flx1'} />
                        <div className='mr15'>
                            <Tooltip title='Show Only Unfinshed'>
                                <IconButton onClick={this.onShowOnlyCompletedClick} className={onlyUnfinished ? 'bcp cw' : ''}>
                                    <Icon>
                                        block
                                    </Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className={'fdr jcfe aic'}>
                            <Typography className='fs16 fw700 cpd'>
                                Total: {assignments.length}
                            </Typography>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<INematodeAssignment>
                            id='assignmentTable'
                            data={filteredAssignments}
                            isLoading={isLoading}
                            rowsPerPage={50}
                            selectable
                            selectedRows={selectedAssignments}
                            onSelectionChanged={this.onSelectionChange}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 145,
                                renderCell: row => (
                                    <div className='aic'>
                                        <NematodeAssignmentEditDialog
                                            value={row}
                                            fullWidth
                                            transition={Transitions.Up}
                                            maxWidth='md'
                                            fullScreen
                                        />
                                        <NematodeAssignmentInfoDialog
                                            value={row}
                                            fullWidth
                                            transition={Transitions.Up}
                                            maxWidth='md'
                                            fullScreen
                                        />
                                        <DeleteDialog
                                            maxWidth='md'
                                            disabled={row.finished }
                                            isLoading={isLoading}
                                            message={`Delete assignment for ${row.landName} - ${row.blockName} on ${AppFunctionsService.formatDateTimeToDateOnly(row.date)}?`}
                                            onConfirm={this.onDelete} title={'Delete'} value={row.id} transition={Transitions.Down}
                                        />
                                    </div>
                                ),
                            },        {
                                header: 'Type',
                                field: 'type',
                                width: 120,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (<span>{AppFunctionsService.toTitleCase(row.assignmentType)}</span>),
                            },        {
                                header: 'Date',
                                field: 'date',
                                width: 120,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (<span>{AppFunctionsService.formatDateTimeToDateOnly(row.date)}</span>),
                                filtered: !!date,
                                filterRender: () => (<ScoutingAssignmentDateFilter value={date} onChange={this.onDateChange} id='date_filter' />),
                            },        {
                                header: 'Started On',
                                field: 'finishedOn[0]',
                                enableSort: true,
                                enableFilter: true,
                                width: 140,
                                filterRender: () => (<ScoutingAssignmentDateFilter value={startedDate} onChange={this.onStartDateChange} id='start_date_filter' />),
                                filtered: !!startedDate,
                                renderCell: row => (
                                    <span>
                                        {
                                            !!row.startedOn &&
                                            <Tooltip title={AppFunctionsService.formatDateTime(row.startedOn)}>
                                                <div>
                                                    {
                                                        AppFunctionsService.formatDateTimeToDateOnly(row.startedOn)
                                                    }
                                                </div>
                                            </Tooltip>
                                        }
                                    </span>
                                ),
                            },        {
                                header: 'Finished On',
                                field: 'finishedOn',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                filtered: !!finishedDate,
                                filterRender: () => (<ScoutingAssignmentDateFilter value={finishedDate}
                                    onChange={this.onFinishedDateChange} id='finished_date_filter' />),
                                renderCell: row => (
                                    <span>
                                        {
                                            !!row.finishedOn &&
                                            <Tooltip title={AppFunctionsService.formatDateTime(row.finishedOn)}>
                                                <div>
                                                    {
                                                        AppFunctionsService.formatDateTimeToDateOnly(row.finishedOn)
                                                    }
                                                </div>
                                            </Tooltip>
                                        }
                                    </span>
                                ),
                            },        {
                                header: 'Scout',
                                field: 'employeeName',
                                enableSort: true,
                                enableFilter: true,
                                width: 150,
                                renderCell: row => (
                                    <Tooltip title={row.employeeNumber ?? ''}>
                                        <div>
                                            {row.employeeName}
                                        </div>
                                    </Tooltip>
                                ),
                                filtered: !!employee,
                                filterRender: () => (<ScoutingAssignmentEmployeeFilter value={employee}
                                    onChange={this.onEmployeeChange} id='employee_filter' />),
                            },        {
                                header: 'Land Name',
                                field: 'landName',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                filterRender: () => (<ScoutingAssignmentLandNameFilter value={landName}
                                    onChange={this.onLandNameChange} id='land_filter' cropType={crop} />),
                            },        {
                                header: 'Block Name',
                                field: 'blockName',
                                width: 160,
                                enableSort: true,
                                enableFilter: true,
                                filterRender: () => (<ScoutingAssignmentBlockNameFilter value={blockName}
                                    onChange={this.onBlockNameChange} id='block_filter' cropType={crop} landName={landName} />),
                            },        {
                                header: 'KM Walked',
                                field: 'distance',
                                width: 100,
                                renderCell: row => (
                                    <NematodeAssignmentDistanceCell assignment={row} />
                                ),
                            },        {
                                header: 'Created By',
                                field: 'createdByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.createdOn)}>
                                        <span>{row.createdByName}</span>
                                    </Tooltip>),
                            },        {
                                header: 'Updated By',
                                field: 'updatedByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.updatedOn)}>
                                        <span>{row.updatedByName}</span>
                                    </Tooltip>),
                            }]}
                        />
                    </Card>
                    {
                        !!selectedAssignments.length &&
                        <DeleteDialog maxWidth='md' disabled={!selectedAssignments.length} isLoading={isLoading}
                            message={`Delete ${selectedAssignments.length} assignments?`}
                            onConfirm={this.onDeleteSelected} title={'Delete'} transition={Transitions.Down}
                        >
                            {
                                onClick => (
                                    <StandardFab aria-label='add' className='cw bcr mr80' disabled={isLoading} onClick={onClick}>
                                        <Delete />
                                    </StandardFab>
                                )
                            }
                        </DeleteDialog>
                    }
                    <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                        <Add />
                    </StandardFab>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.nematode.isLoadingAssignments,
        assignments: state.nematode.assignments,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators({
    setLoading: (isLoading : boolean) => dispatcher(NematodeActions.setLoadingAssignments(isLoading)),
}, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(AssignmentList));
