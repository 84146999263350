import React from 'react';
import HorizontalExpander from '../../customComponents/expander/HorizontalExpander';
import ButtonSelector from '../../customComponents/selector/ButtonSelector';
import { ScoutingType, scoutingTypes } from '../../../types/model/scouting/scoutingAssignment';
import AppFunctionsService from '../../../services/appFunctionServices';
import SpecificScoutingSelectors from './specific/SpecificScoutingSelectors';

interface IScoutingAssignmentTypeSelectorProps {
    value ?: ScoutingType;

    sections ?: Array<string>;
    components ?: Array<string>;
    insects ?: Array<string>;
    diseases ?: Array<string>;
    damages ?: Array<string>;
    onChange ?: (value ?: ScoutingType) => void;
    onSectionsChange ?: (sections ?: Array<string> | null) => void;
    onComponentsChange ?: (components ?: Array<string> | null) => void;
    onInsectsChange ?: (insects ?: Array<string> | null) => void;
    onDiseasesChange ?: (diseases ?: Array<string> | null) => void;
    onDamagesChange ?: (damages ?: Array<string> | null) => void;
}

interface IScoutingAssignmentTypeSelectorState {}

class ScoutingAssignmentTypeSelector extends React.PureComponent<IScoutingAssignmentTypeSelectorProps, IScoutingAssignmentTypeSelectorState> {
    constructor(props : IScoutingAssignmentTypeSelectorProps) {
        super(props);
        this.state = {};
    }

    private onScoutTypeChange = (value ?: ScoutingType) => {
        if (this.props.onChange) this.props.onChange(value);
    }

    public render = () => {
        const { value, sections, onSectionsChange,
            components, onComponentsChange,
            insects, onInsectsChange,
            diseases, onDiseasesChange,
            damages, onDamagesChange } = this.props;

        return (
            <HorizontalExpander
                title={`SCOUTING TYPE${!value ? '' : ' - '.concat(AppFunctionsService.toTitleCase(value))}`}
                color={value ? 'primary' : 'secondary'}
            >
                <div className='fdc'>
                    <div className={'fdc flx1 ais p10'}>
                        <ButtonSelector<ScoutingType>
                            values={scoutingTypes}
                            onChange={this.onScoutTypeChange}
                            value={value}
                            className='flx1'
                        />
                    </div>
                    {
                        value === 'specific' &&
                        <SpecificScoutingSelectors
                            sections={sections}
                            components={components}
                            insects={insects}
                            diseases={diseases}
                            damages={damages}
                            onSectionsChange={onSectionsChange}
                            onComponentsChange={onComponentsChange}
                            onInsectsChange={onInsectsChange}
                            onDiseasesChange={onDiseasesChange}
                            onDamagesChange={onDamagesChange}
                        />
                    }
                </div>
            </HorizontalExpander>
        );
    }
}

export default ScoutingAssignmentTypeSelector;