import { ButtonBaseProps, styled } from '@material-ui/core';
import Button from '@material-ui/core/Button';

type ToggleButtonProps = ButtonBaseProps & {
    selected : boolean;
    stadium ?: string;
};

function getColor(props : ToggleButtonProps) : string {
    if (props.selected) {
        return 'white';
    }

    if (props.disabled) {
        return '#757575';
    }

    return '#085028';
}

function getBorderColor(props : ToggleButtonProps) : string {
    if (props.disabled) {
        return '#757575';
    }

    return '#00502C';
}

const ToggleButton = styled(Button)((props : ToggleButtonProps) => ({
    color: getColor(props),
    backgroundColor: props.selected ? '#085028' : 'transparent',
    '&&.Mui-disabled span': {
        color: getColor(props),
    },
    '&:not(.Mui-disabled):hover': {
        backgroundColor: '#00000050 ',
    },
    fontWeight: props.selected ? 700 : 500,
    fontSize: 13,
    minHeight: 37,
    border: `2px solid ${getBorderColor(props)}`,
    paddingLeft: props.stadium ? 15 : undefined,
    paddingRight: props.stadium ? 15 : undefined,
    borderRadius: props.stadium ? 21 : 2,
}));

export default ToggleButton;