import React from 'react';
import CropTypeDropdown from '../customComponents/dropdowns/CropTypeDropdown';
import { CROP, IKeyValue } from '../../appConstants';
import WeekSelector from '../customComponents/selector/WeekSelector';
import Toolbar from '@material-ui/core/Toolbar';
import moment from 'moment';
import ScoutingReportView from './view/ScoutingReportView';
import { IReportScoutingAssignment, ScoutingAssignmentHelper } from '../../types/model/scouting/scoutingAssignment';
import ReportingHelper from '../../services/helper/reportingHelper';
import StringArrayDropdown from '../customComponents/dropdowns/StringArrayDropdown';
import { IUserSession } from '../../types/model/user';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall } from '../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import GeneralActions from '../../store/general/actions';
import { ThresholdHelper } from '../../types/model/masterData/threshold';
import ReactToPrint from 'react-to-print';
import Tooltip from '@material-ui/core/Tooltip';
import PrintIcon from '@material-ui/icons/Print';
import GeneralFunctions from '../../store/general/functions';
import AppFunctionsService from '../../services/appFunctionServices';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';

interface IScoutingReportProps {
    generalShowErrorSnackbar : DispatchCall<string>;
    generalShowSuccessSnackbar : DispatchCall<string>;
    session ?: IUserSession | null;
}

interface IScoutingReportState {
    cropType ?: CROP;
    startDate ?: moment.Moment;
    endDate ?: moment.Moment;

    sections : Array<string>;
    entities : Array<string>;

    section ?: string;
    entity ?: string;

    isLoading : boolean;

    assignments : Array<IReportScoutingAssignment>;
    thresholds : IKeyValue<IKeyValue<number>>;
}

class ScoutingReport extends React.PureComponent<IScoutingReportProps, IScoutingReportState> {
    private componentRef : React.RefObject<HTMLDivElement>;
    constructor(props : IScoutingReportProps) {
        super(props);
        this.state = {
            sections: [],
            entities: [],
            isLoading: false,
            assignments: [],
            thresholds: {},
        };
        this.componentRef = React.createRef<HTMLDivElement>();
    }

    private onCropTypeChange = (cropType ?: CROP) => {
        this.setState({
            cropType,
        }, () => this.loadData());
    }

    private onWeekChange = (startDate ?: moment.Moment, endDate ?: moment.Moment) => {
        this.setState({
            startDate,
            endDate,
        }, () => this.loadData());
    }

    private onSectionChange = (section ?: string) => {
        this.setState({
            section,
        });
    }

    private onEntityChange = (entity ?: string) => {
        this.setState({
            entity,
        });
    }

    public loadData = async () => {
        const { cropType, startDate, endDate } = this.state;

        if (!cropType || !startDate || !endDate) return;
        this.setState({
            isLoading: true,
        });

        const result = await Promise.all([this.loadAssignments(cropType, startDate, endDate), this.loadThresholds(cropType)]);

        const entities = ReportingHelper.getEntities(result[0]);
        const sections = ReportingHelper.getSectionsList(result[0]);

        this.setState({
            isLoading: false,
            assignments: result[0],
            thresholds: result[1],
            entities,
            sections,
        });
    }

    public loadAssignments = async (cropType : CROP, startDate : moment.Moment, endDate : moment.Moment) => {
        try {
            if (!this.props.session) return [];
            const divisions = Object.keys(this.props.session.user.divisions).map(n => n.toLocaleLowerCase());
            const assignments = await ScoutingAssignmentHelper.getReportAssignments(startDate, endDate, cropType, divisions);

            return assignments;
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error loading assignments', ex);
        }

        return [];
    }

    public loadThresholds = async (cropType : CROP) => {
        try {
            const result = await Promise.all([
                ThresholdHelper.get(cropType, 'insects').get(),
                ThresholdHelper.get(cropType, 'disease').get(),
            ]);

            return {
                insects: result[0].data()?.thresholds ?? {},
                disease: result[1].data()?.thresholds ?? {},
            };
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error loading thresholds', ex);
        }

        return {
            insects: {},
            disease: {},
        };
    }

    public onExportExcelClick = async () => {
        try {
            this.setState({
                isLoading: true,
            });

            await ReportingHelper.requestExcel(this.state.assignments, this.state.section, this.state.entity);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error export excel.', ex);
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    public render = () => {
        const { cropType, startDate, endDate, entities, sections, entity, section,
            isLoading, assignments, thresholds } = this.state;
        return (
            <div className='fdc flx1 ais pl20 pr20 pb20 pt10 bcg0' ref={this.componentRef}>
                <Toolbar>
                    <div className='fdr flx1 aifs'>
                        {
                            !!cropType && !!startDate && !!endDate &&
                            <div className={'fdr aic jcfe'}>
                                <ReactToPrint
                                    trigger={() =>
                                        <div>
                                            <Tooltip className='m5 ph' title='Print'>
                                                <div>
                                                    <Fab
                                                        disabled={isLoading}
                                                        style={{ marginRight: '12px' }}
                                                        size='medium'
                                                        color='default'
                                                        aria-label='Print'>
                                                        <PrintIcon color='primary' style={{ height: '1.25em', width: '1.25em' }} />
                                                    </Fab>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    }
                                    onPrintError={GeneralFunctions.showPrintError}
                                    documentTitle={`Report - ${AppFunctionsService.formatDateTimeToDateOnly(startDate.valueOf())}`}
                                    content={() => this.componentRef.current}
                                    bodyClass={'printElement'}
                                />
                            </div>
                        }
                        {
                            !!cropType && !!startDate && !!endDate &&
                            <Tooltip className='m5  ph' title='Export Excel'>
                                <div>
                                    <Fab
                                        disabled={isLoading}
                                        style={{ marginRight: '12px' }}
                                        size='medium'
                                        color='default'
                                        onClick={this.onExportExcelClick}>
                                        <Icon style={{ height: '1.25em', width: '1.25em' }}>
                                            <img style={{ height: '1.25em', width: '1.4em' }} src={`${ASSET_BASE}/assets/images/icons/excel.svg`} />
                                        </Icon>
                                    </Fab>
                                </div>
                            </Tooltip>
                        }
                        <span className={'flx3'} />
                        <div className={'fdc flx1 jcfs pr20'}>
                            <CropTypeDropdown required fullWidth value={cropType} onChange={this.onCropTypeChange} />
                        </div>
                        <div className={'fdc flx1 jcfs pr20'}>
                            <StringArrayDropdown values={sections} value={section} label='Section' disabled={!sections.length} onChange={this.onSectionChange} />
                        </div>
                        <div className={'fdc flx1 jcfs pr20'}>
                            <StringArrayDropdown values={entities} value={entity} label='Entity' disabled={!entities.length} onChange={this.onEntityChange} />
                        </div>
                        <div className={'fdc flx1 jcfs'}>
                            <WeekSelector label='Filter by Week' startDate={startDate} endDate={endDate} required fullWidth onChange={this.onWeekChange} />
                        </div>
                    </div>
                </Toolbar>
                {
                    !!cropType && !!startDate && !!endDate &&
                    <ScoutingReportView cropType={cropType} isLoading={isLoading} assignments={assignments} thresholds={thresholds} section={section} entity={entity} />
                }
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        session : state.auth.session,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators({
    generalShowErrorSnackbar: (message : string) => dispatcher(GeneralActions.enqueueSnackbar({
        message,
        options: {
            variant: 'error',
        },
    })),
    generalShowSuccessSnackbar: (message : string) => dispatcher(GeneralActions.enqueueSnackbar({
        message,
        options: {
            variant: 'success',
        },
    })),
}, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ScoutingReport);
