import { IKeyValue, CROP } from '../../appConstants';
import { IScoutingEntrySectionEntity } from '../../types/model/scouting/scoutingEntry';
import { IReportScoutingAssignment } from '../../types/model/scouting/scoutingAssignment';
import lodash from 'lodash';
import { IReportRow } from '../../types/reportRow';
import { THRESHOLD_TYPE } from '../../types/model/masterData/threshold';
import { FirebaseService } from '../firebaseService';

export default class ReportingHelper {
    /**
     *  Returns a list of sections from the given assignments and their entries.
     * @param assignments List of assignments
     * @param reportType Optional Section entry type insects/disease
     * @param sectionString Section name to select from assignment entry sections list
     * @param entity Entity name to select from assignment entry sections list
     */
    private static getSections(
        assignments : Array<IReportScoutingAssignment>,
        reportType ?: THRESHOLD_TYPE,
        sectionString ?: string,
        entity ?: string,
    ) {
        const sections : Array<IScoutingEntrySectionEntity> = [];

        assignments
            .forEach(assignment =>
                assignment.entries.forEach(entry =>
                    Array.prototype.push.apply(sections, entry.sections.filter(section =>
                        !reportType || section.type === reportType,
                    ).filter(section =>
                        !sectionString || section.section === sectionString,
                    ).filter(section =>
                        !entity || section.entry === entity,
                    )),
                ),
            );

        return sections;
    }

    /**
     * Aggregate's sections into key value pair, based on headers.
     * Key is the header name and value is array of summed values.
     * @param headers Key Value pair of headers, key is main header name and value is sub headers.
     * @param sections List of sections to aggregate
     */
    private static getRowData(headers : Record<string, Array<string>>, sections : Array<IScoutingEntrySectionEntity>) {
        const result : Record<string, Array<number>> = {};

        // Loop through all the header keys
        lodash.forEach(headers, (subs, header) => {
            // Get all the sections for the current header.
            const filteredSections = sections.filter(n => n.name === header);

            // Initialize value for header
            result[header] = [];

            // Almost the same as the point data loop in getPointData.
            // Loop through each section and add the to the header value array
            filteredSections.forEach((section) => {
                // If section count is an object, it has sub headers
                if (typeof section.count === 'object') {
                    // Loop through sub headers and add value to header value array.
                    lodash.forEach(subs, (sub, i) => {
                        result[header][i] = (result[header][i] ?? 0) + (section.count as any)[sub];
                    });
                // If section count is an number just add value to header value array
                } else if (typeof section.count === 'number') {
                    result[header][0] = (result[header][0] ?? 0) + section.count;
                // If section count is an string just add 1 to header value array
                } else if (typeof section.count === 'string') {
                    if (section.name === 'Avo Bug') {
                        lodash.forEach(subs, (sub, i) => {
                            if (sub === section.count) {
                                result[header][i] = (result[header][i] ?? 0) + 1;
                            } else {
                                result[header][i] = (result[header][i] ?? 0) + 0;
                            }
                        });
                    } else {
                        result[header][0] = (result[header][0] ?? 0) + 1;
                    }
                }
            });
        });
        return result;
    }

    /**
     * Sums rows data to header value key pair
     * @param row Report rows, usually [division] => IData
     */
    public static getTotalData(rows : Record<string, IReportRow>) {
        const result : Record<string, number> = {};
        lodash.forEach(rows, (row) => {
            lodash.forEach(row.data, (values, header) => {
                result[header] = (result[header]) + lodash.sum(values);
            });
        });

        return result;
    }

    /**
     * Calculates the average for each row.
     * @param rows Report rows, usually [division] => IData
     */
    public static getAverageData(rows : Record<string, IReportRow>) {
        const result : Record<string, number> = {};
        const totals = this.getTotalData(rows);

        lodash.forEach(totals, (total, header) => {
            let i = 0;

            lodash.forEach(rows, (row) => {
                if (!row.children) return;

                i += this.getPointsTotal(row);
            });
            result[header] = total / i;
        });

        return result;
    }

    /**
     * Calculates the total points per column.
     * @param rows Report rows, usually [division] => IData
     */
    public static getTotalPoints(rows : Record<string, IReportRow>) {
        const result : Record<string, number> = {};
        lodash.forEach(rows, (row) => {

            lodash.forEach(row.data, (value, header) => {
                if (!result[header]) result[header] = 0;

                if (!row.children) return;

                result[header] += this.getPointsTotal(row);
            });
        });

        return result;
    }

    private static getPointsTotal(row : IReportRow) {
        let i = 0;

        lodash.forEach(row.children, (land) => {
            if (!land.children) return;

            lodash.forEach(land.children, (block) => {
                if (!block.children) return;

                i += Object.keys(block.children).length;
            });
        });

        return i;
    }

    /**
     * Returns a unique list of Entities for given assignments
     * @param assignments List of scouting assignments
     */
    public static getEntities(assignments : Array<IReportScoutingAssignment>) {
        const sections = this.getSections(assignments);

        return lodash
            .chain(sections)
            .map(section => section.entry)
            .uniq()
            .sort()
            .value();
    }

    /**
     * Returns a unique list of Sections for given assignments
     * @param assignments List of scouting assignments
     */
    public static getSectionsList(assignments : Array<IReportScoutingAssignment>) {
        const sections = this.getSections(assignments);

        return lodash
            .chain(sections)
            .map(section => section.section)
            .uniq()
            .sort()
            .value();
    }

    public static getHeaders(assignments : Array<IReportScoutingAssignment>, reportType : THRESHOLD_TYPE, sectionString ?: string, entity ?: string) {
        const sections : Array<IScoutingEntrySectionEntity> = [];

        assignments
            .forEach(assignment =>
                assignment.entries.forEach(entry =>
                    Array.prototype.push.apply(sections,
                        entry.sections
                            .filter(section => reportType === section.type)
                            .filter(section => !sectionString || sectionString === section.section)
                            .filter(section => !entity || entity === section.entry),
                    ),
                ),
            );

        const names = lodash
            .chain(sections)
            .filter(section => reportType === section.type)
            .map(section => section.name)
            .uniq()
            .sort()
            .value();

        const headers : Record<string, Array<string>> = {};
        names.forEach((header) => {
            const section = sections.find(n => n.name === header);

            if (section?.name === 'Avo Bug') {
                headers[header] = ['Numph', 'Adult'];
            } else if (typeof section?.count === 'object') {
                headers[header] = Object.keys(section.count).sort();
            } else {
                headers[header] = [];
            }
        });

        return headers;
    }

    /**
     * Returns key value pair of IData where the key is the division name.
     * Usually used in report rows.
     * @param assignments List of scouting assignments
     * @param headers Report headers
     * @param reportType Section type, insects/disease
     * @param sectionString Optional section name to filter to
     * @param entity Optional entity name to filter to
     */
    public static getReportData(
        assignments : Array<IReportScoutingAssignment>,
        headers : Record<string, Array<string>>,
        reportType : THRESHOLD_TYPE,
        sectionString ?: string,
        entity ?: string,
    ) {
        // Get all the divisions of the assignments.
        const divisions = lodash
            .chain(assignments)
            .map(assignment => assignment.division)
            .uniq()
            .sort()
            .value();

        const result : Record<string, IReportRow> = {};

        // Loop through divisions and calculate the aggregate values for divisions
        divisions.forEach((division) => {
            // Only get current divisions assignments
            const divisionAssignments = assignments.filter(x => x.division === division);
            // Get all the section data for the assignments
            const sections = this.getSections(divisionAssignments, reportType, sectionString, entity);

            // Sum the section data to key value pair where the key is the header name and value
            // the is array of values.
            const data : Record<string, Array<number>> = this.getRowData(headers, sections);

            result[division] = {
                selectedColor: '#92C46D', // Custom colour for when row is selected.
                data,
                // Get all the Land Data
                children: ReportingHelper.getReportLandNameData(divisionAssignments, headers, reportType, sectionString, entity),
            };
        });

        return result;
    }

    /**
     * Returns key value pair of IData where the key is the land name.
     * Usually used in report rows.
     * @param assignments List of scouting assignments, usualy for only one division
     * @param headers Report headers
     * @param reportType Section type, insects/disease
     * @param sectionString Optional section name to filter to
     * @param entity Optional entity name to filter to
     */
    private static getReportLandNameData(
        assignments : Array<IReportScoutingAssignment>,
        headers : IKeyValue<Array<string>>,
        reportType : THRESHOLD_TYPE,
        sectionString ?: string,
        entity ?: string,
    ) {
        // Get all the land names of the assignments.
        const landNames = lodash
            .chain(assignments)
            .map(assignment => assignment.landName)
            .uniq()
            .sort()
            .value();

        const result : IKeyValue<IReportRow> = {};
        // Loop through land names and calculate the aggregate values for the given land name
        landNames.forEach((landName) => {
            const landNameAssignments = assignments.filter(x => x.landName === landName);
            const sections = this.getSections(landNameAssignments, reportType, sectionString, entity);

            const data : IKeyValue<Array<number>> = this.getRowData(headers, sections);
            result[landName] = {
                selectedColor: '#C6E170',
                data,
                // Get all the Block Data
                children: ReportingHelper.getReportBlockData(landNameAssignments, headers, reportType, sectionString, entity),
            };
        });

        return result;
    }

    /**
     * Returns key value pair of IData where the key is the block name.
     * Usually used in report rows.
     * @param assignments List of scouting assignments, usualy for only one land name
     * @param headers Report headers
     * @param reportType Section type, insects/disease
     * @param sectionString Optional section name to filter to
     * @param entity Optional entity name to filter to
     */
    private static getReportBlockData(
        assignments : Array<IReportScoutingAssignment>,
        headers : IKeyValue<Array<string>>,
        reportType : THRESHOLD_TYPE,
        sectionString ?: string,
        entity ?: string,
    ) {
        // Get all the block names of the assignments.
        const blockNames = lodash
            .chain(assignments)
            .map(assignment => assignment.blockName)
            .uniq()
            .sort()
            .value();

        const result : IKeyValue<IReportRow> = {};
        // Loop through block names and calculate the aggregate values for the given land name
        blockNames.forEach((blockName) => {
            const blockNameAssignments = assignments.filter(x => x.blockName === blockName);
            const sections = this.getSections(blockNameAssignments, reportType, sectionString, entity);

            const data : IKeyValue<Array<number>> = this.getRowData(headers, sections);
            result[blockName] = {
                data,
                selectedColor: '#DDECA9',
                children: this.getPointData(blockNameAssignments, headers, reportType, sectionString, entity),
            };
        });

        return result;
    }

    /**
     * Returns key value pair of IData where the key is Point {index + 1}.
     * Usually used in report rows.
     * @param assignments List of scouting assignments, usualy for only one block name
     * @param headers Report headers
     * @param reportType Section type, insects/disease
     * @param sectionString Optional section name to filter to
     * @param entity Optional entity name to filter to
     */
    private static getPointData(
        assignments : Array<IReportScoutingAssignment>,
        headers : IKeyValue<Array<string>>,
        reportType : THRESHOLD_TYPE,
        sectionString ?: string,
        entity ?: string,
    ) {
        const result : IKeyValue<IReportRow> = {};
        // Loop through all assignments
        assignments.forEach((assignment) => {
            // Loop through the keys of all points for given assignment block
            Object.keys(assignment.scoutingBlock.points).sort().forEach((pointGuid, index) => {
                // Get the entry for given point on assignment
                const entry = assignment.entries.find(x => x.pointGuid === pointGuid);
                const key = `Point ${index + 1}`;

                // Initialise if this is the first loop.
                if (typeof result[key] === 'undefined') {
                    result[key] = {
                        data: {},
                    };
                }

                // Loop through headers and get values for given header
                lodash.forEach(headers, (subs, header) => {
                    // Initialise if this is the first loop.
                    if (typeof result[key].data[header] === 'undefined') result[key].data[header] = [];

                    // If there is no entry for point just fill with 0
                    if (!entry) {
                        result[key].data[header].length = subs.length;
                        result[key].data[header].fill(0);
                        return;
                    }

                    // Assign assignment id.
                    result[key].assignmentId = assignment.id;

                    // Get sections of given header for point
                    // Filter to given section name and entity if provided
                    const filteredSections = entry
                        .sections
                        .filter(section => section.type === reportType)
                        .filter(n => n.name === header)
                        .filter(section =>
                            !sectionString || section.section === sectionString,
                        ).filter(section =>
                            !entity || section.entry === entity,
                        );

                    // Loop through each section and aggregate data
                    filteredSections.forEach((section) => {
                        if (typeof section.count === 'object') {
                            lodash.forEach(subs, (sub, i) => {
                                result[key].data[header][i] = (result[key].data[header][i] ?? 0) + (section.count as any)[sub];
                            });
                        } else if (typeof section.count === 'number') {
                            result[key].data[header][0] = (result[key].data[header][0] ?? 0) + section.count;
                        } else if (typeof section.count === 'string') {
                            if (section.name === 'Avo Bug') {
                                lodash.forEach(subs, (sub, i) => {
                                    if (sub === section.count) {
                                        result[key].data[header][i] = (result[key].data[header][i] ?? 0) + 1;
                                    } else {
                                        result[key].data[header][i] = (result[key].data[header][i] ?? 0) + 0;
                                    }
                                });
                            } else {
                                result[key].data[header][0] = (result[key].data[header][0] ?? 0) + 1;
                            }
                        }
                    });
                });
            });
        });
        return result;
    }

    public static async requestExcel(
        assignments : Array<IReportScoutingAssignment>,
        sectionString ?: string,
        entity ?: string,
    ) {
        const exportData : IKeyValue<{
            crop : CROP;
            headers : IKeyValue<Array<string>>;
            data : IKeyValue<IReportRow>;
        }> = {};

        const types : Array<THRESHOLD_TYPE> = ['insects', 'disease'];

        types.forEach((reportType) => {
            exportData[reportType] = {
                crop: assignments[0].crop,
                headers: this.getHeaders(assignments, reportType, sectionString, entity),
                data: {},
            };

            exportData[reportType].data = this.getReportData(assignments, exportData[reportType].headers, reportType, sectionString, entity);
        });

        return FirebaseService.getReportExcel(exportData);
    }
}
