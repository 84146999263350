import DiseaseActions from './actions';
import lodash from 'lodash';
import firebase from 'firebase/app';
import uuid from 'uuid';
import { dispatch, getState } from '../..';
import DiseaseHelper, { IDisease } from '../../../types/model/masterData/disease';
import GeneralFunctions from '../../general/functions';

export default class DiseaseFunctions {
    private static listener ?: () => void;

    public static getList = (refresh ?: boolean) => {

        if (!refresh && DiseaseFunctions.listener) {
            return;
        }

        if (DiseaseFunctions.listener) {
            DiseaseFunctions.listener();
        }

        dispatch(DiseaseActions.setLoading(true));
        dispatch(DiseaseActions.setList([]));

        try {
            
            DiseaseFunctions.listener = DiseaseHelper
                .collection()
                .onSnapshot((snapshot) => {
                    const diseaseState = getState().masterData.disease;

                    const diseases = diseaseState.diseases.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const data = f.doc.data();
                        const index = lodash.findIndex(diseases, n => n.id === data.id);

                        switch (f.type) {
                        case 'added':
                            diseases.push(data);
                            break;
                        case 'modified':
                            diseases.splice(index, 1, data);
                            break;
                        case 'removed':
                            diseases.splice(index, 1);
                            break;
                        }
                    });

                    dispatch(DiseaseActions.setList(diseases));
                    dispatch(DiseaseActions.setLoading(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading diseases.', err);
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading diseases.', ex);
            dispatch(DiseaseActions.setLoading(false));
        }
    }

    public static save = async (item : IDisease) => {
        const session = getState().auth.session;

        if (!session) return;
        
        const diseases = getState().masterData.disease.diseases;

        if (diseases.some(x => x.name === item.name && x.id !== item.id)) {
            throw new Error('Diseas already exists.');
        }

        dispatch(DiseaseActions.setLoading(true));

        try {
            const save : IDisease = {
                ...item,
                createdBy: item.createdBy ? item.createdBy : session.firebaseUser.uid,
                createdByEmployee: item.createdByEmployee ? item.createdByEmployee : session.user.employeeNumber,
                createdByName: item.createdBy ? item.createdByName : session.user.name,
                createdOn: item.createdOn ? item.createdOn : firebase.firestore.Timestamp.now().toMillis(),
                updatedBy: session.firebaseUser.uid,
                updatedByEmployee: session.user.employeeNumber,
                updatedByName: session.user.name,
                updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                id: item.id ? item.id : uuid.v4(),
            };

            await DiseaseHelper.save(save);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving disease.', ex);
            throw ex;
        } finally {
            dispatch(DiseaseActions.setLoading(false));
        }
    }
}