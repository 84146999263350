import { IUsersState, UserManagementAction } from '../../@types/redux';
import actions from './actions';
import { getType } from 'typesafe-actions';

const initialState = {
    isLoading: false,
    users : [],
};

export default function usersReducer(state : IUsersState = initialState, action : UserManagementAction) : IUsersState {
    switch (action.type) {
    case getType(actions.setIsLoading):
        return { ...state, isLoading: action.payload };
    case getType(actions.setUser):
        return { ...state, user: action.payload };
    case getType(actions.setUsers):
        return { ...state, users: action.payload };
    default:
        return state;
    }
}
