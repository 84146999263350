import firebaseApp from '../../services/firebaseService';
import firebase from 'firebase/app';

export enum EnumNotificationType {
    Scouting,
}

export interface IFirestoreNotification {
    /**
     * Document id.
     */
    id : string;

    token : string;

    title : string;
    imageUrl : string | null;

    data : Record<string, string>;

    body : string;

    userId : string;

    isSent : boolean;
    date : number;

    clickUrl : string | null;
    type : EnumNotificationType;
}

export default class NotificationHelper {
    public static readonly COLLECTION = 'notifications';

    public static converter : firebase.firestore.FirestoreDataConverter<IFirestoreNotification> = {
        fromFirestore: (snapshot) => {
            return NotificationHelper.fromFirestore(snapshot);
        },
        toFirestore: () => {
            throw new Error('Not implemented');
        },
    };

    private static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IFirestoreNotification {
        const data = snapshot.data();

        if (!data) throw new Error('Document does not exist!');

        return {
            ...data as IFirestoreNotification,
            id: snapshot.ref.id,
            date: (data['date'] as firebase.firestore.Timestamp).toMillis(),
        };
    }

    public static collection(userId : string) {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).where('userId', '==', userId);
    }

    public static doc(id : string) {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(id);
    }
}