import { getType } from 'typesafe-actions';
import { InsectAction } from '../../../@types/redux';
import { IInsect } from '../../../types/model/masterData/insect';
import InsectActions from './actions';

export interface IInsectState {
    insects : Array<IInsect>;
    isLoading : boolean;

}

const initialState : IInsectState = {
    insects: [],
    isLoading: false,
};

export default function insectReducer(state : IInsectState = initialState, action : InsectAction) : IInsectState {
    switch (action.type) {
    case getType(InsectActions.setList):
        return { ...state, insects: action.payload };
    case getType(InsectActions.setLoading):
        return { ...state, isLoading: action.payload };
    default:
        return state;
    }
}