import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { TransitionProps } from '@material-ui/core/transitions';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppFunctionsService from '../../../services/appFunctionServices';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ScoutingFunctions from '../../../store/scouting/functions';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import ScoutingBlockLandNameDropdown from '../../customComponents/dropdowns/BlockLandNameDropdown';
import ScoutingBlockNameDropdown from '../../customComponents/dropdowns/BlockNameDropdown';
import { IRootState, RootAction, DispatchCall } from '../../../@types/redux';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import GeneralActions from '../../../store/general/actions';
import ScoutingBlocksMap from '../../customComponents/map/ScoutingBlocksMap';
import { IScoutingAssignment } from '../../../types/model/scouting/scoutingAssignment';
import { IUser } from '../../../types/model/user';
import EmployeeDropdown from '../../customComponents/dropdowns/EmployeeDropdown';
import { IAssignmentBlock, IBlock } from '../../../types/model/masterData/block';
import { CROP } from '../../../appConstants';
import DivisionAutocomplete from '../../customComponents/autocomplete/rights/DivisionAutocomplete';
import { IDivision } from '../../../types/model/rights/division';

interface IAssignmentDialogProps {
    value ?: IScoutingAssignment;

    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
    blocks : Array<IBlock>;

    /**
     * Default division to use when creating assignment.
     */
    division ?: string;

    /**
     * Default Land name to use when creating assignment.
     */
    landName ?: string;

    /**
     * Default Block Names to use when creating assignment.
     */
    blockNames ?: Array<string>;

    crop : CROP;

    generalShowErrorSnackbar : DispatchCall<string>;

    disabled ?: boolean;
}

interface IAssignmentDialogState {
    open : boolean;

    division ?: string;
    landName ?: string;

    date : Moment;
    /**
     * Employee Id
     */
    employee ?: string;
    employeeName ?: string;
    employeeNumber ?: string;
    scoutingBlocks : Array<IAssignmentBlock>;
}

class AssignmentDialog extends React.PureComponent<IAssignmentDialogProps, IAssignmentDialogState> {
    constructor(props : IAssignmentDialogProps) {
        super(props);
        this.state = {
            open: false,
            scoutingBlocks: [],
            date: moment.utc().startOf('day'),
        };
    }

    public componentDidUpdate = (prevProps : IAssignmentDialogProps, prevState : IAssignmentDialogState) => {
        if (!prevState.open && this.state.open) {
            const blocks = [];

            if (this.props.value) {
                blocks.push({ ...this.props.value.scoutingBlock });
            }

            if (!this.props.value && !!this.props.blockNames && !!this.props.landName) {
                this.props.blocks
                    .filter(n => n.landName === this.props.landName)
                    .filter(n => !this.props.blockNames?.length || this.props.blockNames.includes(n.name))
                    .forEach(n => blocks.push({ ...n }));
            }

            this.setState({
                division: !this.props.value ? this.props.division : this.props.value.division.toLocaleUpperCase(),
                landName: !this.props.value ? this.props.landName : this.props.value.landName,
                date: !this.props.value ? moment.utc().startOf('day') : moment.utc(this.props.value.date),
                employee: !this.props.value ? undefined : this.props.value.employee,
                employeeNumber: !this.props.value ? undefined : this.props.value.employeeNumber,
                employeeName: !this.props.value ? undefined : this.props.value.employeeName,
                scoutingBlocks: blocks,
            });
        }

    }

    private onIconButtonClick = () => {
        this.setState({
            open: true,
        });
    }

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.save();
    }

    private save = async () => {
        const { date, scoutingBlocks, employee, employeeName, employeeNumber } = this.state;

        if (!employee || !employeeName || !employeeNumber) return;
        if (!scoutingBlocks.length) return;

        const result = await ScoutingFunctions.saveAssignment(
            date,
            employee,
            employeeNumber,
            employeeName,
            scoutingBlocks,
            this.props.value,
        );

        if (result) {
            this.onClose();
        }
    }

    private onDateChange = (momentDate : Moment | null) => {
        if (!momentDate) return;
        this.setState({
            date: momentDate.utc().startOf('day'),
        });
    }

    private onEmployeeChange = (employee ?: IUser) => {
        if (!employee) return;
        this.setState({
            employee: employee.ref,
            employeeName: employee.name,
            employeeNumber: employee.employeeNumber,
        });
    }

    private onBlockChange = (blocks : Array<IBlock>) => {
        this.setState({
            scoutingBlocks: blocks.slice(),
        });
    }

    private onBlockLandNameChange = (landName ?: string) => {
        this.setState({
            landName,
        });
        this.onBlockChange([]);
    }

    private onBlockNameChange = (blockNames : Array<string>) => {
        const { division, landName } = this.state;

        if (!division || !landName) return;

        const scoutingBlocks = this.props.blocks.filter(n =>
            n.landName === landName
            && division.toLocaleLowerCase() === n.division.toLocaleLowerCase()
            && blockNames.includes(n.name));

        if (blockNames.length && scoutingBlocks.length === 0) {
            this.props.generalShowErrorSnackbar('Block not found.');
            return;
        }

        this.onBlockChange(scoutingBlocks);
    }

    private onDivisionChange = (division ?: IDivision) => {
        this.setState({
            division: division?.code,
            landName: undefined,
        });
        this.onBlockChange([]);
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, value, isLoading, crop, disabled } = this.props;
        const { open, division, landName, date, employee, scoutingBlocks } = this.state;

        const blockNames = scoutingBlocks.map(n => n.name);
        return (
            <React.Fragment>
                <Tooltip title={!value ? 'Add' : 'Edit'}>
                    <div>
                        <IconButton color='primary' onClick={this.onIconButtonClick}
                            aria-label={!value ? 'Add' : 'Edit'} disabled={disabled || isLoading || !!value?.finished || !!value?.finishedPoints.length }>
                            <Icon>{!value ? 'add' : 'edit'}</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='scouting-assignment-dialog-title'
                    aria-describedby='scouting-assignment-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {
                                    value &&
                                    `Edit Assignment - ${AppFunctionsService.formatDateTimeToDateOnly(value.date)} -
                                     ${value.landName} -
                                     ${value.blockName}
                                    `
                                }
                                {
                                    !value &&
                                    'Create Assignment'
                                }
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc ais flx1'>
                            <div className={'fdr aifs'}>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <KeyboardDatePicker fullWidth value={date} onChange={this.onDateChange} format='YYYY-MM-DD' label='Date' />
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <DivisionAutocomplete required fullWidth value={division} onChange={this.onDivisionChange} crop={crop} />
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <EmployeeDropdown value={employee} fullWidth required onChange={this.onEmployeeChange} division={division} disabled={!division} />
                                </div>
                            </div>
                            <div className={'fdr aifs'}>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <ScoutingBlockLandNameDropdown crop={crop} value={landName} disabled={!division} division={division} fullWidth required onChange={this.onBlockLandNameChange} />
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <ScoutingBlockNameDropdown value={blockNames}
                                        landName={landName}
                                        disabled={!division || !landName}
                                        division={division}
                                        fullWidth
                                        required
                                        single={!!this.props.value}
                                        onChange={this.onBlockNameChange} />
                                </div>
                            </div>
                            <div className={'h280 flx1 fdc'}>
                                <ScoutingBlocksMap crop={crop} division={division} landName={landName} blockNames={blockNames} />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading || !scoutingBlocks.length || !employee} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.general.blocks,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
    generalShowErrorSnackbar: (message : string) => dispatch(GeneralActions.enqueueSnackbar({
        message,
        options: {
            variant: 'error',
        },
    })),
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssignmentDialog);
