import { INematodeState, NematodeAction } from '../../@types/redux';
import { getType } from 'typesafe-actions';
import Actions from './actions';

const initialState : INematodeState = {
    assignments: [],
    isLoadingAssignments: false,
};

export default function authReducer(state : INematodeState = initialState, action : NematodeAction) : INematodeState {
    switch (action.type) {
    case getType(Actions.setAssignments):
        return { ...state, assignments: action.payload };
    case getType(Actions.setLoadingAssignments):
        return { ...state, isLoadingAssignments: action.payload };
    default:
        return state;
    }
}
