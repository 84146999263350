import NematodeSurveyTypeActions from './actions';
import lodash from 'lodash';
import firebase from 'firebase/app';
import uuid from 'uuid';
import moment from 'moment';
import { dispatch, getState } from '../../..';
import NematodeSurveyTypeHelper, { INematodeSurveyType } from '../../../../types/model/masterData/nematode/survey';
import GeneralFunctions from '../../../general/functions';
import firebaseApp from '../../../../services/firebaseService';

export default class MasterDataNematodeNematodeSurveyTypeFunctions {
    private static listener ?: () => void;

    public static getList = (refresh ?: boolean) => {

        if (!refresh && MasterDataNematodeNematodeSurveyTypeFunctions.listener) {
            return;
        }

        if (MasterDataNematodeNematodeSurveyTypeFunctions.listener) {
            MasterDataNematodeNematodeSurveyTypeFunctions.listener();
        }

        dispatch(NematodeSurveyTypeActions.setLoading(true));
        dispatch(NematodeSurveyTypeActions.setList([]));

        try {
            
            MasterDataNematodeNematodeSurveyTypeFunctions.listener = NematodeSurveyTypeHelper
                .collection()
                .onSnapshot((snapshot) => {
                    const surveyType = getState().masterData.nematode.surveyType;

                    const surveyTypes = surveyType.surveyTypes.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const data = f.doc.data();
                        const index = lodash.findIndex(surveyTypes, n => n.id === data.id);

                        switch (f.type) {
                        case 'added':
                            surveyTypes.push(data);
                            break;
                        case 'modified':
                            surveyTypes.splice(index, 1, data);
                            break;
                        case 'removed':
                            surveyTypes.splice(index, 1);
                            break;
                        }
                    });

                    dispatch(NematodeSurveyTypeActions.setList(surveyTypes));
                    dispatch(NematodeSurveyTypeActions.setLoading(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading survey types.', err);
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading survey types.', ex);
            dispatch(NematodeSurveyTypeActions.setLoading(false));
        }
    }

    private static uploadFiles = async (save : INematodeSurveyType) => {
        for (const level of save.levels ?? []) {
            for (const image of level.images ?? []){
                if (image.file.file) {
                    image.file.path = `nematode/${save.crop}/${save.id}/${moment.utc().valueOf()}_${image.file.name}`;
    
                    const fileRef = firebaseApp.storage().ref(image.file.path);
    
                    try {
                        image.file.url = await fileRef.getDownloadURL();
                    } catch (ex) {
                        const result = await fileRef.put(image.file.file);
                        image.file.url = await result.ref.getDownloadURL();
                    } finally {
                        delete image.file.file;
                    }
    
                }
            }
            
        }
    }

    public static save = async (item : INematodeSurveyType) => {
        const session = getState().auth.session;

        if (!session) return;
        
        const surveyTypes = getState().masterData.nematode.surveyType.surveyTypes;

        if (surveyTypes.some(x => x.name === item.name && x.id !== item.id)) {
            throw new Error('Nematode Survey Type already exists.');
        }

        dispatch(NematodeSurveyTypeActions.setLoading(true));

        try {

            await firebaseApp.firestore().runTransaction(async (db) => {
                const snapshot = await db.get(NematodeSurveyTypeHelper.doc(item.id));
                const save : INematodeSurveyType = {
                    ...item,
                    createdBy: item.createdBy ? item.createdBy : session.firebaseUser.uid,
                    createdByEmployee: item.createdByEmployee ? item.createdByEmployee : session.user.employeeNumber,
                    createdByName: item.createdBy ? item.createdByName : session.user.name,
                    createdOn: item.createdOn ? item.createdOn : firebase.firestore.Timestamp.now().toMillis(),
                    updatedBy: session.firebaseUser.uid,
                    updatedByEmployee: session.user.employeeNumber,
                    updatedByName: session.user.name,
                    updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                    id: item.id ? item.id : uuid.v4(),
                    levels: [
                        ...item.levels.map(level => ({
                            ...level,
                            images: level.images.map(image => ({
                                ...image,
                                file: {
                                    ...image.file,
                                },
                            }))
                        })),
                    ]
                };
                
                await MasterDataNematodeNematodeSurveyTypeFunctions.uploadFiles(save);

                db.set(snapshot.ref, save);
            });
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving nematode survey type.', ex);
            throw ex;
        } finally {
            dispatch(NematodeSurveyTypeActions.setLoading(false));
        }
    }
}