import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { INematodeAssignmentEntry } from '../../../../types/model/nematode/nematodeAssignmentEntry';
import AppFunctionsService from '../../../../services/appFunctionServices';
import { AppBar, Toolbar } from '@material-ui/core';
import NematodeAssignmentEntryTypeView from './AssignmentEntryTypeView';

interface INematodeAssignmentEntryViewProps {
    value ?: INematodeAssignmentEntry;
    pointNumber : number;
}

interface INematodeAssignmentEntryViewState {
    expanded : boolean;
}

export default class NematodeAssignmentEntryView extends React.PureComponent<INematodeAssignmentEntryViewProps, INematodeAssignmentEntryViewState> {

    constructor(props : INematodeAssignmentEntryViewProps) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    private onExpansionChange = (event : React.ChangeEvent<unknown>, expanded : boolean) => {
        this.setState({
            expanded,
        });
    }

    public render = () => {
        const { expanded } = this.state;
        const { value, pointNumber } = this.props;
        return (
            <Accordion expanded={expanded} onChange={this.onExpansionChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className='fdr aic flx1'>
                        <Typography>
                            Point {pointNumber}
                        </Typography>
                        {
                            !!value &&
                            <Typography className='fdr aic'>
                                &nbsp;- {AppFunctionsService.formatDateTime(value.createdOn)}
                            </Typography>
                        }
                        <span className='flx1'></span>
                        {!value ? <CloseIcon className='cpr' /> : <CheckIcon className='cp' />}
                    </div>
                </AccordionSummary>
                <AccordionDetails className={'fdc ais flx1'}>
                    {
                        !value &&
                        <span>No Data</span>
                    }
                    {
                        value &&
                        <div className='fdc ais mb5'>
                            {
                                Object.keys(value.entries).map((type) => (
                                    <NematodeAssignmentEntryTypeView key={type} title={type} value={value.entries[type]} />
                                ))
                            }
                        </div>
                    }
                </AccordionDetails>
            </Accordion>
        );
    }
}
