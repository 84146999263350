import firebase from 'firebase/app';
import { BaseHelper, IBase } from '../../base';
import { GeoPointHelper, IGeoPoint } from '../geoPoint';
import { NematodeAssignmentHelper } from './nematodeAssignment';
import FirestoreHelper from '../../../services/helper/firestoreHelper';
import UserHelper from '../user';

export interface INematodeAssignmentLevelEntry {
    level : string;
    imagePath : string;
}

export interface INematodeAssignmentEntry extends IBase {
    point : IGeoPoint;
    pointGuid : string;
    /** Assignment Path */
    assignment : string;

    employeeName : string;
    employeeNumber : string;
    /** Employee Path */
    employee : string;

    time : number;
    type : string;
    crop : string;
    date : number;
    guid : string;

    entries : Record<string, INematodeAssignmentLevelEntry>;
}

export class NematodeAssignmentEntryHelper extends BaseHelper {
    public static readonly COLLECTION = 'nematodes_entries';

    public static converter : firebase.firestore.FirestoreDataConverter<INematodeAssignmentEntry> = {
        fromFirestore: (snapshot) => {
            return NematodeAssignmentEntryHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : INematodeAssignmentEntry) => {
            return NematodeAssignmentEntryHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : INematodeAssignmentEntry {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as INematodeAssignmentEntry,
            ...result,
            point: GeoPointHelper.fromFirestore(data['point']),
            employee: (data['employee'] as firebase.firestore.DocumentReference).id,
            assignment: (data['assignment'] as firebase.firestore.DocumentReference).id,
            date: (data['date'] as firebase.firestore.Timestamp).toMillis(),
        };
    }

    protected static toFirestore(data : INematodeAssignmentEntry) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
            point: GeoPointHelper.toFirestore(data.point),
            date: FirestoreHelper.millisToTimestamp(data.date),
            employee: UserHelper.get(data.employee),
            assignment: NematodeAssignmentHelper.doc(data.assignment),
        };
    }

    public static collection(assignmentId : string) {
        return NematodeAssignmentHelper.doc(assignmentId).collection(this.COLLECTION).withConverter(this.converter);
    }

    public static doc(assignmentId : string, id ?: string) {
        if (!id) {
            return NematodeAssignmentEntryHelper.collection(assignmentId).doc();
        }

        return NematodeAssignmentEntryHelper.collection(assignmentId).doc(id);
    }
}