import { dispatch, getState } from '..';
import UserManagementActions from './actions';
import GeneralFunctions from '../general/functions';
import firebaseApp, { FirebaseService } from '../../services/firebaseService';
import lodash from 'lodash';
import UserHelper, { IUser } from '../../types/model/user';

export default class UserManagementFunctions {
    private static userListener ?: () => void;

    public static getUsers = async () => {
        if (UserManagementFunctions.userListener) return;

        dispatch(UserManagementActions.setIsLoading(true));
        dispatch(UserManagementActions.setUsers([]));

        try {
            UserManagementFunctions.userListener = UserHelper.collection()
                .orderBy('name')
                .onSnapshot((snapshot) => {
                    const userState = getState().user;

                    const users = userState.users.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const user = f.doc.data();

                        const index = lodash.findIndex(users, n => n.ref === user.ref);

                        switch (f.type) {
                        case 'added':
                            users.push(user);
                            break;
                        case 'modified':
                            users.splice(index, 1, user);
                            break;
                        case 'removed':
                            users.splice(index, 1);
                            break;
                        }
                    });

                    dispatch(UserManagementActions.setUsers(users));

                    if (userState.user) {
                        const id = userState.user.ref;
                        dispatch(UserManagementActions.setUser(users.find(n => n.ref === id)));
                    }

                    dispatch(UserManagementActions.setIsLoading(false));
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading users.');
            dispatch(UserManagementActions.setIsLoading(false));
        }
    }

    public static saveUser = async (saveUser : IUser) => {
        dispatch(UserManagementActions.setIsLoading(true));

        try {
            await UserHelper.save(saveUser);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar(`An error while saving user. ${ex}`);
        } finally {
            dispatch(UserManagementActions.setIsLoading(false));
        }
    }

    public static registerUser = async (employeeNumber : string, name : string) => {
        dispatch(UserManagementActions.setIsLoading(true));

        try {
            const result = await FirebaseService.registerUser(
                employeeNumber,
                name,
            );

            GeneralFunctions.generalShowSuccessSnackbar(`${result}`);

            return true;
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar(`An error while creating user. ${ex}`);
            dispatch(UserManagementActions.setIsLoading(false));
        }
        return false;
    }

    public static deleteUser = async (uid : string) => {
        dispatch(UserManagementActions.setIsLoading(true));

        try {
            await firebaseApp
                .firestore()
                .collection(UserHelper.USER_COLLECTION)
                .doc(uid)
                .delete();
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar(`An error while deleteing user. ${ex}`);
        }

        dispatch(UserManagementActions.setIsLoading(false));
    }
}
