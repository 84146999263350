import { ScoutingAssignmentHelper } from './scoutingAssignment';
import firebase from 'firebase/app';

export interface IScoutingLocationEntry {
    id : string;
    employeeName : string;
    employeeNumber : string;

    /**
     * Employee Path
     */
    employee : firebase.firestore.DocumentReference;

    assignmentId : string;

    /**
     * Assignment Path
     */
    assignment : string;

    date : number;

    latitude : number;
    longitude : number;
    mocked : boolean;
    altitude : number;
    accuracy : number;
    heading : number;
    speed : number;
    speedAccuracy : number;
}

export class ScoutingLocationEntryHelper {
    public static readonly COLLECTION = 'scouting_entries_locations';

    public static converter : firebase.firestore.FirestoreDataConverter<IScoutingLocationEntry> = {
        fromFirestore: (snapshot) => {
            return ScoutingLocationEntryHelper.fromFirestore(snapshot);
        },
        toFirestore: () => {
            throw new Error('Not Implemented');
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IScoutingLocationEntry {
        const data = snapshot.data();

        if (!data) throw new Error('Document not found!');

        return {
            id: snapshot.id,
            date: data['date'] ?? 0,
            employeeName: data['employeeName'],
            employeeNumber: data['employeeNumber'],
            employee: (data['employee'] as firebase.firestore.DocumentReference),
            assignmentId: data['assignmentId'] ?? '',
            assignment: data['assignment'],
            latitude: data['latitude'] ?? 0,
            longitude: data['longitude'] ?? 0,
            accuracy: data['accuracy'] ?? 0,
            mocked: data['mocked'] ?? false,
            altitude: data['altitude'] ?? 0,
            heading: data['heading'] ?? 0,
            speed: data['speed'] ?? 0,
            speedAccuracy: data['speedAccuracy'] ?? 0,
        };
    }

    public static collection(assignmentId : string) {
        return ScoutingAssignmentHelper.collection().doc(assignmentId).collection(this.COLLECTION).withConverter(this.converter);
    }
}
