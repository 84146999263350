import { List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { CROP } from '../../../../appConstants';
import ScoutingFunctions from '../../../../store/scouting/functions';
import { ScoutingType } from '../../../../types/model/scouting/scoutingAssignment';
import ScoutingComponentSelector from './ComponentSelector';
import ScoutingDamageSelector from './DamageSelector';
import ScoutingDiseaseSelector from './DiseaseSelector';
import ScoutingInsectSelector from './InsectSelector';
import ScoutingSectionSelector from './SectionSelector';

interface ISpecificScoutingSelectorsProps extends RouteComponentProps {
    sections ?: Array<string>;
    components ?: Array<string>;
    insects ?: Array<string>;
    diseases ?: Array<string>;
    damages ?: Array<string>;
    onChange ?: (value ?: ScoutingType) => void;
    onSectionsChange ?: (sections ?: Array<string> | null) => void;
    onComponentsChange ?: (sections ?: Array<string> | null) => void;
    onInsectsChange ?: (sections ?: Array<string> | null) => void;
    onDiseasesChange ?: (sections ?: Array<string> | null) => void;
    onDamagesChange ?: (sections ?: Array<string> | null) => void;
}

interface ISpecificScoutingSelectorsState {}

class SpecificScoutingSelectorsComponent extends React.PureComponent<ISpecificScoutingSelectorsProps, ISpecificScoutingSelectorsState> {
    constructor(props : ISpecificScoutingSelectorsProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        this.load();
    }

    public readonly componentDidUpdate = (prevProps : ISpecificScoutingSelectorsProps) => {
        if (prevProps.location.search !== this.props.location.search) {
            this.load();
        }
    }

    public readonly load = () => {
        const crop = this.getCrop(this.props);

        if (crop) {
            ScoutingFunctions.getList(crop);
        }
    }
    private getSearch = (props : ISpecificScoutingSelectorsProps) => props.location.search;

    private getCrop = createSelector(
        [this.getSearch],
        (search) => {
            const urlParams = new URLSearchParams(search);
            if (urlParams.has('crop')) {
                const crop = urlParams.get('crop');
    
                if (crop) {
                    return crop as CROP;
                }
            }

            return null;
        },
    );

    public render = () => {
        const { sections, onSectionsChange,
            components, onComponentsChange,
            insects, onInsectsChange,
            diseases, onDiseasesChange,
            damages, onDamagesChange } = this.props;
        return (
            <div className='fdc pt15 pb15'>
                <List disablePadding dense>
                    <ListItem className={'bclg cw'} key={'SPECIFIC - Section'}>
                        <ListItemText primary={'SPECIFIC - Section'} title={'SPECIFIC - Section'} />
                    </ListItem>
                </List>
                <ScoutingSectionSelector
                    value={sections ?? []}
                    onChange={onSectionsChange}
                />
                <ScoutingComponentSelector
                    sections={sections}
                    components={components}
                    onComponentsChange={onComponentsChange}
                />
                <ScoutingInsectSelector
                    sections={sections}
                    components={components}
                    insects={insects}
                    onInsectsChange={onInsectsChange}
                />
                <ScoutingDiseaseSelector
                    sections={sections}
                    components={components}
                    diseases={diseases}
                    onDiseasesChange={onDiseasesChange}
                />
                <ScoutingDamageSelector
                    sections={sections}
                    components={components}
                    damages={damages}
                    onDamagesChange={onDamagesChange}
                />
            </div>
        );
    }
}


const SpecificScoutingSelectors = withRouter(SpecificScoutingSelectorsComponent);

export default SpecificScoutingSelectors;