import moment from 'moment';
import React, { SVGProps } from 'react';
import { CartesianGrid, Label, LabelList, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { Typography } from '@material-ui/core';

export interface ITrapDataGraphEntry {
    guid : string;
    count : number;
    date : number;
}

interface ITrapDataGraphViewProps {
    values : Array<ITrapDataGraphEntry>;
}

interface ITrapDataGraphViewState {}

export default class TrapDataGraphView extends React.PureComponent<ITrapDataGraphViewProps, ITrapDataGraphViewState> {
    constructor(props : ITrapDataGraphViewProps) {
        super(props);
        this.state = {};
    }

    private readonly getValues = (state : ITrapDataGraphViewState, props : ITrapDataGraphViewProps) => props.values;
    private readonly getData = createSelector([
        this.getValues,
    ], (
        values,
    ) => {
        return lodash.chain(values)
            .orderBy(x => x.date, 'asc')
            .groupBy(x => `Week ${moment.utc(x.date).week()}`)
            .map((x, key) => ({
                week: key,
                count: lodash.sumBy(x, z => z.count),
            }))
            .value();
    });

    private readonly CustomizedLabel : React.FunctionComponent = (
        props : SVGProps<SVGElement>
        & { value ?: string}
        & { children ?: React.ReactNode}
    ) => {
        const { x, y, value } = props;

        return (
            <g className='recharts-layer'>
                <line
                    className='recharts-cartesian-axis-tick-line'
                    x1={x}
                    y1={Number(y) - 2}
                    x2={x}
                    y2={Number(y) - 24}
                    dy={20}
                    stroke={'#CCC'}
                />
                <text
                    x={x}
                    y={y}
                    dy={-30}
                    fill={'#2294FC'}
                    fontSize={17}
                    fontWeight={'bold'}
                    textAnchor='middle'
                >
                    {value}
                </text>
            </g>
        );
    };

    public readonly render = () => {
        const data = this.getData(this.state, this.props);
        
        return (
            !data.length ?
                (
                    <div className='fdc flx1 aic jcc'>
                        <Typography>
                            No Data
                        </Typography>
                    </div>
                ) :
                (
                    <ResponsiveContainer
                        className='flx1'
                    >
                        <LineChart
                            data={data}
                            margin={{
                                bottom: 65,
                                left: 20,
                                right: 20,
                                top: 20,
                            }}
                            
                        >
                            <XAxis
                                dataKey='week'
                                angle={-35}
                                textAnchor='end'
                            >
                                <Label
                                    value='WEEK'
                                    position='bottom'
                                    offset={45}
                                    style={{
                                        fontWeight: 'bold',
                                        color: '#575757'
                                    }}
                                />
                            </XAxis>
                            <YAxis>
                                <Label
                                    value='COUNT'
                                    angle={-90}
                                    position='left'
                                    offset={10}
                                    style={{
                                        fontWeight: 'bold',
                                        color: '#575757'
                                    }}
                                />
                            </YAxis>
                            <CartesianGrid vertical={false} />
                            <Tooltip allowEscapeViewBox={{
                                x: false,
                                y: false,
                            }} />
                            <Line type='monotone' dataKey='count' stroke='#2294FC'>
                                <LabelList content={<this.CustomizedLabel />} />
                            </Line>
                        </LineChart>
                    </ResponsiveContainer>
                )
        );
    }
}
