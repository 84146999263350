import React from 'react';
import { LayerGroup, MapLayerProps, CircleMarker, Polyline, Popup, withLeaflet } from 'react-leaflet';
import lodash from 'lodash';
import { createSelector } from 'reselect';
import AppFunctionsService from '../../../services/appFunctionServices';
import { LatLng } from 'leaflet';
import { IPhenologyLocationEntry, PhenologyLocationEntryHelper } from '../../../types/model/phenology/locationEntry';

interface IPhenologyAssignmentLocationMarkersProps extends MapLayerProps  {
    assignmentId : string;

    finishedPoints ?: Array<LatLng>;
}

interface IPhenologyAssignmentLocationMarkersState {
    locations : Array<IPhenologyLocationEntry>;
}

class PhenologyAssignmentLocationMarkersComponent extends React.PureComponent<IPhenologyAssignmentLocationMarkersProps, IPhenologyAssignmentLocationMarkersState> {
    private listener ?: () => void;
    constructor(props : IPhenologyAssignmentLocationMarkersProps) {
        super(props);
        this.state = {
            locations: [],
        };
    }

    public componentDidMount = () => {
        this.loadLocations();
    }

    public componentWillUnmount = () => {
        if (this.listener) {
            this.listener();
        }
    }

    private loadLocations = () => {
        this.listener = PhenologyLocationEntryHelper.collection(this.props.assignmentId).orderBy('date', 'desc').onSnapshot((snapshot) => {
            const locations = this.state.locations.slice();

            // "added" | "removed" | "modified"
            snapshot.docChanges().forEach((f) => {
                const location = f.doc.data();

                const index = lodash.findIndex(locations, n => n.id === location.id);

                switch (f.type) {
                case 'added':
                    locations.push(location);
                    break;
                case 'modified':
                    locations.splice(index, 1, location);
                    break;
                case 'removed':
                    locations.splice(index, 1);
                    break;
                }
            });

            this.setState({
                locations,
            });
        });
    }

    private getLocations = (props : IPhenologyAssignmentLocationMarkersProps, state : IPhenologyAssignmentLocationMarkersState) => state.locations;
    private getLocationMarkers = createSelector(
        [this.getLocations],
        (locations) => {
            const points : Array<{
                point : LatLng;
                color : string;
                radius : number;
                id : string;
                description : string;
            }> =  locations.map((n, key) => ({
                id: `location_${n.id}`,
                color: key === 0 || key === locations.length - 1 ? '#FF7F00' : 'blue',
                point: new LatLng(n.latitude, n.longitude),
                radius: 3,
                description: AppFunctionsService.formatDateTime(n.date),
            }));

            return points;
        },
    );

    private getLocationPoints = createSelector(
        [this.getLocations],
        (locations) => {
            return {
                positions : locations.map(n => new LatLng(n.latitude, n.longitude)),
                color: 'blue',
            };
        },
    );

    public onLayerRef = () => {
        // TODO: Add to layer control
    }

    public render = () => {
        const locationMarkers = this.getLocationMarkers(this.props, this.state);
        const locationPoints = this.getLocationPoints(this.props, this.state);

        const { finishedPoints = [] } = this.props;
        return (
            <LayerGroup ref={this.onLayerRef}>
                {
                    locationMarkers.map(n => (
                        <CircleMarker key={n.id} center={n.point} radius={n.radius} color={n.color} weight={1} fillOpacity={1}>
                            <Popup>
                                <span>{n.description}</span>
                            </Popup>
                        </CircleMarker>
                    ))
                }
                <Polyline positions={locationPoints.positions} color={locationPoints.color} weight={1} opacity={1} />
                {
                    finishedPoints.map((n, i) => (
                        <CircleMarker key={`${i}_fin_marker`} center={n} radius={8} weight={1} color='blue' fillOpacity={0} />
                    ))
                }
            </LayerGroup>
        );
    }
}

const PhenologyAssignmentLocationMarkers = withLeaflet(PhenologyAssignmentLocationMarkersComponent);

export default PhenologyAssignmentLocationMarkers;
