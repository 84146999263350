import React from 'react';
import getPathLength from 'geolib/es/getPathLength';
import { INematodeAssignment } from '../../../../types/model/nematode/nematodeAssignment';

interface INematodeAssignmentDistanceCellProps {
    assignment : INematodeAssignment;
}

interface INematodeAssignmentDistanceCellState {
    distance ?: number | null;
}

export default class NematodeAssignmentDistanceCell extends React.PureComponent<INematodeAssignmentDistanceCellProps, INematodeAssignmentDistanceCellState> {
    private mounted = true;
    constructor(props : INematodeAssignmentDistanceCellProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadEntry(this.props.assignment);
    }

    public componentWillUnmount = () => {
        this.mounted = false;
    }

    private loadEntry = async (assignment : INematodeAssignment) => {
        if (typeof assignment.distance !== 'undefined') {
            this.setState({
                distance : assignment.distance,
            });
        } else {
            // TODO: Distance calc
        }
    }

    public render = () => {
        const { distance } = this.state;
        return (
            <span>
                {
                    !!distance &&
                    distance.toFixed(2)
                }
            </span>
        );
    }
}
