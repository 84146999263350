import { IGeoPoint, GeoPointHelper } from '../geoPoint';
import { BaseHelper, IBase } from '../../base';
import firebaseApp from '../../../services/firebaseService';
import { PhenologyAssignmentHelper } from './assignment';
import firebase from 'firebase/app';

export interface IPhenologyEntry extends IBase {
    guid : string;
    /** Assignment Path */
    assignmentRef : string;

    results : Array<IPhenologyAssignmentEntryResult>;

    point : IGeoPoint;
    pointGuid : string;
    direction : string;
    imageName : string;

    time : number;
}

export interface IPhenologyAssignmentEntryResult {
    section : string;
    option : string;
    group : string;
    subGroup : string;
    result : IPhenologySectionOptionsOption | null;
}

export interface IPhenologySectionOptionsOption {
    name : string;
    value : number | null;
    imagePath : string | null;
}

export class PhenologyEntryHelper extends BaseHelper {
    public static readonly COLLECTION = 'phenology_assignment_entries';

    public static converter : firebase.firestore.FirestoreDataConverter<IPhenologyEntry> = {
        fromFirestore: (snapshot) => {
            return PhenologyEntryHelper.fromFirestore(snapshot);
        },
        toFirestore: () => {
            throw new Error('Not Implemented');
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IPhenologyEntry {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data || !result) throw new Error('Document not found!');

        return {
            ...result,
            guid: data['guid'],
            point: GeoPointHelper.fromFirestore(data['point']),
            pointGuid: data['pointGuid'],
            assignmentRef: (data['assignmentRef'] as firebase.firestore.DocumentReference).path,
            time: data['time'] ?? 0,
            results: data['results'],
            direction: data['direction'],
            imageName: data['imageName'],
        };
    }

    public static collection(assignmentId : string) {
        return PhenologyAssignmentHelper.collection().doc(assignmentId).collection(this.COLLECTION).withConverter(this.converter);
    }

    public static async batchDelete(assignmentId : string) {
        const snapshot = await PhenologyEntryHelper.collection(assignmentId).get();
        const docs = [...snapshot.docs];

        while(docs.length) {
            const batch = firebaseApp.firestore().batch();
            const deleteDocs = docs.splice(0, 100);

            deleteDocs.forEach(doc => batch.delete(doc.ref));

            batch.commit();
        }
        return ;
    }
}
