export default class ArrayHelper {
    /**
     * Used to compare simple arrays, like string[]/int[]
     * @param arr1 Array one
     * @param arr2 Array two
     */
    public static compareArray<T>(arr1 : Array<T>, arr2 : Array<T>) {
        if (!arr1.length || !arr2.length) return false;

        if (arr1.length !== arr2.length) return false;

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }

        return true;
    }
}
