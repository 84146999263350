import React from 'react';
import { INematodeAssignmentLocationEntry } from '../../../../types/model/nematode/nematodeAssignmentLocationEntry';
import { IScoutingLocationEntry } from '../../../../types/model/scouting/scoutingLocationEntry';
import { createSelector } from 'reselect';
import { LatLng } from 'leaflet';
import AppFunctionsService from '../../../../services/appFunctionServices';
import { CircleMarker, LayerGroup, Polyline, Popup } from 'react-leaflet';

interface IAssignmentLocationHistoryMarkerLayerProps {
    locationEntries : Array<INematodeAssignmentLocationEntry | IScoutingLocationEntry>;
}

interface IAssignmentLocationHistoryMarkerLayerState {}

export default class AssignmentLocationHistoryMarkerLayer extends React.PureComponent<IAssignmentLocationHistoryMarkerLayerProps, IAssignmentLocationHistoryMarkerLayerState> {
    constructor(props : IAssignmentLocationHistoryMarkerLayerProps) {
        super(props);
        this.state = {};
    }

    private getLocations = (props : IAssignmentLocationHistoryMarkerLayerProps, state : IAssignmentLocationHistoryMarkerLayerState) => props.locationEntries;
    private getLocationHistoryMarkers = createSelector(
        [this.getLocations],
        (locations) => {
            const points : Array<{
                point : LatLng;
                color : string;
                radius : number;
                id : string;
                description : string;
            }> =  locations.map((n, key) => ({
                id: `location_${n.id}`,
                color: key === 0 || key === locations.length - 1 ? '#FF7F00' : 'blue',
                point: new LatLng(n.latitude, n.longitude),
                radius: 3,
                description: AppFunctionsService.formatDateTime(n.date),
            }));

            return points;
        },
    );

    private getLocationPoints = createSelector(
        [this.getLocations],
        (locations) => {
            return {
                positions : locations.map(n => new LatLng(n.latitude, n.longitude)),
                color: 'blue',
            };
        },
    );

    public readonly render = () => {
        const locationMarkers = this.getLocationHistoryMarkers(this.props, this.state);
        const locationPoints = this.getLocationPoints(this.props, this.state);

        return (
            <LayerGroup>
                {
                    locationMarkers.map(n => (
                        <CircleMarker key={n.id} center={n.point} radius={n.radius} color={n.color} weight={1} fillOpacity={1}>
                            <Popup>
                                <span>{n.description}</span>
                            </Popup>
                        </CircleMarker>
                    ))
                }
                <Polyline positions={locationPoints.positions} color={locationPoints.color} weight={1} opacity={1} />
            </LayerGroup>
        );
    };
}
