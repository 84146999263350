import firebaseApp from '../../../services/firebaseService';
import { BaseHelper, IBase } from '../../base';
import firebase from 'firebase/app';

export interface IPhenologySpecificTreeEntry extends IBase {
    specificRef : string;
    treeCode : string;
    location : firebase.firestore.GeoPoint;
    locationAccuracy : number;
    elevation : number;
    elevationAccuracy : number;

    guid : string;
    date : string;
    time : string;

    // Key = Section, aka bottom/middle/top
    branches : Record<string, Array<IPhenologySpecificTreeEntryBranch>>
}

export interface IPhenologySpecificTreeEntryBranch {
    branchLength : number;
    spurs : Record<number, number>;
}

export class PhenologySpecificTreeEntryHelper extends BaseHelper {
    public static readonly sections = ['bottom', 'middle', 'top'];
    public static readonly spurs = [2, 3, 4, 5, 6, 7];
    
    public static readonly COLLECTION = 'phenology_specific_entries';

    public static converter : firebase.firestore.FirestoreDataConverter<IPhenologySpecificTreeEntry> = {
        fromFirestore: (snapshot) => {
            return PhenologySpecificTreeEntryHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IPhenologySpecificTreeEntry) => {
            return PhenologySpecificTreeEntryHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IPhenologySpecificTreeEntry {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as IPhenologySpecificTreeEntry,
            ...result,
        };
    }

    protected static toFirestore(data : IPhenologySpecificTreeEntry) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static doc(id ?: string) {
        if (!id) {
            return PhenologySpecificTreeEntryHelper.collection().doc();
        }

        return PhenologySpecificTreeEntryHelper.collection().doc(id);
    }

    public static delete(id : string) {
        return PhenologySpecificTreeEntryHelper.doc(id).delete();
    }

    /**
     * Saves object to firestore using a transaction.
     * @param transaction
     */
    public static saveTransaction(transaction : firebase.firestore.Transaction, data : IPhenologySpecificTreeEntry) {
        if (data.id) {
            transaction.set(this.collection().doc(data.id), data);
        } else {
            transaction.set(this.collection().doc(), data);
        }
    }

    public static save(entry : IPhenologySpecificTreeEntry) {
        if (entry.id) {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(entry.id).set(entry);
        }

        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc().set(entry);
    }
}