import React from 'react';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import ScoutingEntrySectionEntityView from './ScoutingEntrySectionEntityView';
import lodash from 'lodash';
import { createSelector } from 'reselect';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../customComponents/tab/TabPanel';
import { IScoutingEntrySectionEntity } from '../../../types/model/scouting/scoutingEntry';

interface IScoutingEntrySectionViewProps {
    title : string;
    values : Array<IScoutingEntrySectionEntity>;
}

interface IScoutingEntrySectionViewState {
    tabIndex : string;
}

export default class ScoutingEntrySectionView extends React.PureComponent<IScoutingEntrySectionViewProps, IScoutingEntrySectionViewState> {

    constructor(props : IScoutingEntrySectionViewProps) {
        super(props);
        this.state = {
            tabIndex: 'insects',
        };
    }

    private getValues = (props : IScoutingEntrySectionViewProps) => props.values;
    private getEntries = createSelector(
        [this.getValues],
        (values) => {
            if (!values.length) return {};

            return lodash.groupBy(values, x => x.type);
        },
    );

    private onTabChange = (event : React.ChangeEvent<unknown>, value : string) => {
        this.setState({
            tabIndex: value,
        });
    }

    public render = () => {
        const { tabIndex } = this.state;
        const { title } = this.props;

        const entries = this.getEntries(this.props);
        return (
            <div className='fdc ais mb5'>
                <AppBar className='fdc ais' variant='outlined' position='static'>
                    <Toolbar variant='dense' className={'fdr aic jcc'}>
                        <Typography className={'cw'} variant='h6'>
                            {title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Tabs value={tabIndex} variant='fullWidth' onChange={this.onTabChange} aria-label='EntitiesTab'>
                    {
                        Object.keys(entries).map(z => (
                            <Tab value={z} key={`${title}_section_entry_${z}_tab`} label={z} />
                        ))
                    }
                </Tabs>
                {
                    Object.keys(entries).map(z => (
                        <TabPanel key={`${title}_section_entry_${z}_tabpanel`} value={z} index={tabIndex}>
                            <ScoutingEntrySectionEntityView entries={entries[z]} />
                        </TabPanel>
                    ))
                }
            </div>
        );
    }
}
