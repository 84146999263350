import React from 'react';
import { connect } from 'react-redux';
import 'leaflet/dist/leaflet.css';
import { IRootState, RootAction, DispatchCall } from '../../../@types/redux';
import ScoutingFunctions from '../../../store/scouting/functions';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import MaterialTable from '../../customComponents/materialTable/Table';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import AppFunctionsService from '../../../services/appFunctionServices';
import AssignmentDialog from '../dialog/AssignmentDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import AssignmentInfoDialog from '../dialog/AssignmentInfoDialog';
import { withRouter, RouteComponentProps } from 'react-router';
import { createSelector } from 'reselect';
import DeleteDialog from '../../customComponents/dialog/DeleteDialog';
import { Dispatch, bindActionCreators } from 'redux';
import ScoutingActions from '../../../store/scouting/actions';
import { IScoutingAssignment, ScoutingAssignmentHelper } from '../../../types/model/scouting/scoutingAssignment';
import ScoutingStartCell from '../../customComponents/table/ScoutingStartCell';
import moment from 'moment';
import ScoutingAssignmentDistanceCell from '../../customComponents/materialTable/ScoutingAssignmentDistanceCell';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import StandardFab from '../../customComponents/button/StandardFab';
import NavFunctions from '../../../store/nav/functions';
import ScoutingAssignmentDateFilter from '../../customComponents/materialTable/ScoutingAssignmentDateFilter';
import lodash from 'lodash';
import ScoutingAssignmentEmployeeFilter from '../../customComponents/materialTable/ScoutingAssignmentEmployeeFilter';
import { IconButton } from '@material-ui/core';
import { IUser } from '../../../types/model/user';
import GeneralFunctions from '../../../store/general/functions';
import ScoutingAssignmentLandNameFilter from '../../customComponents/materialTable/ScoutingAssignmentLandNameFilter';
import { CROP } from '../../../appConstants';
import ScoutingAssignmentBlockNameFilter from '../../customComponents/materialTable/ScoutingAssignmentBlockNameFilter';

interface IScoutingAssignmentListProps extends RouteComponentProps {
    isLoading : boolean;
    assignments : Array<IScoutingAssignment>;

    setLoading : DispatchCall<boolean>;
}

interface IScoutingAssignmentListState {
    date ?: moment.Moment;
    startedDate ?: moment.Moment;
    finishedDate ?: moment.Moment;
    employee ?: IUser;
    landName ?: string;
    blockName ?: string;

    selectedAssignments : Array<IScoutingAssignment>;
    onlyUnfinished : boolean;
}

class AssignmentList extends React.Component<IScoutingAssignmentListProps, IScoutingAssignmentListState> {
    constructor(props : IScoutingAssignmentListProps) {
        super(props);
        this.state = {
            selectedAssignments: [],
            onlyUnfinished: false,
        };
    }

    public readonly componentDidMount = () => {
        this.loadAssignments(this.state.onlyUnfinished, true);
    }

    public readonly componentDidUpdate = (prevProps : IScoutingAssignmentListProps, prevState : IScoutingAssignmentListState) => {
        if (prevProps.location.search !== this.props.location.search || prevState.onlyUnfinished !== this.state.onlyUnfinished) {
            this.loadAssignments(this.state.onlyUnfinished, true);
        }
    }

    public readonly loadAssignments = (unfinishedOnly : boolean, refresh ?: boolean) => {
        const urlParams = new URLSearchParams(this.props.location.search);

        const crop = urlParams.get('crop');

        if (crop) {
            ScoutingFunctions.getAssignments(crop, unfinishedOnly, refresh);
        }
    }

    private readonly getSearch = (props : IScoutingAssignmentListProps) => props.location.search;
    private readonly getAssignments = (props : IScoutingAssignmentListProps) => props.assignments;
    private readonly getDate = (props : IScoutingAssignmentListProps, state : IScoutingAssignmentListState) => state.date;
    private readonly getStartedDate = (props : IScoutingAssignmentListProps, state : IScoutingAssignmentListState) => state.startedDate;
    private readonly getFinishedDate = (props : IScoutingAssignmentListProps, state : IScoutingAssignmentListState) => state.finishedDate;
    private readonly getEmployee = (props : IScoutingAssignmentListProps, state : IScoutingAssignmentListState) => state.employee;
    private readonly getLandName = (props : IScoutingAssignmentListProps, state : IScoutingAssignmentListState) => state.landName;
    private readonly getBlockName = (props : IScoutingAssignmentListProps, state : IScoutingAssignmentListState) => state.blockName;
    private readonly getOnlyUnfinished = (props : IScoutingAssignmentListProps, state : IScoutingAssignmentListState) => state.onlyUnfinished;
    private readonly getFilteredAssignments = createSelector([
        this.getAssignments,
        this.getDate,
        this.getStartedDate,
        this.getFinishedDate,
        this.getEmployee,
        this.getLandName,
        this.getBlockName,
        this.getOnlyUnfinished,
    ], (
        assignments,
        date,
        startedDate,
        finishedDate,
        employee,
        landName,
        blockName,
        onlyUnfinished,
    ) => {
        return assignments
            .filter(n => !date || n.date === date.valueOf())
            .filter(n => !startedDate || (n.taskCompletions && moment.utc(lodash.min(n.taskCompletions) ?? 0).startOf('day').isSame(startedDate)))
            .filter(n => !finishedDate || moment.utc(n.finishedOn).startOf('day').isSame(finishedDate))
            .filter(n => !employee || employee.employeeNumber === n.employeeNumber || employee.name === n.employeeName)
            .filter(n => !landName || landName === n.landName)
            .filter(n => !blockName || blockName === n.blockName)
            .filter(n => !onlyUnfinished || !n.finished);
    });

    private readonly getCrop = createSelector([
        this.getSearch,
    ], (
        search,
    ) => {
        const urlParams = new URLSearchParams(this.props.location.search);

        return urlParams.get('crop') as CROP;
    });

    private readonly onDelete = async (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        try {
            this.props.setLoading(true);
            await ScoutingAssignmentHelper.bulkDelete([event.currentTarget.value]);
            GeneralFunctions.generalShowSuccessSnackbar('Deleted');
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error deleting assignment', ex);
        } finally {
            this.props.setLoading(false);
        }
    }

    private readonly onAddClick = () => {
        this.setState({
            onlyUnfinished: false,
        });
        NavFunctions.navPath('/scouting/assignments/create', this.props.location.search);
    }

    private readonly onDateChange = (date ?: moment.Moment) => {
        this.setState({
            date: date?.startOf('day'),
        });
    }

    private readonly onStartDateChange = (date ?: moment.Moment) => {
        this.setState({
            startedDate: date?.startOf('day'),
        });
    }

    private readonly onFinishedDateChange = (date ?: moment.Moment) => {
        this.setState({
            finishedDate: date?.startOf('day'),
        });
    }

    private readonly onEmployeeChange = (employee ?: IUser) => {
        this.setState({
            employee,
        });
    }

    private readonly onLandNameChange = (landName ?: string) => {
        this.setState({
            landName,
        });
    }

    private readonly onBlockNameChange = (blockNames : Array<string>) => {
        this.setState({
            blockName: !blockNames.length ? undefined : blockNames[0],
        });
    }

    private readonly onSelectionChange = (selectedAssignments : Array<IScoutingAssignment>) => {
        this.setState({
            selectedAssignments: selectedAssignments.filter(x => !x.finished),
        });
    }

    private readonly onShowOnlyCompletedClick = () => {
        this.setState({
            onlyUnfinished: !this.state.onlyUnfinished,
        });
    }

    private readonly onDeleteSelected = async () => {
        try {
            this.props.setLoading(true);
            await ScoutingAssignmentHelper.bulkDelete(this.state.selectedAssignments.map(x => x.id));
            GeneralFunctions.generalShowSuccessSnackbar('Deleted');
            this.onSelectionChange([]);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error deleting assignments', ex);
        } finally {
            this.props.setLoading(false);
        }
    }

    public readonly render = () => {
        const { isLoading, assignments } = this.props;
        const {
            date,
            startedDate,
            finishedDate,
            employee,
            selectedAssignments,
            onlyUnfinished,
            landName,
            blockName,
        } = this.state;

        const filteredAssignments = this.getFilteredAssignments(this.props, this.state);
        const crop = this.getCrop(this.props);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'fdc flx1 mh0 mw0'}>
                    <Toolbar>
                        <span className={'flx1'} />
                        <div className='mr15'>
                            <Tooltip title='Show Only Unfinshed'>
                                <IconButton onClick={this.onShowOnlyCompletedClick} className={onlyUnfinished ? 'bcp cw' : ''}>
                                    <Icon>
                                        block
                                    </Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className={'fdr jcfe aic'}>
                            <Typography className='fs16 fw700 cpd'>
                                Total: {assignments.length}
                            </Typography>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<IScoutingAssignment>
                            id='assignmentTable'
                            data={filteredAssignments}
                            isLoading={isLoading}
                            rowsPerPage={50}
                            selectable
                            selectedRows={selectedAssignments}
                            onSelectionChanged={this.onSelectionChange}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 145,
                                renderCell: row => (
                                    <div className='aic'>
                                        <AssignmentDialog crop={row.crop} value={row} fullWidth transition={Transitions.Down} isLoading={isLoading} maxWidth='md' />
                                        <AssignmentInfoDialog value={row} fullWidth transition={Transitions.Up} maxWidth='md' fullScreen />
                                        <DeleteDialog maxWidth='md' disabled={row.finished } isLoading={isLoading}
                                            message={`Delete assignment for ${row.landName} - ${row.blockName} on ${AppFunctionsService.formatDateTimeToDateOnly(row.date)}?`}
                                            onConfirm={this.onDelete} title={'Delete'} value={row.id} transition={Transitions.Down}
                                        />
                                    </div>
                                ),
                            },        {
                                header: 'Type',
                                field: 'type',
                                width: 120,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (<span>{AppFunctionsService.toTitleCase(row.type)}</span>),
                            },        {
                                header: 'Date',
                                field: 'date',
                                width: 120,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (<span>{AppFunctionsService.formatDateTimeToDateOnly(row.date)}</span>),
                                filtered: !!date,
                                filterRender: () => (<ScoutingAssignmentDateFilter value={date} onChange={this.onDateChange} id='date_filter' />),
                            },        {
                                header: 'Started On',
                                field: 'taskCompletions[0]',
                                enableSort: true,
                                enableFilter: true,
                                width: 140,
                                filterRender: () => (<ScoutingAssignmentDateFilter value={startedDate} onChange={this.onStartDateChange} id='start_date_filter' />),
                                filtered: !!startedDate,
                                renderCell: row => (
                                    <ScoutingStartCell taskCompletions={row.taskCompletions} />
                                ),
                            },        {
                                header: 'Finished On',
                                field: 'finishedOn',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                filtered: !!finishedDate,
                                filterRender: () => (<ScoutingAssignmentDateFilter value={finishedDate}
                                    onChange={this.onFinishedDateChange} id='finished_date_filter' />),
                                renderCell: row => (
                                    <span>
                                        {
                                            !!row.finishedOn &&
                                            <Tooltip title={AppFunctionsService.formatDateTime(row.finishedOn)}>
                                                <div>
                                                    {
                                                        AppFunctionsService.formatDateTimeToDateOnly(row.finishedOn)
                                                    }
                                                </div>
                                            </Tooltip>
                                        }
                                    </span>
                                ),
                            },        {
                                header: 'Scout',
                                field: 'employeeName',
                                enableSort: true,
                                enableFilter: true,
                                width: 150,
                                renderCell: row => (
                                    <Tooltip title={row.employeeNumber ?? ''}>
                                        <div>
                                            {row.employeeName}
                                        </div>
                                    </Tooltip>
                                ),
                                filtered: !!employee,
                                filterRender: () => (<ScoutingAssignmentEmployeeFilter value={employee}
                                    onChange={this.onEmployeeChange} id='employee_filter' />),
                            },        {
                                header: 'Land Name',
                                field: 'landName',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                filterRender: () => (<ScoutingAssignmentLandNameFilter value={landName}
                                    onChange={this.onLandNameChange} id='land_filter' cropType={crop} />),
                            },        {
                                header: 'Block Name',
                                field: 'blockName',
                                width: 160,
                                enableSort: true,
                                enableFilter: true,
                                filterRender: () => (<ScoutingAssignmentBlockNameFilter value={blockName}
                                    onChange={this.onBlockNameChange} id='block_filter' cropType={crop} landName={landName} />),
                            },        {
                                header: 'KM Walked',
                                field: 'landName',
                                width: 100,
                                renderCell: row => (
                                    <ScoutingAssignmentDistanceCell assignment={row} />
                                ),
                            },        {
                                header: 'Ha',
                                field: 'scoutingBlock.ha',
                                width: 100,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (row.scoutingBlock.ha?.toFixed(2) ?? '-'),
                            },        {
                                header: 'No Points',
                                field: 'finished',
                                width: 100,
                                align: 'center',
                                renderCell: row => (Object.keys(row.scoutingBlock.points).length),
                            },        {
                                header: 'Total Time',
                                field: 'taskCompletions[0]',
                                width: 100,
                                align: 'center',
                                renderCell: row => (
                                    !!row.taskCompletions &&
                                    !!row.finishedOn &&
                                    AppFunctionsService.millisecondsFormat((row.finishedOn) - (lodash.min(row.taskCompletions) ?? 0))
                                ),
                            },        {
                                header: 'Finished',
                                field: 'finished',
                                width: 75,
                                enableSort: true,
                                align: 'center',
                                renderCell: row => (
                                    <Tooltip title={`${row.finishedPoints.length} / ${Object.keys(row.scoutingBlock.points).length}`}>
                                        <Icon className={`${!row.finished ? 'cg1' : 'cp'}`}>
                                            check_circle
                                        </Icon>
                                    </Tooltip>
                                ),
                            },        {
                                header: 'Created By',
                                field: 'createdByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.createdOn)}>
                                        <span>{row.createdByName}</span>
                                    </Tooltip>),
                            },        {
                                header: 'Updated By',
                                field: 'updatedByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.updatedOn)}>
                                        <span>{row.updatedByName}</span>
                                    </Tooltip>),
                            }]}
                        />
                    </Card>
                    {
                        !!selectedAssignments.length &&
                        <DeleteDialog maxWidth='md' disabled={!selectedAssignments.length} isLoading={isLoading}
                            message={`Delete ${selectedAssignments.length} assignments?`}
                            onConfirm={this.onDeleteSelected} title={'Delete'} transition={Transitions.Down}
                        >
                            {
                                onClick => (
                                    <StandardFab aria-label='add' className='cw bcr mr80' disabled={isLoading} onClick={onClick}>
                                        <DeleteIcon />
                                    </StandardFab>
                                )
                            }
                        </DeleteDialog>
                    }
                    <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                        <AddIcon />
                    </StandardFab>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.scouting.isLoadingAssignments,
        assignments: state.scouting.assignments,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators({
    setLoading: (isLoading : boolean) => dispatcher(ScoutingActions.setLoadingAssignments(isLoading)),
}, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(AssignmentList));
