import React from 'react';
import MaterialTable from '../../../customComponents/table/MaterialTable';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { IBlock } from '../../../../types/model/masterData/block';

interface IShapefileTableViewProps {
    blocks : Array<IBlock>;
    selectedBlocks ?: Array<IBlock>;

    currentBlocks : Array<IBlock>;

    cropType ?: string;

    onSelectionChanged ?: (selectedBlocks : Array<IBlock>) => void;

    isLoading : boolean;
}

interface IShapefileTableViewState {
}

export default class ShapefileTableView extends React.PureComponent<IShapefileTableViewProps, IShapefileTableViewState> {
    constructor(props : IShapefileTableViewProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { blocks, selectedBlocks = [], onSelectionChanged, currentBlocks, isLoading } = this.props;
        return (
            <MaterialTable<IBlock>
                id='blocksTable'
                selectable={!!onSelectionChanged}
                selectedRows={selectedBlocks}
                onSelectionChanged={onSelectionChanged}
                data={blocks}
                rowsPerPage={50}
                isLoading={isLoading}
                columns={[{
                    header: '',
                    minWidth: 50,
                    paddingRight: 4,
                    renderCell: row => (
                        <div className='aic'>
                            {
                                currentBlocks.some(n => n.id === row.id) &&
                                <Tooltip title='Guid In Use'>
                                    <div>
                                        <Icon className='co'>
                                            warning
                                        </Icon>
                                    </div>
                                </Tooltip>
                            }
                            {
                                currentBlocks.filter(n => n.id === row.id).length > 1 &&
                                <Tooltip title='Duplicate'>
                                    <div>
                                        <Icon className='cpr'>
                                            warning
                                        </Icon>
                                    </div>
                                </Tooltip>
                            }
                            {
                                Object.keys(row.points).length === 0 &&
                                <Tooltip title='No Points'>
                                    <div>
                                        <Icon className='cpr'>
                                            info
                                        </Icon>
                                    </div>
                                </Tooltip>
                            }
                        </div>
                    ),
                },        {
                    header: 'Division',
                    field: 'division',
                    minWidth: 175,
                    enableSort: true,
                    enableFilter: true,
                },        {
                    header: 'Land Name',
                    field: 'landName',
                    minWidth: 175,
                    enableSort: true,
                    enableFilter: true,
                },        {
                    header: 'Name',
                    field: 'name',
                    minWidth: 70,
                    enableSort: true,
                    enableFilter: true,
                },        {
                    header: 'Points',
                    field: 'points',
                    minWidth: 75,
                    enableSort: true,
                    align: 'center',
                    renderCell: row => (Object.keys(row.points).length),
                },        {
                    header: 'Phen. Points',
                    field: 'points',
                    minWidth: 75,
                    enableSort: true,
                    align: 'center',
                    renderCell: row => (Object.keys(row.phenologyPoints).length),
                },        {
                    header: 'Crop',
                    field: 'crop',
                    minWidth: 70,
                    enableSort: true,
                    enableFilter: true,
                }]}
            />
        );
    }
}
