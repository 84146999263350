import lodash from 'lodash';
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogActions, Typography } from '@material-ui/core';
import OutlinedStadiumButton from '../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../customComponents/button/StadiumButton';
import { IRootState } from '../../../@types/redux';
import { IScouting, IScoutingSection } from '../../../types/model/masterData/scouting';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { CROP } from '../../../appConstants';
import { IDisease } from '../../../types/model/masterData/disease';
import { IDamage } from '../../../types/model/masterData/damage';
import { IInsect } from '../../../types/model/masterData/insect';

interface ISpecificScoutingAssignmentAlertDialogProps extends RouteComponentProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    selectedSections : Array<string>;
    selectedComponents : Array<string>;
    selectedInsects : Array<string>;
    selectedDiseases : Array<string>;
    selectedDamages : Array<string>;

    insects : Array<IInsect>;
    diseases : Array<IDisease>;
    damages : Array<IDamage>;

    onChange ?: (
        sections : Array<string>,
        components : Array<string>,
        insects : Array<string>,
        diseases : Array<string>,
        damages : Array<string>
    ) => void;

    onContinueClick ?: () => void;
    
    scoutings : Array<IScouting>;
    isLoading : boolean;
}

interface ISpecificScoutingAssignmentAlertDialogState {
    open : boolean;
    prevProps ?: Readonly<ISpecificScoutingAssignmentAlertDialogProps>;
}

class SpecificScoutingAssignmentAlertDialogComponent extends React.PureComponent<ISpecificScoutingAssignmentAlertDialogProps, ISpecificScoutingAssignmentAlertDialogState> {
    constructor(props : ISpecificScoutingAssignmentAlertDialogProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    public componentDidUpdate(prevProps : Readonly<ISpecificScoutingAssignmentAlertDialogProps>) : void {
        if (!this.props.isLoading && !this.state.open) {
            this.check(prevProps);
        }
    }

    private readonly getData = (props : ISpecificScoutingAssignmentAlertDialogProps) => props.scoutings;
    private readonly getInsects = (props : ISpecificScoutingAssignmentAlertDialogProps) => props.insects;
    private readonly getDiseases = (props : ISpecificScoutingAssignmentAlertDialogProps) => props.diseases;
    private readonly getDamages = (props : ISpecificScoutingAssignmentAlertDialogProps) => props.damages;
    private readonly getSearch = (props : ISpecificScoutingAssignmentAlertDialogProps) => props.location.search;

    private getCrop = createSelector(
        [this.getSearch],
        (search) => {
            const urlParams = new URLSearchParams(search);
            if (urlParams.has('crop')) {
                const crop = urlParams.get('crop');
    
                if (crop) {
                    return crop as CROP;
                }
            }

            return null;
        },
    );

    private readonly getAllSections = createSelector(
        [this.getData, this.getCrop],
        (scoutings, crop) => {
            return scoutings.find(x => x.crop === crop)?.sections.map(x => x.name) ?? [];
        },
    );

    private readonly getAllComponents = createSelector(
        [this.getData, this.getCrop, this.getAllSections],
        (scoutings, crop, allSections) => {
            const sections : Array<IScoutingSection> = scoutings
                .find(x => x.crop === crop)?.sections
                .filter(x => allSections.includes(x.name)) ?? [];

            return lodash
                .chain(sections)
                .flatMap(x => x.entries)
                .uniqBy(x => x.name)
                .map(n => n.name)
                .value();
        },
    );

    private readonly getAllInsects = createSelector(
        [
            this.getInsects,
            this.getData,
            this.getCrop,
            this.getAllSections,
            this.getAllComponents,
        ],
        (
            insects,
            scoutings,
            crop,
            allSections,
            allComponents
        ) => {
            const sections : Array<IScoutingSection> = scoutings
                .find(x => x.crop === crop)?.sections
                .filter(x => allSections.includes(x.name)) ?? [];

            const ids : Array<string> = lodash
                .chain(sections)
                .flatMap(x => x.entries)
                .filter(x => allComponents.includes(x.name))
                .flatMap(x => x.checks.insects)
                .map(x => x.ref?.id ?? '')
                .uniq()
                .value();

            return insects.filter(x => ids.includes(x.id)).map(x => x.name);
        },
    );

    private readonly getAllDiseases = createSelector(
        [
            this.getDiseases,
            this.getData,
            this.getCrop,
            this.getAllSections,
            this.getAllComponents,
        ],
        (
            diseases,
            scoutings,
            crop,
            allSections,
            allComponents
        ) => {
            const sections : Array<IScoutingSection> = scoutings
                .find(x => x.crop === crop)?.sections
                .filter(x => allSections.includes(x.name)) ?? [];

            const ids : Array<string> = lodash
                .chain(sections)
                .flatMap(x => x.entries)
                .filter(x => allComponents.includes(x.name))
                .flatMap(x => x.checks.disease)
                .map(x => x.ref?.id ?? '')
                .uniq()
                .value();

            return diseases.filter(x => ids.includes(x.id)).map(x => x.name);
        },
    );

    private readonly getAllDamages = createSelector(
        [
            this.getDamages,
            this.getData,
            this.getCrop,
            this.getAllSections,
            this.getAllComponents,
        ],
        (
            damages,
            scoutings,
            crop,
            allSections,
            allComponents
        ) => {
            const sections : Array<IScoutingSection> = scoutings
                .find(x => x.crop === crop)?.sections
                .filter(x => allSections.includes(x.name)) ?? [];

            const ids : Array<string> = lodash
                .chain(sections)
                .flatMap(x => x.entries)
                .filter(x => allComponents.includes(x.name))
                .flatMap(x => x.checks.damage)
                .map(x => x.ref?.id ?? '')
                .uniq()
                .value();

            return damages.filter(x => ids.includes(x.id)).map(x => x.name);
        },
    );

    public check = (prevProps : Readonly<ISpecificScoutingAssignmentAlertDialogProps>) => {
        const allSections = this.props.selectedSections.length === this.getAllSections(this.props).length;
        const allComponents = this.props.selectedComponents.length === this.getAllComponents(this.props).length;
        const allInsects = this.props.selectedInsects.length === this.getAllInsects(this.props).length;
        const allDiseases = this.props.selectedDiseases.length === this.getAllDiseases(this.props).length;
        const allDamages = this.props.selectedDamages.length === this.getAllDamages(this.props).length;

        if (
            allSections &&
            allComponents &&
            allInsects &&
            allDiseases &&
            allDamages) {
            this.setState({
                open: true,
                prevProps,
            });
        }
    }

    public onClick = () => {
        this.setState({
            open: true,
        });
    }

    public onClose = () => {
        this.setState({
            open: false,
        });
    }

    public onBackClick = () => {
        this.setState({
            open: false,
        });

        if (!this.props.onChange) return;
        const { prevProps } = this.state;

        if (!prevProps) return;

        this.props.onChange(
            prevProps.selectedSections,
            prevProps.selectedComponents,
            prevProps.selectedInsects,
            prevProps.selectedDiseases,
            prevProps.selectedDamages,
        );
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, onContinueClick } = this.props;
        const { open } = this.state;
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='tooltip-dialog-title'
                aria-describedby='tooltip-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            REMINDER
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdr flx1 aic'>
                    <div className='fdc mr10'>
                        <Icon className='co'>
                            warning
                        </Icon>
                    </div>
                    <Typography className='co'>
                        You have selected all fields therefore will be changed to general scouting
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <OutlinedStadiumButton color='primary' variant='outlined' onClick={this.onBackClick}>
                        BACK
                    </OutlinedStadiumButton>
                    <StadiumButton color='primary' variant='contained' autoFocus onClick={onContinueClick}>
                        CONTINUE
                    </StadiumButton>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        scoutings: state.scouting.scoutings,
        isLoading: state.scouting.isLoading
        || state.masterData.insect.isLoading
        || state.masterData.disease.isLoading
        || state.masterData.damage.isLoading,
        insects: state.masterData.insect.insects,
        diseases: state.masterData.disease.diseases,
        damages: state.masterData.damage.damages,
    };
};

const SpecificScoutingAssignmentAlertDialog = connect(
    mapStateToProps,
)(withRouter(SpecificScoutingAssignmentAlertDialogComponent));

export default SpecificScoutingAssignmentAlertDialog;
