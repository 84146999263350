import { AppBar, Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, IconButton, Switch, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { Transitions } from '../../../../customComponents/animations/Transitions';
import { Close, Save } from '@material-ui/icons';
import GeneralFunctions from '../../../../../store/general/functions';
import { ITrapEntryComment } from '../../../../../types/model/masterData/trap/trapEntryComment';
import TrapEntryCommentFunctions from '../../../../../store/masterData/trap/trapEntryComment/functions';
import { CROP } from '../../../../../appConstants';
import CropAutocomplete from '../../../../customComponents/autocomplete/masterData/CropAutocomplete';
import TrapTypeDropdown from '../../../../customComponents/dropdowns/trap/TrapTypeDropdown';
import { ITrapType } from '../../../../../types/model/masterData/trap/trapType';

interface ITrapEntryCommentEditDialogProps {
    value : ITrapEntryComment | null;

    isLoading ?: boolean;

    onClose ?: () => void;
}

interface ITrapEntryCommentEditDialogState {
    comment : string;
    crop : CROP | null;
    trapTypeName : string | null;
    trapTypeId : string | null;
    isActive : boolean;
}

export default class TrapEntryCommentEditDialog extends React.PureComponent<ITrapEntryCommentEditDialogProps, ITrapEntryCommentEditDialogState> {
    constructor(props : ITrapEntryCommentEditDialogProps) {
        super(props);
        this.state = {
            comment: '',
            trapTypeName: '',
            trapTypeId: '',
            crop: null,
            isActive: true,
        };
    }

    public componentDidUpdate(prevProps : Readonly<ITrapEntryCommentEditDialogProps>) : void {
        if (!prevProps.value && this.props.value) {
            this.setState({
                comment: this.props.value.comment,
                trapTypeId: this.props.value.trapTypeId,
                trapTypeName: this.props.value.trapTypeName,
                crop: this.props.value.crop,
                isActive: this.props.value.isActive,
            });
        }
    }

    private readonly onCommentChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            comment: e.target.value,
        });
    }

    private readonly onTrapTypeChange = (trapType ?: ITrapType | null) => {
        this.setState({
            trapTypeId: trapType?.id ?? null,
            trapTypeName: trapType?.name ?? null,
        });
    }

    private readonly onCropChange = (crop ?: CROP | null) => {
        this.setState({
            crop: crop ?? null,
        });
    }

    private readonly save = async () => {
        try {
            const {
                value,
            } = this.props;
            if (!value) return;
    
            const {
                comment,
                trapTypeId,
                crop,
                trapTypeName,
                isActive,
            } = this.state;

            if (!comment) return;
            if (!trapTypeId) return;
            if (!trapTypeName) return;
            if (!crop) return;
    
            await TrapEntryCommentFunctions.save({
                ...value,
                comment,
                trapTypeId,
                trapTypeName,
                crop,
                isActive,
            });

            if (this.props.onClose) this.props.onClose();
            GeneralFunctions.generalShowSuccessSnackbar('Saved');
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error saving comment', ex);
        }
    }

    private readonly onSaveClick = () => {
        this.save();
    }

    private readonly onIsActiveChange = () => {
        this.setState({
            isActive: !this.state.isActive,
        });
    }

    public render = () => {
        const {
            value,
            isLoading,
            onClose,
        } = this.props;

        const {
            comment,
            trapTypeId,
            crop,
            isActive,
        } = this.state;

        return (
            <Dialog
                open={!!value}
                TransitionComponent={Transitions.Down}
                transitionDuration={500}
                maxWidth={'md'}
                fullWidth
                aria-labelledby='edit-trap-entry-comment-description-dialog-title'
                aria-describedby='edit-trap-entry-comment-description'>
                <AppBar className='fdr posr aic jcc bcpd' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography>
                            {value?.id ? 'EDIT' : 'ADD'} TRAP ENTRY COMMENT
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={onClose} aria-label='Close'>
                                    <Close />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className={'fdr ais jcfs'}>
                        <div className={'fdc flx1 jcfs mr10'}>
                            <CropAutocomplete
                                required
                                autoFocus
                                fullWidth
                                value={crop}
                                onChange={this.onCropChange}
                                label={'Select Crop'}
                                disabled={isLoading}
                            />
                        </div>
                        <div className={'fdc flx1 jcfs mr10 ml10'}>
                            <TrapTypeDropdown
                                crop={crop ?? 'almonds'}
                                required
                                autoFocus
                                fullWidth
                                value={trapTypeId}
                                onChange={this.onTrapTypeChange}
                                label={'Select TrapType'}
                                disabled={isLoading}
                            />
                        </div>
                        <div className={'fdc flx1 jcfs mr10 ml10'}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete='off'
                                    required
                                    error={!comment}
                                    id='comment'
                                    label='Comment'
                                    type='text'
                                    fullWidth
                                    value={comment}
                                    onChange={this.onCommentChange}
                                    disabled={isLoading}
                                />
                                {
                                    !comment &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className={'fdr flx1 aic ml10'} >
                            <Typography className='pr6' >Is Active?</Typography>
                            <Switch
                                id={'isActive'}
                                name={'isActive'}
                                checked={isActive}
                                color='primary'
                                onChange={this.onIsActiveChange}
                                inputProps={{
                                    'aria-label': 'Is Active',
                                    id: 'isActive',
                                }}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading} onClick={this.onSaveClick}>
                        <Save style={{ marginRight: '10px' }} />
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
