import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { createSelector } from 'reselect';
import AppFunctionsService from '../../../services/appFunctionServices';
import Typography from '@material-ui/core/Typography';

interface IRouteBreadCrumbsProps extends RouteComponentProps<Record<string, never>, never, never> {

}

interface IRouteBreadCrumbsState {}

class RouteBreadCrumbs extends React.PureComponent<IRouteBreadCrumbsProps, IRouteBreadCrumbsState> {
    constructor(props : IRouteBreadCrumbsProps) {
        super(props);
        this.state = {};
    }

    private getLocation = (state : IRouteBreadCrumbsState, props : IRouteBreadCrumbsProps) => props.location;
    private getPath = createSelector(
        [this.getLocation],
        (location) => {
            const paths = location.pathname.substring(1).split('/');
            const urlParams = new URLSearchParams(this.props.location.search);

            urlParams.forEach((n, i) => {
                paths.push(n);
            });
            return paths
                .map(x => {
                    if (!x) return '';

                    const result : string[] = [];

                    x.split('_').forEach((n) => {
                        result.push(n);
                    });

                    return AppFunctionsService.toTitleCase(result.join(' '));
                })
                .join(' > ');
        },
    );

    public render = () => {
        const path = this.getPath(this.state, this.props);
        return (
            <Typography className='cy fs14 mt5'>
                {path}
            </Typography>
        );
    }
}

export default withRouter(RouteBreadCrumbs);
