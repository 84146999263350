import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { TransitionProps } from '@material-ui/core/transitions';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { INematodeAssignment, NematodeAssignmentHelper } from '../../../../types/model/nematode/nematodeAssignment';
import { IUser } from '../../../../types/model/user';
import AppFunctionsService from '../../../../services/appFunctionServices';
import ScoutingBlocksMap from '../../../customComponents/map/ScoutingBlocksMap';
import { Button } from '@material-ui/core';
import { IRootState, RootAction } from '../../../../@types/redux';
import GeneralFunctions from '../../../../store/general/functions';

interface INematodeAssignmentEditDialogProps {
    value : INematodeAssignment;

    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
}

interface INematodeAssignmentEditDialogState {
    open : boolean;
    isLoading : boolean;

    date : Moment;
    /**
     * Employee Id
     */
    employee ?: string;
    employeeName ?: string;
    employeeNumber ?: string;
}

class NematodeAssignmentEditDialogComponent extends React.PureComponent<INematodeAssignmentEditDialogProps, INematodeAssignmentEditDialogState> {
    constructor(props : INematodeAssignmentEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            isLoading: false,
            date: moment.utc().startOf('day'),
        };
    }

    public componentDidUpdate = (prevProps : INematodeAssignmentEditDialogProps, prevState : INematodeAssignmentEditDialogState) => {
        if (!prevState.open && this.state.open) {
            this.setState({
                date: moment.utc(this.props.value.date),
                employee: !this.props.value ? undefined : this.props.value.employee,
                employeeNumber: !this.props.value ? undefined : this.props.value.employeeNumber,
                employeeName: !this.props.value ? undefined : this.props.value.employeeName,
            });
        }

    }

    private onIconButtonClick = () => {
        this.setState({
            open: true,
        });
    }

    private onClose = () => {
        if (this.state.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.save();
    }

    private save = async () => {
        const { date, employee, employeeName, employeeNumber } = this.state;
        const { value } = this.props;

        if (!value) return;
        if (!employee || !employeeName || !employeeNumber) return;

        this.setState({
            isLoading: true,
        });
        try {
            await NematodeAssignmentHelper.save({
                ...value,
                date: date.valueOf(),
                employee,
                employeeNumber,
                employeeName,
            });
    
            this.setState({
                isLoading: false,
            });

            this.onClose();
        } catch(ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error saving assignment.', ex);

            this.setState({
                isLoading: false,
            });
        }
    }

    private onDateChange = (momentDate : Moment | null) => {
        if (!momentDate) return;
        this.setState({
            date: momentDate.utc().startOf('day'),
        });
    }

    private onEmployeeChange = (employee ?: IUser) => {
        if (!employee) return;
        this.setState({
            employee: employee.ref,
            employeeName: employee.name,
            employeeNumber: employee.employeeNumber,
        });
    }

    public render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            value,
        } = this.props;
        const {
            open,
            date,
            employee,
            isLoading,
        } = this.state;

        return (
            <React.Fragment>
                <Tooltip title={!value ? 'Add' : 'Edit'}>
                    <div>
                        <IconButton color='primary' onClick={this.onIconButtonClick}
                            aria-label={!value ? 'Add' : 'Edit'} disabled={isLoading || !!value?.finished || !!value?.finishedPoints.length }>
                            <Icon>{!value ? 'add' : 'edit'}</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='scouting-assignment-dialog-title'
                    aria-describedby='scouting-assignment-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {
                                    value &&
                                    `Edit Assignment - ${AppFunctionsService.formatDateTimeToDateOnly(value.date)} -
                                     ${value.landName} -
                                     ${value.blockName}
                                    `
                                }
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc ais flx1'>
                            <div className={'fdr aifs'}>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <KeyboardDatePicker fullWidth value={date} onChange={this.onDateChange} format='YYYY-MM-DD' label='Date' />
                                </div>
                            </div>
                            <div className={'fdr aifs'}>
                            </div>
                            <div className={'h280 flx1 fdc'}>
                                <ScoutingBlocksMap crop={value.crop} division={value.division} landName={value.landName} blockNames={[value.blockName]} />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading || !employee} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
}, dispatch);

const NematodeAssignmentEditDialog = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NematodeAssignmentEditDialogComponent);

export default NematodeAssignmentEditDialog;