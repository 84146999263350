import { Icon, IconButton, List, ListItem, ListItemText, Switch } from '@material-ui/core';
import React from 'react';
import DamageFunctions from '../../../../store/masterData/damage/functions';
import ToggleButton from '../../../customComponents/button/ToggleButton';
import ScoutingDamageMultiselect from '../../../customComponents/dropdowns/scouting/DamageMultiselect';

interface IScoutingDamageSelectorProps {
    sections ?: Array<string>;
    components ?: Array<string>;
    damages ?: Array<string>;
    onDamagesChange ?: (damages ?: Array<string> | null) => void;
}

interface IScoutingDamageSelectorState {
    edit : boolean;
    toggle : boolean;
}

class ScoutingDamageSelector extends React.PureComponent<IScoutingDamageSelectorProps, IScoutingDamageSelectorState> {
    constructor(props : IScoutingDamageSelectorProps) {
        super(props);
        this.state = {
            edit: true,
            toggle: false,
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        DamageFunctions.getList();
    }

    private readonly onClose = () => {
        if (!this.props.damages?.length) return;
        this.setState({
            edit: false,
        });
    }

    private readonly onEditClick = () => {
        this.setState({
            edit: !this.state.edit,
        });
    }

    private readonly onToggleClick = () => {
        this.setState({
            toggle: !this.state.toggle,
            edit: !this.state.toggle,
        });

        if (!this.props.onDamagesChange) return;

        this.props.onDamagesChange([]);
    }

    public render = () => {
        const { sections, damages, components, onDamagesChange } = this.props;
        const { edit, toggle } = this.state;
        return (
            <>
                <List className='mt15' disablePadding dense>
                    <ListItem className={'bcig cw'} key={'SPECIFIC - Damage'}>
                        <ListItemText primary={'SPECIFIC - Damage'} title={'SPECIFIC - Damage'} />
                        {
                            toggle &&
                            !edit &&
                            <IconButton onClick={this.onEditClick} color='secondary' size='small'>
                                <Icon>edit</Icon>
                            </IconButton>
                        }
                        <Switch
                            id='toggle'
                            size='small'
                            checked={toggle}
                            onClick={this.onToggleClick}
                            color='primary'
                            disabled={!components?.length}
                        />
                    </ListItem>
                </List>
                {
                    toggle &&
                    !edit &&
                    damages &&
                    <div className='fdc aic p10'>
                        <div className='fdr jcsb fww'>
                            {
                                damages.map((n, i) => (
                                    <ToggleButton
                                        key={n}
                                        className={`mt15 ${i !== (damages.length - 1) ? 'mr15' : ''}`}
                                        value={`${n}`}
                                        selected
                                        disabled
                                    >
                                        {`${n}`.toLocaleUpperCase()}
                                    </ToggleButton>
                                ))
                            }
                        </div>
                    </div>
                }
                {
                    toggle &&
                    edit &&
                    <div className='fdc aifs p10'>
                        <ScoutingDamageMultiselect
                            value={damages ?? []}
                            onChange={onDamagesChange}
                            fullWidth
                            sections={sections}
                            components={components}
                            onClose={this.onClose}
                        />
                    </div>
                }
            </>
        );
    }
}

export default ScoutingDamageSelector;
