import { getType } from 'typesafe-actions';
import { INotificationState, NotificationsAction } from '../../@types/redux';
import NotificationsActions from './actions';

const initialState : INotificationState = {
    notifications: [],
};

export default function notificationReducer(state : INotificationState = initialState, action : NotificationsAction) : INotificationState {
    switch (action.type) {
    case getType(NotificationsActions.setNotifications):
        return { ...state, notifications: action.payload };
    default:
        return state;
    }
}