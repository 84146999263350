import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

export default class NoAccess extends React.Component {
    public render = () => (
        <div className='fdc flx1 aic jcc'>
            <Card className='w350 h120'>
                <CardHeader className='bcr cw' title='Insufficient Rights' />
                <CardContent>
                    <Typography variant='body1'>
                        You don&apos;t have access to this page.
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}
