import React from 'react';
import getPathLength from 'geolib/es/getPathLength';
import { ScoutingLocationEntryHelper } from '../../../types/model/scouting/scoutingLocationEntry';
import { IScoutingAssignment } from '../../../types/model/scouting/scoutingAssignment';

interface IScoutingAssignmentDistanceCellProps {
    assignment : IScoutingAssignment;
}

interface IScoutingAssignmentDistanceCellState {
    distance ?: number | null;
}

export default class ScoutingAssignmentDistanceCell extends React.PureComponent<IScoutingAssignmentDistanceCellProps, IScoutingAssignmentDistanceCellState> {
    private mounted = true;
    constructor(props : IScoutingAssignmentDistanceCellProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadEntry(this.props.assignment);
    }

    public componentWillUnmount = () => {
        this.mounted = false;
    }

    private loadEntry = async (assignment : IScoutingAssignment) => {
        if (typeof assignment.distance !== 'undefined') {
            this.setState({
                distance : assignment.distance,
            });
        } else {
            const entryDoc = await ScoutingLocationEntryHelper.collection(assignment.id).orderBy('date', 'asc').get();

            if (entryDoc.size > 0 && this.mounted) {
                const distance = getPathLength(entryDoc.docs.map(x => [x.data().longitude, x.data().latitude])) / 1000;

                this.setState({
                    distance,
                });
            }
        }
    }

    public render = () => {
        const { distance } = this.state;
        return (
            <span>
                {
                    !!distance &&
                    distance.toFixed(2)
                }
            </span>
        );
    }
}
