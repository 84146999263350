import { read, Openable } from 'shapefile';

export default class ShapefileHelperService {
    public static read = (shp : Openable, dbf? : string | ArrayBuffer | Uint8Array | ReadableStream<any>) => {
        return read(shp, dbf);
    }

    public static readFile = async (shpFile : File, dbfFile : File) => {
        const promises = [shpFile.arrayBuffer(), dbfFile.arrayBuffer()];
        const buffers = await Promise.all(promises);
        return ShapefileHelperService.read(buffers[0], buffers[1]);
    }
}
