import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, DialogActions, FormControl, FormHelperText, Switch, TextField, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { INematodeSurveyType, INematodeSurveyTypeLevel } from '../../../../../types/model/masterData/nematode/survey';
import StandardFab from '../../../../customComponents/button/StandardFab';
import NematodeSurveyTypeLevelInput from '../edit/SurveyLevelInput';

interface INematodeSurveyTypeEditDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    value : INematodeSurveyType | null;

    onClose : (value : INematodeSurveyType | null) => void;

    isLoading : boolean;
}

interface INematodeSurveyTypeEditDialogState {
    open : boolean;

    name : string;

    levels : Array<INematodeSurveyTypeLevel>;
    
    isActive : boolean;
}

export default class NematodeSurveyTypeEditDialog extends React.PureComponent<INematodeSurveyTypeEditDialogProps, INematodeSurveyTypeEditDialogState> {
    constructor(props : INematodeSurveyTypeEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            isActive: true,
            levels: [],
        };
    }
    
    public readonly componentDidUpdate = (prevProps : INematodeSurveyTypeEditDialogProps) => {
        if (this.props.value && !prevProps.value) {
            this.show();

            this.setInitialValues(this.props.value);
        }

        if (!this.props.value && prevProps.value) {
            this.hide();
        }
    }

    public readonly show = () => {
        this.setState({
            open: true,
        });
    }

    public readonly hide = () => {
        this.setState({
            open: false,
        });
    }

    public readonly onClose = () => {
        this.props.onClose(null);
    }

    private readonly setInitialValues = (value : INematodeSurveyType) => {
        this.setState({
            name: value.name,
            levels: value.levels ?? [],
            isActive: value.isActive,
        });
    }

    private readonly onNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: e.target.value,
        });
    }

    private readonly onIsActiveChange = () => {
        this.setState({
            isActive: !this.state.isActive,
        });
    }

    private readonly onSaveClick = () => {
        if (!this.props.value) return;
        this.props.onClose({
            ...this.props.value,
            name: this.state.name,
            levels: this.state.levels.map((level) => ({
                ...level,
                images: [
                    ...level.images,
                ],
            })),
            isActive: this.state.isActive,
        });
    }

    private readonly onAddClick = () => {
        this.setState({
            levels: [
                ...this.state.levels.slice(),
                {
                    name: '',
                    images: [],
                },
            ],
        });
    };

    private readonly onLevelChange = (index : number, value : INematodeSurveyTypeLevel) => {
        const levels = this.state.levels.slice();
        levels.splice(index, 1, value)
        this.setState({
            levels,
        });
    };

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            value,
            isLoading,
        } = this.props;

        const {
            open,
            name,
            isActive,
            levels,
        } = this.state;
        
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='nematodeSurveyType-general-dialog-title'
                aria-describedby='nematodeSurveyType-general-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            {
                                !value?.name ? 'Create' : `Edit ${value.name}`
                            }
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='hfill oys'>
                    <div className={'fdr mt10 jcfs aifs'}>
                        <div className={'fdc flx1 jcfs mr10'}>
                            <FormControl>
                                <TextField
                                    required
                                    error={!name}
                                    autoFocus
                                    id='name'
                                    label='Name'
                                    type='text'
                                    fullWidth
                                    value={name}
                                    disabled={isLoading}
                                    onChange={this.onNameChange}
                                />
                                {
                                    !name &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className={'fdr flx1 aic ml10'}>
                            <Typography className='pr6' >Is Active?</Typography>
                            <Switch
                                id={'isActive'}
                                name={'isActive'}
                                checked={isActive}
                                disabled={isLoading}
                                color='primary'
                                onChange={this.onIsActiveChange}
                                inputProps={{
                                    'aria-label': 'Is Active',
                                    id: 'isActive',
                                }}
                            />
                        </div>
                    </div>
                    <div  className={'fdc jcc aifs mt15'}>
                    </div>
                    <div  className={'fdr jcfs aic mt15'}>
                    </div>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            LEVELS
                        </Typography>
                        <span className={'flx1'} />
                        <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                            <Add />
                        </StandardFab>
                    </Toolbar>
                    <div className='fdc jcfs ais bcg0'>
                        {
                            levels.map((level, index) => (
                                <NematodeSurveyTypeLevelInput
                                    key={index}
                                    value={level}
                                    index={index}
                                    disabled={isLoading}
                                    onChange={this.onLevelChange}
                                />
                            ))
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading} onClick={this.onSaveClick}>
                        <SaveIcon style={{ marginRight: '10px' }} />
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
