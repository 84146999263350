import React from 'react';
import { createSelector } from 'reselect';
import ToggleButton from '../button/ToggleButton';

interface IButtonSelectorProps<T> {
    value ?: T | Array<T> | null;
    values : Array<T>;
    onChange ?: (value : T) => void;

    className ?: string;
}

interface IButtonSelectorState {}

export default class ButtonSelector<T> extends React.PureComponent<IButtonSelectorProps<T>, IButtonSelectorState> {
    constructor(props : IButtonSelectorProps<T>) {
        super(props);
        this.state = {};
    }

    private readonly onClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        if (!this.props.onChange) return;

        this.props.onChange(event.currentTarget.value as T);
    }

    private readonly getData = (props : IButtonSelectorProps<T>) => props.value;
    private readonly getValue = createSelector(
        [this.getData],
        (value) => {
            if (typeof value === 'string') return [value];
            
            if (!value) return [];

            if ('length' in value) return [...value];

            return [];
        },
    );

    public render = () => {
        const { values, className } = this.props;
        const value = this.getValue(this.props);
        return (
            <div className='fdr jcsb fww'>
                {
                    values.map((n, i) => (
                        <React.Fragment key={`${n}`}>
                            <ToggleButton
                                className={className}
                                value={`${n}`}
                                selected={value.includes(n)}
                                onClick={this.onClick}
                            >
                                {`${n}`.toLocaleUpperCase()}
                            </ToggleButton>
                            {
                                i !== (values.length - 1) &&
                                <span className='mr15' />
                            }
                        </React.Fragment>
                    ))
                }
            </div>
        );
    }
}
