import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, DialogActions, FormControl, FormHelperText, Switch, TextField, Typography } from '@material-ui/core';
import { IDamage } from '../../../../types/model/masterData/damage';

interface IDamageEditDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    value : IDamage | null;

    onClose : (value : IDamage | null) => void;

    isLoading : boolean;
}

interface IDamageEditDialogState {
    open : boolean;

    name : string;
    isActive : boolean;
}

export default class DamageEditDialog extends React.PureComponent<IDamageEditDialogProps, IDamageEditDialogState> {
    constructor(props : IDamageEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            isActive: true,
        };
    }
    
    public readonly componentDidUpdate = (prevProps : IDamageEditDialogProps) => {
        if (this.props.value && !prevProps.value) {
            this.show();

            this.setInitialValues(this.props.value);
        }

        if (!this.props.value && prevProps.value) {
            this.hide();
        }
    }

    public readonly show = () => {
        this.setState({
            open: true,
        });
    }

    public readonly hide = () => {
        this.setState({
            open: false,
        });
    }

    public readonly onClose = () => {
        this.props.onClose(null);
    }

    private readonly setInitialValues = (value : IDamage) => {
        this.setState({
            name: value.name,
            isActive: value.isActive,
        });
    }

    private readonly onNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: e.target.value,
        });
    }

    private readonly onSaveClick = () => {
        if (!this.props.value) return;
        this.props.onClose({
            ...this.props.value,
            name: this.state.name,
            isActive: this.state.isActive,
        });
    }

    private readonly onIsActiveChange = () => {
        this.setState({
            isActive: !this.state.isActive,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, value, isLoading } = this.props;
        const { open, name, isActive } = this.state;
        
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='damage-general-dialog-title'
                aria-describedby='damage-general-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            {
                                !value?.name ? 'Create' : `Edit ${value.name}`
                            }
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className={'fdr ais jcfs'}>
                        <div className={'fdc flx1 jcfs p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <FormControl>
                                <TextField
                                    required
                                    error={!name}
                                    autoFocus
                                    margin='dense'
                                    id='name'
                                    label='Name'
                                    type='text'
                                    fullWidth
                                    value={name}
                                    onChange={this.onNameChange}
                                />
                                {
                                    !name &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className={'fdr flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <Typography className='pr6' >Is Active?</Typography>
                            <Switch
                                id={'isActive'}
                                name={'isActive'}
                                checked={isActive}
                                color='primary'
                                onChange={this.onIsActiveChange}
                                inputProps={{
                                    'aria-label': 'Is Active',
                                    id: 'isActive',
                                }}
                            />
                        </div>
                        <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading} onClick={this.onSaveClick}>
                        <SaveIcon style={{ marginRight: '10px' }} />
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
