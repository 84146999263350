import React from 'react';
import { IKeyValue } from '../../../appConstants';
import Typography from '@material-ui/core/Typography';

interface IScoutingReportTotalRowProps {
    rowData : Record<string, number>;
    headers : IKeyValue<Array<string>>;
}

interface IScoutingReportTotalRowState {}

export default class ScoutingReportTotalRow extends React.PureComponent<IScoutingReportTotalRowProps, IScoutingReportTotalRowState> {
    constructor(props : IScoutingReportTotalRowProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { rowData } = this.props;
        return (
            <div className='fdr mnh32 bocg5 bnl bcdg'>
                <div className='fdr mnw180 mxw180 aic'>
                    <Typography className='fwb fs12-16 flx1 cw pl24'>Totals</Typography>
                </div>
                {
                    Object.keys(this.props.headers).map(header => (
                        <div className='flx1 fwb fs13-18 lsp02 cw tac brw1 bocg4 bnl' key={`value_totals_${header}`}>
                            <div className='flx1 aic jcc bocg3 brw1'>
                                {
                                    rowData[header]
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}
