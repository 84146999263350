import { dispatch, getState } from '../..';
import { CROP } from '../../../appConstants';
import PhenologyHelper, { IPhenology } from '../../../types/model/masterData/phenology';
import PhenologyActions from './actions';
import lodash from 'lodash';
import GeneralFunctions from '../../general/functions';
import firebase from 'firebase/app';
import firebaseApp from '../../../services/firebaseService';
import uuid from 'uuid';
import { IPhenologyAssignment, PhenologyAssignmentHelper, PHENOLOGY_ASSIGNMENT_TYPE } from '../../../types/model/phenology/assignment';
import { IAssignmentBlock } from '../../../types/model/masterData/block';
import { IUser } from '../../../types/model/user';
import { Moment } from 'moment';

export default class PhenologyFunctions {
    private static listener ?: () => void;
    private static assignmentListener ?: () => void;

    public static getList = (crop : CROP | string) => {

        if (PhenologyFunctions.listener) {
            PhenologyFunctions.listener();
        }

        dispatch(PhenologyActions.setLoading(true));

        try {
            
            PhenologyFunctions.listener = PhenologyHelper
                .collection()
                .where('crop', '==', crop)
                .onSnapshot((snapshot) => {
                    const phenologyState = getState().phenology;

                    const phenologies = phenologyState.general.phenologies.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const data = f.doc.data();
                        const index = lodash.findIndex(phenologies, n => n.id === data.id);

                        switch (f.type) {
                        case 'added':
                            phenologies.push(data);
                            break;
                        case 'modified':
                            phenologies.splice(index, 1, data);
                            break;
                        case 'removed':
                            phenologies.splice(index, 1);
                            break;
                        }
                    });

                    dispatch(PhenologyActions.setList(phenologies));
                    dispatch(PhenologyActions.setLoading(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading phenologies.', err);
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading phenologies.', ex);
            dispatch(PhenologyActions.setLoading(false));
        }
    }

    private static uploadFiles = async (save : IPhenology) => {
        for(const section of save.sections) {
            for (const option of section.options) {
                for (const image of option.images) {
                    if (image.file) {
                        image.path = `phenology/${save.id}/${image.name}`;

                        const fileRef = firebaseApp.storage().ref(image.path);

                        try {
                            image.url = await fileRef.getDownloadURL();
                        } catch (ex) {
                            const result = await fileRef.put(image.file);
                            image.url = await result.ref.getDownloadURL();
                        } finally {
                            delete image.file;
                        }

                    }
                }
            }
        }
    }

    public static save = async (item : IPhenology) => {
        const session = getState().auth.session;

        if (!session) return;
        dispatch(PhenologyActions.setLoading(true));

        try {
            const save : IPhenology = {
                ...item,
                createdBy: item.createdBy ? item.createdBy : session.firebaseUser.uid,
                createdByEmployee: item.createdByEmployee ? item.createdByEmployee : session.user.employeeNumber,
                createdByName: item.createdBy ? item.createdByName : session.user.name,
                createdOn: item.createdOn ? item.createdOn : firebase.firestore.Timestamp.now().toMillis(),
                updatedBy: session.firebaseUser.uid,
                updatedByEmployee: session.user.employeeNumber,
                updatedByName: session.user.name,
                updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                sections: lodash.cloneDeep(item.sections),
                id: item.id ? item.id : uuid.v4(),
            };
            
            await PhenologyFunctions.uploadFiles(save);

            await PhenologyHelper.save(save);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving phenology.', ex);
            throw ex;
        } finally {
            dispatch(PhenologyActions.setLoading(false));
        }
    }

    private static getCollection = (crop : CROP, type : PHENOLOGY_ASSIGNMENT_TYPE) => PhenologyAssignmentHelper
        .collection()
        .where('crop', '==', crop)
        .where('type', '==', type)
        .orderBy('date', 'desc')
        .limit(1000);

    public static getAssignments = (crop : CROP, refresh ?: boolean) => {
        if (!refresh && PhenologyFunctions.assignmentListener) return;

        if (PhenologyFunctions.assignmentListener) {
            PhenologyFunctions.assignmentListener();
        }

        dispatch(PhenologyActions.setLoadingAssignments(true));
        dispatch(PhenologyActions.setAssignments([]));
        try {
            const session = getState().auth.session;
            if (!session) return;
            const divisions = Object.keys(session.user.divisions).map(n => n.toLocaleLowerCase());

            PhenologyFunctions.assignmentListener = this
                .getCollection(crop, 'Farmer Survey')
                .onSnapshot((snapshot) => {
                    const phenologyState = getState().phenology;

                    const assignments = lodash.cloneDeep(phenologyState.general.assignments);

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const assignment = f.doc.data();

                        if (!assignment || !divisions.includes(assignment.division)) return;

                        const index = lodash.findIndex(assignments, n => n.id === assignment.id);

                        switch (f.type) {
                        case 'added':
                            assignments.push(assignment);
                            break;
                        case 'modified':
                            assignments.splice(index, 1, assignment);
                            break;
                        case 'removed':
                            assignments.splice(index, 1);
                            break;
                        }
                    });

                    dispatch(PhenologyActions.setAssignments(assignments.sort((a, b) => b.date - a.date)));
                    dispatch(PhenologyActions.setLoadingAssignments(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading assignments.', err);
                    dispatch(PhenologyActions.setLoadingAssignments(false));
                }, () => {
                    dispatch(PhenologyActions.setAssignments([]));
                    dispatch(PhenologyActions.setLoadingAssignments(false));
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading assignments.', ex);
        }
    }

    public static createAssignments = async (
        date : Moment,
        scoutingBlocks : Record<string, Array<IAssignmentBlock>>,
        type : PHENOLOGY_ASSIGNMENT_TYPE,
        employees : Record<string, IUser | null>,
    ) => {
        dispatch(PhenologyActions.setLoadingAssignments(true));

        try {
            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const authState = getState().auth;

                if (!authState.session) return;

                for (const landName of Object.keys(scoutingBlocks)) {
                    for (const block of scoutingBlocks[landName]) {
                        const employee = employees[landName];

                        if (!employee) throw new Error(`Land ${landName} has no Scout.`);
                        const assignment = {
                            id: '',
                            createdBy: authState.session.user.ref,
                            createdByName: authState.session.user.name,
                            createdByEmployee: authState.session.user.employeeNumber,
                            createdOn: firebase.firestore.Timestamp.now().toMillis(),

                            date: date.valueOf(),
                            employee: employee.ref,
                            employeeNumber: employee.employeeNumber,
                            employeeName: employee.name,

                            block: block.id,
                            blockName: block.name,
                            landName: block.landName,
                            division: block.division,
                            crop: block.crop,
                            scoutingBlock: block,
                            finished: false,
                            finishedPoints: {},

                            updatedBy: authState.session.user.ref,
                            updatedByName: authState.session.user.name,
                            updatedByEmployee: authState.session.user.employeeNumber,
                            updatedOn: firebase.firestore.Timestamp.now().toMillis(),

                            type,
                            distance: null,
                            finishedOn: {},
                            taskCompletions: [],
                            startedOn: {},
                            totalDuration: null,
                            directions: PhenologyAssignmentHelper.DIRECTIONS,
                            specific: null,
                        } as IPhenologyAssignment;

                        PhenologyFunctions.validateAssignment(assignment);
                        PhenologyAssignmentHelper.saveTransaction(transaction, assignment);
                    }

                }
            });

            GeneralFunctions.generalShowSuccessSnackbar(`Assignment${Object.keys(scoutingBlocks).length > 1 ? 's' : ''} created.`);
            return true;
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while creating assignment.', ex);
        } finally {
            dispatch(PhenologyActions.setLoadingAssignments(false));
        }
        return false;
    }

    /**
     * Validates an assignment.
     * Throws `Error` if invalid.
     * @param assignment
     */
    private static validateAssignment(assignment : IPhenologyAssignment) {

        if (!assignment.date) {
            throw new Error('Date required.');
        }

        if (!assignment.block) {
            throw new Error('Block required.');
        }

        if (!assignment.division) {
            throw new Error('Division required.');
        }

        if (!assignment.blockName) {
            throw new Error(`${assignment.scoutingBlock.landName} required a Block.`);
        }

        if (!Object.keys(assignment.scoutingBlock.points).length) {
            throw new Error(`Block ${assignment.scoutingBlock.name} has no points.`);
        }
    }
}