import firebase from 'firebase/app';

export interface IGeoPoint {
    /**
     * The latitude of this GeoPoint instance.
     */
    readonly latitude : number;
    /**
     * The longitude of this GeoPoint instance.
     */
    readonly longitude : number;
}

export class GeoPointHelper {
    public static fromFirestore(geoPoint : firebase.firestore.GeoPoint) : IGeoPoint {
        return {
            latitude: geoPoint.latitude,
            longitude: geoPoint.longitude,
        };
    }
    public static toFirestore(geoPoint : IGeoPoint) : firebase.firestore.GeoPoint {
        return new firebase.firestore.GeoPoint(geoPoint.latitude, geoPoint.longitude);
    }
}
