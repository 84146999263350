import { dispatch, getState } from '../..';
import HttpGeoServerService from '../../../services/geoserverHttpService';
import { IGeoServerBlock } from '../../../types/model/masterData/geoserver/block';
import GeneralFunctions from '../../general/functions';
import MasterDataGeoServerActions from './actions';

export default class MasterDataGeoServerFunctions {
    public static readonly getBlockList = async (refresh ?: boolean) => {
        if (getState().masterData.geoserver.isLoadingBlocks) return;

        if (!refresh && getState().masterData.geoserver.blocks.length) return;

        dispatch(MasterDataGeoServerActions.setLoadingBlockList(true));
        dispatch(MasterDataGeoServerActions.setBlockList([]));

        try {
            const geoServerBlocks = await HttpGeoServerService.getBlocks();

            const blocks : Array<IGeoServerBlock> = geoServerBlocks.data.features.map(x => ({
                code: x.properties.blkCode,
                color: x.properties.blkColor,
                departmentGuid: x.properties.blkDepartmentGuid,
                departmentName: x.properties.blkDepartmentName,
                departmentShortName: x.properties.blkDepartmentShortName,
                divisionGuid: x.properties.blkDivisionGuid,
                divisionName: x.properties.blkDivisionName,
                fieldGuid: x.properties.blkFieldGuid,
                fieldName: x.properties.blkFieldName,
                guid: x.properties.blkGuid,
                name: x.properties.blkName,
                subDivisionGuid: x.properties.blkSubDivisionGuid,
                subDivisionName: x.properties.blkSubDivisionName,
                geometry: x.geometry,
            }));

            dispatch(MasterDataGeoServerActions.setBlockList(blocks));
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading blocks.', ex);
        } finally {
            dispatch(MasterDataGeoServerActions.setLoadingBlockList(false));
        }
    };
}