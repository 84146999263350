import React from 'react';
import Input, { InputProps } from '@material-ui/core/Input';

interface IThresholdInputProps extends InputProps {
}

interface IThresholdInputState {
    focus : boolean;
}

export default class ThresholdInput extends React.PureComponent<IThresholdInputProps, IThresholdInputState> {
    constructor(props : IThresholdInputProps) {
        super(props);
        this.state = {
            focus: false,
        };
    }

    private onFocus = () => {
        this.setState({
            focus: true,
        });
    }

    private onBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        this.setState({
            focus: false,
        });

        if (this.props.onBlur) this.props.onBlur(event);
    }

    public render = () => {
        const { focus } = this.state;
        return (
            <Input
                disableUnderline={!focus && !this.props.error}
                style={{
                    marginLeft: '20%',
                    marginRight: '20%',
                }}
                inputProps={{
                    min: 0,
                    style: {
                        font: '400 14px/37px Roboto',
                        color: '#424242',
                        textAlign: 'center',
                    },
                }}
                onFocus={this.onFocus} { ...this.props } onBlur={this.onBlur} />
        );
    }
}
