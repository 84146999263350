import React from 'react';
import { IRootState } from '../../../@types/redux';
import { createSelector } from 'reselect';
import StandardLayerControl from './StandardLayerControl';
import 'leaflet/dist/leaflet.css';
import L, { LatLng } from 'leaflet';
import { Map } from 'react-leaflet';
import BlockPolygonLayerGroup from './BlockPolygonLayerGroup';
import { connect } from 'react-redux';
import ArrayHelper from '../../../services/arrayHelper';
import { BlockHelper, IBlock } from '../../../types/model/masterData/block';
import { IBlockPolygonLayer } from '../../../types/model/masterData/blockPolygonLayer';
import GeneralFunctions from '../../../store/general/functions';

interface IScoutingBlocksMapProps {
    blocks : Array<IBlock>;
    division ?: string;
    landName ?: string;
    blockNames ?: Array<string>;

    crop ?: string;
}

interface IScoutingBlocksMapState {}

class ScoutingBlocksMap extends React.PureComponent<IScoutingBlocksMapProps, IScoutingBlocksMapState> {
    private mapRef ?: Map;

    private mapZoom = 8;
    private mapCenter = new LatLng(-23.5520414, 30.1148622);

    constructor(props : IScoutingBlocksMapProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount = () => {
        GeneralFunctions.getBlocks();
    }

    public componentDidUpdate = (prevProps : IScoutingBlocksMapProps) => {
        if (!!prevProps.blockNames
            && !!this.props.blockNames
            && !ArrayHelper.compareArray(prevProps.blockNames, this.props.blockNames)) {
            this.flyToBlock();
        }

        if (prevProps.landName !== this.props.landName) {
            this.flyToBlock();
        }
    }

    private setMapRef = (ref : Map) => {
        this.mapRef = ref;

        if (this.props.blocks.length > 0) {
            this.flyToBlock();
        }
    }

    private flyToBlock = () => {
        const blocks = this.getBlocks(this.props);
        const block = blocks.find(n =>
            (n.landName === this.props.landName)
            && (!this.props.blockNames
                || !this.props.blockNames.length
                || n.blockName === this.props.blockNames[this.props.blockNames.length - 1]),
        );
        if (block) {
            this.flyTo({ ...block });
        }
    }

    private flyTo = (block : IBlockPolygonLayer | null) => {
        if (this.mapRef && block) {
            this.mapRef.leafletElement.flyToBounds(L.polygon(block.positions).getBounds());
        }
    }

    private getData = (props : IScoutingBlocksMapProps) => props.blocks;
    private getDivision = (props : IScoutingBlocksMapProps) => props.division;
    private getLandName = (props : IScoutingBlocksMapProps) => props.landName;
    private getBlockNames = (props : IScoutingBlocksMapProps) => props.blockNames;
    private getCrop = (props : IScoutingBlocksMapProps) => props.crop;

    private getBlocks = createSelector([
        this.getData,
        this.getDivision,
        this.getLandName,
        this.getBlockNames,
        this.getCrop],
    (blocks, division, landName, blockNames, crop) => {
        const layer : Array<IBlockPolygonLayer> = blocks
            .filter(n => !division || division.toLocaleUpperCase() === n.division.toLocaleUpperCase())
            .filter(n => !landName || n.landName === landName)
            .filter(n => !blockNames || !blockNames.length || blockNames.includes(n.name))
            .filter(n => !crop || n.crop === crop)
            .map(n => BlockHelper.toLayer(n));

        return layer;
    },
    );

    public render = () => {
        const { division } = this.props;
        const blocks = this.getBlocks(this.props);

        return (
            <Map ref={this.setMapRef} maxZoom={20} className={'flx1 bcw'} center={this.mapCenter} zoom={this.mapZoom} preferCanvas>
                <BlockPolygonLayerGroup zIndex={5} blocks={blocks} />
                <StandardLayerControl division={division} polygonOpacity={0.2} polygonWidth={5} />
            </Map>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.general.blocks,
    };
};

export default connect(
    mapStateToProps,
)(ScoutingBlocksMap);
