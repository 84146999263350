import { ClickAwayListener, Grow, Paper, Popper, Tooltip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ControlPosition, useMap } from 'react-map-gl';
import MapControlCheckbox from './MapControlCheckbox';

interface IGeoServerLayerControlProps {
    position ?: ControlPosition;
    transformOrigin ?: 'left bottom';

    divisionChecked ?: boolean;
    onDivisionClick ?: (checked ?: boolean) => void;

    subDivisionChecked ?: boolean;
    onSubDivisionClick ?: (checked ?: boolean) => void;

    departmentChecked ?: boolean;
    onDepartmentClick ?: (checked ?: boolean) => void;

    fieldChecked ?: boolean;
    onFieldClick ?: (checked ?: boolean) => void;

    blockChecked ?: boolean;
    onBlockClick ?: (checked ?: boolean) => void;
}

const GeoServerLayerControl = memo(({
    position = 'top-right',
    transformOrigin = 'left bottom',

    divisionChecked = true,
    onDivisionClick,

    subDivisionChecked = true,
    onSubDivisionClick,

    departmentChecked = true,
    onDepartmentClick,

    fieldChecked = true,
    onFieldClick,

    blockChecked = true,
    onBlockClick,
} : IGeoServerLayerControlProps) => {
    const anchorElement = useRef<HTMLButtonElement>(null);
    const map = useMap();
    const [open, setOpen] = useState<boolean>(false);

    // This creates a control div container that we will add to the map's control container,
    const controlDiv = useMemo(() => {
        const control = document.createElement('div');
        control.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        return control;
    }, []);

    // This adds the control to the end of the maps control container.
    useEffect(() => {
        if (!map.current) return;

        const controlContainer = map.current.getContainer().getElementsByClassName(`mapboxgl-ctrl-${position}`)[0];

        controlContainer.append(controlDiv);

        return () => {
            controlContainer.removeChild(controlDiv);
        };
    }, [map.current]);

    const onOpenClick = useCallback(() => {
        setOpen(prev => !prev);
    }, []);

    const onClickAway = useCallback(() => {
        setOpen(false);
    }, []);

    return ReactDOM.createPortal((
        <>
            <Tooltip title='Layer Controls'>
                <button className='' ref={anchorElement} aria-controls='map-layer-controls' aria-haspopup='true' onClick={onOpenClick}>
                    <MoreVert className='mapboxgl-ctrl-icon' fontSize='small' />
                </button>
            </Tooltip>
            <Popper open={open} anchorEl={anchorElement.current} transition disablePortal>
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={onClickAway}>
                                <div className='fdc' style={{
                                    minWidth: 200,
                                    marginTop: 5,
                                    marginRight: 10,
                                }}>
                                    {
                                        onDivisionClick &&
                                        <MapControlCheckbox
                                            value={divisionChecked}
                                            label='Divisions' 
                                            onChange={onDivisionClick}
                                        />
                                    }
                                    {
                                        onSubDivisionClick &&
                                        <MapControlCheckbox
                                            value={subDivisionChecked}
                                            label='Sub Divisions' 
                                            onChange={onSubDivisionClick}
                                        />
                                    }
                                    {
                                        onDepartmentClick &&
                                        <MapControlCheckbox
                                            value={departmentChecked}
                                            label='Departments' 
                                            onChange={onDepartmentClick}
                                        />
                                    }
                                    {
                                        onFieldClick &&
                                        <MapControlCheckbox
                                            value={fieldChecked}
                                            label='Fields' 
                                            onChange={onFieldClick}
                                        />
                                    }
                                    {
                                        onBlockClick &&
                                        <MapControlCheckbox
                                            value={blockChecked}
                                            label='Blocks' 
                                            onChange={onBlockClick}
                                        />
                                    }
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    ), controlDiv);
});

export default GeoServerLayerControl;