import React from 'react';
import { LayerGroup, MapLayerProps, Marker, Popup, withLeaflet } from 'react-leaflet';
import { createSelector } from 'reselect';
import { ITrap } from '../../../types/model/trap/trap';
import { Icon } from 'leaflet';
import { IGeoPoint } from '../../../types/model/geoPoint';
import { Button, Link } from '@material-ui/core';

interface ITrapLocationMarkersProps extends MapLayerProps  {
    traps : Array<ITrap | { id : string; location : IGeoPoint }>;

    onTrapClick ?: (event : React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

interface ITrapLocationMarkersState {
}

class TrapLocationMarkersComponent extends React.PureComponent<ITrapLocationMarkersProps, ITrapLocationMarkersState> {
    constructor(props : ITrapLocationMarkersProps) {
        super(props);
        this.state = {
        };
    }

    private getTraps = (state : ITrapLocationMarkersState, props : ITrapLocationMarkersProps) => props.traps;
    private getOnTrapClick = (state : ITrapLocationMarkersState, props : ITrapLocationMarkersProps) => props.onTrapClick;
    private getLocationMarkers = createSelector(
        [
            this.getTraps,
            this.getOnTrapClick,
        ],
        (
            traps,
            onTrapClick,
        ) => {
            return traps.map((n) => (
                <Marker
                    key={n.id}
                    position={{
                        lat: n.location?.latitude ?? 0,
                        lng: n.location?.longitude ?? 0,
                    }}
                    icon={new Icon({
                        iconUrl: '/assets/images/icons/traps.svg',
                        iconSize: [32,32],
                        iconAnchor: [16, 16],
                        popupAnchor: [0, -16]
                    })}
                >
                    {
                        'code' in n &&
                        <Popup>
                            {
                                onTrapClick &&
                                <Link component={Button} onClick={onTrapClick} id={n.id}>{n.code}</Link>
                            }
                            {
                                !onTrapClick &&
                                <span>{n.code}</span>
                            }
                        </Popup>
                    }
                </Marker>
            ));
        },
    );

    public onLayerRef = () => {
        // TODO: Add to layer control
    }

    public render = () => {
        const locationMarkers = this.getLocationMarkers(this.state, this.props);
        return (
            <LayerGroup ref={this.onLayerRef}>
                {
                    locationMarkers
                }
            </LayerGroup>
        );
    }
}

const TrapLocationMarkers = withLeaflet(TrapLocationMarkersComponent);

export default TrapLocationMarkers;
