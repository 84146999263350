import { Card, Icon, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { createSelector } from 'reselect';
import { IRootState } from '../../../@types/redux';
import AppFunctionsService from '../../../services/appFunctionServices';
import GeneralFunctions from '../../../store/general/functions';
import InsectFunctions from '../../../store/masterData/insect/functions';
import { IInsect } from '../../../types/model/masterData/insect';
import StandardFab from '../../customComponents/button/StandardFab';
import MaterialTable from '../../customComponents/materialTable/Table';
import InsectEditDialog from './dialog/Edit';

interface IInsectListProps extends RouteComponentProps {
    insects : Array<IInsect>;
    isLoading : boolean;
}

interface IInsectListState {
    selected : IInsect |null;
}

class InsectListComponent extends React.PureComponent<IInsectListProps, IInsectListState> {
    constructor(props : IInsectListProps) {
        super(props);
        this.state = {
            selected: null,
        };
    }

    public readonly componentDidMount = () => {
        this.load();
    }

    public readonly load = () => {
        InsectFunctions.getList();
    }

    private readonly onAddClick = () => {
        this.setState({
            selected: {
                name: '',
                subTrapTypes: [],
                images: [],
                isActive: true,
            } as IInsect,
        });
    };

    private readonly getData = (props : IInsectListProps) => props.insects;

    private readonly getInsects = createSelector(
        [this.getData],
        (insects) => insects.sort((a, b) => a.name.localeCompare(b.name)),
    );

    private save = async (insect : IInsect) => {
        try {
            await InsectFunctions.save(insect);

            this.setState({
                selected: null,
            });
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error saving insect.', ex);
        }
    };

    private readonly onEditClose = (insect : IInsect | null) => {
        if (insect) {
            this.save(insect);
            return;
        }

        this.setState({
            selected: null,
        });
    }

    private readonly onRowClick = (e : React.MouseEvent<HTMLButtonElement>) => {
        const insect = this.props.insects.find(x => x.id === e.currentTarget.value);

        if (!insect) return;

        this.setState({
            selected: {
                ...insect,
            },
        });
    }

    public render = () => {
        const { selected } = this.state;
        const { isLoading } = this.props;
        const insects = this.getInsects(this.props);

        return (
            <div className={'fdc hfill mh0 mw0'}>
                <div className={'flx1 fdc ml15 mr15 mt20'}>
                    <Card className={'flx1 fdc mb90'}>
                        <MaterialTable<IInsect>
                            id='insectTable'
                            data={insects}
                            rowsPerPage={50}
                            isLoading={isLoading}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 25,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Edit'>
                                            <div>
                                                <IconButton value={row.id} color='primary' onClick={this.onRowClick} aria-label='info' disabled={isLoading}>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                paddingRight: 4,
                                header: 'Name',
                                field: 'name',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            },        {
                                header: 'Created By',
                                field: 'createdByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.createdOn)}>
                                        <span>{row.createdByName}</span>
                                    </Tooltip>),
                            },        {
                                header: 'Updated By',
                                field: 'updatedByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.updatedOn)}>
                                        <span>{row.updatedByName}</span>
                                    </Tooltip>),
                            },        {
                                header: 'Is Active?',
                                field: 'isActive',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => !n.isActive ? (<Icon className='cpr'>close</Icon>) : (<Icon className='cdg'>check</Icon>),
                            }]}
                        />
                    </Card>
                    <InsectEditDialog value={selected} fullWidth maxWidth='md' onClose={this.onEditClose} isLoading={isLoading} />
                    <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                        <AddIcon />
                    </StandardFab>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        insects: state.masterData.insect.insects,
        isLoading: state.masterData.insect.isLoading,
    };
};

const InsectList = connect(
    mapStateToProps,
)(InsectListComponent);

export default InsectList;