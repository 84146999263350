import { EnumFileUploadQueueType, IScoutingFile } from '../file';
import { BaseHelper, IBase } from '../../base';
import { GeoPointHelper, IGeoPoint } from '../geoPoint';
import firebaseApp from '../../../services/firebaseService';
import InsectHelper from '../masterData/insect';
import firebase from 'firebase/app';

export interface ITrapEntryResult {
    insectRef : string;
    insectName : string;
    count : number;
}

export interface ITrapEntryFileMetaData {
    thumbnailFieldName : string;
    fieldName : string;
    refGuid : string;
    fileType : EnumFileUploadQueueType;
}

export interface ITrapEntry extends IBase {
    trapRef : string;
    trapCode : string;
    location : IGeoPoint;
    elevation : number;
    locationAccuracy : number;
    guid : string;
    date : number;
    time : string;

    comments : Array<string | 'None' | 'Other'>;
    otherComment : string | null;

    imagePath : string | null;
    imageComment : string | null;

    files : Array<IScoutingFile> | null;

    results : Array<ITrapEntryResult>;
}

export class TrapEntryHelper extends BaseHelper {
    public static readonly COLLECTION = 'trap_entries';

    public static converter : firebase.firestore.FirestoreDataConverter<ITrapEntry> = {
        fromFirestore: (snapshot) => {
            return TrapEntryHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : ITrapEntry) => {
            return TrapEntryHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : ITrapEntry {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as ITrapEntry,
            ...result,
            location: GeoPointHelper.fromFirestore(data['location']),
            trapRef: (data['trapRef'] as firebase.firestore.DocumentReference).id,
            date: (data['date'] as firebase.firestore.Timestamp).toMillis(),
            results: (data['results'] as Array<firebase.firestore.DocumentData>).map((n) => ({
                ...n as ITrapEntryResult,
                insectRef: (n['insectRef'] as firebase.firestore.DocumentReference).id,
            })),
        };
    }

    protected static toFirestore(data : ITrapEntry) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
            location: GeoPointHelper.toFirestore(data.location),
            trapRef: TrapEntryHelper.doc(data.trapRef),
            date: firebase.firestore.Timestamp.fromMillis(data.date),
            results: data.results.map(x => ({
                ...x,
                insectRef: InsectHelper.doc(x.insectRef),
            }))
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static doc(id ?: string) {
        if (!id) {
            return TrapEntryHelper.collection().doc();
        }

        return TrapEntryHelper.collection().doc(id);
    }

}