import { Ecc, QrCode } from '../types/qrcodegen';

export interface IQrCodeOptions {
    text : string;
    ecc : Ecc;
    scale ?: number;
    border ?: number;
    forgroundColor ?: string;
    backgroundColor ?: string;
}

export default class QrCodeService {
    private static readonly backgroundColor = '#FFFFFF';
    private static readonly forgroundColor = '#000000';

    public static drawOnCanvas(canvas : HTMLCanvasElement, options : IQrCodeOptions) {
        const qr = QrCode.encodeText(options.text, options.ecc);

        const scale = options.scale ?? 1;
        const border = options.border ?? 1;

        const width : number = (qr.size + border * 2) * scale;

        canvas.width = width;
        canvas.height = width;

        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        for (let y = -border; y < qr.size + border; y++) {
            for (let x = -border; x < qr.size + border; x++) {
                ctx.fillStyle = qr.getModule(x, y) ? (options.forgroundColor ?? this.forgroundColor) : (options.backgroundColor ?? this.backgroundColor);
                ctx.fillRect((x + border) * scale, (y + border) * scale, scale, scale);
            }
        }
    }

    public static paintOnPdfCanvas(painter : IPainter, options : IQrCodeOptions) {
        const qr = QrCode.encodeText(options.text, options.ecc);

        const scale = options.scale ?? 1;
        const border = options.border ?? 1;

        for (let y = -border; y < qr.size + border; y++) {
            for (let x = -border; x < qr.size + border; x++) {
                painter
                    .rect((x + border) * scale, (y + border) * scale, scale, scale)
                    .fill(qr.getModule(x, y) ? (options.forgroundColor ?? this.forgroundColor) : (options.backgroundColor ?? this.backgroundColor));
            }
        }
    }
}