import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IInsect } from '../../../types/model/masterData/insect';
import InsectFunctions from '../../../store/masterData/insect/functions';

interface IInsectDropdownProps {
    id ?: string;
    value ?: IInsect | string;

    onChange : (name ?: string, value ?: IInsect, event ?: React.ChangeEvent<unknown>) => void;

    insects : Array<IInsect>;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;
    disabled ?: boolean;
    name ?: string;
}

interface IInsectDropdownState {
}

class InsectDropdown extends React.Component<IInsectDropdownProps, IInsectDropdownState> {
    constructor(props : IInsectDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        InsectFunctions.getList();
    }

    private onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        const result = this.props.insects.find(n => n.id === value?.value);

        this.props.onChange(this.props.name, result, event);
    }

    private getData = (state : IInsectDropdownState, props : IInsectDropdownProps) => props.insects;
    private getValue = (state : IInsectDropdownState, props : IInsectDropdownProps) => props.value;
    private getRequired = (state : IInsectDropdownState, props : IInsectDropdownProps) => props.required;
    private getInsectDropdown = createSelector([
        this.getData, this.getRequired,
    ], (insects, required) => {
        const insectsDrop = insects
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(x => ({ label: `${x.name}`, value: x.id }));

        if (!required) {
            insectsDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return insectsDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getData,
    ], (value, required, insects) => {
        if (typeof(value) === 'object') {
            return {
                label:  `${value.name}`,
                value: value.id,
            };
        } else if (value && typeof(value) === 'string') {
            const insect = insects.slice().find(x => x.id === value || x.name === value);

            if (insect) {
                return {
                    label:  `${insect.name}`,
                    value: insect.id,
                };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const { required,  fullWidth, isLoading, disabled } = this.props;

        const insects = this.getInsectDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        disabled={isLoading || disabled}
                        id='insect_select'
                        options={insects}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChange}
                        disableClearable={required}
                        openOnFocus
                        renderInput={params => <TextField error={required && !value} required={required} {...params} fullWidth={fullWidth} label='Insect' />}
                    />
                    {
                        required && !value &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        insects: state.masterData.insect.insects.filter(x => x.isActive),
        isLoading: state.masterData.insect.isLoading,
    };
};

export default connect(
    mapStateToProps,
)(InsectDropdown);
