import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import React from 'react';
import { IUser } from '../../../../types/model/user';
import AppFunctionsService from '../../../../services/appFunctionServices';

interface IUserInfoEditTabProps {
    user : IUser;

    onChange : (user : IUser) => void;
}

interface IUserInfoEditTabState {}

export default class UserInfoEditTab extends React.PureComponent<IUserInfoEditTabProps, IUserInfoEditTabState> {
    constructor(props : IUserInfoEditTabProps) {
        super(props);
        this.state = {};
    }


    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const user = {
            ...this.props.user,
        };

        user.name = event.currentTarget.value;
        
        this.props.onChange(user);
    }

    private readonly onEmployeeNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const user = {
            ...this.props.user,
        };
        user.employeeNumber = event.currentTarget.value;
        
        this.props.onChange(user);
    }

    private readonly onEmailChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const user = {
            ...this.props.user,
        };

        user.email = event.currentTarget.value;
        
        this.props.onChange(user);
    }

    public render = () => {
        const {
            name,
            email,
            employeeNumber,
            lastSignIn,
            createdOn,
        } = this.props.user;

        return (
            <>
                <div className={'aic p5 mb10 pr20'}>
                    <FormControl fullWidth>
                        <TextField
                            autoComplete='off'
                            id='name'
                            label='Name'
                            value={name ? name : ''}
                            required
                            error={!name}
                            onChange={this.onNameChange}
                            margin='normal'
                            className={'TextField'}
                        />
                        {
                            !name &&
                            <FormHelperText error>Required</FormHelperText>
                        }
                    </FormControl>
                </div>
                <div className='fdr'>
                    <div className={'aic flx1 p5 mb10 pr20'}>
                        <FormControl fullWidth>
                            <TextField
                                autoComplete='off'
                                id='employeeNumber'
                                onChange={this.onEmployeeNumberChange}
                                label='Employee Number'
                                value={employeeNumber ? employeeNumber : ''}
                                error={!employeeNumber && !!this.props.user.employeeNumber}
                                margin='normal'
                                className={'TextField'}
                                disabled={!!this.props.user.employeeNumber}
                            />
                            {
                                !employeeNumber && !!this.props.user.employeeNumber &&
                                <FormHelperText error>Required</FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <div className={'aic flx1 p5 mb10 pr20'}>
                        <FormControl fullWidth>
                            <TextField
                                autoComplete='off'
                                id='email'
                                label='Email Address'
                                type='email'
                                onChange={this.onEmailChange}
                                value={email ? email : ''}
                                error={!email && !!this.props.user.email }
                                margin='normal'
                                className={'TextField'}
                                disabled={!!this.props.user.email}
                            />
                            {
                                !email && !!this.props.user.email &&
                            <FormHelperText error>Required</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </div>
                <div className='fdr'>
                    <div className={'flx1 aic p5 mb10 pr20'}>
                        <FormControl fullWidth>
                            <TextField
                                autoComplete='off'
                                id='createdOn'
                                label='Created On'
                                value={createdOn ? AppFunctionsService.formatDateTime(createdOn) : ''}
                                margin='normal'
                                className={'TextField'}
                                disabled
                            />
                        </FormControl>
                    </div>
                    <div className={'flx1 aic p5 mb10 pr20'}>
                        <FormControl fullWidth>
                            <TextField
                                autoComplete='off'
                                id='LastSignIn'
                                label='Last Sign In'
                                value={lastSignIn ? AppFunctionsService.formatDateTime(lastSignIn) : ''}
                                margin='normal'
                                className={'TextField'}
                                disabled
                            />
                        </FormControl>
                    </div>
                </div>
            </>
        
        );
    }
}
