import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import BlockMap from '../../customComponents/map/BlockMap';
import AssignmentLocationMarkers from '../../customComponents/map/AssignmentLocationMarkers';
import { createSelector } from 'reselect';
import { LatLng } from 'leaflet';
import AppFunctionsService from '../../../services/appFunctionServices';
import { IScoutingAssignment } from '../../../types/model/scouting/scoutingAssignment';
import ToggleButton from '../../customComponents/button/ToggleButton';
import HorizontalExpander from '../../customComponents/expander/HorizontalExpander';
import ScoutingEmergencyPhotosView from './ScoutingEmergencyPhotosView';
import { IScoutingEntry } from '../../../types/model/scouting/scoutingEntry';

interface IAssignmentInfoViewProps {
    value : IScoutingAssignment;
    entries : Array<IScoutingEntry>;
}

interface IAssignmentInfoViewState {
}

export default class AssignmentInfoView extends React.PureComponent<IAssignmentInfoViewProps, IAssignmentInfoViewState> {

    constructor(props : IAssignmentInfoViewProps) {
        super(props);
        this.state = {
            entries: [],
            isLoading: true,
        };
    }
    
    

    private getScoutingBlock = (props : IAssignmentInfoViewProps) => props.value.scoutingBlock;
    private getFinishedGuids = (props : IAssignmentInfoViewProps) => props.value.finishedPoints;
    private getFinishedPoints = createSelector(
        [this.getFinishedGuids, this.getScoutingBlock],
        (guids, block) => {
            return guids.map(n => new LatLng(block.points[n].latitude, block.points[n].longitude));
        },
    );

    public render = () => {
        const { value, entries, } = this.props;

        const finishedPoints = this.getFinishedPoints(this.props);
        return (
            <div className='fdr flx1 ais'>
                <div className='fdc flx1 ais mr5'>
                    <AppBar className='fdc ais' variant='outlined' position='static'>
                        <Toolbar variant='dense' className={'fdr aic jcc'}>
                            <Typography className={'cw'} variant='h6'>
                                BLOCK - {value.blockName}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdc flx1'>
                        <BlockMap block={this.props.value.scoutingBlock}>
                            <AssignmentLocationMarkers
                                assignmentId={value.id}
                                finishedPoints={finishedPoints}
                            />
                        </BlockMap>
                    </div>
                </div>
                <div className='fdc flx1 ais ml5'>
                    <AppBar className='fdc ais' variant='outlined' position='static'>
                        <Toolbar variant='dense' className={'fdr aic jcc'}>
                            <Typography className={'cw'} variant='h6'>
                            ASSIGNMENT
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            DATE
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {AppFunctionsService.formatDateTimeToDateOnly(value.date)}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            COMPLETED POINTS
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {`${value.finishedPoints.length} / ${Object.keys(value.scoutingBlock.points).length}`}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                SCOUT
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {`${value.employeeNumber} - ${value.employeeName}`}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            DIVISION
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.division.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            LAND NAME
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.landName.toLocaleUpperCase()}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            BLOCK NAME
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.blockName.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            LAND DESCRIPTION
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.scoutingBlock.description.toLocaleUpperCase()}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            CROP
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.crop.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            AGE
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.scoutingBlock.age.toFixed(0)}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            AREA
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    !value.scoutingBlock.ha &&
                                    '-'
                                }
                                {
                                    !!value.scoutingBlock.ha &&
                                    `${value.scoutingBlock.ha.toFixed(2)} Ha`
                                }
                            </Typography>
                        </div>
                    </div>
                    {
                        value.type === 'specific' &&
                        <>
                            <AppBar className='fdc ais bcig' variant='outlined' position='static'>
                                <Toolbar variant='dense' className={'fdr aic jcc'}>
                                    <Typography className={'cw'} variant='h6'>
                                        SPECIFIC
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <HorizontalExpander
                                title={'SECTIONS'}
                                color={'primary'}
                            >
                                <div className='fdc aic mb15'>
                                    <div className='fdr jcsb fww'>
                                        {
                                            value.sections?.map((n, i) => (
                                                <ToggleButton
                                                    key={n}
                                                    className={`mt15 ${i !== ((value.sections?.length ?? 0) - 1) ? 'mr15' : ''}`}
                                                    value={`${n}`}
                                                    selected
                                                    disabled
                                                >
                                                    {`${n}`.toLocaleUpperCase()}
                                                </ToggleButton>
                                            ))
                                        }
                                    </div>
                                </div>
                            </HorizontalExpander>
                            <HorizontalExpander
                                title={'COMPONENTS'}
                                color={'primary'}
                            >
                                <div className='fdc aic mb15'>
                                    <div className='fdr jcsb fww'>
                                        {
                                            value.components?.map((n, i) => (
                                                <ToggleButton
                                                    key={n}
                                                    className={`mt15 ${i !== ((value.components?.length ?? 0) - 1) ? 'mr15' : ''}`}
                                                    value={`${n}`}
                                                    selected
                                                    disabled
                                                >
                                                    {`${n}`.toLocaleUpperCase()}
                                                </ToggleButton>
                                            ))
                                        }
                                    </div>
                                </div>
                            </HorizontalExpander>
                            {
                                !!value.insects?.length &&
                                <HorizontalExpander
                                    title={'INSECTS'}
                                    color={'primary'}
                                >
                                    <div className='fdc aic mb15'>
                                        <div className='fdr jcsb fww'>
                                            {
                                                value.insects.map((n, i) => (
                                                    <ToggleButton
                                                        key={n}
                                                        className={`mt15 ${i !== ((value.insects?.length ?? 0) - 1) ? 'mr15' : ''}`}
                                                        value={`${n}`}
                                                        selected
                                                        disabled
                                                    >
                                                        {`${n}`.toLocaleUpperCase()}
                                                    </ToggleButton>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </HorizontalExpander>
                            }
                            {
                                !!value.diseases?.length &&
                                <HorizontalExpander
                                    title={'DISEASES'}
                                    color={'primary'}
                                >
                                    <div className='fdc aic mb15'>
                                        <div className='fdr jcsb fww'>
                                            {
                                                value.diseases.map((n, i) => (
                                                    <ToggleButton
                                                        key={n}
                                                        className={`mt15 ${i !== ((value.diseases?.length ?? 0) - 1) ? 'mr15' : ''}`}
                                                        value={`${n}`}
                                                        selected
                                                        disabled
                                                    >
                                                        {`${n}`.toLocaleUpperCase()}
                                                    </ToggleButton>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </HorizontalExpander>
                            }
                            {
                                !!value.damages?.length &&
                                <HorizontalExpander
                                    title={'DAMAGES'}
                                    color={'primary'}
                                >
                                    <div className='fdc aic mb15'>
                                        <div className='fdr jcsb fww'>
                                            {
                                                value.damages.map((n, i) => (
                                                    <ToggleButton
                                                        key={n}
                                                        className={`mt15 ${i !== ((value.damages?.length ?? 0) - 1) ? 'mr15' : ''}`}
                                                        value={`${n}`}
                                                        selected
                                                        disabled
                                                    >
                                                        {`${n}`.toLocaleUpperCase()}
                                                    </ToggleButton>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </HorizontalExpander>
                            }
                        </>
                    }
                    <ScoutingEmergencyPhotosView
                        value={value}
                        entries={entries}
                    />
                </div>
            </div>
        );
    }
}
