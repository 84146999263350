import React from 'react';
import { LayerGroup, MapLayerProps, Polyline, withLeaflet, CircleMarker, Popup } from 'react-leaflet';
import { createSelector } from 'reselect';
import { LatLng } from 'leaflet';
import moment from 'moment';
import { RootAction, DispatchCall } from '../../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import ScoutingActions from '../../../store/scouting/actions';
import { connect } from 'react-redux';
import GeneralActions from '../../../store/general/actions';
import { IAssignmentBlock } from '../../../types/model/masterData/block';

interface IAssignmentLocationHistoryPolylineProps extends MapLayerProps  {
    date : moment.Moment;
    scoutingHistory : number;
    landName : string;
    selectedBlocks : Array<IAssignmentBlock>;

    generalShowErrorSnackbar : DispatchCall<string>;
    setLoading : DispatchCall<boolean>;

    loadLocations : () => Promise<Record<string, {
        positions : Array<LatLng>;
        color : string;
    }>>; 
}

interface IAssignmentLocationHistoryPolylineState {
    locations : Record<string, {
        positions : Array<LatLng>;
        color : string;
    }>;
}

class AssignmentLocationHistoryPolylineComponent extends React.PureComponent<IAssignmentLocationHistoryPolylineProps, IAssignmentLocationHistoryPolylineState> {
    constructor(props : IAssignmentLocationHistoryPolylineProps) {
        super(props);
        this.state = {
            locations: {},
        };
    }

    public readonly componentDidMount = () => {
        this.loadLocations();
    }

    public readonly componentDidUpdate = (prevProps : IAssignmentLocationHistoryPolylineProps) => {
        if (!this.props.date.isSame(prevProps.date) ||
            prevProps.scoutingHistory !== this.props.scoutingHistory) {
            this.loadLocations();
        }
    }

    private readonly loadLocations = async () => {
        this.props.setLoading(true);

        const locations : Record<string, {
            positions : Array<LatLng>;
            color : string;
        }> = await this.props.loadLocations();

        this.props.setLoading(false);

        this.setState({
            locations,
        });
    }

    private readonly getLocations = (state : IAssignmentLocationHistoryPolylineState) => state.locations;
    private readonly getSelectedBlocks = (state : IAssignmentLocationHistoryPolylineState, props : IAssignmentLocationHistoryPolylineProps) => props.selectedBlocks;
    private readonly getBlockNames = createSelector(
        [this.getLocations, this.getSelectedBlocks],
        (locations, selectedScoutingBlocks) => {
            const selectedBlocks = selectedScoutingBlocks.map(n => n.name).sort();
            return Object.keys(locations).sort().filter(x => selectedBlocks.includes(x));
        },
    );

    public readonly onLayerRef = () => {
        // TODO: Add to layer control
    }

    public readonly render = () => {
        const { locations } = this.state;
        const blockNames = this.getBlockNames(this.state, this.props);
        return (
            <LayerGroup ref={this.onLayerRef}>
                {
                    !blockNames.length &&
                    <div></div>
                }
                {
                    blockNames.map(n => (
                        <CircleMarker key={`${n}_start`} center={locations[n].positions[0]} radius={3} color={'red'} weight={1} fillOpacity={1}>
                            <Popup>
                                <span>Start - {n}</span>
                            </Popup>
                        </CircleMarker>
                    ))
                }
                {
                    blockNames.map(n => (
                        <CircleMarker key={`${n}_start`} center={locations[n].positions[locations[n].positions.length - 1]} radius={3} color={'orange'} weight={1} fillOpacity={1}>
                            <Popup>
                                <span>End - {n}</span>
                            </Popup>
                        </CircleMarker>
                    ))
                }
                {
                    blockNames.map(n => (
                        <Polyline key={`${n}_line`} positions={locations[n].positions} color={locations[n].color} weight={1} opacity={1} />
                    ))
                }
            </LayerGroup>
        );
    }
}

const mapStateToProps = () => {
    return {
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators({
    generalShowErrorSnackbar: (message : string) => dispatcher(GeneralActions.enqueueSnackbar({
        message,
        options: {
            variant: 'error',
        },
    })),
    setLoading: (isLoading : boolean) => dispatcher(ScoutingActions.setLoadingAssignments(isLoading)),
}, dispatcher);

const AssignmentLocationHistoryPolyline = connect(
    mapStateToProps,
    mapDispatchToProps,
)(withLeaflet(AssignmentLocationHistoryPolylineComponent));

export default AssignmentLocationHistoryPolyline;
