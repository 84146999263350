import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import BlockMap from '../../customComponents/map/BlockMap';
import PhenologyAssignmentLocationMarkers from '../../customComponents/map/PhenologyAssignmentLocationMarkers';
import { createSelector } from 'reselect';
import { LatLng } from 'leaflet';
import AppFunctionsService from '../../../services/appFunctionServices';
import { IPhenologyAssignment } from '../../../types/model/phenology/assignment';
import lodash from 'lodash';

interface IPhenologyAssignmentInfoViewProps {
    value : IPhenologyAssignment;
}

interface IPhenologyAssignmentInfoViewState {}

export default class PhenologyAssignmentInfoView extends React.PureComponent<IPhenologyAssignmentInfoViewProps, IPhenologyAssignmentInfoViewState> {

    constructor(props : IPhenologyAssignmentInfoViewProps) {
        super(props);
        this.state = {};
    }

    private getScoutingBlock = (props : IPhenologyAssignmentInfoViewProps) => props.value.scoutingBlock;
    private getFinished = (props : IPhenologyAssignmentInfoViewProps) => props.value.finishedPoints;
    private getFinishedPoints = createSelector(
        [this.getFinished, this.getScoutingBlock],
        (finishedPoints, block) => {
            const guids = lodash
                .chain(finishedPoints)
                .map(x => x)
                .flatMap(x => x)
                .uniq()
                .value();
            return guids.map(n => new LatLng(block.phenologyPoints[n].latitude, block.phenologyPoints[n].longitude));
        },
    );

    public render = () => {
        const { value } = this.props;

        const finishedPoints = this.getFinishedPoints(this.props);
        return (
            <div className='fdr flx1 ais'>
                <div className='fdc flx1 ais mr5'>
                    <AppBar className='fdc ais' variant='outlined' position='static'>
                        <Toolbar variant='dense' className={'fdr aic jcc'}>
                            <Typography className={'cw'} variant='h6'>
                            BLOCK - {value.blockName}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdc flx1'>
                        <BlockMap phenology block={this.props.value.scoutingBlock}>
                            <PhenologyAssignmentLocationMarkers assignmentId={value.id} finishedPoints={finishedPoints} />
                        </BlockMap>
                    </div>
                </div>
                <div className='fdc flx1 ais ml5'>
                    <AppBar className='fdc ais' variant='outlined' position='static'>
                        <Toolbar variant='dense' className={'fdr aic jcc'}>
                            <Typography className={'cw'} variant='h6'>
                            ASSIGNMENT
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            DATE
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {AppFunctionsService.formatDateTimeToDateOnly(value.date)}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            COMPLETED POINTS
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {`${value.finishedPoints.length} / ${Object.keys(value.scoutingBlock.points).length}`}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                SCOUT
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {`${value.employeeNumber} - ${value.employeeName}`}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            DIVISION
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.division.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            LAND NAME
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.landName.toLocaleUpperCase()}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            BLOCK NAME
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.blockName.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            LAND DESCRIPTION
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.scoutingBlock.description.toLocaleUpperCase()}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            CROP
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.crop.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            AGE
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.scoutingBlock.age.toFixed(0)}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            AREA
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    !value.scoutingBlock.ha &&
                                    '-'
                                }
                                {
                                    !!value.scoutingBlock.ha &&
                                    `${value.scoutingBlock.ha.toFixed(2)} Ha`
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
