import ReactPDF, { Document, Font, Text , Page, View, Canvas } from '@react-pdf/renderer';
import moment from 'moment';
import React from 'react';
import { createSelector } from 'reselect';
import pdfStyles from '../../../../style/pdfStyleSheet';
import { Ecc } from '../../../../types/qrcodegen';
import AppFunctionsService from '../../../../services/appFunctionServices';
import QrCodeService from '../../../../services/qrCodeService';
import { PhenologySpecificHelper, PhenologySpecificTreeType } from '../../../../types/model/phenology/specific';

interface IPhenologySpecificTreeQrCodeDocumentProps {
    onRender ?: (props : ReactPDF.OnRenderProps) => void;

    trees : Array<PhenologySpecificTreeType>;
}

interface IPhenologySpecificTreeQrCodeDocumentState {}

export default class PhenologySpecificTreeQrCodeDocument extends React.PureComponent<IPhenologySpecificTreeQrCodeDocumentProps, IPhenologySpecificTreeQrCodeDocumentState> {
    private readonly documentSize = {
        width: 120,
        height: 135,
    };

    constructor(props : IPhenologySpecificTreeQrCodeDocumentProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount() {
        Font.register({
            family: 'Roboto',
            src: '/assets/fonts/Roboto-Regular.ttf',
            format: 'truetype',
            fonts: [
                { src: '/assets/fonts/Roboto-Regular.ttf' },
                { src: '/assets/fonts/Roboto-Medium.ttf', fontWeight: 500 },
                { src: '/assets/fonts/Roboto-Bold.ttf', fontWeight: 700 },
                { src: '/assets/fonts/Roboto-Black.ttf', fontWeight: 800 },
            ],
        });
    }

    public getPhenologySpecificTrees = (state : IPhenologySpecificTreeQrCodeDocumentState, props : IPhenologySpecificTreeQrCodeDocumentProps) => props.trees;

    public readonly getFileName = createSelector([],
        () => {
            return `trees-${moment.utc().format('YYYY-MM-DD')}.pdf`;
        },
    );

    public getPages = createSelector([
        this.getPhenologySpecificTrees,
    ],
    (trees) => {
        return trees.map(tree => (
            <Page
                key={tree.id}
                orientation='portrait'
                break
                size={this.documentSize}
                style={[
                    pdfStyles.body,
                    this.documentSize,
                ]}
            >
                <View style={[
                    pdfStyles.fdc,
                    pdfStyles.flx1,
                    pdfStyles.aic,
                    pdfStyles.jcc,
                ]}>
                    <Canvas paint={(
                        painter,
                    ) => {
                        QrCodeService.paintOnPdfCanvas(
                            painter,
                            {
                                text: PhenologySpecificHelper.getQrCodeText(tree),
                                ecc: Ecc.HIGH,
                                scale: 1.3,
                                border: 1,
                            }
                        );

                        return null;
                    }} />
                    <View style={[
                        pdfStyles.fdc,
                        pdfStyles.aic,
                        pdfStyles.jcc,
                        pdfStyles.mt15,
                    ]}>
                        <Text
                            style={[
                                pdfStyles.fs8,
                                pdfStyles.cdg,
                                pdfStyles.fwm,
                            ]}
                        >
                            {tree.code}
                        </Text>
                        <Text
                            style={[
                                pdfStyles.fs8,
                                pdfStyles.cdg,
                                pdfStyles.fwm,
                            ]}
                        >
                            ({AppFunctionsService.toTitleCase(tree.landName)} - {tree.blockName})
                        </Text>
                        <Text
                            style={[
                                pdfStyles.fs8,
                                pdfStyles.cdg,
                                pdfStyles.fwm,
                            ]}
                        >
                            {AppFunctionsService.toTitleCase(tree.cultivarName)}
                        </Text>
                    </View>
                </View>
            </Page>
        ));
    },);

    public render = () => {
        const {
            onRender,
        } = this.props;

        const pages = this.getPages(this.state, this.props);
        return (
            <Document
                title={this.getFileName(this.props)}
                subject={this.getFileName(this.props)}
                onRender={onRender}
            >
                {
                    pages
                }
            </Document>
        );
    }
}
