import { createAction } from 'typesafe-actions';
import { IScouting } from '../../types/model/masterData/scouting';
import { IScoutingAssignment } from '../../types/model/scouting/scoutingAssignment';

export default class ScoutingActions {
    public static setLoading = createAction('SCOUTING_SET_LOADING', resolve => (loading : boolean) => resolve(loading));
    public static setList = createAction('SCOUTING_SET', resolve => (data : Array<IScouting>) => resolve(data));
    public static setLoadingAssignments = createAction('SCOUTING_SET_LOADING_ASSIGNMENTS', resolve => (loading : boolean) => resolve(loading));
    public static setAssignments = createAction('SCOUTING_SET_ASSIGNMENTS', resolve => (list : Array<IScoutingAssignment>) => resolve(list));
}
