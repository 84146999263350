import React from 'react';
import { connect } from 'react-redux';
import 'leaflet/dist/leaflet.css';
import { IRootState } from '../../../@types/redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Paper from '@material-ui/core/Paper';
import L, { LatLng } from 'leaflet';
import { Map } from 'react-leaflet';
import { createSelector } from 'reselect';
import { CROP } from '../../../appConstants';
import { IScoutingAssignment, ScoutingType } from '../../../types/model/scouting/scoutingAssignment';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import ScoutingAssignmentTypeSelector from './ScoutTypeSelector';
import ScoutingAssignmentFieldSelector from './FieldSelector';
import lodash from 'lodash';
import ScoutingFunctions from '../../../store/scouting/functions';
import StandardLayerControl from '../../customComponents/map/StandardLayerControl';
import BlockPolygonLayerGroup from '../../customComponents/map/BlockPolygonLayerGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import StadiumButton from '../../customComponents/button/StadiumButton';
import ScoutingAssignmentFieldsView from './FieldsView';
import ScoutingAssignmentCreateDialog from '../dialog/AssignmentCreateDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import NavFunctions from '../../../store/nav/functions';
import Button from '@material-ui/core/Button';
import AssignmentLocationHistoryPolyline from '../../customComponents/map/AssignmentLocationHistoryPolyline';
import AssignmentWeekSelector from '../../customComponents/selector/AssignmentWeekSelector';
import AssignmentDivisionSelector from '../../customComponents/selector/AssignmentDivisionSelector';
import GeneralFunctions from '../../../store/general/functions';
import { BlockHelper, IAssignmentBlock, IBlock } from '../../../types/model/masterData/block';
import { IBlockPolygonLayer } from '../../../types/model/masterData/blockPolygonLayer';
import { ScoutingLocationEntryHelper } from '../../../types/model/scouting/scoutingLocationEntry';
import SpecificScoutingAssignmentAlertDialog from '../dialog/SpecificScoutingAlertDialog';
import { IDivision } from '../../../types/model/rights/division';

interface IScoutingAssignmentCreateProps extends RouteComponentProps {
    isLoading : boolean;

    blocks : Array<IBlock>;
    assignments : Array<IScoutingAssignment>;
}

interface IScoutingAssignmentCreateState {
    landName ?: string;
    division ?: IDivision;
    scoutType ?: ScoutingType;

    sections : Array<string>;
    components : Array<string>;
    insects : Array<string>;
    diseases : Array<string>;
    damages : Array<string>;

    /**
     * Scouting blocks that will be used to create assignments
     */
    scoutingBlocks : Record<string, Array<IAssignmentBlock>>;

    /**
     * Currently selected blocks for zooming and panning on map.
     * Used when Add Fields is clicked.
     */
    selectedScoutingBlocks : Array<IAssignmentBlock>;

    date ?: moment.Moment;

    showAssignment : boolean;
    showCreate : boolean;

    showScoutHistory : boolean;
    scoutingHistory ?: number;
}

class AssignmentCreate extends React.Component<IScoutingAssignmentCreateProps, IScoutingAssignmentCreateState> {
    private mapRef ?: Map;

    private readonly mapZoom = 8;
    private readonly mapCenter = new LatLng(-23.5520414, 30.1148622);

    constructor(props : IScoutingAssignmentCreateProps) {
        super(props);
        this.state = {
            scoutingBlocks: {},
            selectedScoutingBlocks: [],
            date: moment.utc().startOf('day'),
            showAssignment: true,
            showCreate: false,
            showScoutHistory: false,
            sections: [],
            components: [],
            insects: [],
            diseases: [],
            damages: [],
        };
    }

    public readonly componentDidMount = () => {
        GeneralFunctions.getBlocks();
        ScoutingFunctions.getAssignments(this.getCrop(this.props), false);
    }

    private readonly setMapRef = (ref : Map) => {
        this.mapRef = ref;
    }

    private readonly getSearch = (props : IScoutingAssignmentCreateProps) => props.location.search;
    private readonly getScoutingBlocks = (props : IScoutingAssignmentCreateProps, state : IScoutingAssignmentCreateState) => state.scoutingBlocks;
    private readonly getBlocks = (props : IScoutingAssignmentCreateProps) => props.blocks;
    private readonly getSelectedBlocks = (props : IScoutingAssignmentCreateProps, state : IScoutingAssignmentCreateState) => state.selectedScoutingBlocks;
    private readonly getShowAssignment = (props : IScoutingAssignmentCreateProps, state : IScoutingAssignmentCreateState) => state.showAssignment;
    private readonly getDivision = (props : IScoutingAssignmentCreateProps, state : IScoutingAssignmentCreateState) => state.division;
    
    private readonly getCrop = createSelector([
        this.getSearch,
    ], (search) => {
        const urlParams = new URLSearchParams(search);
        const crop = urlParams.get('crop');
        return crop as CROP;
    });

    private readonly getBlockLayers = createSelector([
        this.getScoutingBlocks, this.getSelectedBlocks, this.getShowAssignment,
        this.getBlocks, this.getCrop, this.getDivision,
    ], (scoutingBlocks, selectedBlocks, showAssignment, blocks, crop, division) => {
        const layer : Array<IBlockPolygonLayer> = [];

        if (!showAssignment) {
            selectedBlocks
                .forEach(n => layer.push(BlockHelper.toLayer(n)));

            return layer;
        }

        const keys = Object.keys(scoutingBlocks);

        keys.forEach((key) => {
            scoutingBlocks[key]
                .forEach(n => layer.push(BlockHelper.toLayer(n)));
        });

        if (!layer.length) {
            blocks
                .filter(x => x.crop === crop)
                .filter(x => !division || division.code.toLocaleUpperCase() === x.division.toLocaleUpperCase())
                .forEach(n => layer.push(BlockHelper.toLayer(n)));
        }

        return layer;
    });

    private readonly onWeekChanged = (date ?: moment.Moment) => {
        this.setState({
            date,
        });
    }

    private readonly onDivisionChanged = (division ?: IDivision) => {
        this.setState({
            division,
            selectedScoutingBlocks: [],
            landName: undefined,
            scoutingBlocks: {},
        });
    }

    private readonly onScoutTypeChange = (scoutType ?: ScoutingType) => {
        this.setState({
            scoutType,
            sections: [],
            components: [],
            insects: [],
            diseases: [],
            damages: [],
        });
    }

    private readonly onSectionsChange = (sections ?: Array<string> | null) => {
        this.setState({
            sections: sections ?? [],
            components: [],
            insects: [],
            diseases: [],
            damages: [],
        });
    }

    private readonly onComponentsChange = (components ?: Array<string> | null) => {
        this.setState({
            components: components ?? [],
            insects: [],
            diseases: [],
            damages: [],
        });
    }

    private readonly onInsectsChange = (insects ?: Array<string> | null) => {
        this.setState({
            insects: insects ?? [],
        });
    }

    private readonly onDiseasesChange = (diseases ?: Array<string> | null) => {
        this.setState({
            diseases: diseases ?? [],
        });
    }

    private readonly onDamagesChange = (damages ?: Array<string> | null) => {
        this.setState({
            damages: damages ?? [],
        });
    }

    private readonly onAddFieldsClick = () => {
        this.setState({
            showAssignment: !this.state.showAssignment,
            showScoutHistory: false,
            selectedScoutingBlocks: [],
            landName: undefined,
        });
    }

    private readonly onLandScoutingChange = (landName : string, blocks : Array<IAssignmentBlock>) => {
        this.setState({
            selectedScoutingBlocks: blocks,
            landName,
        });

        if (this.mapRef && blocks.length) {
            const block = BlockHelper.toLayer(blocks[blocks.length - 1]);
            if (typeof block !== 'undefined') {
                this.flyTo(block);
            }
        }
    }

    private readonly flyTo = (block : IBlockPolygonLayer) => {
        if (this.mapRef && typeof block !== 'undefined') {
            this.mapRef.leafletElement.flyToBounds(L.polygon(block.positions).getBounds());
        }
    }

    private readonly onAddClick = () => {
        const { landName, selectedScoutingBlocks } = this.state;
        if (!landName || !selectedScoutingBlocks.length) return;

        const scoutingBlocks = lodash.cloneDeep(this.state.scoutingBlocks);

        scoutingBlocks[landName] = selectedScoutingBlocks.slice();

        this.setState({
            showAssignment: !this.state.showAssignment,
            showScoutHistory: false,
            selectedScoutingBlocks: [],
            landName: undefined,
            scoutingBlocks,
        });
    }

    public readonly onDeleteLandClick = (landName : string) => {
        if (!landName) return;

        const scoutingBlocks = lodash.cloneDeep(this.state.scoutingBlocks);
        delete scoutingBlocks[landName];

        this.setState({
            scoutingBlocks,
        });
    }

    public readonly onZoomClick = (landName : string) => {
        if (!landName) return;

        const blocks = [...this.state.scoutingBlocks[landName]];

        if (this.mapRef && blocks.length) {
            this.mapRef.leafletElement.flyToBounds(L.polygon(blocks.map(n => BlockHelper.toLayer(n)).map(n => n.positions)).getBounds());
        }

    }

    private readonly onEditClick = (landName : string) => {
        if (!landName) return;

        const selectedScoutingBlocks = [...this.state.scoutingBlocks[landName]];

        this.setState({
            showAssignment: !this.state.showAssignment,
            selectedScoutingBlocks,
            landName,
        });
    }

    private readonly onAssignmentAddClick = () => {
        this.setState({
            showCreate: !this.state.showCreate,
        });
    }

    private readonly onAssignmentCreateClose = (result : boolean) => {
        this.setState({
            showCreate: !this.state.showCreate,
        });

        if (result) {
            NavFunctions.navReplacePath('/scouting/assignments', this.props.location.search);
        }
    }

    private readonly onShowScoutsClick = () => {
        this.setState({
            showScoutHistory: !this.state.showScoutHistory,
            scoutingHistory: !this.state.showScoutHistory ? 1 : undefined,
        });
    }

    private readonly onShowScoutsDayClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.setState({
            scoutingHistory: parseInt(event.currentTarget.value),
        });
    }

    private readonly onContinueClick = () => {
        this.setState({
            scoutType: 'general',
            sections: [],
            components: [],
            insects: [],
            diseases: [],
            damages: [],
        });
    }

    private readonly onAlertChange = (
        sections : Array<string>,
        components : Array<string>,
        insects : Array<string>,
        diseases : Array<string>,
        damages : Array<string>
    ) => {
        this.setState({
            sections,
            components,
            insects,
            diseases,
            damages,
        });
    }

    private loadHistoryLocations  = async () => {
        const { date, landName } = this.state;

        const locations : Record<string, {
            positions : Array<LatLng>;
            color : string;
        }> = {};

        const assignments = this.props.assignments.filter(x =>
            x.landName === landName
            && x.finished
            && x.date === date?.valueOf());

        const promises : Array<Promise<unknown>> = [];

        try {
            assignments.forEach((assignment) => {
                promises.push(
                    ScoutingLocationEntryHelper.collection(assignment.id).get().then((snap) => {
                        locations[assignment.blockName] = {
                            positions : snap.docs.map(n => n.data()).sort((a, b) => a.date - b.date).map(n => new LatLng(n.latitude, n.longitude, n.altitude)),
                            color: 'blue',
                        };
                    }),
                );
            });

            await Promise.all(promises);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar(`${ex}`);
        }

        return locations;
    }

    public render = () => {
        const { isLoading } = this.props;
        const { division, date, scoutType,
            showAssignment, landName,
            selectedScoutingBlocks, scoutingBlocks,
            showCreate, showScoutHistory, scoutingHistory,
            sections, components, insects,
            diseases, damages } = this.state;

        const blocks = this.getBlockLayers(this.props, this.state);
        const crop = this.getCrop(this.props);

        return (
            <div className={'fdr flx1 p10 mh0 mw0 bcg0'}>
                <Paper className={'flx3 fdc oxh p5 m5'}>
                    <div style={{ position: 'relative' }}>
                        {
                            isLoading &&
                            <LinearProgress className='wfill' style={{
                                position: 'absolute',
                                zIndex: 1000,
                            }} />
                        }
                    </div>
                    <Map ref={this.setMapRef} maxZoom={20} className={'flx1 bcw'} center={this.mapCenter} zoom={this.mapZoom} preferCanvas>
                        <BlockPolygonLayerGroup zIndex={5} blocks={blocks} />
                        <StandardLayerControl division={division?.code} polygonOpacity={0.2} polygonWidth={5} managementAreas={false} />
                        {
                            !showAssignment &&
                            !!date &&
                            !!landName &&
                            !!scoutingHistory &&
                            <AssignmentLocationHistoryPolyline
                                landName={landName}
                                scoutingHistory={scoutingHistory}
                                date={date}
                                selectedBlocks={selectedScoutingBlocks}
                                loadLocations={this.loadHistoryLocations} />
                        }
                    </Map>
                    {
                        !showAssignment &&
                        !!landName &&
                        <div style={{ position: 'relative' }}>
                            <div style={{
                                position: 'absolute',
                                bottom: 5,
                                left: 5,
                                zIndex: 1000,
                            }}>
                                <Button className={`${showScoutHistory ? 'bcy cpd' : 'bcp cw'} dbcg dcg pl25 pr25 br25 bsb h36 fwb`} disabled={!landName || isLoading} onClick={this.onShowScoutsClick}>
                                    VIEW SCOUTS
                                </Button>
                            </div>
                        </div>
                    }
                    {
                        showScoutHistory &&
                        !!landName &&
                        <div style={{ position: 'relative', flexDirection: 'row' }}>
                            <div style={{
                                position: 'absolute',
                                bottom: 5,
                                left: 180,
                                zIndex: 1000,
                            }}>
                                {
                                    [1, 2, 3].map(n => (
                                        <Button key={`scout_his_${n}`} value={n} className={`${scoutingHistory === n ? 'bcy cpd' : 'bcp cw'} dbcg dcg pl25 pr25 br25 bsb h36 fwb mr15`}
                                            disabled={!landName || isLoading} onClick={this.onShowScoutsDayClick}>
                                            VIEW {n} DAY{n > 1 ? 'S' : ''} AGO
                                        </Button>
                                    ))
                                }
                            </div>
                        </div>
                    }
                </Paper>
                {
                    showAssignment &&
                    <Paper className={'flx1 fdc m5'}>
                        <AppBar position='static' className='bcdg pbr' elevation={0}>
                            <Toolbar variant='dense'>
                                <Typography className='fs17'>
                                    ASSIGNMENTS
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <div className='fdc hfill oya drawer'>
                            <AssignmentWeekSelector date={date} onChanged={this.onWeekChanged} />
                            <AssignmentDivisionSelector crop={crop} value={division} onChanged={this.onDivisionChanged} />
                            <ScoutingAssignmentTypeSelector
                                value={scoutType}
                                sections={sections}
                                components={components}
                                insects={insects}
                                diseases={diseases}
                                damages={damages}
                                onChange={this.onScoutTypeChange}
                                onSectionsChange={this.onSectionsChange}
                                onComponentsChange={this.onComponentsChange}
                                onInsectsChange={this.onInsectsChange}
                                onDiseasesChange={this.onDiseasesChange}
                                onDamagesChange={this.onDamagesChange}
                            />
                            <ScoutingAssignmentFieldsView
                                division={division?.code}
                                landName={landName}
                                date={date}
                                scoutType={scoutType}
                                isLoading={isLoading}
                                scoutingBlocks={scoutingBlocks}
                                onAddFieldsClick={this.onAddFieldsClick}
                                onDeleteClick={this.onDeleteLandClick}
                                onZoomClick={this.onZoomClick}
                                onEditClick={this.onEditClick} />
                        </div>
                        <div className={'fdc aife jcc p10 mt5'}>
                            <StadiumButton variant='contained' className='bcy cpd dbcg dcg bsd' disabled={!Object.keys(scoutingBlocks).length} onClick={this.onAssignmentAddClick}>
                                ADD ASSIGNMENT
                            </StadiumButton>
                        </div>
                    </Paper>
                }
                {
                    !showAssignment &&
                    <ScoutingAssignmentFieldSelector crop={crop}
                        division={division?.code} landName={landName} scoutType={scoutType}
                        onBackClick={this.onAddFieldsClick} date={date} selectedScoutingBlocks={selectedScoutingBlocks}
                        onChange={this.onLandScoutingChange} onAddClick={this.onAddClick} />
                }
                {
                    scoutType === 'specific' &&
                    <SpecificScoutingAssignmentAlertDialog
                        selectedSections={sections}
                        selectedComponents={components}
                        selectedInsects={insects}
                        selectedDiseases={diseases}
                        selectedDamages={damages}
                        onContinueClick={this.onContinueClick}
                        onChange={this.onAlertChange}
                    />
                }
                {
                    scoutType &&
                    <ScoutingAssignmentCreateDialog
                        open={showCreate}
                        isLoading={isLoading}
                        date={date}
                        division={division?.code}
                        transition={Transitions.Down}
                        maxWidth='md' fullWidth
                        onClose={this.onAssignmentCreateClose}
                        scoutingBlocks={scoutingBlocks}
                        scoutType={scoutType}
                        sections={sections}
                        components={components}
                        insects={insects}
                        diseases={diseases}
                        damages={damages}
                        crop={crop}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.scouting.isLoadingAssignments || state.general.isLoadingBlocks,
        blocks: state.general.blocks,
        assignments: state.scouting.assignments,
    };
};

export default connect(
    mapStateToProps,
)(withRouter(AssignmentCreate));
