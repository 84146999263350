import { createAction } from 'typesafe-actions';
import { INotification } from '../../@types/redux';
import { IArea } from '../../types/model/area';
import { IBlock } from '../../types/model/masterData/block';

export default class GeneralActions {
    public static enqueueSnackbar = createAction('ENQUEUE_SNACKBAR', resolve => (notification : INotification) => resolve({
        key: new Date().getTime() + Math.random(),
        ...notification,
    }));
    public static removeSnackbar = createAction('REMOVE_SNACKBAR', resolve => (key : number) => resolve(key));

    public static setLoadingAreas = createAction('GENERAL_SET_LOADING_AREAS', resolve => (loading : boolean) => resolve(loading));
    public static setAreas = createAction('GENERAL_SET_AREAS', resolve => (list : Array<IArea>) => resolve(list));
    public static setLoadingBlocks = createAction('SCOUTING_SET_LOADING_BLOCKS', resolve => (loading : boolean) => resolve(loading));
    public static setBlocks = createAction('SCOUTING_SET_BLOCKS', resolve => (blocks : Array<IBlock>) => resolve(blocks));
}
