import React from 'react';
import { PolygonProps, Polygon, Marker } from 'react-leaflet';
import { createSelector } from 'reselect';
import L, { LatLng } from 'leaflet';

interface IPolygonWithTextProps extends PolygonProps {
    title ?: string;

    showMarker ?: boolean;
}

interface IPolygonWithTextState {}

export default class PolygonWithText extends React.Component<IPolygonWithTextProps, IPolygonWithTextState> {
    constructor(props : IPolygonWithTextProps) {
        super(props);
        this.state = {};
    }

    private getTitle = (state : IPolygonWithTextState, props : IPolygonWithTextProps) => props.title;
    private getPositions = (state : IPolygonWithTextState, props : IPolygonWithTextProps) => props.positions;

    private getMarkers = createSelector(
        [this.getPositions, this.getTitle],
        (positions, title) => {
            const markers : Array<{
                center : LatLng;
                textIcon : L.DivIcon;
            }> = [];
            positions.forEach((n) => {
                if (title) {
                    const element = document.createElement('span');
                    element.append(title);
                    const textIcon = L.divIcon({
                        html: element,
                        className: 'bct cw fwb bts fs16',
                    });

                    const center : L.LatLng = L.polygon(n).getBounds().getCenter();
                    markers.push({
                        center,
                        textIcon,
                    });
                }
            });
            return markers;
        },
    );

    public render = () => {
        const { title:_, showMarker = true, children, ...rest } = this.props;
        const markers = this.getMarkers(this.state, this.props);

        return (
            <Polygon {...rest}>
                {
                    showMarker &&
                    markers.map((marker, i) => (
                        <Marker key={`marker_icon_${i}`} position={marker.center} icon={marker.textIcon}  />
                    ))
                }
                {
                    children
                }
            </Polygon>
        );
    }
}
