import firebase from 'firebase/app';
import { CROP } from '../../../appConstants';
import firebaseApp from '../../../services/firebaseService';
import { BaseHelper, IBase } from '../../base';

export const ScoutingEntryChecks : ['insects', 'disease', 'damage'] = ['insects', 'disease', 'damage'];
export type ScoutingEntryCheckType = typeof ScoutingEntryChecks[number];

export interface IScouting extends IBase {
    crop : CROP;
    sections : Array<IScoutingSection>;
}

export interface IScoutingSection {
    name : string;

    entries : Array<IScoutingSectionEntry>;
}

export interface IScoutingSectionEntry {
    months : Array<number>;
    name : string;

    checks : Record<ScoutingEntryCheckType, Array<IScoutingSectionEntryCheck>>;
}

export interface IScoutingSectionEntryCheck {
    ref : firebase.firestore.DocumentReference | null;
    showOptions : boolean;
    singleRow : boolean;
    photoRequired : boolean;
    subName : string | null;
    options : Array<IScoutingEntryCheckOption>;
}

export interface IScoutingEntryCheckOption {
    name : string;
    counter : boolean;
    limit : number;
}

export default class ScoutingHelper extends BaseHelper {
    public static readonly COLLECTION = 'scouting';

    public static converter : firebase.firestore.FirestoreDataConverter<IScouting> = {
        fromFirestore: (snapshot) => {
            return ScoutingHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IScouting) => {
            return ScoutingHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IScouting {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as IScouting,
            ...result,
        };
    }
    protected static toFirestore(data : IScouting) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static save(scouting : IScouting) {
        if (scouting.id) {
            return this.collection().doc(scouting.id).set(scouting);
        }

        return this.collection().doc().set(scouting);
    }
}