import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import CheckTreeItem from '../../../customComponents/tree/CheckTreeItem';
import AppFunctionsService from '../../../../services/appFunctionServices';
import { createSelector } from 'reselect';
import GeneralFunctions from '../../../../store/general/functions';
import { connect } from 'react-redux';
import { IRootState } from '../../../../@types/redux';
import { IBlock } from '../../../../types/model/masterData/block';
import lodash from 'lodash';
import { CircularProgress } from '@material-ui/core';
import UserFcmHelper, { IUserFcm } from '../../../../types/model/rights/userFcm';
import { IUser } from '../../../../types/model/user';

interface IUserLandNotificationsEditTabProps {
    userFcm : IUserFcm;

    isLoading : boolean;
    blocks : Array<IBlock>;

    user : IUser | null;
}

interface IUserLandNotificationsEditTabState {}

class UserLandNotificationsEditTabComponent extends React.PureComponent<IUserLandNotificationsEditTabProps, IUserLandNotificationsEditTabState> {
    constructor(props : IUserLandNotificationsEditTabProps) {
        super(props);
        this.state = {};
    }
    
    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        GeneralFunctions.getBlocks();
    }

    private readonly onDivisionChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const userFcm : IUserFcm = {
            ...this.props.userFcm,
            notificationLands: {
                ...this.props.userFcm.notificationLands,
            }
        };

        const divisionCode = event.currentTarget.value;
        const landNames = this.getDivisionLands(this.state, this.props)[divisionCode];

        if (!landNames) return;

        if (userFcm.notificationLands[divisionCode]?.length !== landNames.length) {
            userFcm.notificationLands[divisionCode] = [...landNames];
        } else {
            delete userFcm.notificationLands[divisionCode];
        }

        UserFcmHelper.save(userFcm);
    }

    private readonly onLandNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const userFcm : IUserFcm = {
            ...this.props.userFcm,
            notificationLands: {
                ...this.props.userFcm.notificationLands,
            }
        };

        const [divisionCode, landName] = event.currentTarget.value.split(':');
        const landNames = userFcm.notificationLands[divisionCode]?.slice() ?? [];

        const index = landNames.indexOf(landName);
        if (index === -1) {
            landNames.unshift(landName);
        } else {
            landNames.splice(index, 1);
        }
        userFcm.notificationLands[divisionCode] = landNames;

        UserFcmHelper.save(userFcm);
    }

    private readonly getBlocks = (state : IUserLandNotificationsEditTabState, props : IUserLandNotificationsEditTabProps) => props.blocks;
    private readonly getUserDivisions = (state : IUserLandNotificationsEditTabState, props : IUserLandNotificationsEditTabProps) => props.user?.divisions ?? {};
    
    private readonly getDivisionCodes = createSelector(
        [this.getUserDivisions],
        (divisions) => {
            return lodash
                .chain(Object.keys(divisions))
                .sort((a, b) => divisions[a].localeCompare(divisions[b]))
                .value();
        },
    );

    private readonly getDivisionLands = createSelector([
        this.getBlocks,
        this.getUserDivisions,
    ], (
        blocks,
        divisions,
    ) => {
        const divisionLands = lodash.chain(blocks)
            .filter(n => Object.keys(divisions).map(x => x.toLocaleLowerCase()).includes(n.division.toLocaleLowerCase()))
            .groupBy(n => n.division.toLocaleUpperCase())
            .reduce((current, groupedBlocks, divisionCode) => ({
                ...current,
                [divisionCode]: lodash
                    .chain(groupedBlocks)
                    .map(x => AppFunctionsService.toTitleCase(x.landName))
                    .uniq()
                    .sort()
                    .value(),
            }), {} as Record<string, Array<string> | null>)
            .value();

        return divisionLands;
    });

    public readonly render = () => {
        const {
            isLoading,
            userFcm,
        } = this.props;

        const divisionCodes = this.getDivisionCodes(this.state, this.props);
        const divisionLands = this.getDivisionLands(this.state, this.props);
        const divisions = this.getUserDivisions(this.state, this.props);

        return (
            <div className='fdc ais flx1 p20'>
                {
                    !isLoading &&
                    <TreeView
                        defaultExpanded={['right_node_land']}
                        defaultCollapseIcon={<ArrowDropDownIcon />}
                        defaultExpandIcon={<ArrowRightIcon />}
                        defaultEndIcon={<div style={{ width: 24 }} />}>
                        {
                            divisionCodes.map((divisionCode) => (
                                <TreeItem
                                    key={divisionCode}
                                    nodeId={`division_node_${divisionCode}`}
                                    label={
                                        <CheckTreeItem
                                            labelText={AppFunctionsService.toTitleCase(divisions[divisionCode])}
                                            checked={
                                                !!userFcm.notificationLands[divisionCode] &&
                                                userFcm.notificationLands[divisionCode]?.length === divisionLands[divisionCode]?.length
                                            }
                                            indeterminate={
                                                !!userFcm.notificationLands[divisionCode] &&
                                                userFcm.notificationLands[divisionCode]?.length !== divisionLands[divisionCode]?.length
                                            }
                                            value={divisionCode}
                                            onChange={this.onDivisionChange}
                                        />
                                    }>
                                        
                                    {
                                        divisionLands[divisionCode]?.map((landName) => (
                                            <TreeItem
                                                key={landName}
                                                nodeId={`division_node_${divisionCode}_${landName}`}
                                                label={
                                                    <CheckTreeItem
                                                        labelText={landName}
                                                        checked={!!userFcm.notificationLands[divisionCode]?.includes(landName)}
                                                        indeterminate={false}
                                                        value={`${divisionCode}:${landName}`}
                                                        onChange={this.onLandNameChange}
                                                    />
                                                }
                                            />
                                        ))
                                    }
                                </TreeItem>
                            ))
                        }
                    </TreeView>
                }
                {
                    isLoading &&
                    <div className='fdc aic jcc flx1'>
                        <CircularProgress />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.general.blocks,
        isLoading: state.general.isLoadingBlocks,
        user: state.auth.session?.user ?? null,
    };
};

const UserLandNotificationsEditTab = connect(
    mapStateToProps,
)(UserLandNotificationsEditTabComponent);

export default UserLandNotificationsEditTab;