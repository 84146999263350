import { IBase, BaseHelper } from '../../base';
import firebaseApp from '../../../services/firebaseService';
import { CROP } from '../../../appConstants';
import firebase from 'firebase/app';

export interface IThreshold extends IBase {
    thresholds : { [key : string] : number };
    crop : CROP;
    type : THRESHOLD_TYPE;
}

export type THRESHOLD_TYPE = 'insects' | 'disease';

export class ThresholdHelper extends BaseHelper {
    public static readonly COLLECTION = 'thresholds';

    public static converter : firebase.firestore.FirestoreDataConverter<IThreshold | null> = {
        fromFirestore: (snapshot) => {
            return ThresholdHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IThreshold) => {
            return ThresholdHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IThreshold | null {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data || !result) return null;

        return {
            ...result,
            thresholds: data['thresholds'],
            crop: data['crop'],
            type: data['type'],
        };
    }

    protected static toFirestore(data : IThreshold) {

        const result = super.toFirestore(data);
        return {
            ...result,
            thresholds: data.thresholds,
            crop: data.crop,
            type: data.type,
        };
    }

    public static get(crop : string, type : string) {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(`${crop}_${type}`);
    }

    public static save(threshold : IThreshold) {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(threshold.id).set(threshold);
    }
}
