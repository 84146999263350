import GeneralActions from '../general/actions';
import lodash from 'lodash';
import { dispatch, getState } from '../../store';
import HttpService from '../../services/httpService';
import { AreaHelper } from '../../types/model/area';
import { BlockHelper, IBlock } from '../../types/model/masterData/block';
import firebaseApp from '../../services/firebaseService';
import firebase from 'firebase/app';

export default class GeneralFunctions {
    private static blockListener ?: () => void;

    public static generalShowErrorSnackbar = (message : string, ex ?: Error | unknown) => {
        let errorMessage = message;

        if (typeof(ex) === 'object' && ex instanceof Error) {
            if (ex.message && typeof(ex.message) === 'string') {
                errorMessage = ex.message;
            }
        } else if (typeof(ex) === 'string') {
            errorMessage += ` ${ex}`;
        }

        dispatch(GeneralActions.enqueueSnackbar({
            message: errorMessage,
            options: {
                variant: 'error',
            },
        }));
    }

    public static generalShowSuccessSnackbar = (message : string) => {
        dispatch(GeneralActions.enqueueSnackbar({
            message,
            options: {
                variant: 'success',
            },
        }));
    }

    public static generalShowWarningSnackbar = (message : string) => {
        dispatch(GeneralActions.enqueueSnackbar({
            message,
            options: {
                variant: 'warning',
            },
        }));
    }

    public static generalShowInfoSnackbar = (message : string) => {
        dispatch(GeneralActions.enqueueSnackbar({
            message,
            options: {
                variant: 'info',
            },
        }));
    }

    public static generalRemoveSnackbar = (key : number) => {
        dispatch(GeneralActions.removeSnackbar(key));
    }

    public static showPrintError(errorLocation : string, error : Error) {
        GeneralFunctions.generalShowErrorSnackbar(error.message);
    }

    public static getAreas = async (refresh ?: boolean) => {

        const generalState = getState().general;

        if (generalState.isLoadingAreas) return;
        if (generalState.areas.length && !refresh) return;

        try {
            dispatch(GeneralActions.setLoadingAreas(true));

            const token = getState().auth.arcGisToken;

            const result = await HttpService.getDivisions(token);

            const areas = result.data.features.map(n => AreaHelper.fromAttributes(n.attributes));
            dispatch(GeneralActions.setAreas(areas));
        } catch (ex) {
            if (ex) {
                GeneralFunctions.generalShowErrorSnackbar('An error while loading divisions.', ex);
            }
        } finally {
            dispatch(GeneralActions.setLoadingAreas(false));
        }
    }

    public static getBlocks = (refresh ?: boolean) => {
        if (!refresh && GeneralFunctions.blockListener) return;

        if (GeneralFunctions.blockListener) {
            GeneralFunctions.blockListener();
        }

        dispatch(GeneralActions.setLoadingBlocks(true));

        try {
            const session = getState().auth.session;
            if (!session) return;
            const divisions = Object.keys(session.user.divisions).map(n => n.toLocaleLowerCase());
            if (!divisions.length) throw new Error('No Divisions assigned to user.');
            GeneralFunctions.blockListener = BlockHelper
                .collection()
            // TODO: Filter divisions! Currently in limit is 10...
            // .where('division', 'in', divisions)
                .onSnapshot((snapshot) => {
                    const blocks = getState().general.blocks.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const block = f.doc.data();

                        if (!block || !divisions.includes(block.division)) return;

                        const index = lodash.findIndex(blocks, n => n.id === block.id);

                        switch (f.type) {
                        case 'added':
                            blocks.push(block);
                            break;
                        case 'modified':
                            blocks.splice(index, 1, block);
                            break;
                        case 'removed':
                            blocks.splice(index, 1);
                            break;
                        }
                    });

                    dispatch(GeneralActions.setBlocks(blocks));
                    dispatch(GeneralActions.setLoadingBlocks(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading blocks.', err);
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading blocks.', ex);
            dispatch(GeneralActions.setLoadingBlocks(false));
        }
    }

    /**
     * Batch saves a list of block
     */
    public static saveBlocks = async (selectedBlocks : Array<IBlock>) => {
        const blocks = lodash.cloneDeep(selectedBlocks);
        const ids = blocks.map(n => n.id);
        const deleteIds = getState().general.blocks
            .filter(x => x.crop === blocks[0].crop)
            .map(n => n.id)
            .filter(n => !ids.includes(n));

        let batchWrite = firebaseApp
            .firestore()
            .batch();

        let count = 0;

        const session = lodash.cloneDeep(getState().auth.session);
        if (!session) return;

        for (const id of deleteIds) {
            batchWrite.delete(BlockHelper.collection().doc(id));
        }
        await batchWrite.commit();
        batchWrite = firebaseApp
            .firestore()
            .batch();

        for (let i = 0; i < blocks.length; i++) {
            try {
                let block = getState().general.blocks.slice().find(x => x.id === blocks[i].id);

                if (!block) {
                    block = {} as IBlock;
                    block.id = blocks[i].id;
                    block.createdBy = session.user.ref;
                    block.createdByEmployee = session.user.employeeNumber;
                    block.createdByName = session.user.name;
                    block.createdOn = firebase.firestore.Timestamp.now().toMillis();
                }

                block.age = blocks[i].age;
                block.area = blocks[i].area;
                block.center = blocks[i].center;
                block.crop = blocks[i].crop;
                block.description = blocks[i].description;
                block.division = blocks[i].division;
                block.fatherId = blocks[i].fatherId;
                block.ha = blocks[i].ha;
                block.landId = blocks[i].landId;
                block.landName = blocks[i].landName;
                block.name = blocks[i].name;
                block.points = blocks[i].points;
                block.phenologyPoints = blocks[i].phenologyPoints;
                block.polygons = blocks[i].polygons;

                block.updatedBy = session.user.ref;
                block.updatedByEmployee = session.user.employeeNumber;
                block.updatedByName = session.user.name;
                block.updatedOn = firebase.firestore.Timestamp.now().toMillis();

                BlockHelper.batchSave(batchWrite, block);
                BlockHelper.batchSavePoints(batchWrite, block);
                BlockHelper.batchSavePhenologyPoints(batchWrite, block);

                count++;
                count++;
            } catch (ex) {
                GeneralFunctions.generalShowErrorSnackbar('An error saving block', ex);
            }

            // Batch write only supports 500 writes at a time, but I limit it to 200 at a time to be safe.
            if (count === 200 || (i === blocks.length - 1)) {
                try {
                    await batchWrite.commit();
                    batchWrite = firebaseApp
                        .firestore()
                        .batch();
                } catch (ex) {
                    GeneralFunctions.generalShowErrorSnackbar('An error saving block batch', ex);
                } finally {
                    count = 0;
                }
            }
        }
    }
}
