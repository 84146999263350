import React from 'react';
import { IGeoPoint } from '../../types/model/geoPoint';
import { AppBar, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, IconButton, PropTypes, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';
import { Transitions } from '../customComponents/animations/Transitions';
import StadiumButton from '../customComponents/button/StadiumButton';
import StandardLayerControl from '../customComponents/map/StandardLayerControl';
import { Map, Marker } from 'react-leaflet';
import { createSelector } from 'reselect';
import { DEFAULT_MAP_CENTER } from '../../appConstants';
import { Icon, LatLng, LeafletMouseEvent } from 'leaflet';

interface ILocationEditDialogProps {
    location : IGeoPoint | null;
    elevation : number | null;

    onClose ?: (location : IGeoPoint | null, elevation : number | null) => void;

    disabled ?: boolean;
    color ?: PropTypes.Color;

    iconUrl ?: string | null;
}

interface ILocationEditDialogState {
    open : boolean;
    location : IGeoPoint | null;
    elevation : number | null;

    zoom : number;
}

export default class LocationEditDialog extends React.PureComponent<ILocationEditDialogProps, ILocationEditDialogState> {
    private readonly mapRef : React.RefObject<Map>;
    private readonly mapZoom = 16;

    constructor(props : ILocationEditDialogProps) {
        super(props);
        this.state = {
            location: null,
            elevation: null,
            open: false,
            zoom: 16,
        };

        this.mapRef = React.createRef();
    }

    public componentDidMount() : void {
        this.setState({
            location: this.props.location,
            elevation: this.props.elevation,
        });
    }

    public componentDidUpdate(prevProps : Readonly<ILocationEditDialogProps>) : void {
        if (prevProps.location !== this.props.location) {
            this.setState({
                location: this.props.location,
            });
        }
        if (prevProps.elevation !== this.props.elevation) {
            this.setState({
                elevation: this.props.elevation,
            });
        }
    }

    private readonly onClick = () => {
        this.setState({
            open: true,
        });
    }

    private readonly onClose = (event: React.MouseEventHandler<HTMLButtonElement>, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason === 'backdropClick') return;

        this.setState({
            open: false,
        });
        this.mapRef.current?.leafletElement.off('click', this.onMapClick);
    }

    private readonly onSaveClick = () => {
        this.setState({
            open: false,
        });

        if (!this.props.onClose) return;
        this.props.onClose(this.state.location, this.state.elevation);
    }

    private readonly onLongitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            location: {
                ...this.state.location ?? {
                    latitude: 0,
                    longitude: 0,
                },
                longitude: Number(event.currentTarget.value),
            }
        });
    }

    private readonly onLatitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            location: {
                ...this.state.location ?? {
                    latitude: 0,
                    longitude: 0,
                },
                latitude: Number(event.currentTarget.value),
            }
        });
    }

    private readonly onElevationChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            elevation: Number(event.currentTarget.value),
        });
    }

    private readonly onDialogRendered = () => {
        if (!this.mapRef.current) return;
        this.mapRef.current.leafletElement.on('click', this.onMapClick);
        this.mapRef.current.leafletElement.on('zoomend', this.onZoom);
    }

    private readonly onMapClick = (event : LeafletMouseEvent) => {
        this.setState({
            location: {
                ...this.state.location ?? {
                    latitude: 0,
                    longitude: 0,
                },
                latitude: event.latlng.lat,
                longitude: event.latlng.lng,
            }
        });
    }

    private readonly onZoom = () => {
        this.setState({
            zoom: this.mapRef.current?.leafletElement.getZoom() ?? this.mapZoom,
        });
    }

    private getLocation = (state : ILocationEditDialogState) => state.location;
    private getCenter = createSelector(
        [this.getLocation],
        (location) => {
            if (!location) return DEFAULT_MAP_CENTER;

            return new LatLng(location.latitude, location.longitude);
        },
    );

    private getMarkerPosition = createSelector(
        [this.getLocation],
        (location) => {
            if (!location) return null;

            return new LatLng(location.latitude, location.longitude);
        },
    );

    private icon = new Icon({
        iconUrl: this.props.iconUrl ?? '/assets/images/icons/traps.svg',
        iconSize: [32,32],
        iconAnchor: [16, 16],
        popupAnchor: [0, -16]
    });

    public readonly render = () => {
        const {
            color,
            disabled,
        } = this.props;

        const {
            open,
            location,
            elevation,
            zoom,
        } = this.state;

        const center = this.getCenter(this.state);
        const position = this.getMarkerPosition(this.state);
        const icon = this.icon;

        return (
            <>
                <div className='fdc aic jcc'>
                    <Tooltip title='Edit'>
                        <div>
                            <IconButton
                                disabled={disabled}
                                color={color}
                                onClick={this.onClick}
                            >
                                <Edit />
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
                <Dialog
                    open={open}
                    TransitionComponent={Transitions.ZoomIn}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth='md'
                    fullWidth
                    aria-labelledby='location-edit-title'
                    aria-describedby='location-edit-description'
                    onRendered={this.onDialogRendered}
                >
                    <AppBar className='fdr posr aic jcc' position='static' elevation={0}>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Location
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={disabled} onClick={this.onClose} aria-label='Add'>
                                        <Close />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill p0'>
                        <div className='fdc flx1'>
                            <div className={'fdc ml17 mr17 mb13'}>
                                <div className={'fdc flx1 mt15'}>
                                    <div className='fdr'>
                                        <div className={'fdc flx1 jcfs mr10'}>
                                            <FormControl fullWidth error={!location?.longitude}>
                                                <TextField
                                                    autoComplete='off'
                                                    required
                                                    error={!location?.longitude}
                                                    id='longitude'
                                                    label='Longitude'
                                                    type='number'
                                                    fullWidth
                                                    value={location?.longitude ?? ''}
                                                    onChange={this.onLongitudeChange}
                                                    disabled={disabled}
                                                />
                                                {
                                                    !location?.longitude &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                        <div className={'fdc flx1 jcfs ml10'}>
                                            <FormControl fullWidth error={!location?.latitude}>
                                                <TextField
                                                    autoComplete='off'
                                                    required
                                                    error={!location?.latitude}
                                                    id='latitude'
                                                    label='Latitude'
                                                    type='number'
                                                    fullWidth
                                                    value={location?.latitude ?? ''}
                                                    onChange={this.onLatitudeChange}
                                                    disabled={disabled}
                                                />
                                                {
                                                    !location?.latitude &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='fdr'>
                                        <div className={'fdc flx1 jcfs mr10'}>
                                            <FormControl fullWidth error={!elevation}>
                                                <TextField
                                                    autoComplete='off'
                                                    required
                                                    error={!elevation}
                                                    id='elevation'
                                                    label='Elevation'
                                                    type='number'
                                                    fullWidth
                                                    value={elevation ?? ''}
                                                    onChange={this.onElevationChange}
                                                    disabled={disabled}
                                                />
                                                {
                                                    !elevation &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                        <div className={'fdc flx1 jcfs ml10'}>
                                        </div>
                                    </div>
                                </div>
                                <div className={'fdc bcw mt30 h350'}>
                                    <Map
                                        maxZoom={20}
                                        className={'flx1 bcw'}
                                        center={center}
                                        zoom={zoom}
                                        preferCanvas
                                        ref={this.mapRef}
                                    >
                                        <StandardLayerControl
                                            polygonOpacity={0.2}
                                            polygonWidth={5}
                                        />
                                        {
                                            position &&
                                            <Marker
                                                position={position}
                                                icon={icon}
                                            />
                                        }
                                    </Map>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className='fdr'>
                            <StadiumButton className='fw500 fs14 cpd mr15 bw1' variant='text' onClick={this.onClose}>
                                    BACK
                            </StadiumButton>
                            <StadiumButton className='fw500 fs14 cw bcp' onClick={this.onSaveClick}>
                                    SAVE
                            </StadiumButton>
                        </div>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
