import { push, goBack } from 'connected-react-router';
import { dispatch } from '../../store';

export default class NavActions {
    public static navPath = (pathname : string) => {
        dispatch(push(pathname));
    }

    public static navBack = () => {
        dispatch(goBack());
    }

    public static navUrl = (url : string, state : any = {}) => {
        dispatch(push({
            pathname: url,
            state,
        }));
    }
}
