import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import CropTypeDropdown from '../../../customComponents/dropdowns/CropTypeDropdown';
import FileDropdown from '../../../customComponents/dropdowns/FileDropdown';
import ShapefileUploadMap from '../view/ShapefileUploadMap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../../customComponents/tab/TabPanel';
import ShapefileTableView from '../view/ShapefileTableView';
import Region from '../../../../types/model/region';
import { Feature, Geometry, GeoJsonProperties } from 'geojson';
import ShapefileHelperService from '../../../../services/shapefileHelperService';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import { IUserSession } from '../../../../types/model/user';
import { CROP } from '../../../../appConstants';
import GeneralFunctions from '../../../../store/general/functions';
import { IBlock } from '../../../../types/model/masterData/block';

interface IBlockShapefileUploadDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    session ?: IUserSession | null;
    blocks : Array<IBlock>;
}

interface IBlockShapefileUploadDialogState {
    open : boolean;

    cropType ?: CROP;
    shpFileBlock ?: File;
    dbfFileBlock ?: File;
    shpFilePoint ?: File;
    dbfFilePoint ?: File;
    shpFilePhenologyPoint ?: File;
    dbfFilePhenologyPoint ?: File;

    tabIndex : number;
    blocks : Array<IBlock>;

    isLoading : boolean;
}

class BlockShapefileUploadDialog extends React.PureComponent<IBlockShapefileUploadDialogProps, IBlockShapefileUploadDialogState> {
    constructor(props : IBlockShapefileUploadDialogProps) {
        super(props);
        this.state = {
            open: false,
            tabIndex: 0,
            blocks: [],
            isLoading: false,
        };
    }

    public componentDidUpdate = (prevProps : IBlockShapefileUploadDialogProps, prevState : IBlockShapefileUploadDialogState) => {
        if (prevState.dbfFileBlock !== this.state.dbfFileBlock || prevState.shpFileBlock !== this.state.shpFileBlock) {
            this.loadMapData();
        }

        if (prevState.dbfFilePoint !== this.state.dbfFilePoint || prevState.dbfFilePhenologyPoint !== this.state.dbfFilePhenologyPoint) {
            this.loadMapData();
        }

        if (prevState.shpFilePhenologyPoint !== this.state.shpFilePhenologyPoint || prevState.shpFilePoint !== this.state.shpFilePoint) {
            this.loadMapData();
        }
    }

    public getBlock = (properties : GeoJsonProperties) => {
        if (properties == null) return null;

        const block = {
            id: properties['GUID'],
            age: properties['AGE'] ?? 0,
            area: properties['AREA'],
            crop: this.state.cropType ?? '',
            description: properties['DESCRIP'],
            division: properties['PLAAS_NAAM'].toLowerCase(),
            fatherId: properties['FATHER_ID'] ?? 0,
            ha: properties['HA'],
            landId: `${!properties['LAND_ID'] ? properties['LANDID'] : properties['LAND_ID']}`,
            landName: properties['LAND_NAAM'],
            name: properties['BLOCK_NAME'] ?? properties['BLOCK_NAAM'],
        } as IBlock;

        return block;
    }

    public getPoints = (blockGuid : string, features : Array<Feature<Geometry, GeoJsonProperties>>) => {
        const points : Record<string, firebase.firestore.GeoPoint> = {};

        for (const feature of features.filter(x => !!x.properties && x.properties['GUID'] === blockGuid)) {
            if (feature.geometry.type === 'Point' && !!feature.properties && !!feature.properties['SCOUT_GUID']) {
                points[feature.properties['SCOUT_GUID']] = new firebase.firestore.GeoPoint(
                    feature.geometry.coordinates[1],
                    feature.geometry.coordinates[0],
                );
            }

            if (feature.geometry.type === 'MultiPoint' && !!feature.properties && !!feature.properties['SCOUT_GUID']) {
                points[feature.properties['SCOUT_GUID']] = new firebase.firestore.GeoPoint(
                    feature.geometry.coordinates[0][1],
                    feature.geometry.coordinates[0][0],
                );
            }
        }

        return points;
    }
    /**
     * Returns block made from a polygon shape.
     */
    public getPolyBlock = (feature : Feature<Geometry, GeoJsonProperties>) => {
        if (feature.geometry.type !== 'Polygon') return null;
        const block = this.getBlock(feature.properties);

        if (block == null) return null;

        const firstPoly = feature.geometry.coordinates[0].map(n => new firebase.firestore.GeoPoint(n[1], n[0]));
        block.center = new Region(firstPoly).centroid(),

        block.polygons = {};
        feature.geometry.coordinates.forEach((c, i) => {
            block.polygons[i] = c.map(polyPoint => new firebase.firestore.GeoPoint(polyPoint[1], polyPoint[0]));
        });

        return block;
    }

    public getMultiPolyBlock = (feature : Feature<Geometry, GeoJsonProperties>) => {
        if (feature.geometry.type !== 'MultiPolygon') return null;
        const block = this.getBlock(feature.properties);

        if (block == null) return null;
        if (!feature.geometry.coordinates.length) return null;
        if (!feature.geometry.coordinates[0].length) return null;
        if (!feature.geometry.coordinates[0][0].length) return null;

        const firstPoly = feature.geometry.coordinates[0][0].map(n => new firebase.firestore.GeoPoint(n[1], n[0]));
        block.center = new Region(firstPoly).centroid(),

        block.polygons = {};
        feature.geometry.coordinates.forEach((c, i) => {
            c.forEach((position) => {
                block.polygons[i] = position.map(polyPoint => new firebase.firestore.GeoPoint(polyPoint[1], polyPoint[0]));
            });
        });

        return block;
    }

    private loadMapData = async () => {
        const { shpFileBlock, dbfFileBlock, shpFilePoint, dbfFilePoint, cropType,
            shpFilePhenologyPoint, dbfFilePhenologyPoint } = this.state;
        if (!cropType) return;
        if (!dbfFileBlock || !shpFileBlock) return;
        if (!shpFilePoint || !dbfFilePoint) return;
        if (!shpFilePhenologyPoint || !dbfFilePhenologyPoint) return;

        this.setState({
            isLoading: true,
        });

        const promises = [
            ShapefileHelperService.readFile(shpFileBlock, dbfFileBlock),
            ShapefileHelperService.readFile(shpFilePoint, dbfFilePoint),
            ShapefileHelperService.readFile(shpFilePhenologyPoint, dbfFilePhenologyPoint),
        ];

        const featureFiles = await Promise.all(promises);

        const featureFile = featureFiles[0];
        const pointFile = featureFiles[1];
        const phenologyPointFile = featureFiles[2];

        const blocks : Array<IBlock> = [];
        for (const feature of featureFile.features) {
            let block;
            if (feature.geometry.type === 'Polygon') {
                block = this.getPolyBlock(feature);
            } else if (feature.geometry.type === 'MultiPolygon') {
                block = this.getMultiPolyBlock(feature);
            }

            if (block) {
                block.points = this.getPoints(block.id, pointFile.features);
                block.phenologyPoints = this.getPoints(block.id, phenologyPointFile.features);

                if (Object.keys(block.points).length || Object.keys(block.phenologyPoints).length) {
                    blocks.push(block);
                } 
            }
        }

        this.setState({
            blocks,
            isLoading: false,
        });
    }

    public onClick = () => {
        this.setState({
            open: true,
        });
    }

    public onClose = () => {
        if (this.state.isLoading) return;

        this.setState({
            open: false,
        });
    }

    private onCropTypeChange = (cropType ?: CROP) => {
        this.setState({
            cropType,
        });
    }

    private onShapeFileChange = (shpFileBlock ?: File) => {
        this.setState({
            shpFileBlock,
        });
    }

    private onDBFFileChange = (dbfFileBlock ?: File) => {
        this.setState({
            dbfFileBlock,
        });
    }

    private onPointShapeFileChange = (shpFilePoint ?: File) => {
        this.setState({
            shpFilePoint,
        });
    }

    private onPointDBFFileChange = (dbfFilePoint ?: File) => {
        this.setState({
            dbfFilePoint,
        });
    }

    private onPhenologyPointShapeFileChange = (shpFilePhenologyPoint ?: File) => {
        this.setState({
            shpFilePhenologyPoint,
        });
    }

    private onPhenologyPointDBFFileChange = (dbfFilePhenologyPoint ?: File) => {
        this.setState({
            dbfFilePhenologyPoint,
        });
    }

    private onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    }

    private onSubmit = async (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            isLoading: true,
        });

        await GeneralFunctions.saveBlocks(this.state.blocks);

        this.setState({
            open: false,
            tabIndex: 0,
            blocks: [],
            isLoading: false,
            shpFileBlock: undefined,
            dbfFileBlock: undefined,
            shpFilePoint: undefined,
            dbfFilePoint: undefined,
            shpFilePhenologyPoint: undefined,
            dbfFilePhenologyPoint: undefined,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, session } = this.props;
        const { open, cropType, shpFileBlock, dbfFileBlock, blocks, tabIndex,
            shpFilePoint, dbfFilePoint, isLoading, shpFilePhenologyPoint, dbfFilePhenologyPoint } = this.state;
        return (
            <React.Fragment>
                {
                    session?.user.permissions.isBlockAdmin &&
                    <Tooltip title='Upload Shapefile'>
                        <div>
                            <IconButton color='primary' onClick={this.onClick} aria-label='Upload Shapefile'>
                                <Icon>publish</Icon>
                            </IconButton>
                        </div>
                    </Tooltip>
                }
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='Upload Shapefile-dialog-title'
                    aria-describedby='Upload Shapefile-description'>
                    <AppBar elevation={0} className='fdc posr ais' position='static'>
                        <Toolbar variant='dense' className={'fdr'}>
                            <div className={'fdr flx1 aic'}>
                                <Typography variant='h5' color='inherit'>
                                    Upload Block Files
                                </Typography>
                                <span className='flx1' />
                                <Tooltip title='Close'>
                                    <div>
                                        <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                            <Icon>close</Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                        </Toolbar>
                        <Tabs value={tabIndex} variant='fullWidth' onChange={this.onTabChange} aria-label='UploadTabs'>
                            <Tab value={0} label='LIST' />
                            <Tab value={1} label='MAP' />
                        </Tabs>
                    </AppBar>
                    <form autoComplete='off' className='fdc flx1 hfill' onSubmit={this.onSubmit}>
                        <DialogContent className='fdc ais flx1'>
                            <div className={'fdr aifs'}>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <CropTypeDropdown fullWidth value={cropType} required onChange={this.onCropTypeChange} />
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FileDropdown label='Block Shape File' disabled={!cropType} accept={'.shp'} fullWidth value={shpFileBlock} required onChange={this.onShapeFileChange} />
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FileDropdown label='Block DBF File' disabled={!shpFileBlock} accept={'.dbf'} fullWidth value={dbfFileBlock} required onChange={this.onDBFFileChange} />
                                </div>
                            </div>
                            <div className={'fdr aifs'}>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FileDropdown label='Points Shape File' disabled={!dbfFileBlock} accept={'.shp'} fullWidth value={shpFilePoint} required onChange={this.onPointShapeFileChange} />
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FileDropdown label='Points DBF File'  disabled={!shpFilePoint} accept={'.dbf'} fullWidth value={dbfFilePoint} required onChange={this.onPointDBFFileChange} />
                                </div>
                            </div>
                            <div className={'fdr aifs'}>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FileDropdown label='Phenology Points Shape File' disabled={!dbfFileBlock} accept={'.shp'} fullWidth value={shpFilePhenologyPoint} required onChange={this.onPhenologyPointShapeFileChange} />
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <FileDropdown label='Phenology Points DBF File'  disabled={!shpFilePhenologyPoint} accept={'.dbf'} fullWidth value={dbfFilePhenologyPoint} required onChange={this.onPhenologyPointDBFFileChange} />
                                </div>
                            </div>
                            <TabPanel value={tabIndex} index={0}>
                                <div className={'flx1 fdc'}>
                                    <ShapefileTableView isLoading={isLoading} currentBlocks={this.props.blocks} blocks={blocks} />
                                </div>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1}>
                                <div className={'flx1 fdc'}>
                                    <ShapefileUploadMap blocks={blocks} />
                                </div>
                            </TabPanel>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading || !blocks.length} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
        blocks: state.general.blocks,
    };
};

export default connect(
    mapStateToProps,
)(BlockShapefileUploadDialog);
