import React from 'react';
import { IUserSession } from '../../../../types/model/user';
import { IPhenologySpecific, PhenologySpecificTreeType } from '../../../../types/model/phenology/specific';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { AppBar, Dialog, DialogContent, IconButton, Tab, Tabs, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Transitions } from '../../../customComponents/animations/Transitions';
import { Close } from '@material-ui/icons';
import TabPanel from '../../../customComponents/tab/TabPanel';
import PhenologySpecificTreeSummaryView from '../view/SummaryView';
import PhenologySpecificTreeSurveyView from '../view/SurveyView';

interface IPhenologySpecificTreeInfoDialogProps {
    value ?: PhenologySpecificTreeType;

    onClose ?: () => void;
    
    session ?: IUserSession | null;
    isLoading ?: boolean;

    specifics : Array<IPhenologySpecific>;
}

interface IPhenologySpecificTreeInfoDialogState {
    open : boolean;

    tabIndex : number;
}

class PhenologySpecificTreeInfoDialogComponent extends React.PureComponent<IPhenologySpecificTreeInfoDialogProps, IPhenologySpecificTreeInfoDialogState> {
    constructor(props : IPhenologySpecificTreeInfoDialogProps) {
        super(props);
        this.state = {
            open: false,
            tabIndex: 0,
        };
    }

    public componentDidUpdate(prevProps : Readonly<IPhenologySpecificTreeInfoDialogProps>) : void {
        if (this.props.value && prevProps.value?.code !== this.props.value?.code) {
            this.setState({
                open: true,
            });
        }
    }

    private readonly onClose = () => {
        this.setState({
            open: false,
            tabIndex: 0,
        });

        if (!this.props.onClose) return;
        this.props.onClose();
    }

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    }
    private readonly getValue = (state : IPhenologySpecificTreeInfoDialogState, props : IPhenologySpecificTreeInfoDialogProps) => props.value;

    public render = () => {
        const {
            session,
            isLoading,
            specifics,
        } = this.props;

        const {
            open,
            tabIndex,
        } = this.state;

        const tree = this.getValue(this.state, this.props);

        return (
            <Dialog
                open={open}
                fullScreen
                fullWidth
                TransitionComponent={Transitions.ZoomIn}
                transitionDuration={500}
                aria-labelledby='trap-info-dialog-title'
                aria-describedby='trap-info-dialog-description'
                closeAfterTransition
            >
                <AppBar elevation={0} className='fdc posr ais' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit' className='flx1'>
                            {tree?.code} - {tree?.divisionName}
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Close />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                    <Tabs value={tabIndex} variant='scrollable' onChange={this.onTabChange} aria-label='PhenologyTabs'>
                        <Tab value={0} label='SUMMARY' />
                        <Tab value={1} label='SURVEYS' />
                    </Tabs>
                </AppBar>
                {
                    tree &&
                    <DialogContent className='fdc flx1 bcg0 p0'>
                        <TabPanel value={tabIndex} index={0}>
                            <PhenologySpecificTreeSummaryView tree={tree} session={session} isLoading={isLoading} specifics={specifics} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            <PhenologySpecificTreeSurveyView tree={tree} session={session} />
                        </TabPanel>
                    </DialogContent>
                }
            </Dialog>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
        isLoading: state.phenology.specific.isLoading,
        specifics: state.phenology.specific.specifics,
    };
};

const PhenologySpecificTreeInfoDialog = connect(
    mapStateToProps,
)(PhenologySpecificTreeInfoDialogComponent);

export default PhenologySpecificTreeInfoDialog;