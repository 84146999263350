import React from 'react';
import Typography from '@material-ui/core/Typography';
import { IKeyValue } from '../../../appConstants';

interface IScoutingReportPercentageRowProps {
    rowData : Record<string, number>;
    headers : IKeyValue<Array<string>>;
}

interface IScoutingReportPercentageRowState {}

export default class ScoutingReportPercentageRow extends React.PureComponent<IScoutingReportPercentageRowProps, IScoutingReportPercentageRowState> {
    constructor(props : IScoutingReportPercentageRowProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { rowData } = this.props;
        return (
            <div className='fdr mnh32 bocg5 bnl bcig'>
                <div className='fdr mnw180 mxw180 aic'>
                    <Typography className='fwb fs12-16 flx1 cw pl24'>Percentage</Typography>
                </div>
                {
                    Object.keys(rowData).map(header => (
                        <div className='flx1 fwb fs13-18 lsp02 cw tac brw1 bocg4 bnl' key={`value_totals_${header}`}>
                            <div className='flx1 aic jcc bocg3 brw1'>
                                { (rowData[header] * 100).toFixed(2) }%
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}
