import { IUser, IUserSession } from '../../types/model/user';

export default class AuthMapper {
    public static mapFirebaseUserToSession = (firebaseUser : firebase.User, user : IUser | null) : IUserSession | null => {
        return user ? {
            firebaseUser,
            user,
        } : null;
    }
}
