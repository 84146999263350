import React from 'react';
import { Map, CircleMarker, Marker } from 'react-leaflet';
import L, { LatLng } from 'leaflet';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import StandardLayerControl from '../../customComponents/map/StandardLayerControl';
import PolygonWithText from '../../customComponents/map/PolygonWithText';
import randomColor from 'randomcolor';
import { IAssignmentBlock } from '../../../types/model/masterData/block';

interface IBlockMapProps {
    block : IAssignmentBlock;

    children ?: React.ReactNode;

    phenology ?: boolean;
}

interface IBlockMapState {}

export default class BlockMap extends React.Component<IBlockMapProps, IBlockMapState> {
    private mapRef ?: Map;
    private mapZoom = 8;
    private mapCenter = new LatLng(-23.5520414, 30.1148622);
    constructor(props : IBlockMapProps) {
        super(props);
        this.state = {};
    }

    private setMapRef = (ref : Map) => {
        this.mapRef = ref;
        this.flyToBlock();
    }

    private flyToBlock = () => {
        if (!Object.keys(this.props.block.polygons).length) return;
        const positions = this.props.block.polygons[0].map(n => new LatLng(n.latitude, n.longitude));

        if (this.mapRef) {
            const currentZoom = this.mapRef.leafletElement.getZoom();
            this.mapRef.leafletElement.flyTo(L.polygon(positions).getBounds().getCenter(), currentZoom > 18 ? currentZoom : 18);
        }
    }

    private getBlock = ( state : IBlockMapState, props : IBlockMapProps) => props.block;
    private getPhenology = ( state : IBlockMapState, props : IBlockMapProps) => props.phenology;
    private getPolygon = createSelector(
        [this.getBlock],
        (block) => {
            const poly : {
                positions : Array<Array<LatLng>>;
                color : string;
                id : string;
                title : string;
            } = {
                id: `land_point_${block.landId}`,
                positions: [],
                color: randomColor({ seed: block.landName }),
                title: block.name,
            };

            lodash.forEach(block.polygons, (polyPoints) => {
                poly.positions.push(polyPoints.map(polyPoint => new LatLng(polyPoint.latitude, polyPoint.longitude)));
            });

            return poly;
        },
    );

    private getScoutingPoints = createSelector(
        [this.getBlock, this.getPhenology],
        (block, phenology) => {

            const blockPoints = {
                ...(!phenology ? block.points : block.phenologyPoints)
            };

            const points : Array<{
                center : LatLng;
                color : string;
                radius : number;
                id : string;
            }> = lodash.map((!phenology ? block.pointsArray : block.phenologyPointsArray), (n) => ({
                id: `scout_${n}`,
                color: randomColor({ seed: block.landName }),
                center: new LatLng(blockPoints[n].latitude, blockPoints[n].longitude),
                radius: 3,
            }));

            return points;
        },
    );

    private getMarkers = createSelector(
        [this.getBlock, this.getPhenology],
        (block, phenology) => {
            const markers : Array<{
                center : {
                    lat : number;
                    lng : number;
                };
                textIcon : L.DivIcon;
            }> = [];

            const points = {
                ...(!phenology ? block.points : block.phenologyPoints)
            };

            (!phenology ? block.pointsArray : block.phenologyPointsArray).forEach((point, i) => {
                const element = document.createElement('span');
                element.append(`${i + 1}`);
                const textIcon = L.divIcon({
                    html: element,
                    className: 'bct cw fwb bts fs12',
                });

                markers.push({
                    center: {
                        lng: points[point].longitude,
                        lat: points[point].latitude,
                    },
                    textIcon,
                });
            });
            return markers;
        },
    );

    public render = () => {
        const polygon = this.getPolygon(this.state, this.props);
        const scoutingPoints = this.getScoutingPoints(this.state, this.props);
        const markers = this.getMarkers(this.state, this.props);
        return (
            <Map maxZoom={20} ref={this.setMapRef} className={'flx1 bcw'} center={this.mapCenter} zoom={this.mapZoom} preferCanvas>
                <StandardLayerControl managementAreas={false} />
                {
                    <PolygonWithText title={polygon.title} key={polygon.id} positions={polygon.positions} color={polygon.color} />
                }
                {
                    scoutingPoints.map((n) => (
                        <CircleMarker weight={2} key={n.id} fillOpacity={1} color={n.color} center={n.center} radius={n.radius} />
                    ))
                }
                {
                    markers.map((marker, i) => (
                        <Marker key={`marker_icon_${i}`} position={marker.center} icon={marker.textIcon} />
                    ))
                }
                {
                    this.props.children &&
                    this.props.children
                }
            </Map>
        );
    }
}
