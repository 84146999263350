import { CROP } from '../../../appConstants';
import firebaseApp from '../../../services/firebaseService';
import { BaseHelper, IBase } from '../../base';
import { GeoPointHelper, IGeoPoint } from '../geoPoint';
import { BlockHelper } from '../masterData/block';
import TrapTypeHelper from '../masterData/trap/trapType';
import firebase from 'firebase/app';

export interface ITrap extends IBase {
    location : IGeoPoint | null;
    elevation : number | null;
    locationAccuracy : number | null;

    code : string | null;

    /**
     * Block Id
     */
    block : string | null;
    blockName : string | null;
    landName : string | null;
    divisionName : string;
    divisionCode : string;
    
    crop : CROP;

    archived : boolean;
    monitoring : boolean;

    description : string | null;
    otherDescription : string | null;

    /**
     * Type Id
     */
    type : string;
    typeName : string;

    isSent : boolean;
}

export class TrapHelper extends BaseHelper {
    public static readonly COLLECTION = 'traps';

    public static converter : firebase.firestore.FirestoreDataConverter<ITrap> = {
        fromFirestore: (snapshot) => {
            return TrapHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : ITrap) => {
            return TrapHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : ITrap {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as ITrap,
            ...result,
            block: !data['block'] ? null : (data['block'] as firebase.firestore.DocumentReference).id,
            type: (data['type'] as firebase.firestore.DocumentReference).id,
            location: !data['location'] ? null : GeoPointHelper.fromFirestore(data['location']),
        };
    }

    protected static toFirestore(data : ITrap) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
            type: !data.type ? null : TrapTypeHelper.doc(data.type),
            block: !data.block ? null : BlockHelper.doc(data.block),
            location: !data.location ? null : GeoPointHelper.toFirestore(data.location),
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static doc(id ?: string) {
        if (!id) {
            return TrapHelper.collection().doc();
        }

        return TrapHelper.collection().doc(id);
    }

    public static delete(id : string) {
        return TrapHelper.doc(id).delete();
    }

    public static save(trap : ITrap) {
        if (trap.id) {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(trap.id).set(trap);
        }

        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc().set(trap);
    }

    public static getQrCodeText(trap : ITrap) {
        return `1:${trap.id}:${trap.code}`;
    }

}