import { CROP } from "../../../appConstants";
import firebaseApp from "../../../services/firebaseService";
import { BaseHelper, IBase } from "../../base";
import firebase from 'firebase/app';

export interface ICultivar extends IBase {
    crop : CROP;
    name : string;
    isActive : boolean;
}

export default class CultivarHelper extends BaseHelper {
    public static readonly COLLECTION = 'cultivars';

    public static converter : firebase.firestore.FirestoreDataConverter<ICultivar> = {
        fromFirestore: (snapshot) => {
            return CultivarHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : ICultivar) => {
            return CultivarHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : ICultivar {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as ICultivar,
            ...result,
            isActive: data.isActive ?? true,
        };
    }
    protected static toFirestore(data : ICultivar) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static save(cultivar : ICultivar) {
        if (cultivar.id) {
            return this.collection().doc(cultivar.id).set(cultivar);
        }

        return this.collection().doc().set(cultivar);
    }
}