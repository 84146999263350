import React from 'react';
import { motion } from 'framer-motion';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

interface IVerticalExpanderProps {
    initiallyExpanded ?: boolean;
    disabled ?: boolean;
    title : string;
    subTitle ?: string;
    className ?: string | undefined;

    location ?: 'left' | 'right';

    children ?: React.ReactNode;
}

interface IVerticalExpanderState {
    collapsed ?: boolean;
}

export default class VerticalExpander extends React.PureComponent<IVerticalExpanderProps, IVerticalExpanderState> {
    private readonly COLLAPSED_WIDTH = 50;
    private readonly EXPANDED_WIDTH = 500;

    constructor(props : IVerticalExpanderProps) {
        super(props);
        this.state = {
        };
    }

    public readonly onToggleExpander = () => {
        this.setState({
            collapsed: !this.props.disabled && !this.state.collapsed,
        });
    };

    public readonly render = () => {
        const { title, subTitle, children, className, location = 'right', disabled, initiallyExpanded } = this.props;
        const { collapsed = !initiallyExpanded } = this.state;

        return (
            <div className={`fdc ais flx1 mh0 ${className}`}>
                <motion.div initial={{ width: this.COLLAPSED_WIDTH }}
                    animate={{ width: !collapsed ? this.EXPANDED_WIDTH : this.COLLAPSED_WIDTH }}
                    transition={{ type: 'tween' }} className='fdc flx1 ais' key={`expander_${title}`}>
                    <div className={`bcpd cw fdc ${collapsed ? 'flx1' : ''} cursorPointer`} onClick={this.onToggleExpander}>
                        <span className='flx1' />
                        <Typography className={`aic fdr rbt p10 ${collapsed ? 'VerticalText' : ''} fs18 `} variant={'subtitle1'}>
                            {title.toUpperCase()}{!subTitle ? '' : ` - ${subTitle.toUpperCase()}`}
                        </Typography>
                    </div>
                    {
                        !collapsed &&
                        <div className='fdc ais flx1 mh0 oya report m5'>
                            {children}
                        </div>
                    }
                    <div className={`fdr ais ${collapsed ? 'bcpd' : 'wfill'} ${location !== 'left' ? 'jcfs' : 'jcfe'}`}>
                        <IconButton disabled={disabled} color='inherit' onClick={this.onToggleExpander}>
                            <motion.div className='aic jcc'
                                initial={{
                                    transform: 'rotate(0deg)',
                                    color: initiallyExpanded ? '#FFF' : '#000',
                                }}
                                animate={{
                                    transform: !collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
                                    color: !collapsed ? '#000' : '#FFF',
                                }}
                            >
                                {
                                    location === 'left' &&
                                <Icon>chevron_right</Icon>
                                }
                                {
                                    location !== 'left' &&
                                <Icon>chevron_left</Icon>
                                }
                            </motion.div>
                        </IconButton>
                    </div>
                </motion.div>
            </div>
        );
    };
}
