import { IconButton, Modal, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { createSelector } from 'reselect';

export interface IImageCarouselImage {
    thumbnailUrl ?: string | null;
    url : string | null;
    legend ?: string;
    id ?: string | null;
}

interface IImageCarouselModalProps {
    images : Array<IImageCarouselImage>;

    open : boolean;
    onClose ?: () => void;

    selectedId ?: string | null;
}

interface IImageCarouselModalState {}

export default class ImageCarouselModal extends React.PureComponent<IImageCarouselModalProps, IImageCarouselModalState> {
    constructor(props : IImageCarouselModalProps) {
        super(props);
        this.state = {};
    }

    private readonly getImages = (state : IImageCarouselModalState, props : IImageCarouselModalProps) => props.images;
    private readonly getSelectedId = (state : IImageCarouselModalState, props : IImageCarouselModalProps) => props.selectedId;

    private readonly getSelectedIndex = createSelector(
        [
            this.getImages,
            this.getSelectedId,
        ],
        (
            images,
            selectedId,
        ) => {
            return images.findIndex(x => x.id === selectedId);
        },
    );

    private readonly renderThumbs = (children : Array<React.ReactChild>) => children.map((child, i) => (
        <img key={i} src={this.props.images[i]?.thumbnailUrl ?? this.props.images[i]?.url ?? ''} />
    ))

    public readonly render = () => {
        const {
            open,
            images,
            onClose,
        } = this.props;

        const selectedItem = this.getSelectedIndex(this.state, this.props);

        return (
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open={open}
                aria-labelledby='image-carousel-modal-title'
                aria-describedby='image-carousel-modal-description'
                closeAfterTransition
                className='fdc aic jcc'
            >
                <div className='fdr hfill wfill'>
                    <Carousel
                        infiniteLoop
                        centerMode
                        centerSlidePercentage={80}
                        className='wfill hfill p20'
                        renderThumbs={this.renderThumbs}
                        useKeyboardArrows
                        selectedItem={selectedItem}
                    >
                        {
                            images.map((x, i) => (
                                <div key={i}>
                                    <img src={x.url ?? ''} height={'815px'} />
                                    <p
                                        className='legend bcp fwb fs30'
                                    >
                                        {x.legend ?? ''}
                                    </p>
                                </div>
                            ))
                        }
                    </Carousel>
                    <div className='posr'>
                        <div className='posa posr0'>
                            <Tooltip title='Close'>
                                <IconButton color='secondary' onClick={onClose}>
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
