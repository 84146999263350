import React from 'react';
import { CircleMarker } from 'react-leaflet';
import { IBlockPolygonLayer } from '../../../types/model/masterData/blockPolygonLayer';
import PolygonWithText from './PolygonWithText';

interface IBlockPolygonLayerProps {
    showMarkers ?: boolean;

    block : IBlockPolygonLayer;
}

interface IBlockPolygonLayerState {}

export default class BlockPolygonLayer extends React.PureComponent<IBlockPolygonLayerProps, IBlockPolygonLayerState> {
    constructor(props : IBlockPolygonLayerProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { showMarkers, block } = this.props;
        return (
            <PolygonWithText weight={2} showMarker={showMarkers} title={block.blockName} color={block.color} positions={block.positions}>
                {
                    showMarkers  &&
                    block.points.map((n, i) => (
                        <CircleMarker weight={2} key={`land_point_${block.landId}_${i}`} fillOpacity={1} color={n.color} center={n.center} radius={5} />
                    ))
                }
            </PolygonWithText>
        );
    }
}
