import React from 'react';
import LegendLayer from './LegendLayer';
import lodash from 'lodash';
import { withLeaflet, LeafletContext } from 'react-leaflet';
import L from 'leaflet';
import { createSelector } from 'reselect';
import { IBlockPolygonLayer } from '../../../types/model/masterData/blockPolygonLayer';

interface IBlockLegendLayerProps {
    leaflet : LeafletContext;
    blocks : Array<IBlockPolygonLayer>;
}

interface IBlockLegendLayerState {
    blocks : Array<IBlockPolygonLayer>;
}

class BlockLegendLayer extends React.PureComponent<IBlockLegendLayerProps, IBlockLegendLayerState> {
    constructor(props : IBlockLegendLayerProps) {
        super(props);
        this.state = {
            blocks: [],
        };
    }

    public componentDidMount = () => {
        this.props.leaflet.map?.on('moveend', this.onMoveEnd);
    }

    public componentWillUnmount = () => {
        this.props.leaflet.map?.off('moveend', this.onMoveEnd);
    }

    private onMoveEnd = () => {
        const bounds = this.props.leaflet.map?.getBounds();
        if (bounds) {
            const blocks = lodash.filter(this.props.blocks, n => n.positions.some(position => bounds.contains(L.polygon(position).getBounds())));
            this.setState({
                blocks,
            });
        }
    }

    private getData = (state : IBlockLegendLayerState) => state.blocks;
    private getBlocks = createSelector(
        [this.getData],
        (blocks) => {
            return lodash.chain(blocks).groupBy(n => n.landName).map(n => ({
                color: n[0].color,
                text: `${n[0].landName}`,
            })).value();
        },
    );

    public render = () => {
        const blocks = this.getBlocks(this.state);
        return (
            <LegendLayer legends={blocks} />
        );
    }
}

export default withLeaflet(BlockLegendLayer);
