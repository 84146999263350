import moment from 'moment';
import firebaseApp from '../../../services/firebaseService';
import firebase from 'firebase/app';

export interface IUserFcm {
    /**
     * Document id.
     */
    id : string;
    mobileFcm : string | null;
    webFcm : string | null;
    updatedOn : number;

    notificationLands : Record<string, Array<string> | null>;
}

export default class UserFcmHelper {
    public static readonly COLLECTION = 'user_fcms';

    public static converter : firebase.firestore.FirestoreDataConverter<IUserFcm> = {
        fromFirestore: (snapshot) => {
            return UserFcmHelper.fromFirestore(snapshot);
        },
        toFirestore: (userFcm : IUserFcm) => {
            return UserFcmHelper.toObject(userFcm);
        },
    };

    private static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IUserFcm {
        const data = snapshot.data();

        if (!data) throw new Error('Document does not exist!');

        return {
            id: snapshot.ref.id,
            mobileFcm: data['mobileFcm'],
            webFcm: data['webFcm'],
            updatedOn: (data['updatedOn'] as firebase.firestore.Timestamp).toMillis(),
            notificationLands: data['notificationLands'] ?? {},
        };
    }

    private static toObject(userFcm : IUserFcm) {
        const mergedObject : firebase.firestore.DocumentData = {
            mobileFcm: userFcm.mobileFcm,
            webFcm: userFcm.webFcm,
            updatedOn: firebase.firestore.Timestamp.fromMillis(userFcm.updatedOn),
            notificationLands: userFcm.notificationLands,
        };

        // I am not sure why this needs to be done all of a sudden...
        const keys = Object.keys(mergedObject);
        for(const key of keys) {
            if (typeof mergedObject[key] !== 'undefined') continue;

            delete mergedObject[key];
        }

        return mergedObject;
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static updateWebFcm(token : string, userId : string) {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(userId).set({
            webFcm: token,
            updatedOn: moment.utc().valueOf(),
        }, {
            // We only want to update certain fields.
            // If we just use merge, divisions do not update when removing one.
            mergeFields: [
                'webFcm',
                'updatedOn',
            ],
        });
    }

    public static save(userFcm : IUserFcm) {
        if (userFcm.id) {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(userFcm.id).set(userFcm, {
                // We only want to update certain fields.
                // If we just use merge, divisions do not update when removing one.
                mergeFields: [
                    'webFcm',
                    'notificationLands',
                    'updatedOn',
                ],
            });
        } else {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).add(userFcm);
        }
    }
}
