import { push, replace } from 'connected-react-router';
import { dispatch } from '../../store';
import NavActions from './actions';

export default class NavFunctions {
    public static navPath = (pathname : string, search? : string) => {
        dispatch(push({
            pathname,
            search,
        }));
    }

    public static navScoutingMap(code : string) {
        NavActions.navPath('/scouting/map?id=' + code);
    }

    public static navReplacePath = (pathname : string, search? : string) => {
        dispatch(replace({
            pathname,
            search,
        }));
    }
}
