import authActions from './actions';
import { AuthAction, IAuthState } from '../../@types/redux';
import { getType } from 'typesafe-actions';

const initialState = {
    isLoggedIn: false,
    session: undefined,
    arcGisToken: '',
    isLoggingIn: false,
};

export default function authReducer(state : IAuthState = initialState, action : AuthAction) : IAuthState {
    switch (action.type) {
    case getType(authActions.authSetSession):
        return { ...state, session: action.payload };
    case getType(authActions.authSetLoggedIn):
        return { ...state, isLoggedIn: action.payload };
    case getType(authActions.authSetLoggingIn):
        return { ...state, isLoggingIn: action.payload };
    case getType(authActions.authSetGISToken):
        return { ...state, arcGisToken: action.payload };
    default:
        return state;
    }
}
