import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { IErrorInformation } from '../../types/model/errorInformation';

interface IErrorScreenProps {
    errorInformation ?: IErrorInformation;
}

const ErrorScreen = (props : IErrorScreenProps) : React.ReactElement => { 
    return (
        <div className={'fdc flx1 aic jcc bcpd'}>
            <img src={`${ASSET_BASE}/assets/images/error.svg`} className={'posr h600 ml65'} />
            <Typography className={'fs50 fwb cw'}>{props.errorInformation?.message ?? ''}</Typography>
        </div>
    );
};

export default ErrorScreen;
