import React from 'react';
import { IRootState, RootAction, DispatchCall } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import GeneralActions from '../../../store/general/actions';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { ThresholdHelper, THRESHOLD_TYPE, IThreshold } from '../../../types/model/masterData/threshold';
import { IUserSession } from '../../../types/model/user';
import moment from 'moment';
import { CROP_INSECTS, CROP, CROP_DISEASES } from '../../../appConstants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { Formik, FieldArray } from 'formik';
import LinearProgress from '@material-ui/core/LinearProgress';
import ThresholdField from '../../customComponents/threshold/ThresholdField';
import StadiumButton from '../../customComponents/button/StadiumButton';
import lodash from 'lodash';
import GeneralFunctions from '../../../store/general/functions';

interface IThresholdEditProps extends RouteComponentProps {
    generalShowErrorSnackbar : DispatchCall<string>;
    generalShowSuccessSnackbar : DispatchCall<string>;
    session ?: IUserSession | null;
}

interface IThresholdEditState {
    crop ?: CROP;
    type ?: THRESHOLD_TYPE;
    threshold ?: IThreshold;
    isLoading : boolean;
}

class ThresholdEdit extends React.PureComponent<IThresholdEditProps, IThresholdEditState> {
    private listenerCancel ?: () => void;
    constructor(props : IThresholdEditProps) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    public componentDidMount = () => {
        this.setCropType();
    }

    public componentDidUpdate = (prevProps : IThresholdEditProps, prevState : IThresholdEditState) => {
        if (prevProps.location.search !== this.props.location.search) {
            this.setCropType();
        }

        if (prevState.crop !== this.state.crop || prevState.type !== this.state.type) {
            this.refresh();
        }
    }

    private setCropType = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const crop = urlParams.get('crop') as CROP | null;
        const type = urlParams.get('type') as THRESHOLD_TYPE | null;

        if (crop && type) {
            this.setState({
                crop,
                type,
                isLoading: true,
            });
        }
    }

    public refresh = () => {
        if (this.listenerCancel) this.listenerCancel();

        if (!this.state.crop || !this.state.type) return;

        this.listenerCancel = ThresholdHelper.get(this.state.crop, this.state.type).onSnapshot((snapshot) => {
            if (!this.state.crop || !this.state.type) return;
            if (!this.props.session) return;
            let threshold = snapshot.data();

            if (!threshold) {
                threshold = {
                    id: `${this.state.crop}_${this.state.type}`,
                    thresholds: {},
                    createdBy: this.props.session.firebaseUser.uid,
                    createdByEmployee: this.props.session.user.employeeNumber,
                    createdByName: this.props.session.user.name,
                    createdOn: moment.utc().valueOf(),
                    updatedBy: this.props.session.firebaseUser.uid,
                    updatedByEmployee: this.props.session.user.employeeNumber,
                    updatedByName: this.props.session.user.name,
                    updatedOn: moment.utc().valueOf(),
                    crop: this.state.crop,
                    type: this.state.type,
                };
            }

            threshold.thresholds = this.getThresholds(threshold.thresholds, this.state.crop, this.state.type);

            this.setState({
                threshold,
                isLoading: false,
            });
        }, (err) => {
            this.props.generalShowErrorSnackbar(err.message);
            this.setState({
                isLoading: false,
            });
        });
    }

    public getThresholds(thresholds : { [key : string] : number }, crop : CROP, type : THRESHOLD_TYPE) : { [key : string] : number } {
        const array : Array<string> = [];

        if (type === 'insects') {
            Array.prototype.push.apply(array, CROP_INSECTS[crop]);
        }

        if (type === 'disease') {
            Array.prototype.push.apply(array, CROP_DISEASES[crop]);
        }

        const result : { [key : string] : number } = {};

        for (const header of array.sort()) {
            result[header] = thresholds[header] ? thresholds[header] : 0;
        }

        return result;
    }

    private save = async (values : IThreshold) => {
        if (!this.props.session) return;
        const threshold = lodash.cloneDeep(values);
        threshold.updatedBy = this.props.session.firebaseUser.uid;
        threshold.updatedByEmployee = this.props.session.user.employeeNumber;
        threshold.updatedByName = this.props.session.user.name;
        threshold.updatedOn = moment.utc().valueOf();

        try {
            await ThresholdHelper.save(threshold);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error saving', ex);
        }
    }

    public render = () => {
        const { threshold, isLoading } = this.state;
        return (
            <div className='fdc flx1 ais'>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading && !!threshold &&
                    <div className='fdc flx1 ais jcfs p20'>
                        <Formik
                            enableReinitialize
                            initialValues={threshold}
                            onSubmit={this.save}
                            validateOnChange
                            validate={(values) => {
                                const error : {
                                    thresholds ?: { [key : string] : string };
                                } = {
                                };

                                Object.keys(values.thresholds).forEach((header) => {
                                    if (typeof values.thresholds[header] !== 'number') {
                                        if (!error.thresholds) error.thresholds = {};
                                        return error.thresholds[header] = 'Invalid';
                                    }
                                });

                                return error;
                            }}
                        >{({
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                                isSubmitting,
                                dirty,
                                isValid,
                                errors,
                            }) => (
                                <form id='threshold-form' className='' onSubmit={handleSubmit} onReset={handleReset}>
                                    <Paper  className='fdc ais mb15' elevation={3}>
                                        {
                                            isSubmitting &&
                                            <LinearProgress />
                                        }
                                        <FieldArray
                                            name='thresholds'
                                            render={ () => (
                                                <div className='fdr pb10'>
                                                    {
                                                        Object.keys(values.thresholds).map(header => (
                                                            <ThresholdField
                                                                key={header}
                                                                header={header}
                                                                value={values.thresholds[header]}
                                                                name={`thresholds.${header}`}
                                                                onChange={handleChange}
                                                                error={!!errors.thresholds && !!errors.thresholds[header]}
                                                                onBlur={handleBlur} />
                                                        ))
                                                    }
                                                </div>
                                            )}
                                        />
                                    </Paper>
                                    {
                                        dirty &&
                                        <div className='fdr flx1 jcfe aife mt15'>
                                            <StadiumButton className='fw500 fs14 cpd mr15 bw1' variant='text' type='reset' disabled={isSubmitting}>
                                                    CANCEL
                                            </StadiumButton>
                                            <StadiumButton className='fw500 fs14 cpd bcy' type='submit' disabled={!isValid || isSubmitting}>
                                                    SAVE
                                            </StadiumButton>
                                        </div>
                                    }
                                </form>
                            )}
                        </Formik>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators({
    generalShowErrorSnackbar: (message : string) => dispatcher(GeneralActions.enqueueSnackbar({
        message,
        options: {
            variant: 'error',
        },
    })),
    generalShowSuccessSnackbar: (message : string) => dispatcher(GeneralActions.enqueueSnackbar({
        message,
        options: {
            variant: 'success',
        },
    })),
}, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ThresholdEdit));
