import { Checkbox } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';

interface IMapControlCheckboxProps {
    label : string;
    value : boolean;

    onChange ?: (checked : boolean) => void;
}

interface IMapControlCheckboxState {
}

export default class MapControlCheckbox extends React.PureComponent<IMapControlCheckboxProps, IMapControlCheckboxState> {
    constructor(props : IMapControlCheckboxProps) {
        super(props);
        this.state = {
        };
    }

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        if (this.props.onChange) this.props.onChange(checked);
    };

    public readonly render = () => {
        const { label, value } = this.props;

        return (
            <div className={'fdr aic'}>
                <Checkbox 
                    checked={value}
                    onChange={this.onChange}
                    color='primary'
                    size='small'
                />
                <Typography className={'map-ctrl-text'}>{label}</Typography>
            </div>
        );
    };
}
