import React, { ChangeEvent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

interface IMaterialTableFilterProps {
    field : string;

    onFilterChanged : (filterValue ?: string) => void;
}

interface IMaterialTableFilterState {
    open : boolean;

    filterValue : string;
}

export default class MaterialTableFilter extends React.Component<IMaterialTableFilterProps, IMaterialTableFilterState> {
    private anchorEl : any;

    constructor(props : IMaterialTableFilterProps) {
        super(props);

        this.state = {
            open: false,
            filterValue: '',
        };
    }

    public componentDidUpdate = (prevProps : IMaterialTableFilterProps, prevState : IMaterialTableFilterState) => {
        if (!prevState.open && this.state.open) {
            const element = document.getElementById(`filter-${this.props.field}-id`); // propably read ref? Find element with [autoFocus] attribute in component?
            if (element) element.focus();
        }
    }

    private handleClick = () => {
        this.setState({ open: !this.state.open });
    }

    private handleClose = (event : React.MouseEvent<Document, MouseEvent>) => {
        if (this.anchorEl?.contains(event.target)) {
            return;
        }

        event.preventDefault();

        this.setState({ open: false });
        this.props.onFilterChanged(this.state.filterValue);
    }

    private filterValueChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            filterValue: event.target.value,
        });
    }

    private submitForm = async (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.setState({ open: false });

        this.props.onFilterChanged(this.state.filterValue);
    }

    private onClearClicked = () => {
        this.setState({
            filterValue: '',
            open: false,
        });

        this.props.onFilterChanged();
    }

    public render = () => {
        const { open, filterValue } = this.state;

        return (
            <React.Fragment>
                <IconButton
                    aria-owns={`filter-menu-${this.props.field}`}
                    aria-haspopup='true'
                    onClick={this.handleClick}
                    ref={(node) => { this.anchorEl = node; }}
                    color={filterValue ? 'primary' : 'inherit'}>
                    <Icon fontSize='small'>
                        filter_list
                    </Icon>
                </IconButton>
                <Popper anchorEl={this.anchorEl} open={open} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', marginTop: 8 }}
                        >
                            <Paper id={`filter-menu-${this.props.field}`}>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <div className='fdr aic'>
                                        <form autoComplete='off' onSubmit={this.submitForm}>
                                            <FormControl>
                                                <TextField
                                                    id={`filter-${this.props.field}-id`}
                                                    autoComplete='off'
                                                    variant='outlined'
                                                    value={filterValue}
                                                    onChange={this.filterValueChanged}
                                                    margin='none'
                                                    style={{ minWidth: '150px' }}
                                                />
                                            </FormControl>
                                        </form>
                                        <Tooltip title='Clear'>
                                            <IconButton onClick={this.onClearClicked}>
                                                <Icon>
                                                    close
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </React.Fragment>
        );
    }
}
