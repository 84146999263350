import firebase from 'firebase/app';
import { dispatch, getState } from '../../..';
import TrapTypeHelper, { ITrapType } from '../../../../types/model/masterData/trap/trapType';
import GeneralFunctions from '../../../general/functions';
import TrapTypeActions from './actions';
import uuid from 'uuid';

export default class TrapTypeFunctions {
    private static listener ?: () => void;

    public static getList = (refresh ?: boolean) => {

        if (!refresh && TrapTypeFunctions.listener) {
            return;
        }

        if (TrapTypeFunctions.listener) {
            TrapTypeFunctions.listener();
        }

        dispatch(TrapTypeActions.setLoading(true));
        dispatch(TrapTypeActions.setList([]));

        try {
            
            TrapTypeFunctions.listener = TrapTypeHelper
                .collection()
                .onSnapshot((snapshot) => {
                    const trapTypeState = getState().masterData.trap.trapType;

                    const trapTypes = trapTypeState.types.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const data = f.doc.data();

                        switch (f.type) {
                        case 'added':
                            trapTypes.splice(f.newIndex, 0, data);
                            break;
                        case 'modified':
                            trapTypes.splice(f.newIndex, 1, data);
                            break;
                        case 'removed':
                            trapTypes.splice(f.oldIndex, 1);
                            break;
                        }
                    });

                    dispatch(TrapTypeActions.setList(trapTypes));
                    dispatch(TrapTypeActions.setLoading(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading trap types.', err);
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading trap types.', ex);
            dispatch(TrapTypeActions.setLoading(false));
        }
    }

    public static save = async (item : ITrapType) => {
        const session = getState().auth.session;

        if (!session) return;
        
        const trapTypes = getState().masterData.trap.trapType.types;

        if (trapTypes.some(x => x.name === item.name && x.crop === item.crop && x.id !== item.id)) {
            throw new Error('Trap Type already exists.');
        }

        dispatch(TrapTypeActions.setLoading(true));

        try {
            const save : ITrapType = {
                ...item,
                createdBy: item.createdBy ? item.createdBy : session.firebaseUser.uid,
                createdByEmployee: item.createdByEmployee ? item.createdByEmployee : session.user.employeeNumber,
                createdByName: item.createdBy ? item.createdByName : session.user.name,
                createdOn: item.createdOn ? item.createdOn : firebase.firestore.Timestamp.now().toMillis(),
                updatedBy: session.firebaseUser.uid,
                updatedByEmployee: session.user.employeeNumber,
                updatedByName: session.user.name,
                updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                id: item.id ? item.id : uuid.v4(),
            };

            await TrapTypeHelper.save(save);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving trap type.', ex);
            throw ex;
        } finally {
            dispatch(TrapTypeActions.setLoading(false));
        }
    }
}