import React from 'react';
import lodash from 'lodash';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import AppFunctionsService from '../../../services/appFunctionServices';
import ReactToPrint from 'react-to-print';
import Tooltip from '@material-ui/core/Tooltip';
import PrintIcon from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton';
import GeneralFunctions from '../../../store/general/functions';
import HorizontalAssignmnetReport from '../report/HorizontalReport';
import VerticalAssignmentReport from '../report/VerticalReport';
import { IScoutingAssignment } from '../../../types/model/scouting/scoutingAssignment';
import { IScoutingEntry } from '../../../types/model/scouting/scoutingEntry';

interface IAssignmentReportViewProps {
    value : IScoutingAssignment;
    vertical ?: boolean;

    entries : Array<IScoutingEntry>;
}

interface IAssignmentReportViewState {
    monitors : string;
    averageTime : number;
    totalTime : number;
}

export default class AssignmentReportView extends React.PureComponent<IAssignmentReportViewProps, IAssignmentReportViewState> {
    private listener ?: () => void;
    private componentRef : React.RefObject<HTMLDivElement>;

    constructor(props : IAssignmentReportViewProps) {
        super(props);
        this.state = {
            monitors: '',
            averageTime: 0,
            totalTime: 0,
        };

        this.componentRef = React.createRef<HTMLDivElement>();
    }

    public componentDidMount = () => {
        this.loadEntries();
    }

    private loadEntries = () => {
        const { entries } = this.props;

        const monitors = lodash.chain(entries).map(n => n.createdByName).uniq().join(', ').value();
        const averageTime = lodash.meanBy(entries, 'time');
        const totalTime = lodash.sumBy(entries, 'time');
        this.setState({
            monitors,
            averageTime,
            totalTime,
        });
    }

    public render = () => {
        const { monitors, averageTime } = this.state;
        const { value, vertical, entries } = this.props;
        return (
            <div className={'fdc ais flx1'}>
                <div className={'fdc ais pm15'} ref={this.componentRef}>
                    <div className={'fdr ais jcc'}>
                        <Typography>{value.crop.toLocaleUpperCase()}</Typography>
                    </div>
                    <div className={'fdc ais'}>
                        <div className={'fdr ais'}>
                            <div className={'fdr ais w130'}>
                                <Typography>Week No.</Typography>
                            </div>
                            <div className={'fdr ais flx1'}>
                                <Typography>{moment.utc(value.date).week()}</Typography>
                            </div>
                        </div>
                        <div className={'fdr ais'}>
                            <div className={'fdr ais w130'}>
                                <Typography>Date</Typography>
                            </div>
                            <div className={'fdr ais flx1'}>
                                <Typography>{AppFunctionsService.formatDateTimeToDateOnly(value.date)}</Typography>
                            </div>
                        </div>
                        <div className={'fdr ais'}>
                            <div className={'fdr ais w130'}>
                                <Typography>Monitor</Typography>
                            </div>
                            <div className={'fdr ais flx1'}>
                                <Typography>{monitors}</Typography>
                            </div>
                        </div>
                        <div className={'fdr ais'}>
                            <div className={'fdr ais w130'}>
                                <Typography>Block No.</Typography>
                            </div>
                            <div className={'fdr ais flx1'}>
                                <Typography>{value.blockName} - {value.landName}</Typography>
                            </div>
                        </div>
                        <div className={'fdr ais'}>
                            <div className={'fdr ais w130'}>
                                <Typography>Avg. Time</Typography>
                            </div>
                            <div className={'fdr ais flx1'}>
                                <Typography>{AppFunctionsService.millisecondsFormat(averageTime)}</Typography>
                            </div>
                        </div>
                        <div className={'fdr ais'}>
                            <div className={'fdr ais w130'}>
                                <Typography>Total Time</Typography>
                            </div>
                            <div className={'fdr ais flx1'}>
                                <Typography>{AppFunctionsService.millisecondsFormat((value.finishedOn ?? (new Date().valueOf())) - (lodash.min(value.taskCompletions) ?? 0))}</Typography>
                            </div>
                        </div>
                    </div>
                    {
                        !!entries.length && !vertical &&
                        <HorizontalAssignmnetReport entries={entries} value={value} />
                    }
                    {
                        !!entries.length && !!vertical &&
                        <VerticalAssignmentReport entries={entries} value={value} />
                    }
                </div>
                <div className={'fdr aic jcfe'}>
                    <ReactToPrint
                        trigger={() =>
                            <div>
                                <Tooltip title='Print'>
                                    <div>
                                        <IconButton color='primary' aria-label='Print'>
                                            <PrintIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                        }
                        onPrintError={GeneralFunctions.showPrintError}
                        documentTitle={`Assignment - ${AppFunctionsService.formatDateTimeToDateOnly(value.date)} -
                        ${value.landName} -
                        ${value.blockName}
                        `}
                        content={() => this.componentRef.current}
                        bodyClass={'printElement'}
                    />
                </div>
            </div>);
    }
}
