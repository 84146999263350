import { IGeoPoint, GeoPointHelper } from '../geoPoint';
import { BaseHelper, IBase } from '../../base';
import firebaseApp from '../../../services/firebaseService';
import { ScoutingAssignmentHelper } from './scoutingAssignment';
import firebase from 'firebase/app';

export interface IScoutingEntrySectionEntity {
    name : string;
    entry : string;
    section : string;
    type : string;
    count : string | number | Record<string, number>;
    photoPath : string | null;
    counterType : string;
}

export interface IScoutingEntry extends IBase {
    sections : Array<IScoutingEntrySectionEntity>;
    guid : string;
    blockId : string;
    /** Block Path */
    block : string;
    point : IGeoPoint;
    pointGuid : string;

    /** Assignment Path */
    assignment : string;

    employeeName : string;
    employeeNumber : string;
    /** Employee Path */
    employee : string;

    time : number;
}

export class ScoutingEntryHelper extends BaseHelper {
    public static readonly COLLECTION = 'scouting_entries';

    public static converter : firebase.firestore.FirestoreDataConverter<IScoutingEntry> = {
        fromFirestore: (snapshot) => {
            return ScoutingEntryHelper.fromFirestore(snapshot);
        },
        toFirestore: () => {
            throw new Error('Not Implemented');
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IScoutingEntry {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data || !result) throw new Error('Document not found!');

        return {
            ...result,
            sections: data['sections'],
            guid: data['guid'],
            block: (data['block'] as firebase.firestore.DocumentReference).path,
            blockId: data['blockId'],
            point: GeoPointHelper.fromFirestore(data['point']),
            pointGuid: data['pointGuid'],
            assignment: (data['assignment'] as firebase.firestore.DocumentReference).path,
            employee: (data['employee'] as firebase.firestore.DocumentReference).path,
            employeeName: data['employeeName'],
            employeeNumber: data['employeeNumber'],
            time: data['time'] ?? 0,
        };
    }

    public static collection(assignmentId : string) {
        return firebaseApp.firestore().collection(ScoutingAssignmentHelper.COLLECTION).doc(assignmentId).collection(this.COLLECTION).withConverter(this.converter);
    }
}
