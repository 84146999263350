import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { createSelector } from 'reselect';
import { IPhenologySpecificTreeEntry, PhenologySpecificTreeEntryHelper } from '../../../../types/model/phenology/specificEntry';
import { PhenologySpecificHelper, PhenologySpecificTreeType } from '../../../../types/model/phenology/specific';
import { IUserSession } from '../../../../types/model/user';
import GeneralFunctions from '../../../../store/general/functions';
import PhenologySpecificTreeEntryView from './SurveyEntryView';

interface IPhenologySpecificTreeSurveyViewProps {
    tree : PhenologySpecificTreeType;
    
    session ?: IUserSession | null;
}

interface IPhenologySpecificTreeSurveyViewState {
    entries : Array<IPhenologySpecificTreeEntry>;
    isLoading : boolean;
}

export default class PhenologySpecificTreeSurveyView extends React.PureComponent<IPhenologySpecificTreeSurveyViewProps, IPhenologySpecificTreeSurveyViewState> {
    private listener ?: () => void;

    constructor(props : IPhenologySpecificTreeSurveyViewProps) {
        super(props);
        this.state = {
            entries: [],
            isLoading: false,
        };
    }

    public componentDidMount() : void {
        this.load();
    }

    public componentWillUnmount() : void {
        if (this.listener) this.listener();
    }

    public async load() : Promise<void> {
        if (this.listener) this.listener();

        this.setState({
            isLoading: true,
        });

        this.listener = PhenologySpecificTreeEntryHelper.collection()
            .where('specificRef', '==', PhenologySpecificHelper.doc(this.props.tree.id))
            .where('treeCode', '==', this.props.tree.code)
            .orderBy('date', 'desc')
            .onSnapshot((event) => {
                const entries = this.state.entries.slice();

                event.docChanges().forEach((element) => {
                    switch (element.type) {
                    case 'added':
                        entries.splice(element.newIndex, 0, element.doc.data());
                        break;
                    case 'modified':
                        entries.splice(
                            element.newIndex,
                            1,
                            element.doc.data(),
                        );
                        break;
                    case 'removed':
                        entries.splice(element.oldIndex, 1);
                        break;
                    }
                });

                this.setState({
                    entries,
                    isLoading: false,
                });
            }, (ex) => {
                GeneralFunctions.generalShowErrorSnackbar('Error loading entries', ex);
            });
    }

    private readonly getEntries = (state : IPhenologySpecificTreeSurveyViewState) => state.entries;

    public render = () => {
        const {
            session,
            tree,
        } = this.props;
        const {
            entries,
            isLoading,
        } = this.state;

        return (
            <div className='fdc flx1 pt20 bcg0'>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    !entries.length &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography>No Data</Typography>
                    </div>
                }
                <div className='ml15 mr15 pb80'>
                    {
                        entries.map(x => (
                            <PhenologySpecificTreeEntryView
                                key={x.guid}
                                value={x}
                                tree={tree}
                                session={session}
                            />
                        ))
                    }
                </div>
            </div>
        );
    }
}
