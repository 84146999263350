import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import { CircularProgress, DialogContent, Tab, Tabs } from '@material-ui/core';
import UserLandNotificationsEditTab from '../edit/LandsEdit';
import TabPanel from '../../../customComponents/tab/TabPanel';
import UserFcmHelper, { IUserFcm } from '../../../../types/model/rights/userFcm';
import firebase from 'firebase/app';
import GeneralFunctions from '../../../../store/general/functions';

interface IUserNotificationSettingsDialogProps {
    open : boolean;
    onClose : () => void;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    user : firebase.User;
}

interface IUserNotificationSettingsDialogState {
    currentTab : number;
    
    userFcm : IUserFcm | null;

    isLoading : boolean;
}

export default class UserNotificationSettingsDialog extends React.Component<IUserNotificationSettingsDialogProps, IUserNotificationSettingsDialogState> {
    private sub ?: () => void;
    
    constructor(props : IUserNotificationSettingsDialogProps) {
        super(props);
        this.state = {
            currentTab: 0,
            userFcm: null,
            isLoading: false,
        };
    }

    public componentDidUpdate(prevProps : Readonly<IUserNotificationSettingsDialogProps>) : void {
        if (!prevProps.open && this.props.open) {
            this.loadData();
        }

        if (prevProps.open && !this.props.open) {
            if (this.sub) this.sub();
        }
    }

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            currentTab: value,
        });
    }

    private readonly loadData = () => {
        const {
            user,
        } = this.props;

        this.setState({
            isLoading: true,
        });

        this.sub = UserFcmHelper.collection().doc(user.uid).onSnapshot((snapshot) => {
            this.setState({
                userFcm: snapshot.data() ?? null,
                isLoading: false,
            });
        }, (error) => {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading FCM.', error);

            this.setState({
                userFcm: null,
                isLoading: false,
            });
        });
    }

    public readonly render = () => {
        const {
            open,
            onClose,
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
        } = this.props;
        const {
            currentTab,
            userFcm,
            isLoading,
        } = this.state;

        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='confirm-dialog-title'
                aria-describedby='confirm-dialog-description'
            >
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Notification Settings
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='p0'>
                    <AppBar position='static'>
                        <Tabs value={currentTab} onChange={this.onTabChange} aria-label='user tabs'>
                            <Tab label='Land Subscriptions' />
                        </Tabs>
                    </AppBar>
                    {
                        !isLoading &&
                        <div className='fdc h450 oys oxh'>
                            {
                                userFcm &&
                                <TabPanel value={currentTab} index={0}>
                                    <UserLandNotificationsEditTab userFcm={userFcm} />
                                </TabPanel>
                            }
                        </div>
                    }
                    {
                        isLoading &&
                        <div className='fdc h450'>
                            <div className='fdc flx1 aic jcc'>
                                <CircularProgress />
                            </div>
                        </div>
                    }
                </DialogContent>
            </Dialog>
        );
    }
}
