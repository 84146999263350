import React from 'react';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

interface ICheckTreeItemProps {
    checked ?: boolean;
    indeterminate ?: boolean;
    labelText : string;
    value ?: string | number;
    onChange : (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => void;
}

interface ICheckTreeItemState {}

export default class CheckTreeItem extends React.Component<ICheckTreeItemProps, ICheckTreeItemState> {
    constructor(props : ICheckTreeItemProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const {
            checked,
            indeterminate,
            labelText,
            onChange,
            value,
        } = this.props;
        return (
            <div className='fdr aic'>
                <Checkbox
                    inputProps={{
                        value: value,
                    }}
                    value={value}
                    onClick={e => (e.stopPropagation())}
                    onChange={onChange}
                    color='primary'
                    className='pr5'
                    checked={checked}
                    indeterminate={!checked && indeterminate}
                />
                <Typography variant='body1'>
                    {labelText}
                </Typography>
            </div>
        );
    }
}
