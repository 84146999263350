import { IBase, BaseHelper } from '../../base';
import firebaseApp from '../../../services/firebaseService';
import { IScoutingEntry, ScoutingEntryHelper } from './scoutingEntry';
import FirestoreHelper from '../../../services/helper/firestoreHelper';
import moment from 'moment';
import { CROP } from '../../../appConstants';
import UserHelper from '../user';
import { BlockHelper, IAssignmentBlock } from '../masterData/block';
import { ScoutingLocationEntryHelper } from './scoutingLocationEntry';
import firebase from 'firebase/app';

export const scoutingTypes : ['general', 'specific'] = ['general', 'specific'];
export type ScoutingType = typeof scoutingTypes[number];

export interface IScoutingAssignment extends IBase {
    employeeName ?: string;
    employeeNumber ?: string;

    /**
     * Employee Id
     */
    employee ?: string;

    /**
     * Date in Millis
     */
    date : number;

    /**
     * Block Id
     */
    block : string;
    blockName : string;
    landName : string;
    division : string;
    crop : CROP;
    finishedPoints : Array<string>;
    finished : boolean;
    /**
     * DateTime in milliseconds
     */
    finishedOn ?: number;

    scoutingBlock : IAssignmentBlock;
    taskCompletions ?: Array<number>;
    distance ?: number;

    assignmentType ?: string;

    type : ScoutingType;

    sections : Array<string> | null;
    components : Array<string> | null;
    insects : Array<string> | null;
    diseases : Array<string> | null;
    damages : Array<string> | null;
}

export interface IReportScoutingAssignment extends IScoutingAssignment {
    entries : Array<IScoutingEntry>;
}

export class ScoutingAssignmentHelper extends BaseHelper {
    public static readonly COLLECTION = 'scouting_assignments';

    public static converter : firebase.firestore.FirestoreDataConverter<IScoutingAssignment | null> = {
        fromFirestore: (snapshot) => {
            return ScoutingAssignmentHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IScoutingAssignment) => {
            return ScoutingAssignmentHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IScoutingAssignment | null {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data || !result) return null;

        return {
            ...result,
            employeeName: data['employeeName'],
            employeeNumber: data['employeeNumber'],
            employee: (data['employee'] as firebase.firestore.DocumentReference | null)?.id,
            date: (data['date'] as firebase.firestore.Timestamp).toMillis(),
            block: (data['block'] as firebase.firestore.DocumentReference).id,
            blockName: data['blockName'],
            landName: data['landName'],
            division: data['division'],
            crop: data['crop'],
            finishedPoints: data['finishedPoints'],
            finished: data['finished'],
            finishedOn: (data['finishedOn'] as firebase.firestore.Timestamp | null)?.toMillis(),
            scoutingBlock: BlockHelper.fromFirestoreData((data['block'] as firebase.firestore.DocumentReference).id, data['scoutingBlock']),
            taskCompletions: data['taskCompletions'],
            distance: data['distance'],
            type: data['type'] ?? 'general',
            sections: data['sections'] ?? [],
            components: data['components'] ?? [],
            insects: data['insects'] ?? [],
            diseases: data['diseases'] ?? [],
            damages: data['damages'] ?? [],
        };
    }

    protected static toFirestore(data : IScoutingAssignment) {

        const result = super.toFirestore(data);
        return {
            ...result,
            date: FirestoreHelper.millisToTimestamp(data.date),
            employeeName: data.employeeName,
            employeeNumber: data.employeeNumber,
            employee: firebaseApp.firestore().collection(UserHelper.USER_COLLECTION).doc(data.employee),
            block: BlockHelper.doc(data.block),
            blockName: data.blockName,
            landName: data.landName,
            division: data.division,
            crop: data.crop,
            finished: data.finished,
            finishedPoints: data.finishedPoints,
            scoutingBlock: BlockHelper.toFirestoreData(data.scoutingBlock),
            type: data.type,
            sections: data.sections,
            components: data.components,
            insects: data.insects,
            diseases: data.diseases,
            damages: data.damages,
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    private static async onBatchDelete(batch : firebase.firestore.WriteBatch, id : string) {
        const entries = await ScoutingEntryHelper.collection(id).get();
        const locations = await ScoutingLocationEntryHelper.collection(id).get();

        for (const entryDoc of entries.docs) {
            batch.delete(entryDoc.ref);
        }

        for (const locationDoc of locations.docs) {
            batch.delete(locationDoc.ref);
        }

        batch.delete(this.collection().doc(id));
    }

    public static async bulkDelete(ids : Array<string>) {
        const currentIds = ids.slice();

        while(currentIds.length) {
            const batch = firebaseApp.firestore().batch();
            const deleteIds = currentIds.splice(0, 50);
            
            await Promise.all(deleteIds.map((id) => this.onBatchDelete(batch, id)));

            batch.commit();
        }
    }

    /**
     * Saves object to firestore using a transaction.
     * @param transaction
     */
    public static saveTransaction(transaction : firebase.firestore.Transaction, data : IScoutingAssignment) {
        if (data.id) {
            transaction.set(this.collection().doc(data.id), data);
        } else {
            transaction.set(this.collection().doc(), data);
        }
    }

    /**
     * Appends entries to given assignment.
     * @param assignment Assignment to append entries to.
     */
    public static async getReportAssignment(assignment : IScoutingAssignment) {
        const entries = await ScoutingEntryHelper.collection(assignment.id).get();

        const result : IReportScoutingAssignment = {
            ...assignment,
            entries: entries.docs.map(entry => entry.data()),
        };

        return result;
    }

    /**
     * Returns all Assignments for given date range and their entries.
     * @param startDate Inclusive Start Date
     * @param endDate Inclusive End Date
     * @param cropType Crop Type of assignments
     * @param divisions User's list of division in lower case codes.
     */
    public static async getReportAssignments(startDate : moment.Moment, endDate : moment.Moment, cropType : CROP, divisions : Array<string>) : Promise<Array<IReportScoutingAssignment>> {
        const assignments = await ScoutingAssignmentHelper.collection()
            .where('date', '>=', FirestoreHelper.momentToTimestamp(startDate))
            .where('date', '<=', FirestoreHelper.momentToTimestamp(endDate))
            .where('crop', '==', cropType)
            // TODO: Filter divisions! Currently in limit is 10...
            // .where('division', 'in', divisions)
            .get();
        const promises = [];
        for (const doc of assignments.docs) {
            const assignment = doc.data();
            if (!assignment || !assignment.finished) continue;
            if (!divisions.includes(assignment.division)) continue;
            promises.push(this.getReportAssignment(assignment));
        }

        return Promise.all(promises);
    }
}
