import React from 'react';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import UserManagementFunctions from '../../../store/userManagement/functions';
import lodash from 'lodash';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from '../../customComponents/tab/TabPanel';
import { IUser } from '../../../types/model/user';
import UserInfoEditTab from './edit/InfoEdit';
import UserCropsEditTab from './edit/CropsEdit';
import UserDivisionsEditTab from './edit/DivisionEdit';
import UserRightsEditTab from './edit/RightsEdit';

interface IUserViewProps {
    user : IUser;
    isLoading : boolean;
}

interface IUserViewState {
    user : IUser;

    currentTab : number;
}

export default class UserView extends React.PureComponent<IUserViewProps, IUserViewState> {
    constructor(props : IUserViewProps) {
        super(props);
        this.state = {
            user: props.user,
            currentTab: 0,
        };
    }

    public readonly componentDidMount = () => {
        const { user } = this.props;
        this.setData(user);
    }

    public readonly componentDidUpdate = (prevProps : IUserViewProps) => {
        const { user } = this.props;

        if (user.ref !== prevProps.user.ref) {
            this.setData(user);
        }
    }

    private readonly setData = (user : IUser) => {
        this.setState({
            user: lodash.cloneDeep(user),
        });
    }

    private  readonly onSubmit = (e : React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const user = lodash.cloneDeep(this.state.user);

        UserManagementFunctions.saveUser(user);
    }

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            currentTab: value,
        });
    }

    private readonly onUserChange = (user : IUser) => {
        this.setState({
            user,
        });
    }

    public readonly render = () => {
        const { isLoading } = this.props;
        const { currentTab } = this.state;

        return (
            <div className='fdc flx1 m10 hfill'>
                <Paper className='fdc flx1 p10 hfill'>
                    <form autoComplete='off' onSubmit={this.onSubmit}  className='fdc flx1 p10 hfill'>
                        <Toolbar color='secondary'>
                            <div className='fdr flx1'>
                                <Typography variant='h5' color='inherit'>
                                    User {this.props.user.name}
                                </Typography>
                                <span className='flx1' />
                                <Tooltip title='Save'>
                                    <div>
                                        <IconButton color='primary' disabled={isLoading} type='submit' aria-label='Edit'>
                                            <Icon>save</Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                        </Toolbar>
                        <Divider />
                        <AppBar position='static'>
                            <Tabs value={currentTab} onChange={this.onTabChange} aria-label='user tabs'>
                                <Tab label='Info' />
                                <Tab label='Crops' />
                                <Tab label='Divisions' />
                                <Tab label='Rights' />
                            </Tabs>
                        </AppBar>
                        <div className='fdc flx1 hfill oys oxh'>
                            <TabPanel value={currentTab} index={0}>
                                <UserInfoEditTab user={this.state.user} onChange={this.onUserChange} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={1}>
                                <UserCropsEditTab user={this.state.user} onChange={this.onUserChange} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={2}>
                                <UserDivisionsEditTab user={this.state.user} onChange={this.onUserChange} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={3}>
                                <UserRightsEditTab user={this.state.user} onChange={this.onUserChange} />
                            </TabPanel>
                        </div>
                    </form>
                </Paper>
            </div>);
    }
}
