import { getType } from 'typesafe-actions';
import { DiseaseAction } from '../../../@types/redux';
import { IDisease } from '../../../types/model/masterData/disease';
import DiseaseActions from './actions';

export interface IDiseaseState {
    diseases : Array<IDisease>;
    isLoading : boolean;

}

const initialState : IDiseaseState = {
    diseases: [],
    isLoading: false,
};

export default function diseaseReducer(state : IDiseaseState = initialState, action : DiseaseAction) : IDiseaseState {
    switch (action.type) {
    case getType(DiseaseActions.setList):
        return { ...state, diseases: action.payload };
    case getType(DiseaseActions.setLoading):
        return { ...state, isLoading: action.payload };
    default:
        return state;
    }
}