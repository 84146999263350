import React, { ChangeEvent } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall  } from '../../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { createSelector } from 'reselect';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import UserManagementFunctions from '../../../store/userManagement/functions';
import UserManagementActions from '../../../store/userManagement/actions';
import UserView from './View';
import UserRegisterDialog from './dialog/Create';
import { Transitions } from '../../customComponents/animations/Transitions';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';
import { IUser } from '../../../types/model/user';

interface IUserListProps {
    user ?: IUser;
    users : Array<IUser>;
    isLoading : boolean;

    setUser : DispatchCall<IUser | undefined>;
}

interface IUserListState {
    searchText : string;
    deleteUser ?: IUser;
    showAdd : boolean;
}

class UserList extends React.Component<IUserListProps, IUserListState> {
    constructor(props : IUserListProps) {
        super(props);
        this.state = {
            searchText: '',
            showAdd: false,
        };
    }

    public componentDidMount = () => {
        UserManagementFunctions.getUsers();
    }

    private onUserClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const id = event.currentTarget.id;
        const user = this.props.users.slice().find(n => n.ref === id);
        this.props.setUser(user);
    }

    private onSearchChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            searchText: event.currentTarget.value,
        });
    }

    private getData = (props : IUserListProps) => props.users;
    private getSearch = (props : IUserListProps, state : IUserListState) => state.searchText;

    private getFilteredData = createSelector(
        [this.getData, this.getSearch],
        (users, searchText) => {
            return users.slice().filter(n => (n.email.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())) ||
            (n.employeeNumber.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())) ||
            (n.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())));
        },
    );

    private getMobileTotal = createSelector(
        [this.getData],
        (users) => {
            return users.slice().filter(n => n.employeeNumber).length;
        },
    );

    private getWebTotal = createSelector(
        [this.getData],
        (users) => {
            return users.slice().filter(n => n.email).length;
        },
    );

    private onDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const deleteUser = this.props.users.slice().find(n => n.ref === event.currentTarget.value);

        this.setState({
            deleteUser,
        });
    }

    private onDelete = (result : boolean) => {
        if (result) {
            const uid = !this.state.deleteUser ? '' : this.state.deleteUser.ref;
            UserManagementFunctions.deleteUser(uid);
        }
        this.setState({
            deleteUser: undefined,
        });
    }

    private onAddClick = () => {
        this.setState({
            showAdd: true,
        });
    }

    private onCreateClose = () => {
        this.setState({
            showAdd: false,
        });
    }

    public render = () => {
        const { isLoading, user } = this.props;
        const { deleteUser, showAdd } = this.state;

        const users = this.getFilteredData(this.props, this.state);
        const mobileTotal = this.getMobileTotal(this.props);
        const webTotal = this.getWebTotal(this.props);

        return (
            <div className='fdr flx1 hfill'>
                <div className='fdc flx1 hfill'>
                    <Paper className='fdc flx1 hfill m10'>
                        <Toolbar className={'fdr aic jcc ais'}>
                            <TextField
                                fullWidth
                                onChange={this.onSearchChanged}
                                placeholder='Search'
                                margin='dense'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Icon>search</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Tooltip title='Add'>
                                <div>
                                    <IconButton color='primary' onClick={this.onAddClick} aria-label='Add'>
                                        <Icon>add</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                        <Divider />
                        <Typography variant={'subtitle2'} className={'fdr pl20 pr30 pb5 pt5'}>
                            {'Mobile: ' + mobileTotal}
                            <div className={'flx1'} />
                            {'Web: '  + webTotal}
                        </Typography>
                        <Divider />
                        <List className={'fdc flx1 oys oxh'}>
                            <div className='mnh4'>
                                { isLoading && <LinearProgress />}
                            </div>
                            {
                                users.map(n => (
                                    <ListItem button key={n.ref} id={n.ref} onClick={this.onUserClick} selected={user && n.ref === user.ref}>
                                        <ListItemText
                                            className={n.isActive ? '' : 'cpr'}
                                            primary={n.name}
                                            secondary={n.email ? n.email : n.employeeNumber}
                                        />
                                        {
                                            n.isActive &&
                                            <ListItemSecondaryAction>
                                                <IconButton className='cpr' value={n.ref} onClick={this.onDeleteClick} aria-label='Delete'>
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                ))
                            }
                        </List>
                        <Divider />
                        <Typography variant={'subtitle2'} className={'fdr pl20 pr30 pb5 pt5'}>
                            <div className={'flx1'} />
                            {'Total: '  + users.length}
                        </Typography>
                    </Paper>
                </div>
                <div className='fdc flx4 hfill'>
                    {
                        !!user &&
                        <UserView user={user} isLoading={isLoading} />
                    }
                </div>
                <UserRegisterDialog open={showAdd} transition={Transitions.Up} onClose={this.onCreateClose} maxWidth='md' isLoading={isLoading}/>
                <ConfirmDialog open={!!deleteUser} title={!deleteUser ? '' : `Confirm Delete ${deleteUser.name}`}
                    message={!deleteUser ? '' : `Delete user ${deleteUser.name}?`}
                    onClose={this.onDelete} transition={Transitions.Up}/>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        users: state.user.users,
        user: state.user.user,
        isLoading: state.user.isLoading,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators(
    {
        setUser: UserManagementActions.setUser,
    }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserList);
