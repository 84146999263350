import React from 'react';
import { LayersControl, TileLayer, withLeaflet, LeafletContext } from 'react-leaflet';
import leaflet, { LatLng } from 'leaflet';
import { connect } from 'react-redux';
import { IRootState } from '../../../@types/redux';
import * as esri from 'esri-leaflet';
import randomColor from 'randomcolor';
import { IUserSession } from '../../../types/model/user';
import AppFunctionsService from '../../../services/appFunctionServices';

interface IStandardLayerControlProps {
    arcGisToken : string;

    managementAreas ?: boolean;
    leaflet : LeafletContext;

    division ?: string;

    polygonWidth ?: number;
    polygonOpacity ?: number;

    session ?: IUserSession | null;
}

interface IStandardLayerControlState { }

class StandardLayerControl extends React.PureComponent<IStandardLayerControlProps, IStandardLayerControlState> {
    private readonly featureLayer ?: esri.FeatureLayer;
    private readonly legend ?: L.Control = new leaflet.Control({ position: 'bottomleft' });
    private readonly esriLayer = new leaflet.GeoJSON();
    private readonly markerLayer = new leaflet.FeatureGroup();
    private readonly legends : {[key : string] : string} = {};
    private readonly divisionCenters : {[key : string] : Array<LatLng>} = {};

    private readonly esriRef : React.RefObject<LayersControl.Overlay>;
    private readonly managmentRef : React.RefObject<LayersControl.Overlay>;

    // We need this to keep track of wether the easi layer is showing or not.
    private showEsri ?: boolean;
    constructor(props : IStandardLayerControlProps) {
        super(props);
        this.state = {};

        this.esriRef = React.createRef();
        this.managmentRef = React.createRef();

        if (props.session) {
            const divisions = Object.values(props.session.user.divisions).map(n => `'${n.toLocaleLowerCase()}'`);
            this.featureLayer = esri.featureLayer({
                url: 'https://services.arcgis.com/yNX9XhIRRfSnSyUQ/arcgis/rest/services/Boundaries/FeatureServer/0',
                useCors: true,
                isModern: true,
                token: props.arcGisToken,
                style: this.featureStyle,
                renderer: leaflet.canvas(),
                cacheLayers: true,
                where: `LOWER(AFDELING) IN (${divisions.join(',')})`,
                onEachFeature: (f) => {
                    if (!this.props.session) return;
    
                    this.legends[f.properties.AFDELING?.toLocaleLowerCase()] =  randomColor({ seed: f.properties.AFDELING });
    
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    if (!this.divisionCenters[f.properties.AFDELING?.toLocaleLowerCase()]) this.divisionCenters[f.properties.AFDELING?.toLocaleLowerCase()] = [];
                    this.divisionCenters[f.properties.AFDELING?.toLocaleLowerCase()].push(leaflet.geoJSON<any>(f).getBounds().getCenter());
                },
            });
        }
    }

    public componentDidMount = () => {
        if (this.esriRef.current) this.setEsriRef(this.esriRef.current);
        if (this.managmentRef.current) this.setManagementAreaRef(this.managmentRef.current);
    }

    public componentDidUpdate = (prevProps : IStandardLayerControlProps) => {
        if (prevProps.division !== this.props.division) {
            this.onDivisionChange(this.props.division);
        }
    }

    public onDivisionChange = (divisionCode ?: string) => {
        const division = !divisionCode ? null : this.props.session?.user.divisions[divisionCode];
        
        // If the esri layer is not showing we cannot use setWhere
        if (division && !this.showEsri) {
            // Fly to selected division
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (this.divisionCenters[division]) {
                this.props.leaflet.map?.flyToBounds(leaflet.polygon(this.divisionCenters[division]).getBounds());
            }

            return;
        }

        if (division && this.featureLayer) {
            this.featureLayer.setWhere(`LOWER(AFDELING) = '${division}'`, () => {
                if (!this.legend) return;
                this.props.leaflet.map?.removeControl(this.legend);
                if (this.legend.onAdd) this.props.leaflet.map?.addControl(this.legend);

                // Fly to selected division
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (this.divisionCenters[division]) {
                    this.props.leaflet.map?.flyToBounds(leaflet.polygon(this.divisionCenters[division]).getBounds());
                }
            });
        } else if (this.featureLayer && this.props.session) {
            const divisions = Object.values(this.props.session.user.divisions).map(n => `'${n.toLocaleLowerCase()}'`);
            this.featureLayer.setWhere(`LOWER(AFDELING) IN (${divisions.join(',')})`, () => {
                if (!this.legend) return;
                this.props.leaflet.map?.removeControl(this.legend);
                if (this.legend.onAdd) this.props.leaflet.map?.addControl(this.legend);
            });
        }
    }

    private featureStyle = (feature : IFarmBoundaryFeature) => {
        return {
            color: randomColor({ seed: feature.properties.AFDELING }),
            weight: this.props.polygonWidth ?? 1,
            fillOpacity: this.props.polygonOpacity ?? 0.5,
            zIndex: -1,
        };
    }

    private onEsriFeatureRemove = () => {
        this.showEsri = false;
        if (!this.legend) return;
        this.props.leaflet.map?.removeControl(this.legend);
    }

    private onEsriFeatureAdd = () => {
        if (this.legend?.onAdd) this.props.leaflet.map?.addControl(this.legend);

        this.showEsri = true;

        if (this.props.division) this.onDivisionChange(this.props.division);
    }

    private onEsriFeatureLoadOnce = () => {
        this.esriLayer.bringToBack();

        if (!this.legend) return;
        this.legend.onAdd = () => {
            this.markerLayer.clearLayers();
            const div = leaflet.DomUtil.create('div', 'info legend');
            div.onwheel = (event) => {
                event.stopPropagation();
            };

            const division = !this.props.division ? null : this.props.session?.user.divisions[this.props.division];

            Object.keys(this.legends).filter(a => !division || a === division).sort((a, b) => a.localeCompare(b)).forEach((k) => {
                const legendDiv = document.createElement('div');
                legendDiv.onclick = () => {
                    const randomNum = Math.floor(Math.random() * this.divisionCenters[k].length);
                    this.props.leaflet.map?.flyTo(this.divisionCenters[k][randomNum], 12);
                };

                legendDiv.setAttribute('class', 'fdr aic cursorPointer');

                const legendColour = document.createElement('div');
                legendColour.setAttribute('class', 'square mr5');
                legendColour.style.backgroundColor = this.legends[k];
                legendDiv.appendChild(legendColour);

                const legendText = document.createElement('div');
                legendText.append(`${AppFunctionsService.toTitleCase(k)}`);
                legendDiv.appendChild(legendText);
                div.appendChild(legendDiv);
                // Load Markers

                this.divisionCenters[k].forEach((n) => {
                    const element = document.createElement('span');
                    element.append(AppFunctionsService.toTitleCase(k));
                    const text = leaflet.divIcon({
                        html: element,
                        className: 'bct cw fwb bts fs16',
                    });
                    new leaflet.Marker(n, {
                        icon: text,
                    }).addTo(this.markerLayer);
                });
            });

            if (!Object.keys(this.legends).length) {
                div.setAttribute('class', 'dn');
            }

            return div;
        };
        this.props.leaflet.map?.addControl(this.legend);
    }

    private setEsriRef = (ref : LayersControl.Overlay | null) => {
        if (this.featureLayer && ref) {
            this.featureLayer.on('remove', this.onEsriFeatureRemove);
            this.featureLayer.on('add', this.onEsriFeatureAdd);

            this.featureLayer.once('load', this.onEsriFeatureLoadOnce);

            this.featureLayer.addTo(this.esriLayer);
            ref.addLayer(this.esriLayer);
        }
    }

    private setManagementAreaRef = (ref : LayersControl.Overlay | null) => {
        ref?.addLayer(this.markerLayer);
    }

    public render = () => {
        const { managementAreas = true } = this.props;
        return (
            <LayersControl position='topright'>
                <LayersControl.BaseLayer name='Satellite' checked>
                    <TileLayer
                        zIndex={1}
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name='Terrain'>
                    <TileLayer
                        zIndex={1}
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        url='https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name='Map'>
                    <TileLayer
                        zIndex={1}
                        maxZoom={20} url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}' />
                </LayersControl.BaseLayer>
                <LayersControl.Overlay ref={this.esriRef} name='Management Areas' checked={managementAreas}>
                </LayersControl.Overlay>
                <LayersControl.Overlay ref={this.managmentRef} name='Management Area Names' checked={managementAreas}>
                </LayersControl.Overlay>
            </LayersControl>);
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        arcGisToken: state.auth.arcGisToken,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(withLeaflet(StandardLayerControl));
