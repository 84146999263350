import React from 'react';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { INematodeAssignment, NematodeAssignmentType } from '../../../../types/model/nematode/nematodeAssignment';
import { IAssignmentBlock } from '../../../../types/model/masterData/block';
import HorizontalExpander from '../../../customComponents/expander/HorizontalExpander';
import AssignmentFieldView from '../../../assignment/AssignmentFieldView';
import { IRootState } from '../../../../@types/redux';

interface INematodeAssignmentFieldsViewProps {
    isLoading ?: boolean;
    date ?: moment.Moment;
    landName ?: string;
    division ?: string;
    assignmentType ?: NematodeAssignmentType;
    scoutingBlocks : Record<string, Array<IAssignmentBlock>>;

    onAddFieldsClick ?: () => void;
    onDeleteClick ?: (landName : string) => void;
    onZoomClick ?: (landName : string) => void;
    onEditClick ?: (landName : string) => void;

    assignments : Array<INematodeAssignment>;
}

interface INematodeAssignmentFieldsViewState {
}

class NematodeAssignmentFieldsViewComponent extends React.PureComponent<INematodeAssignmentFieldsViewProps, INematodeAssignmentFieldsViewState> {
    constructor(props : INematodeAssignmentFieldsViewProps) {
        super(props);
        this.state = {
        };
    }

    private readonly getScoutingBlocks = (props : INematodeAssignmentFieldsViewProps) => props.scoutingBlocks;
    private readonly getAssignment = (props : INematodeAssignmentFieldsViewProps) => props.assignments;
    private readonly getDate = (props : INematodeAssignmentFieldsViewProps) => props.date;

    private readonly getScoutingFieldNames = createSelector([
        this.getScoutingBlocks,
    ], (scoutingBlocks) => {
        return Object.keys(scoutingBlocks).sort();
    });

    private readonly getDatedAssignment = createSelector([
        this.getAssignment, this.getDate,
    ], (assignments, date) => {
        return assignments
            .filter(x => moment.utc(x.date).isSame(date));
    });

    private readonly getAlreadyScoutedBlockNames =
    (landName : string, blocks : Array<IAssignmentBlock>) => {
        const assignments = this.getDatedAssignment(this.props);
        return assignments
            .filter(x => x.landName === landName)
            .filter(x => blocks.some(n => n.name === x.blockName))
            .map(n => n.blockName).sort();
    };

    public readonly render = () => {
        const {
            division,
            date,
            assignmentType,
            isLoading,
            scoutingBlocks,
            onDeleteClick,
            onZoomClick,
            onEditClick,
            onAddFieldsClick,
        } = this.props;

        const scoutingFields = this.getScoutingFieldNames(this.props);

        return (
            <HorizontalExpander initiallyExpanded title='FIELDS' color={date && division && assignmentType ? 'primary' : 'secondary'}
                disabled={!date || !division || !assignmentType || isLoading}>
                <div className='fdc pt5 pb5 pl15 pr15'>
                    <div className='fdr aic jcfe'>
                        <Button
                            onClick={onAddFieldsClick}
                            className='cp fwm fs14'
                            startIcon={
                                <div className='h24 w24 bcp brh'>
                                    <AddIcon color='secondary' />
                                </div>
                            }
                        >
                            ADD
                        </Button>
                    </div>
                    {
                        scoutingFields.map(fieldName => (
                            <div key={`${fieldName}_field`} className='mb10'>
                                <AssignmentFieldView
                                    name={fieldName}
                                    scoutingBlocks={scoutingBlocks[fieldName]}
                                    onDeleteClick={onDeleteClick}
                                    onZoomClick={onZoomClick}
                                    onEditClick={onEditClick}
                                    excludedBlocks={this.getAlreadyScoutedBlockNames(fieldName, scoutingBlocks[fieldName])} />
                            </div>
                        ))
                    }
                </div>
            </HorizontalExpander>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        assignments: state.nematode.assignments,
    };
};

const NematodeAssignmentFieldsView = connect(
    mapStateToProps,
)(NematodeAssignmentFieldsViewComponent);

export default NematodeAssignmentFieldsView;
