import axios, { AxiosRequestConfig, Canceler, CancelTokenStatic, AxiosResponse } from 'axios';
import GeneralFunctions from '../store/general/functions';

export default class HttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;

    private static requestInterceptorNum = 0;
    private static responseInterceptorNum = 0;

    /**
     * Allows you to cancel current latest inbound request
     */
    public static cancelDivisions? : Canceler;

    public static initializeInterceptor = (token : string) => {
        axios.interceptors.request.eject(HttpService.requestInterceptorNum);
        axios.interceptors.response.eject(HttpService.responseInterceptorNum);

        if (!!token) {
            HttpService.requestInterceptorNum = axios.interceptors.request.use((config : AxiosRequestConfig) => {
                config.headers['Authorization'] = 'Bearer ' + token;
                return config;
            });

            HttpService.responseInterceptorNum = axios.interceptors.response.use((response) => {
                return response;
            }, (error) => {
                if (axios.isCancel(error)) {
                    return Promise.reject();
                }

                if (!!error.response && error.response.status === 401) {
                    HttpService.initializeInterceptor('');
                    GeneralFunctions.generalShowErrorSnackbar(`Unauthorised. ${error.response.data}`);
                    return Promise.reject(error.response);
                } else if (!!error.response && error.response.status === 403) {
                    GeneralFunctions.generalShowErrorSnackbar(`Insufficient rights. ${error.response.data}`);
                    return Promise.reject(error.response);
                }

                if (!error || !error.response) {
                    GeneralFunctions.generalShowErrorSnackbar(`Connection Error.`);
                    return Promise.reject();
                }

                return Promise.reject(error.response);
            });
        }
    }

    public static getDivisions = (token : string) => {
        if (HttpService.cancelDivisions) {
            HttpService.cancelDivisions('Cancel');
        }

        return axios.create().get<IARCGISQueryResult>(`https://services.arcgis.com/yNX9XhIRRfSnSyUQ/arcgis/rest/services/Boundaries/FeatureServer/0/query`, {
            cancelToken: new HttpService.CancelToken((c) => {
                HttpService.cancelDivisions = c;
            }),
            params: {
                returnGeometry: false,
                outFields: '*',
                f: 'pjson',
                where: 'FID > 0',
                token,
            },
        });
    }

    public static getContentDispositionFileName(headers : Record<string, string>) : string {
        if (!!!headers['content-disposition']) return '';

        const result = headers['content-disposition'].split(';')[1].trim().split('=')[1];

        return result.replace(/"/g, '');
    }

    public static getDownloadFile(data : Blob, fileName : string) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    public static getFileDownload(response : AxiosResponse<Blob>) {
        const fileName = this.getContentDispositionFileName(response.headers);

        this.getDownloadFile(response.data, fileName);
    }

    public static getCloudFunctionFile = async (functionName : string, data : any) => {
        const result = await axios.post(`https://${CLOUD_FUNCTIONS_REGION}-zz2-scouting-qa.cloudfunctions.net/${functionName}`, {
            data,
        }, {
            responseType: 'blob',
        });
        HttpService.getFileDownload(result);
        return;
    }
}
