import React from 'react';
import Typography from '@material-ui/core/Typography';
import DivisionButton from '../../customComponents/button/DivisionButton';
import { CROP, IKeyValue } from '../../../appConstants';
import { motion } from 'framer-motion';
import { IReportRow } from '../../../types/reportRow';
import ScoutingReportCell from './ScoutingReportCell';
import { THRESHOLD_TYPE } from '../../../types/model/masterData/threshold';
import PointButton from '../../customComponents/button/PointButton';

interface IScoutingReportRowViewProps {
    title : string;
    rowData : IReportRow;
    reportType : THRESHOLD_TYPE;
    threshold : IKeyValue<number>;

    loopCount : number;

    cropType : CROP;

    onPointClick ?: (assignmentId : string) => void;
}

interface IScoutingReportRowViewState {
    selected : boolean;
}

export default class ScoutingReportRowView extends React.PureComponent<IScoutingReportRowViewProps, IScoutingReportRowViewState> {
    constructor(props : IScoutingReportRowViewProps) {
        super(props);
        this.state = {
            selected: false,
        };
    }

    public onSelectClick = () => {
        this.setState({
            selected: !this.state.selected,
        });
    }

    public onPointClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.props.onPointClick) this.props.onPointClick(event.currentTarget.value);
    }

    public render = () => {
        const { rowData, title, reportType, threshold, loopCount, cropType, onPointClick } = this.props;
        const { selected } = this.state;

        return (
            <div className='fdc'>
                <div className='fdr bbw1 mnh32 bocg5 bnl'>
                    <div className='fdr mnw180 mxw180 bcw'>
                        {
                            !!rowData.children && !!rowData.selectedColor &&
                            <DivisionButton onClick={this.onSelectClick} selectedColor={rowData.selectedColor} selected={selected} variant='text' className='br0 fdr aic jcc flx1 cb1'>
                                <Typography className='fwn fs15-20 flx1'>{title}</Typography>
                            </DivisionButton>
                        }
                        {
                            (!rowData.children || !rowData.selectedColor) &&
                            <PointButton variant='text'
                                value={rowData.assignmentId ?? ''}
                                className='br0 fdr aic jcc flx1 cb1'
                                onClick={this.onPointClick}>
                                <Typography className='fwn fs15-20 flx1 pt6 pb6 pr8 pl8'>{title}</Typography>
                            </PointButton>
                        }
                    </div>
                    {
                        Object.keys(rowData.data).map(header => (
                            <ScoutingReportCell loopCount={loopCount} type={reportType} thresholdValue={threshold[header]} values={rowData.data[header]} key={`value_${title}_${header}`} />
                        ))
                    }
                </div>
                {
                    !!rowData.children &&
                    <motion.div initial={{
                        overflowY: 'hidden',
                        height: 0,
                    }} animate={{
                        height: selected ? '100%' : 0,
                    }}>
                        <div className='fdc'>
                            {
                                selected &&
                                Object.keys(rowData.children).map(d => (
                                    <>
                                        {
                                            rowData.children &&
                                            <ScoutingReportRowView key={`row_${title}_${d}`}
                                                title={d}
                                                rowData={rowData.children[d]}
                                                threshold={threshold}
                                                reportType={reportType}
                                                loopCount={loopCount + 1}
                                                cropType={cropType}
                                                onPointClick={onPointClick} />
                                        }
                                    </>
                                ))
                            }
                        </div>
                    </motion.div>
                }
            </div>
        );
    }
}
