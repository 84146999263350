import firebaseApp from '../../../services/firebaseService';
import { BaseHelper, IBase } from '../../base';
import firebase from 'firebase/app';

export interface IDisease extends IBase {
    name : string;

    isActive : boolean;
}

export default class DiseaseHelper extends BaseHelper {
    public static readonly COLLECTION = 'diseases';

    public static converter : firebase.firestore.FirestoreDataConverter<IDisease> = {
        fromFirestore: (snapshot) => {
            return DiseaseHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IDisease) => {
            return DiseaseHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IDisease {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as IDisease,
            ...result,
            isActive: data.isActive ?? true,
        };
    }
    protected static toFirestore(data : IDisease) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static save(insect : IDisease) {
        if (insect.id) {
            return this.collection().doc(insect.id).set(insect);
        }

        return this.collection().doc().set(insect);
    }
}