import { DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT_NO_TIME, DATE_FORMAT_MMM_DD_HH_MM, DATEPICKER_FORMAT_DEFAULT, TIME_FORMAT_DEFAULT, WEEK_FILTER_FORMAT_DEFAULT, WEEK_INPUT_FILTER_FORMAT_DEFAULT } from '../appConstants';
import lodash from 'lodash';
import moment, { Moment } from 'moment/moment';
import firebase from 'firebase/app';

export default class AppFunctionsService {
    public static setArrayElement = <T>(arr : null | Array<T> | undefined, index : number, value : T) : Array<T> => {
        if (arr === undefined) return [];
        if (arr === null) return [];
        if (index === -1) return AppFunctionsService.addArrayElement(arr, value);

        return Object.assign([...arr], { [index]: value });
    }

    public static addArrayElement = <T>(arr : null | Array<T> | undefined, value : T, position : 'start' | 'end' = 'start') : Array<T> => {
        if (arr === undefined) return [];
        if (arr === null) return [];

        if (position === 'start') {
            return [value, ...arr];
        } else {
            return [...arr, value];
        }
    }

    public static removeArrayElement = <T>(arr : null | Array<T> | undefined, index : number) : Array<T> => {
        if (arr === undefined) return [];
        if (arr === null) return [];
        if (index === -1) return arr;

        return [...arr.slice(0, index), ...arr.slice(index + 1)];
    }

    public static getIndexOfArrayElement = <T>(arr : null | Array<T> | any, item : any, field : string) => {
        if (arr === undefined) return -1;
        if (arr === null) return -1;
        if (item === undefined || item === null) return -1;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i][field] === item[field]) {
                return i;
            }
        }
        return -1;
    }

    public static sortArray = <T>(arr : null | Array<T> | any, direction : 'asc' | 'desc', field : string) => {
        if (arr === undefined) return -1;
        if (arr === null) return -1;

        arr.sort((a : any, b : any) => {
            if (typeof(a[field]) === 'string') {
                const field1 = !a[field] ? '' : a[field];
                const field2 = !b[field] ? '' : b[field];

                if (direction === 'desc') {
                    return field1.localeCompare(field2);
                } else {
                    return field2.localeCompare(field1);
                }
            } else {
                const field1 = !a[field] ? 0 : a[field];
                const field2 = !b[field] ? 0 : b[field];

                if (direction === 'desc') {
                    return field1 - field2;
                } else {
                    return field2 - field1;
                }
            }
        });

        return arr;
    }

    public static addObjectAttribute = <T>(obj : {[key : string] : T}, key : string, value : T) : {[key : string] : T} => {
        const tempObj : { [key : string] : T } = {};
        tempObj[key] = value;
        return Object.assign({}, obj, tempObj);
    }

    public static removeObjectAttribute = (obj : {[key : string] : any}, key : string) : {[key : string] : any} => {
        return lodash.omit(obj, key);
    }

    public static isEmptyObject = (obj : {[key : string] : any}) : boolean => {
        return !Object.keys(obj).length;
    }

    public static formatDateTimeFromRecord(dateTimes : Record<string, string | number | firebase.firestore.Timestamp>) : string {
        const result : Array<string> = [];

        lodash.map(dateTimes, (dateTime, key) => {
            if (dateTime instanceof firebase.firestore.Timestamp) {
                result.push(`${key} - ${moment.utc(dateTime.toMillis()).local().format(DATE_FORMAT_DEFAULT)}`);
            }
            return result.push(`${key} - ${moment.utc(dateTime).local().format(DATE_FORMAT_DEFAULT)}`);
        });

        return result.join(', ');
    }

    public static formatDateTime(dateTime : string | number | firebase.firestore.Timestamp) : string {
        if (dateTime instanceof firebase.firestore.Timestamp) {
            return moment.utc(dateTime.toMillis()).local().format(DATE_FORMAT_DEFAULT);
        }

        return moment.utc(dateTime).local().format(DATE_FORMAT_DEFAULT);
    }

    public static formatToDatePicker(dateTime : string | firebase.firestore.Timestamp) : string {
        if (dateTime instanceof firebase.firestore.Timestamp) {
            return moment.utc(dateTime.toMillis()).local().format(DATEPICKER_FORMAT_DEFAULT);
        }
        return moment.utc(dateTime, DATE_FORMAT_DEFAULT_NO_TIME).local().format(DATEPICKER_FORMAT_DEFAULT);
    }

    public static formatFromDatePicker(dateTime : string) : string {
        return moment.utc(dateTime, DATEPICKER_FORMAT_DEFAULT).local().format(DATE_FORMAT_DEFAULT_NO_TIME);
    }

    public static formatDateTimeToDateOnly(dateTime : string | number | firebase.firestore.Timestamp) : string {
        if (dateTime instanceof firebase.firestore.Timestamp) {
            return moment.utc(dateTime.toMillis()).local().format(DATE_FORMAT_DEFAULT_NO_TIME);
        }

        return moment.utc(dateTime).format(DATE_FORMAT_DEFAULT_NO_TIME);
    }

    public static formatDateTimeToDateOnlyRecord(dateTimes : Record<string, string | number | firebase.firestore.Timestamp>) : string {
        const result : Array<string> = [];

        lodash.map(dateTimes, (dateTime, key) => {
            if (dateTime instanceof firebase.firestore.Timestamp) {
                result.push(`${key} - ${moment.utc(dateTime.toMillis()).local().format(DATE_FORMAT_DEFAULT_NO_TIME)}`);
            }
            return result.push(`${key} - ${moment.utc(dateTime).local().format(DATE_FORMAT_DEFAULT_NO_TIME)}`);
        });

        return result.join(', ');
    }

    public static formatDateTimeToTimeOnly(dateTime : string | number | firebase.firestore.Timestamp) : string {
        if (dateTime instanceof firebase.firestore.Timestamp) {
            return moment.utc(dateTime.toMillis()).local().format(TIME_FORMAT_DEFAULT);
        }

        return moment.utc(dateTime).local().format(TIME_FORMAT_DEFAULT);
    }

    public static formatWeekFilter(date : Moment) : string {
        return moment.utc(date).format(WEEK_FILTER_FORMAT_DEFAULT);
    }

    public static formatInputWeekFilter(date : Moment) : string {
        return moment.utc(date).format(WEEK_INPUT_FILTER_FORMAT_DEFAULT);
    }

    public static millisecondsFormat(millseconds : number) : string {
        const hours = Math.floor((millseconds / (1000 * 60 * 60)) % 60).toString().padStart(2, '0');
        const minutes = Math.floor((millseconds / (1000 * 60)) % 60).toString().padStart(2, '0');
        const seconds = Math.floor((millseconds / 1000) % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }


    public static formatDateTimeToMMMDDHHmm(dateTime : string | number | firebase.firestore.Timestamp | moment.Moment) : string {
        if (dateTime instanceof firebase.firestore.Timestamp) {
            return moment.utc(dateTime.toMillis()).format(DATE_FORMAT_MMM_DD_HH_MM);
        }

        return moment.utc(dateTime).local().format(DATE_FORMAT_MMM_DD_HH_MM);
    }

    public static dateDifferenceMillis(start : number, end : number) : moment.Duration {
        const startDate = moment(start);
        const endDate = moment(end);

        return moment.duration(endDate.diff(startDate));
    }

    public static formatSurnameAndInitials(surname : string, name : string) : string {
        const lastname = surname ? AppFunctionsService.toTitleCase(surname) : '';
        const names = name ? name : '';
        const initials = names.split(' ').map(value => value.charAt(0));
        return lastname + ' ' + initials.toString().replace(/,/g, '');
    }

    public static toTitleCase(value : string) : string {
        if (!value) return '';
        const words = value.split(' ');
        
        return words.map(word => word[0].toUpperCase() + word.toLowerCase().slice(1)).toString().replace(/,/g, ' ');
    }

    /* removes PR from EmployeeNumber */
    public static trimEmployeeNumber = (employeeNumber : string) : string => {
        if (employeeNumber.includes('PR')) {
            return employeeNumber.replace('PR', '');
        }
        return employeeNumber;
    }

    /* adds PR and leading zeros to EmployeeNumber */
    public static expandEmployeeNumber = (employeeNumber : string) : string => {
        let expandedEmployeeNumber : string = employeeNumber;
        /* First Ensure that the string does not aleady contain 'PR' */
        if (employeeNumber.includes('PR')) {
            expandedEmployeeNumber = AppFunctionsService.trimEmployeeNumber(expandedEmployeeNumber);
        }
        /* add PR and return */
        return 'PR' + expandedEmployeeNumber;
    }

    public static wait = (seconds : number) => {
        return new Promise((res) => {
            setTimeout(() => {
                res(undefined);
            }, seconds * 1000);
        });
    }

    public static parseParams = (params : any) => {
        const keys = Object.keys(params);
        let options = '';

        keys.forEach((key) => {
            const isParamTypeObject = typeof params[key] === 'object';
            const isParamTypeArray = isParamTypeObject && (params[key].length >= 0);

            if (!isParamTypeObject) {
                options += `${key}=${params[key]}&`;
            }

            if (isParamTypeObject && isParamTypeArray) {
                params[key].forEach((element : any) => {
                    options += `${key}=${element}&`;
                });
            }
        });

        return options ? options.slice(0, -1) : options;
    }

    public static getNestedObject = (nestedObj : { [key : string] : any }, pathArr : Array<string>) => {
        return pathArr.reduce((obj, key) =>
            (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    }

    public static getField = <T>(row : T, field : string) => {
        return AppFunctionsService.getNestedObject(row, field.split('.'));
    }

    public static blobToBase64 = (blob : Blob) => {
        return new Promise<string>((res, rej) => {
            try {
                const reader = new FileReader();
                reader.onloadend = () => {

                    if (reader.result && typeof(reader.result) === 'string') {
                        res(reader.result.split(',')[1]);
                    } else {
                        rej();
                    }
                };
                reader.readAsDataURL(blob);
            } catch (ex) {
                rej(ex);
            }
        });
    }

    public static fileExtensionFromName = (fileName : string) => {
        const re = /(?:\.([^.]+))?$/.exec(fileName);
        return !re ? null : re[1];
    }
}
