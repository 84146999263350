import { dispatch, getState } from '..';
import GeneralFunctions from '../general/functions';
import NotificationHelper from '../../types/model/notification';
import NotificationsActions from './actions';

export default class NotificationFunctions {
    private static listener ?: () => void;

    public static getList = () => {

        if (NotificationFunctions.listener) {
            NotificationFunctions.listener();
        }

        try {
            const session = getState().auth.session;

            if (!session) return;

            NotificationFunctions.listener = NotificationHelper
                .collection(session.firebaseUser.uid)
                .onSnapshot((snapshot) => {
                    dispatch(NotificationsActions.setNotifications(snapshot.docs.map(x => x.data())));

                    if (snapshot.docChanges().some(x => x.type === 'added')) {
                        GeneralFunctions.generalShowInfoSnackbar('New Notification');
                    }

                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading notifications.', err);
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading notifications.', ex);
        }
    }
}