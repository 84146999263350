import React from 'react';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FilterList from '@material-ui/icons/FilterList';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import moment from 'moment';
import AppFunctionsService from '../../../services/appFunctionServices';
import { createSelector } from 'reselect';
import WeekFilterSelector from './WeekFilterSelector';

interface IWeekSelectorProps {
    id ?: string;
    fullWidth ?: boolean;
    required ?: boolean;

    label : string;

    startDate ?: moment.Moment;
    endDate ?: moment.Moment;
    onChange ?: (startDate ?: moment.Moment, endDate ?: moment.Moment) => void;
}

interface IWeekSelectorState {
    showFilter : boolean;
}

export default class WeekSelector extends React.PureComponent<IWeekSelectorProps, IWeekSelectorState> {
    private filterRef : React.RefObject<HTMLButtonElement>;
    constructor(props : IWeekSelectorProps) {
        super(props);
        this.state = {
            showFilter: false,
        };

        this.filterRef = React.createRef<HTMLButtonElement>();
    }

    private onFilterIconButtonClick = () => {
        this.setState({
            showFilter: !this.state.showFilter,
        });
    }

    private onFilterFocusClick = () => {
        this.setState({
            showFilter: true,
        });
    }

    private onSave = (startDate ?: moment.Moment, endDate ?: moment.Moment) => {
        if (this.props.onChange) this.props.onChange(startDate, endDate);
        this.setState({
            showFilter: false,
        });
    }

    private onCancel = () => {
        this.setState({
            showFilter: false,
        });
    }

    private getStartDate = (props : IWeekSelectorProps) => props.startDate;
    private getEndDate = (props : IWeekSelectorProps) => props.endDate;
    private getLabel = createSelector(
        [this.getStartDate, this.getEndDate],
        (startDate, endDate) => {
            if (!startDate || !endDate) return '';
            if (endDate.diff(startDate, 'd') !== 6 || startDate.week() !== endDate.week()) {
                return `${AppFunctionsService.formatInputWeekFilter(startDate)} - ${AppFunctionsService.formatWeekFilter(endDate)}`;
            }

            return `Week ${startDate.week()}`;
        },
    );

    public render = () => {
        const { required,  fullWidth, label, id, startDate, endDate } = this.props;
        const { showFilter } = this.state;

        const valueLabel = this.getLabel(this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !this.props.startDate} required={required}>
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <Input
                    onFocus={this.onFilterFocusClick}
                    onChange={undefined}
                    value={valueLabel}
                    readOnly
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton
                                ref={this.filterRef}
                                aria-label='toggle week menu'
                                onClick={this.onFilterIconButtonClick}
                            >
                                <FilterList />
                            </IconButton>
                            <Popper
                                id={`${id}_week_filter_menu`} open={showFilter} anchorEl={this.filterRef.current} transition
                                placement='bottom' className='zi3'>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <div>
                                            {
                                                showFilter &&
                                                <WeekFilterSelector startDate={startDate} endDate={endDate} onSave={this.onSave} onCancel={this.onCancel} clearable={!required} />
                                            }
                                        </div>
                                    </Fade>
                                )}
                            </Popper>
                        </InputAdornment>
                    }
                />
                <FormHelperText style={{
                    height: 19.2,
                }} error>
                    {
                        required && !this.props.startDate &&
                        'Required'
                    }
                </FormHelperText>
            </FormControl>
        );
    }
}
