import React from 'react';
import { ITrap } from '../../../types/model/trap/trap';
import { DispatchCall, IRootState, RootAction } from '../../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import TrapActions from '../../../store/trap/actions';
import { connect } from 'react-redux';
import TrapFunctions from '../../../store/trap/functions';
import 'leaflet/dist/leaflet.css';
import { LatLng, polygon } from 'leaflet';
import { Map as LMap } from 'react-leaflet';
import { CROP, DEFAULT_MAP_CENTER } from '../../../appConstants';
import TrapLocationMarkers from './TrapMarkersLayer';
import StandardLayerControl from './StandardLayerControl';
import { createSelector } from 'reselect';
import { BlockHelper, IBlock } from '../../../types/model/masterData/block';
import GeneralFunctions from '../../../store/general/functions';
import { IBlockPolygonLayer } from '../../../types/model/masterData/blockPolygonLayer';
import BlockPolygonLayerGroup from './BlockPolygonLayerGroup';

interface ITrapBlocksMapProps {
    isLoading : boolean;
    traps : Array<ITrap>;
    blocks : Array<IBlock>;

    setLoading : DispatchCall<boolean>;

    cropType ?: CROP | null;
    selectedCode ?: string | null;
    divisionCode ?: string | null;
    blockId ?: string | null;
    landName ?: string | null;
    
    onTrapClick ?: (trap : ITrap, event : React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

interface ITrapBlocksMapState {}

class TrapBlocksMapComponent extends React.PureComponent<ITrapBlocksMapProps, ITrapBlocksMapState> {
    private readonly mapRef ?: React.RefObject<LMap>;

    private readonly mapZoom = 8;
    private readonly mapCenter : LatLng = DEFAULT_MAP_CENTER;

    constructor(props : ITrapBlocksMapProps) {
        super(props);
        this.state = {};

        this.mapRef = React.createRef();
    }
    
    public readonly componentDidMount = () => {
        TrapFunctions.getList();
        GeneralFunctions.getBlocks();
    }
    
    public readonly componentDidUpdate = (prevProps : Readonly<ITrapBlocksMapProps>) => {
        if (prevProps.isLoading && !this.props.isLoading) {
            this.onInit();
        }

        if (!this.props.isLoading && prevProps.divisionCode !== this.props.divisionCode) {
            this.onDivisionBlockLandChange();
        }

        if (!this.props.isLoading && prevProps.landName !== this.props.landName) {
            this.onDivisionBlockLandChange();
        }

        if (!this.props.isLoading && prevProps.blockId !== this.props.blockId) {
            this.onDivisionBlockLandChange();
        }
    }

    public readonly componentWillUnmount = () => {
        TrapFunctions.unsubscribe();
    }

    private readonly flyToTrap = (trap ?: ITrap, zoom ?: number) => {
        if (this.mapRef?.current && trap?.location) {
            this.mapRef.current.leafletElement.flyTo(
                new LatLng(trap.location.latitude, trap.location.longitude),
                zoom,
            );
        }
    }

    private readonly flyToBlock = (block ?: IBlock) => {
        if (this.mapRef?.current && block?.polygons) {
            this.mapRef.current.leafletElement.flyToBounds(
                polygon(BlockHelper.toLayer(block).positions).getBounds(),
            );
        }
    }

    private readonly onInit = () => {
        this.flyToTrap(this.props.traps.find(x => x.code === this.props.selectedCode), 18);
    }

    private readonly onDivisionBlockLandChange = () => {
        this.flyToBlock(this.props.blocks.find(x =>
            (!this.props.blockId || x.id === this.props.blockId)
            && (!this.props.cropType || x.crop === this.props.cropType)
            && (!this.props.landName || x.landName === this.props.landName)
            && (!this.props.divisionCode || x.division.toLocaleLowerCase() === this.props.divisionCode.toLocaleLowerCase())
        ));
    }

    private readonly getData = (state : ITrapBlocksMapState, props : ITrapBlocksMapProps) => props.traps;
    private readonly getDataBlocks = (state : ITrapBlocksMapState, props : ITrapBlocksMapProps) => props.blocks;
    private readonly getCropType = (state : ITrapBlocksMapState, props : ITrapBlocksMapProps) => props.cropType;
    private readonly getDivision = (state : ITrapBlocksMapState, props : ITrapBlocksMapProps) => props.divisionCode;
    private readonly getLandName = (state : ITrapBlocksMapState, props : ITrapBlocksMapProps) => props.landName;
    private readonly getBlockId = (state : ITrapBlocksMapState, props : ITrapBlocksMapProps) => props.blockId;

    private readonly getTraps = createSelector([
        this.getData,
        this.getCropType,
        this.getDivision,
        this.getLandName,
        this.getBlockId,
    ], (
        traps,
        cropType,
        divisionCode,
        landName,
        blockId,
    ) => {
        return traps
            .filter(x => !!x.location)
            .filter(x => !cropType || cropType === x.crop)
            .filter(x => !divisionCode || divisionCode === x.divisionCode)
            .filter(x => !landName || landName === x.landName)
            .filter(x => !blockId || blockId === x.block);
    });

    private readonly getBlocks = createSelector([
        this.getDataBlocks,
        this.getDivision,
        this.getLandName,
        this.getBlockId,
        this.getCropType,
    ],
    (
        blocks,
        division,
        landName,
        blockNames,
        crop,
    ) => {
        const layer : Array<IBlockPolygonLayer> = blocks
            .filter(n => !division || division.toLocaleLowerCase() === n.division.toLocaleLowerCase())
            .filter(n => !landName || n.landName === landName)
            .filter(n => !blockNames || !blockNames.length || blockNames.includes(n.name))
            .filter(n => !crop || n.crop === crop)
            .map(n => BlockHelper.toLayer(n));

        return layer;
    },
    );

    private readonly onTrapClick = (event : React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (!this.props.onTrapClick) return;
        const trap = this.props.traps.find(x => x.id === event.currentTarget.id);
        if (!trap) return;

        this.props.onTrapClick(trap, event);
    }

    public readonly render = () => {
        const filteredTraps = this.getTraps(this.state, this.props);
        const blocks = this.getBlocks(this.state, this.props);

        return (
            <LMap
                ref={this.mapRef}
                maxZoom={20}
                className={'flx1 bcw'}
                center={this.mapCenter}
                zoom={this.mapZoom}
                preferCanvas
            >
                <StandardLayerControl
                    polygonOpacity={0.2}
                    polygonWidth={5}
                />
                <BlockPolygonLayerGroup zIndex={5} blocks={blocks} />
                <TrapLocationMarkers
                    traps={filteredTraps}
                    onTrapClick={this.onTrapClick}
                />
            </LMap>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.general.blocks,
        isLoading: state.trap.isLoading || state.general.isLoadingBlocks,
        traps: state.trap.traps,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators({
    setLoading: (isLoading : boolean) => dispatcher(TrapActions.setLoading(isLoading)),
}, dispatcher);

const TrapBlocksMap = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TrapBlocksMapComponent);

export default TrapBlocksMap;