import React from 'react';

interface IModeCommentIconProps extends React.SVGProps<SVGSVGElement> {}

interface IModeCommentIconState {}

export default class ModeCommentIcon extends React.PureComponent<IModeCommentIconProps, IModeCommentIconState> {
    constructor(props : IModeCommentIconProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        return (
            <svg className='MuiSvgIcon-root' focusable='false' viewBox='0 0 24 24' aria-hidden='true'>
                <path d='M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM17 11h-4v4h-2v-4H7V9h4V5h2v4h4v2z'>
                </path>
            </svg>
        );
    }
}
