import React from 'react';
import { connect } from 'react-redux';
import 'leaflet/dist/leaflet.css';
import { IRootState, DispatchCall } from '../../../@types/redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Paper from '@material-ui/core/Paper';
import L, { LatLng } from 'leaflet';
import { Map } from 'react-leaflet';
import { createSelector } from 'reselect';
import { CROP } from '../../../appConstants';
import { PHENOLOGY_ASSIGNMENT_TYPE, IPhenologyAssignment, PhenologyAssignmentHelper } from '../../../types/model/phenology/assignment';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import lodash from 'lodash';
import PhenologyFunctions from '../../../store/scouting/functions';
import StandardLayerControl from '../../customComponents/map/StandardLayerControl';
import BlockPolygonLayerGroup from '../../customComponents/map/BlockPolygonLayerGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import StadiumButton from '../../customComponents/button/StadiumButton';
import { Transitions } from '../../customComponents/animations/Transitions';
import NavFunctions from '../../../store/nav/functions';
import Button from '@material-ui/core/Button';
import AssignmentLocationHistoryPolyline from '../../customComponents/map/AssignmentLocationHistoryPolyline';
import AssignmentWeekSelector from '../../customComponents/selector/AssignmentWeekSelector';
import AssignmentDivisionSelector from '../../customComponents/selector/AssignmentDivisionSelector';
import { BlockHelper, IAssignmentBlock, IBlock } from '../../../types/model/masterData/block';
import GeneralFunctions from '../../../store/general/functions';
import { IBlockPolygonLayer } from '../../../types/model/masterData/blockPolygonLayer';
import PhenologyAssignmentTypeSelector from './TypeSelector';
import PhenologyAssignmentFieldsView from './FieldsView';
import PhenologyAssignmentFieldSelector from './FieldSelector';
import PhenologyAssignmentCreateDialog from '../dialog/AssignmentCreateDialog';
import { PhenologyLocationEntryHelper } from '../../../types/model/phenology/locationEntry';
import { IDivision } from '../../../types/model/rights/division';

interface IPhenologyAssignmentCreateProps extends RouteComponentProps {
    isLoading : boolean;

    generalShowErrorSnackbar : DispatchCall<string>;
    generalShowSuccessSnackbar : DispatchCall<string>;
    setLoading : DispatchCall<boolean>;
    blocks : Array<IBlock>;
    assignments : Array<IPhenologyAssignment>;
}

interface IPhenologyAssignmentCreateState {
    landName ?: string;
    division ?: IDivision;
    type : PHENOLOGY_ASSIGNMENT_TYPE | null;
    
    /**
     * Phenology blocks that will be used to create assignments
     */
    assignmentBlocks : Record<string, Array<IAssignmentBlock>>;

    /**
     * Currently selected blocks for zooming and panning on map.
     * Used when Add Fields is clicked.
     */
    selectedPhenologyBlocks : Array<IAssignmentBlock>;

    date ?: moment.Moment;

    showAssignment : boolean;
    showCreate : boolean;

    showScoutHistory : boolean;
    scoutingHistory ?: number;
}

class PhenologyAssignmentCreateComponent extends React.Component<IPhenologyAssignmentCreateProps, IPhenologyAssignmentCreateState> {
    private mapRef ?: Map;

    private readonly mapZoom = 8;
    private readonly mapCenter = new LatLng(-23.5520414, 30.1148622);

    constructor(props : IPhenologyAssignmentCreateProps) {
        super(props);
        this.state = {
            assignmentBlocks: {},
            selectedPhenologyBlocks: [],
            date: moment.utc().startOf('day'),
            showAssignment: true,
            showCreate: false,
            showScoutHistory: false,
            type: null,
        };
    }

    public readonly componentDidMount = () => {
        GeneralFunctions.getBlocks();
        PhenologyFunctions.getAssignments(this.getCrop(this.props), false);
    }

    private readonly setMapRef = (ref : Map) => {
        this.mapRef = ref;
    }

    private readonly getSearch = (props : IPhenologyAssignmentCreateProps) => props.location.search;
    private readonly getPhenologyBlocks = (props : IPhenologyAssignmentCreateProps, state : IPhenologyAssignmentCreateState) => state.assignmentBlocks;
    private readonly getBlocks = (props : IPhenologyAssignmentCreateProps) => props.blocks;
    private readonly getSelectedBlocks = (props : IPhenologyAssignmentCreateProps, state : IPhenologyAssignmentCreateState) => state.selectedPhenologyBlocks;
    private readonly getShowAssignment = (props : IPhenologyAssignmentCreateProps, state : IPhenologyAssignmentCreateState) => state.showAssignment;
    private readonly getDivision = (props : IPhenologyAssignmentCreateProps, state : IPhenologyAssignmentCreateState) => state.division;
    
    private readonly getCrop = createSelector([
        this.getSearch,
    ], (search) => {
        const urlParams = new URLSearchParams(search);
        const crop = urlParams.get('crop');
        return crop as CROP;
    });

    private readonly getBlockLayers = createSelector([
        this.getPhenologyBlocks, this.getSelectedBlocks, this.getShowAssignment,
        this.getBlocks, this.getCrop, this.getDivision,
    ], (assignmentBlocks, selectedBlocks, showAssignment, blocks, crop, division) => {
        const layer : Array<IBlockPolygonLayer> = [];

        if (!showAssignment) {
            selectedBlocks
                .forEach(n => layer.push(BlockHelper.toLayer(n, true)));

            return layer;
        }

        const keys = Object.keys(assignmentBlocks);

        keys.forEach((key) => {
            assignmentBlocks[key]
                .forEach(n => layer.push(BlockHelper.toLayer(n, true)));
        });

        if (!layer.length) {
            blocks
                .filter(x => x.crop === crop)
                .filter(x => !division || division.code.toLocaleUpperCase() === x.division.toLocaleUpperCase())
                .forEach(n => layer.push(BlockHelper.toLayer(n, true)));
        }

        return layer;
    });

    private readonly onWeekChanged = (date ?: moment.Moment) => {
        this.setState({
            date,
        });
    }

    private readonly onDivisionChanged = (division ?: IDivision) => {
        this.setState({
            division,
            selectedPhenologyBlocks: [],
            landName: undefined,
            assignmentBlocks: {},
        });
    }

    private readonly onTypeChanged = (type : PHENOLOGY_ASSIGNMENT_TYPE) => {
        this.setState({
            type,
        });
    }

    private readonly onAddFieldsClick = () => {
        this.setState({
            showAssignment: !this.state.showAssignment,
            showScoutHistory: false,
            selectedPhenologyBlocks: [],
            landName: undefined,
        });
    }

    private readonly onLandPhenologyChange = (landName : string, blocks : Array<IAssignmentBlock>) => {
        this.setState({
            selectedPhenologyBlocks: blocks,
            landName,
        });

        if (this.mapRef && blocks.length) {
            const block = BlockHelper.toLayer(blocks[blocks.length - 1], true);
            if (typeof block !== 'undefined') {
                this.flyTo(block);
            }
        }
    }

    private readonly flyTo = (block : IBlockPolygonLayer) => {
        if (this.mapRef && typeof block !== 'undefined') {
            this.mapRef.leafletElement.flyToBounds(L.polygon(block.positions).getBounds());
        }
    }

    private readonly onAddClick = () => {
        const { landName, selectedPhenologyBlocks } = this.state;
        if (!landName || !selectedPhenologyBlocks.length) return;

        const assignmentBlocks = lodash.cloneDeep(this.state.assignmentBlocks);

        assignmentBlocks[landName] = selectedPhenologyBlocks.slice();

        this.setState({
            showAssignment: !this.state.showAssignment,
            showScoutHistory: false,
            selectedPhenologyBlocks: [],
            landName: undefined,
            assignmentBlocks,
        });
    }

    public readonly onDeleteLandClick = (landName : string) => {
        if (!landName) return;

        const assignmentBlocks = lodash.cloneDeep(this.state.assignmentBlocks);
        delete assignmentBlocks[landName];

        this.setState({
            assignmentBlocks,
        });
    }

    public readonly onZoomClick = (landName : string) => {
        if (!landName) return;

        const blocks = [...this.state.assignmentBlocks[landName]];

        if (this.mapRef && blocks.length) {
            this.mapRef.leafletElement.flyToBounds(L.polygon(blocks.map(n => BlockHelper.toLayer(n, true)).map(n => n.positions)).getBounds());
        }

    }

    private readonly onEditClick = (landName : string) => {
        if (!landName) return;

        const selectedPhenologyBlocks = [...this.state.assignmentBlocks[landName]];

        this.setState({
            showAssignment: !this.state.showAssignment,
            selectedPhenologyBlocks,
            landName,
        });
    }

    private readonly onAssignmentAddClick = () => {
        this.setState({
            showCreate: !this.state.showCreate,
        });
    }

    private readonly onAssignmentCreateClose = (result : boolean) => {
        this.setState({
            showCreate: !this.state.showCreate,
        });

        if (result) {
            NavFunctions.navReplacePath('/phenology/assignments', this.props.location.search);
        }
    }

    private readonly onShowScoutsClick = () => {
        this.setState({
            showScoutHistory: !this.state.showScoutHistory,
            scoutingHistory: !this.state.showScoutHistory ? 1 : undefined,
        });
    }

    private readonly onShowScoutsDayClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.setState({
            scoutingHistory: parseInt(event.currentTarget.value),
        });
    }

    private loadHistoryLocations  = async () => {
        const { date, landName } = this.state;

        const locations : Record<string, {
            positions : Array<LatLng>;
            color : string;
        }> = {};

        const assignments = this.props.assignments.filter(x =>
            x.landName === landName
            && Object.keys(x.finished).length === PhenologyAssignmentHelper.DIRECTIONS.length
            && x.date === date?.valueOf());

        const promises : Array<Promise<unknown>> = [];

        try {
            assignments.forEach((assignment) => {
                promises.push(
                    PhenologyLocationEntryHelper.collection(assignment.id).get().then((snap) => {
                        locations[assignment.blockName] = {
                            positions : snap.docs.map(n => n.data()).sort((a, b) => a.date - b.date).map(n => new LatLng(n.latitude, n.longitude, n.altitude)),
                            color: 'blue',
                        };
                    }),
                );
            });

            await Promise.all(promises);
        } catch (ex) {
            this.props.generalShowErrorSnackbar(`${ex}`);
        }

        return locations;
    }

    public render = () => {
        const { isLoading } = this.props;
        const { division, date, type,
            showAssignment, landName,
            selectedPhenologyBlocks, assignmentBlocks,
            showCreate, showScoutHistory, scoutingHistory } = this.state;

        const blocks = this.getBlockLayers(this.props, this.state);
        const crop = this.getCrop(this.props);

        return (
            <div className={'fdr flx1 p10 mh0 mw0 bcg0'}>
                <Paper className={'flx3 fdc oxh p5 m5'}>
                    <div style={{ position: 'relative' }}>
                        {
                            isLoading &&
                            <LinearProgress className='wfill' style={{
                                position: 'absolute',
                                zIndex: 1000,
                            }} />
                        }
                    </div>
                    <Map ref={this.setMapRef} maxZoom={20} className={'flx1 bcw'} center={this.mapCenter} zoom={this.mapZoom} preferCanvas>
                        <BlockPolygonLayerGroup zIndex={5} blocks={blocks} />
                        <StandardLayerControl division={division?.code} polygonOpacity={0.2} polygonWidth={5} managementAreas={false} />
                        {
                            !showAssignment &&
                            !!date &&
                            !!landName &&
                            !!scoutingHistory &&
                            <AssignmentLocationHistoryPolyline
                                landName={landName}
                                scoutingHistory={scoutingHistory}
                                date={date}
                                selectedBlocks={selectedPhenologyBlocks}
                                loadLocations={this.loadHistoryLocations} />
                        }
                    </Map>
                    {
                        !showAssignment &&
                        !!landName &&
                        <div style={{ position: 'relative' }}>
                            <div style={{
                                position: 'absolute',
                                bottom: 5,
                                left: 5,
                                zIndex: 1000,
                            }}>
                                <Button className={`${showScoutHistory ? 'bcy cpd' : 'bcp cw'} dbcg dcg pl25 pr25 br25 bsb h36 fwb`} disabled={!landName || isLoading} onClick={this.onShowScoutsClick}>
                                    VIEW SCOUTS
                                </Button>
                            </div>
                        </div>
                    }
                    {
                        showScoutHistory &&
                        !!landName &&
                        <div style={{ position: 'relative', flexDirection: 'row' }}>
                            <div style={{
                                position: 'absolute',
                                bottom: 5,
                                left: 180,
                                zIndex: 1000,
                            }}>
                                {
                                    [1, 2, 3].map(n => (
                                        <Button key={`scout_his_${n}`} value={n} className={`${scoutingHistory === n ? 'bcy cpd' : 'bcp cw'} dbcg dcg pl25 pr25 br25 bsb h36 fwb mr15`}
                                            disabled={!landName || isLoading} onClick={this.onShowScoutsDayClick}>
                                            VIEW {n} DAY{n > 1 ? 'S' : ''} AGO
                                        </Button>
                                    ))
                                }
                            </div>
                        </div>
                    }
                </Paper>
                {
                    showAssignment &&
                    <Paper className={'flx1 fdc m5'}>
                        <AppBar position='static' className='bcdg pbr' elevation={0}>
                            <Toolbar variant='dense'>
                                <Typography className='fs17'>
                                    ASSIGNMENTS
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <div className='fdc hfill oya drawer'>
                            <AssignmentWeekSelector date={date} onChanged={this.onWeekChanged} />
                            <AssignmentDivisionSelector crop={crop} value={division} onChanged={this.onDivisionChanged} />
                            <PhenologyAssignmentTypeSelector value={type} onChanged={this.onTypeChanged} />
                            <div className='fdc flx1 hfill oya drawer'>
                                <PhenologyAssignmentFieldsView
                                    division={division?.code}
                                    landName={landName}
                                    date={date}
                                    type={type}
                                    isLoading={isLoading}
                                    assignmentBlocks={assignmentBlocks}
                                    onAddFieldsClick={this.onAddFieldsClick}
                                    onDeleteClick={this.onDeleteLandClick}
                                    onZoomClick={this.onZoomClick}
                                    onEditClick={this.onEditClick} />
                            </div>
                            <div className={'fdc aife jcc p10 mt5'}>
                                <StadiumButton variant='contained' className='bcy cpd dbcg dcg bsd' disabled={!Object.keys(assignmentBlocks).length} onClick={this.onAssignmentAddClick}>
                                    ADD ASSIGNMENT
                                </StadiumButton>
                            </div>
                        </div>
                    </Paper>
                }
                {
                    !showAssignment &&
                    <PhenologyAssignmentFieldSelector crop={crop}
                        division={division?.code} landName={landName} type={type}
                        onBackClick={this.onAddFieldsClick} date={date} selectedAssignmentBlocks={selectedPhenologyBlocks}
                        onChange={this.onLandPhenologyChange} onAddClick={this.onAddClick} />
                }
                <PhenologyAssignmentCreateDialog
                    open={showCreate}
                    isLoading={isLoading}
                    date={date}
                    division={division?.code}
                    transition={Transitions.Down}
                    maxWidth='md'
                    fullWidth
                    onClose={this.onAssignmentCreateClose}
                    blocks={assignmentBlocks}
                    type={type}
                    crop={crop}
                />
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.phenology.general.isLoadingAssignments || state.general.isLoadingBlocks,
        blocks: state.general.blocks,
        assignments: state.phenology.general.assignments,
    };
};

const PhenologyAssignmentCreate = connect(
    mapStateToProps,
)(withRouter(PhenologyAssignmentCreateComponent));

export default PhenologyAssignmentCreate;
