import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import AppFunctionsService from '../../../services/appFunctionServices';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../customComponents/tab/TabPanel';
import AssignmentCompletedHistoryView from '../view/AssignmentCompletedHistoryView';
import AssignmentInfoView from '../view/AssignmentInfoView';
import AssignmentReportView from '../view/AssignmentReportView';
import { IScoutingAssignment } from '../../../types/model/scouting/scoutingAssignment';
import { RouteComponentProps, withRouter } from 'react-router';
import { IScoutingEntry, ScoutingEntryHelper } from '../../../types/model/scouting/scoutingEntry';
import lodash from 'lodash';
import GeneralFunctions from '../../../store/general/functions';
import { CircularProgress } from '@material-ui/core';

interface IAssignmentInfoDialogProps extends RouteComponentProps {
    value : IScoutingAssignment;

    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    onClosed ?: () => void;
    defaultOpen ?: boolean;
}

interface IAssignmentInfoDialogState {
    open : boolean;

    tabIndex : number;

    entries : Array<IScoutingEntry>;
    isLoading : boolean;
}

class AssignmentInfoDialogComponent extends React.PureComponent<IAssignmentInfoDialogProps, IAssignmentInfoDialogState> {
    private listener ?: () => void;
    
    constructor(props : IAssignmentInfoDialogProps) {
        super(props);
        this.state = {
            open: false,
            tabIndex: 0,
            entries: [],
            isLoading: false,
        };
    }

    public readonly componentDidMount = () => {
        this.setState({
            open: !!this.props.defaultOpen || this.inPath(),
        });
    }

    public onDialogRender = () => {
        this.loadEntries();
    }

    public componentWillUnmount = () => {
        if (this.listener) {
            this.listener();
        }
    }

    private readonly loadEntries = () => {
        this.setState({
            isLoading: true,
        });

        this.listener = ScoutingEntryHelper.collection(this.props.value.id).orderBy('createdOn', 'asc').onSnapshot((snapshot) => {
            const entries = this.state.entries.slice();

            // "added" | "removed" | "modified"
            snapshot.docChanges().forEach((f) => {
                const entry = f.doc.data();

                const index = lodash.findIndex(entries, n => n.id === entry.id);

                switch (f.type) {
                case 'added':
                    entries.push(entry);
                    break;
                case 'modified':
                    entries.splice(index, 1, entry);
                    break;
                case 'removed':
                    entries.splice(index, 1);
                    break;
                }
            });

            this.setState({
                entries,
                isLoading: false,
            });
        }, (ex) => {
            GeneralFunctions.generalShowErrorSnackbar('Error loading entries', ex);
        });
    }

    private readonly inPath = () : boolean => {
        const urlParams = new URLSearchParams(this.props.location.search);

        const assignmentId = urlParams.get('assignmentId');

        return this.props.value.id === assignmentId;
    }

    private readonly onIconButtonClick = () => {
        this.setState({
            open: true,
        });
    }

    private readonly onClose = () => {
        if (this.state.isLoading) return;

        const urlParams = new URLSearchParams(this.props.location.search);
        urlParams.delete('assignmentId');

        this.props.history.replace({
            pathname: this.props.location.pathname,
            search: urlParams.toString(),
        },);
        
        if (this.listener) {
            this.listener();
        }

        this.setState({
            open: false,
        });

        if (this.props.onClosed) this.props.onClosed();
    }

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    }

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, value, defaultOpen } = this.props;
        const { open, tabIndex, isLoading, entries } = this.state;

        return (
            <React.Fragment>
                {
                    !defaultOpen &&
                    <Tooltip title='Info'>
                        <div>
                            <IconButton color='primary' onClick={this.onIconButtonClick} aria-label='info' disabled={isLoading}>
                                <Icon>info</Icon>
                            </IconButton>
                        </div>
                    </Tooltip>
                }
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='scouting-assignment-info-dialog-title'
                    aria-describedby='scouting-assignment-info-description'
                    onRendered={this.onDialogRender}>
                    <AppBar elevation={0} className='fdc posr ais' position='static'>
                        <Toolbar variant='dense' className={'fdr'}>
                            <div className={'fdr flx1 aic'}>
                                <Typography variant='h5' color='inherit'>
                                    {
                                        `Assignment - ${AppFunctionsService.formatDateTimeToDateOnly(value.date)} -
                                        ${value.landName} -
                                        ${value.blockName}
                                        `
                                    }
                                </Typography>
                                <span className='flx1' />
                                <Tooltip title='Close'>
                                    <div>
                                        <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                            <Icon>close</Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                        </Toolbar>
                        <Tabs value={tabIndex} variant='fullWidth' onChange={this.onTabChange} aria-label='AssignmentTabs'>
                            <Tab value={0} label='INFO' />
                            <Tab value={1} label='RESULTS' />
                            <Tab value={2} label='REPORT' />
                            <Tab value={3} label='VERTICAL REPORT' />
                        </Tabs>
                    </AppBar>
                    <DialogContent className='fdc flx1'>
                        {
                            isLoading &&
                            <div className='fdc flx1 aic jcc'>
                                <CircularProgress />
                            </div>
                        }
                        {
                            !isLoading &&
                            <>
                                <TabPanel value={tabIndex} index={0}>
                                    <AssignmentInfoView value={value} entries={entries} />
                                </TabPanel>
                                <TabPanel value={tabIndex} index={1}>
                                    <AssignmentCompletedHistoryView value={value} entries={entries} />
                                </TabPanel>
                                <TabPanel value={tabIndex} index={2}>
                                    <AssignmentReportView value={value} entries={entries} />
                                </TabPanel>
                                <TabPanel value={tabIndex} index={3}>
                                    <AssignmentReportView vertical value={value} entries={entries} />
                                </TabPanel>
                            </>
                        }
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}

const AssignmentInfoDialog = withRouter(AssignmentInfoDialogComponent);

export default AssignmentInfoDialog;