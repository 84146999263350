import { CROP } from '../../../appConstants';
import firebaseApp from '../../../services/firebaseService';
import { BaseHelper, IBase } from '../../base';
import firebase from 'firebase/app';

export interface IDivision extends IBase {
    code : string;
    name : string;

    crops : Array<CROP>;

    isActive : boolean;
}

export default class DivisionHelper extends BaseHelper {
    public static readonly COLLECTION = 'divisions';

    public static converter : firebase.firestore.FirestoreDataConverter<IDivision> = {
        fromFirestore: (snapshot) => {
            return DivisionHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IDivision) => {
            return DivisionHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IDivision {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as IDivision,
            ...result,
        };
    }
    protected static toFirestore(data : IDivision) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static save(division : IDivision) {
        if (division.id) {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(division.id).set(division);
        }

        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc().set(division);
    }
}