import { TransitionProps } from '@material-ui/core/transitions/transition';
import Slide from '@material-ui/core/Slide';
import React from 'react';
import { Zoom } from '@material-ui/core';

const Up = React.forwardRef(function slideTransition(props : React.PropsWithChildren<TransitionProps & {
    children ?: React.ReactElement<unknown, ''> | undefined;
}>, ref : React.Ref<unknown>) {
    return <Slide ref={ref} {...props} direction='up' />;
});

const Down = React.forwardRef(function slideTransition(props : React.PropsWithChildren<TransitionProps & {
    children ?: React.ReactElement<unknown, ''> | undefined;
}>, ref : React.Ref<unknown>) {
    return <Slide ref={ref} {...props} direction='down' />;
});

const Left = React.forwardRef(function slideTransition(props : React.PropsWithChildren<TransitionProps & {
    children ?: React.ReactElement<unknown, ''> | undefined;
}>, ref : React.Ref<unknown>) {
    return <Slide ref={ref} {...props} direction='left' />;
});

const Right = React.forwardRef(function slideTransition(props : React.PropsWithChildren<TransitionProps & {
    children ?: React.ReactElement<unknown, ''> | undefined;
}>, ref : React.Ref<unknown>) {
    return <Slide ref={ref} {...props} direction='right' />;
});

const ZoomIn = React.forwardRef(function slideTransition(props : React.PropsWithChildren<TransitionProps & {
    children ?: React.ReactElement<unknown, ''> | undefined;
}>, ref : React.Ref<unknown>) {
    return <Zoom ref={ref} {...props} />;
});

export const Transitions = {
    Up,
    Down,
    Left,
    Right,
    ZoomIn,
};
