import * as React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import appStore, { hashHistory } from './store';
import App from './App';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider } from '@material-ui/core/styles';
import defaultTheme from './material';

if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-console
    console.log('Production?', process.env.NODE_ENV);
}

// TODO: Remove on future date!
if (window.location.href.startsWith('https://zz2-scouting.firebaseapp.com')) {
    window.location.replace('https://scouting.zz2.co.za');
}

interface IAppRootProps {}

class AppRoot extends React.Component<IAppRootProps> {
    protected readonly store : typeof appStore;

    constructor(props : IAppRootProps) {
        super(props);
        this.store = appStore;
    }

    public render() {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <MuiThemeProvider theme={defaultTheme}>
                    <Provider store={this.store}>
                        <ConnectedRouter history={hashHistory}>
                            <SnackbarProvider maxSnack={4}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                classes={{
                                    variantSuccess: defaultTheme.palette.primary.main,
                                    variantError: '#e81d2c',
                                    variantWarning: '#FF7F00',
                                    variantInfo: '#0d703f',
                                }}>
                                <App />
                            </SnackbarProvider>
                        </ConnectedRouter>
                    </Provider>
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        );
    }
}

const rootDomElement = document.getElementById('root');

if (rootDomElement) {
    ReactDOM.render(<AppRoot />, rootDomElement);
}
