import React from 'react';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import OutlinedStadiumButtonSelector from '../StadiumToggleButtonSelector';
import { ITrapFarmDescription } from '../../../../types/model/masterData/trap/farmDescription';
import { createSelector } from 'reselect';
import TrapFarmDescriptionFunctions from '../../../../store/masterData/trap/farmDescription/functions';

interface ITrapFarmDescriptionSelectorProps {
    farmDescriptions : Array<ITrapFarmDescription>;
    isLoading : boolean;

    value ?: string | null;
    onChange ?: (value ?: string | null) => void;
    disabled ?: boolean;
}

interface ITrapFarmDescriptionSelectorState {}

class TrapFarmDescriptionSelectorComponent extends React.PureComponent<ITrapFarmDescriptionSelectorProps, ITrapFarmDescriptionSelectorState> {
    constructor(props : ITrapFarmDescriptionSelectorProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount() : void {
        TrapFarmDescriptionFunctions.getList();
    }

    private readonly getData = (state : ITrapFarmDescriptionSelectorState, props : ITrapFarmDescriptionSelectorProps) => props.farmDescriptions;

    private readonly getDescriptions = createSelector([
        this.getData,
    ], (farmDescriptions) => {
        const resultList = farmDescriptions
            .filter(x => x.description.toLowerCase() !== 'other')
            .map(x => x.description)
            .sort();

        return [
            ...resultList,
            'Other'
        ];
    });

    private readonly onChange = (value : string) => {
        if (!this.props.onChange) return;
        this.props.onChange(value);
    }

    public render = () => {
        const {
            isLoading,
            value,
            disabled,
        } = this.props;

        const descriptions = this.getDescriptions(this.state, this.props);
        return (
            <OutlinedStadiumButtonSelector
                values={descriptions}
                value={value}
                disabled={isLoading || disabled}
                onChange={this.onChange}
            />
        );
    }
}
const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.masterData.trap.farmDescription.isLoading,
        farmDescriptions: state.masterData.trap.farmDescription.farmDescriptions,
    };
};

const TrapFarmDescriptionSelector = connect(
    mapStateToProps,
)(TrapFarmDescriptionSelectorComponent);

export default TrapFarmDescriptionSelector;
