import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Typography } from '@material-ui/core';
import NotificationHelper, { IFirestoreNotification } from '../../../types/model/notification';
import HorizontalExpander from '../../customComponents/expander/HorizontalExpander';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { Delete, SpeakerNotesOff } from '@material-ui/icons';

interface INotificationDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    open : boolean;
    onClose : () => void;

    notifications : Array<IFirestoreNotification>;
}

interface INotificationDialogState {
}

class NotificationDialogComponent extends React.PureComponent<INotificationDialogProps, INotificationDialogState> {
    constructor(props : INotificationDialogProps) {
        super(props);
        this.state = {
        };
    }

    public readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const notification = this.props.notifications.find(x => x.id === event.currentTarget.value);

        if (!notification) return;
        NotificationHelper.doc(notification.id).delete();

        if (!notification.clickUrl) return;
        window.open(notification.clickUrl, '_blank');
    }

    public readonly onImageClick = (event : React.MouseEvent<HTMLImageElement>) => {
        event.stopPropagation();

        window.open(event.currentTarget.src, '_blank');
    }

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            open,
            onClose,
            notifications,
        } = this.props;

        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='notification-dialog-title'
                aria-describedby='notification-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Notifications
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='p0'>
                    <div className='fdc h450 oya oxh'>
                        {
                            notifications.map((notification) => (
                                <HorizontalExpander
                                    key={notification.id}
                                    title={notification.title}
                                    color={'primary'}
                                    actionComponent={(
                                        <div className='fdr aic'>
                                            {
                                                !notification.isSent &&
                                                <div className='aic mr15'>
                                                    <Tooltip title='Undelivered'>
                                                        <SpeakerNotesOff className='cy' />
                                                    </Tooltip>
                                                </div>
                                            }
                                            <Tooltip title='Delete'>
                                                <IconButton
                                                    size='small'
                                                    value={notification.id}
                                                    onClick={this.onDeleteClick}
                                                    color='secondary'
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        
                                    )}
                                >
                                    <div className='fdc p10'>
                                        <div className='fdr'>
                                            <div className='fdc flx1 mr15'>
                                                <img
                                                    width='100%'
                                                    height={250}
                                                    className='cursorPointer'
                                                    onClick={this.onImageClick}
                                                    src={notification.imageUrl ?? `${ASSET_BASE}/assets/images/logo.png`}
                                                />
                                            </div>
                                            <div className='fdc flx1 ml15'>
                                                {
                                                    notification.body.split('\n').map(n => (
                                                        <Typography key={n} className='fs15 cg3' component='p'>
                                                            {
                                                                n
                                                            }
                                                        </Typography>
                                                    ))
                                                }
                                            </div>
                                            {
                                                notification.clickUrl &&
                                                <div className='fdr aife jcfe'>
                                                    {
                                                        <Button
                                                            value={notification.id}
                                                            onClick={this.onDeleteClick}
                                                            variant='text'
                                                            color='primary'
                                                        >
                                                            OPEN
                                                        </Button>
                                                    }
                                                </div>

                                            }
                                        </div>
                                    </div>
                                </HorizontalExpander>
                            ))
                        }
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        notifications: state.notifications.notifications,
    };
};

const NotificationDialog = connect(
    mapStateToProps,
)(NotificationDialogComponent);

export default NotificationDialog;