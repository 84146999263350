import React from 'react';
import { IUser } from '../../../../types/model/user';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import CheckTreeItem from '../../../customComponents/tree/CheckTreeItem';

interface IUserRightsEditTabProps {
    user : IUser;

    onChange : (user : IUser) => void;
}

interface IUserRightsEditTabState {}

export default class UserRightsEditTab extends React.PureComponent<IUserRightsEditTabProps, IUserRightsEditTabState> {
    constructor(props : IUserRightsEditTabProps) {
        super(props);
        this.state = {};
    }

    private readonly onRightCheckChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const user = {
            ...this.props.user,
            permissions: {
                ...this.props.user.permissions,
            }
        };
        user.permissions.isUserAdmin = checked;

        this.props.onChange(user);
    }

    private readonly onUserCheckChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const user = {
            ...this.props.user,
            permissions: {
                ...this.props.user.permissions,
            }
        };
        user.permissions.isUserAdmin = checked;

        this.props.onChange(user);
    }

    private readonly onAdminCheckChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const user = {
            ...this.props.user,
            permissions: {
                ...this.props.user.permissions,
            }
        };
        user.permissions.isThresholdAdmin = checked;
        user.permissions.isBlockAdmin = checked;
        user.permissions.isSuperAdmin = checked;

        this.props.onChange(user);
    }

    private readonly onBlockCheckChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const user = {
            ...this.props.user,
            permissions: {
                ...this.props.user.permissions,
            }
        };
        user.permissions.isBlockAdmin = checked;
        this.setState({
            user,
        });
    }

    private readonly onSuperCheckChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const user = {
            ...this.props.user,
            permissions: {
                ...this.props.user.permissions,
            }
        };
        user.permissions.isSuperAdmin = checked;

        this.props.onChange(user);
    }

    private readonly onThresholdCheckChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const user = {
            ...this.props.user,
            permissions: {
                ...this.props.user.permissions,
            }
        };
        user.permissions.isThresholdAdmin = checked;

        this.props.onChange(user);
    }

    private readonly onPhenologyCheckChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const user = {
            ...this.props.user,
            permissions: {
                ...this.props.user.permissions,
            }
        };
        user.permissions.isPhenologyAdmin = checked;

        this.props.onChange(user);
    }

    private readonly onScoutingCheckChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const user = {
            ...this.props.user,
            permissions: {
                ...this.props.user.permissions,
            }
        };
        user.permissions.isScoutingAdmin = checked;
        user.permissions.isScoutingLocationAdmin = checked;

        this.props.onChange(user);
    }

    private readonly onScoutingAdminCheckChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const user = {
            ...this.props.user,
            permissions: {
                ...this.props.user.permissions,
            }
        };
        user.permissions.isScoutingAdmin = checked;

        this.props.onChange(user);
    }

    private readonly onScoutingLocationAdminCheckChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const user = {
            ...this.props.user,
            permissions: {
                ...this.props.user.permissions,
            }
        };
        user.permissions.isScoutingLocationAdmin = checked;

        this.props.onChange(user);
    }

    private readonly onTrapCheckChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const user = {
            ...this.props.user,
            permissions: {
                ...this.props.user.permissions,
            }
        };
        user.permissions.isTrapAdmin = checked;

        this.props.onChange(user);
    }

    private readonly onNematodeCheckChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const user = {
            ...this.props.user,
            permissions: {
                ...this.props.user.permissions,
            }
        };
        user.permissions.isNematodeAdmin = checked;

        this.props.onChange(user);
    }

    public render = () => {
        const { user } = this.props;
        const {
            isUserAdmin,
            isScoutingAdmin,
            isScoutingLocationAdmin,
            isBlockAdmin,
            isThresholdAdmin,
            isPhenologyAdmin,
            isSuperAdmin,
            isTrapAdmin,
            isNematodeAdmin,
        } = user.permissions;

        return (
            <TreeView
                defaultExpanded={['right_node_admin']}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}>
                <TreeItem
                    nodeId='right_node_scouting'
                    label={
                        <CheckTreeItem labelText='Scouting' checked={!!isScoutingAdmin && !!isScoutingLocationAdmin}
                            indeterminate={isScoutingAdmin || isScoutingLocationAdmin} onChange={this.onScoutingCheckChange} />
                    }>
                    <TreeItem
                        nodeId='right_node_scouting_admin'
                        label={
                            <CheckTreeItem labelText='Admin' checked={!!isScoutingAdmin} onChange={this.onScoutingAdminCheckChange} />
                        } />
                    <TreeItem
                        nodeId='right_node_scouting_any_location'
                        label={
                            <CheckTreeItem labelText='Any Location' checked={!!isScoutingLocationAdmin} onChange={this.onScoutingLocationAdminCheckChange} />
                        } />
                </TreeItem>
                <TreeItem
                    nodeId='right_node_admin'
                    label={
                        <CheckTreeItem labelText='Admin'
                            checked={isThresholdAdmin && isBlockAdmin && isSuperAdmin}
                            indeterminate={isThresholdAdmin || isBlockAdmin || isSuperAdmin} onChange={this.onAdminCheckChange} />
                    }>
                    <TreeItem
                        nodeId='right_node_block'
                        label={
                            <CheckTreeItem labelText='Block' checked={!!isBlockAdmin} onChange={this.onBlockCheckChange} />
                        } />
                    <TreeItem
                        nodeId='right_node_super'
                        label={
                            <CheckTreeItem labelText='Super' checked={!!isSuperAdmin} onChange={this.onSuperCheckChange} />
                        } />
                    <TreeItem
                        nodeId='right_node_threshold'
                        label={
                            <CheckTreeItem labelText='Threshold' checked={!!isThresholdAdmin} onChange={this.onThresholdCheckChange} />
                        } />
                </TreeItem>
                <TreeItem
                    nodeId='right_node_phenology'
                    label={
                        <CheckTreeItem labelText='Phenology' checked={!!isPhenologyAdmin}
                            indeterminate={isPhenologyAdmin} onChange={this.onPhenologyCheckChange} />
                    }>
                    <TreeItem
                        nodeId='right_node_phenology_admin'
                        label={
                            <CheckTreeItem labelText='Admin' checked={!!isPhenologyAdmin} onChange={this.onPhenologyCheckChange} />
                        } />
                </TreeItem>
                <TreeItem
                    nodeId='right_node_trap'
                    label={
                        <CheckTreeItem labelText='Trap' checked={!!isTrapAdmin}
                            indeterminate={isTrapAdmin} onChange={this.onTrapCheckChange} />
                    }>
                    <TreeItem
                        nodeId='right_node_trap_admin'
                        label={
                            <CheckTreeItem labelText='Admin' checked={!!isTrapAdmin} onChange={this.onTrapCheckChange} />
                        } />
                </TreeItem>
                <TreeItem
                    nodeId='right_node_Nematode'
                    label={
                        <CheckTreeItem
                            labelText='Nematode'
                            checked={!!isNematodeAdmin}
                            indeterminate={isNematodeAdmin}
                            onChange={this.onNematodeCheckChange}
                        />
                    }>
                    <TreeItem
                        nodeId='right_node_Nematode_admin'
                        label={
                            <CheckTreeItem labelText='Admin' checked={!!isNematodeAdmin} onChange={this.onNematodeCheckChange} />
                        } />
                </TreeItem>
                <TreeItem
                    nodeId='right_node_right'
                    label={
                        <CheckTreeItem labelText='Right'
                            checked={isUserAdmin}
                            indeterminate={isUserAdmin} onChange={this.onRightCheckChange} />
                    }>
                    <TreeItem
                        nodeId='right_node_user'
                        label={
                            <CheckTreeItem labelText='User' checked={!!isUserAdmin} onChange={this.onUserCheckChange} />
                        } />
                </TreeItem>
            </TreeView>
        
        );
    }
}
