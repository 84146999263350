import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import UserManagementFunctions from '../../../store/userManagement/functions';
import { IUser } from '../../../types/model/user';

interface IUserDropdownProps {
    id ?: string;
    value ?: IUser | string;

    onChange : (value ?: IUser) => void;

    employees : Array<IUser>;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;

    division ?: string;

    disabled ?: boolean;
}

interface IUserDropdownState {
}

class EmployeeDropdown extends React.Component<IUserDropdownProps, IUserDropdownState> {
    constructor(props : IUserDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        UserManagementFunctions.getUsers();
    }

    private onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        const result = this.props.employees.find(n => n.ref === value?.value);

        this.props.onChange(result);
    }

    private getData = (state : IUserDropdownState, props : IUserDropdownProps) => props.employees;
    private getValue = (state : IUserDropdownState, props : IUserDropdownProps) => props.value;
    private getRequired = (state : IUserDropdownState, props : IUserDropdownProps) => props.required;
    private getDivision = (state : IUserDropdownState, props : IUserDropdownProps) => props.division;
    private getEmployeeDropdown = createSelector([
        this.getData, this.getRequired, this.getDivision,
    ], (employees, required, division) => {
        const employeesDrop = employees
            .filter(x => !division || Object.keys(x.divisions).map(z => z.toLocaleLowerCase()).includes(division.toLocaleLowerCase()))
            .sort((a, b) => a.employeeNumber.localeCompare(b.employeeNumber))
            .map(x => ({ label: ` ${x.employeeNumber} - ${x.name}`, value: x.ref }));

        if (!required) {
            employeesDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return employeesDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getData,
    ], (value, required, employees) => {
        if (typeof(value) === 'object') {
            return {
                label:  `${value.employeeNumber} - ${value.name}`,
                value: value.ref,
            };
        } else if (value && typeof(value) === 'string') {
            const employee = employees.slice().find(x => x.ref === value);

            if (employee) {
                return {
                    label:  `${employee.employeeNumber} - ${employee.name}`,
                    value: employee.ref,
                };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const { required,  fullWidth, isLoading, disabled } = this.props;

        const employees = this.getEmployeeDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        disabled={isLoading || disabled}
                        id='employee_select'
                        options={employees}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChange}
                        disableClearable={required}
                        openOnFocus
                        renderInput={params => <TextField error={required && !value} {...params} fullWidth={fullWidth} label='Employee' />}
                    />
                    {
                        required && !value &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        employees: state.user.users.filter(x => x.isActive),
        isLoading: state.user.isLoading,
    };
};

export default connect(
    mapStateToProps,
)(EmployeeDropdown);
