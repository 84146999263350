import React from 'react';
import HorizontalExpander from '../../customComponents/expander/HorizontalExpander';
import { PHENOLOGY_ASSIGNMENT_TYPE } from '../../../types/model/phenology/assignment';
import ButtonSelector from '../../customComponents/selector/ButtonSelector';
import { Icon } from '@material-ui/core';

interface IPhenologyAssignmentTypeSelectorProps {
    value ?: PHENOLOGY_ASSIGNMENT_TYPE | null;
    onChanged ?: (value : PHENOLOGY_ASSIGNMENT_TYPE) => void;
}

interface IPhenologyAssignmentTypeSelectorState {}

export default class PhenologyAssignmentTypeSelector extends React.PureComponent<IPhenologyAssignmentTypeSelectorProps, IPhenologyAssignmentTypeSelectorState> {
    constructor(props : IPhenologyAssignmentTypeSelectorProps) {
        super(props);
        this.state = {};
    }

    private onScoutTypeChange = (value : string) => {
        if (!value) return;
        if (!this.props.onChanged) return;
        
        this.props.onChanged(value as PHENOLOGY_ASSIGNMENT_TYPE);
    }

    public render = () => {
        const { value } = this.props;

        return (
            <HorizontalExpander title={`PHENOLOGY TYPE${!value ? '' : ' - '.concat(value.toLocaleUpperCase())}`} color={value ? 'primary' : 'secondary'}>
                <div className='fdc p10'>
                    <div className={'fdc flx1 ais'}>
                        <ButtonSelector<PHENOLOGY_ASSIGNMENT_TYPE>
                            values={['Farmer Survey', 'Specific']}
                            onChange={this.onScoutTypeChange}
                            value={value}
                        />
                    </div>
                    {
                        value === 'Farmer Survey' &&
                        <div className='fdr aic mt15 co'>
                            <Icon className='mr15'>warning</Icon>
                            <span>Point based phenology tracking</span>
                        </div>
                    }
                    {
                        value === 'Specific' &&
                        <div className='fdr aic mt15 co'>
                            <Icon className='mr15'>warning</Icon>
                            <span>QR Code based phenology tracking</span>
                        </div>
                    }
                </div>
            </HorizontalExpander>
        );
    }
}
