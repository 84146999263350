import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import CultivarFunctions from '../../../../store/masterData/cultivar/functions';
import { ICultivar } from '../../../../types/model/masterData/cultivars';
import { CROP } from '../../../../appConstants';

interface ICultivarAutocompleteProps {
    id ?: string;
    value : Array<string>;

    onChange : (value : Array<ICultivar>, event ?: React.ChangeEvent<unknown>) => void;

    cultivars : Array<ICultivar>;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;
    disabled ?: boolean;
    single ?: boolean;
    name ?: string;

    label ?: string;

    crop ?: CROP;
}

interface ICultivarAutocompleteState {
}

class CultivarAutocompleteComponent extends React.Component<ICultivarAutocompleteProps, ICultivarAutocompleteState> {
    constructor(props : ICultivarAutocompleteProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        CultivarFunctions.getList();
    }

    private onChange = (event : React.ChangeEvent<unknown>, value : Array<{
        label : string;
        value : string;
    }> | null) => {
        const cultivars = this.props.cultivars.filter(x => value?.some(z => z.value === x.id));
        this.props.onChange(cultivars);
    }

    private onSingleChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        const cultivar = this.props.cultivars.find(x => value?.value === x.id);
        if (cultivar) {
            this.props.onChange([cultivar]);
        } else {
            this.props.onChange([]);
        }
    }

    private getCultivars = (state : ICultivarAutocompleteState, props : ICultivarAutocompleteProps) => props.cultivars;
    private getValue = (state : ICultivarAutocompleteState, props : ICultivarAutocompleteProps) => props.value;
    private getRequired = (state : ICultivarAutocompleteState, props : ICultivarAutocompleteProps) => props.required;
    private getCrop = (state : ICultivarAutocompleteState, props : ICultivarAutocompleteProps) => props.crop;

    private getData = createSelector([
        this.getCultivars,
        this.getRequired,
        this.getCrop,
    ], (
        cultivars,
        required,
        crop,
    ) => {
        const cultivarsDrop = cultivars
            .filter(n => !crop || n.crop.toLocaleUpperCase() === crop.toLocaleUpperCase())
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(x => ({
                label: `${x.name}`,
                value: x.id,
            }));

        if (!required) {
            cultivarsDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return cultivarsDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getData,
    ], (value, required, cultivars) => {
        const values = [];

        cultivars
            .slice()
            .filter(x => value.includes(x.value))
            .forEach(n => values.push({
                label: n.label,
                value: n.value,
            }));

        if (!value.length && !required) {
            values.push({
                label: 'ALL',
                value: '',
            });
        }

        return values;
    });

    public render = () => {
        const {
            required,
            fullWidth,
            isLoading,
            disabled,
            label,
            single,
        } = this.props;

        const cultivars = this.getData(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    {
                        single &&
                        <Autocomplete
                            disabled={isLoading || disabled}
                            id='cultivar_select'
                            options={cultivars}
                            value={!value.length ? null : value[0]}
                            getOptionSelected={(option, val) => option.value === val.value}
                            getOptionLabel={option => option.label}
                            onChange={this.onSingleChange}
                            disableClearable={required}
                            openOnFocus
                            renderInput={params => (
                                <TextField
                                    error={required && !value.length}
                                    required={required}
                                    {...params}
                                    fullWidth={fullWidth}
                                    label={label ?? 'Cultivar'}
                                />)}
                        />
                    }
                    {
                        !single &&
                        <Autocomplete
                            disabled={isLoading || disabled}
                            id='cultivar_select'
                            multiple
                            options={cultivars}
                            value={value}
                            getOptionSelected={(option, val) => option.value === val.value}
                            getOptionLabel={option => option.label}
                            onChange={this.onChange}
                            disableClearable={required}
                            openOnFocus
                            renderInput={params => (
                                <TextField
                                    error={required && !value.length}
                                    required={required}
                                    {...params}
                                    fullWidth={fullWidth}
                                    label={label ?? 'Cultivar'}
                                />)}
                        />
                    }
                    {
                        required &&
                        !value &&
                        <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        cultivars: state.masterData.cultivar.cultivars.filter(x => x.isActive),
        isLoading: state.masterData.cultivar.isLoading,
    };
};

const CultivarAutocomplete = connect(
    mapStateToProps,
)(CultivarAutocompleteComponent);

export default CultivarAutocomplete;
