import React from 'react';
import ThresholdHeader from './ThresholdHeader';
import ThresholdInput from './ThresholdInput';
import { InputProps } from '@material-ui/core/Input';

interface IThresholdFieldProps extends InputProps {
    header : string;
}

interface IThresholdFieldState {}

export default class ThresholdField extends React.PureComponent<IThresholdFieldProps, IThresholdFieldState> {
    constructor(props : IThresholdFieldProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { header, name, onChange, onBlur, value, error, required } = this.props;
        return (
            <div className='fdc flx1'>
                <ThresholdHeader title={header} />
                <div className='aic jcc bcg2 mnh45'>
                    <ThresholdInput name={name} value={value} onChange={onChange} onBlur={onBlur} type='number' error={error} required={required}  />
                </div>
            </div>
        );
    }
}
