import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import lodash from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { IBlock } from '../../../types/model/masterData/block';
import GeneralFunctions from '../../../store/general/functions';

interface IScoutingBlockNameDropdownProps {
    value : Array<string>;

    onChange : (value : Array<string>) => void;

    blocks : Array<IBlock>;

    required ?: boolean;
    autoFocus ?: boolean;
    disabled ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;

    division ?: string;
    landName ?: string;

    single ?: boolean;
    crop ?: string;
}

interface IScoutingBlockNameDropdownState {
}

class ScoutingBlockNameDropdown extends React.Component<IScoutingBlockNameDropdownProps, IScoutingBlockNameDropdownState> {
    constructor(props : IScoutingBlockNameDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        GeneralFunctions.getBlocks();
    }

    private onChange = (event : React.ChangeEvent<unknown>, value : Array<{
        label : string;
        value : string;
    }> | null) => {
        if (this.props.single && value?.length) {
            this.props.onChange([value[value.length - 1].value]);
        } else {
            this.props.onChange(value?.map(n => n.value) ?? []);
        }
    }

    private onSingleChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        if (value) {
            this.props.onChange([value.value]);
        } else {
            this.props.onChange([]);
        }
    }

    private getDivision = (state : IScoutingBlockNameDropdownState, props : IScoutingBlockNameDropdownProps) => props.division;
    private getLandName = (state : IScoutingBlockNameDropdownState, props : IScoutingBlockNameDropdownProps) => props.landName;
    private getBlocks = (state : IScoutingBlockNameDropdownState, props : IScoutingBlockNameDropdownProps) => props.blocks;
    private getValue = (state : IScoutingBlockNameDropdownState, props : IScoutingBlockNameDropdownProps) => props.value;
    private getCrop = (state : IScoutingBlockNameDropdownState, props : IScoutingBlockNameDropdownProps) => props.crop;
    private getRequired = (state : IScoutingBlockNameDropdownState, props : IScoutingBlockNameDropdownProps) => props.required;

    private getData = createSelector([
        this.getBlocks, this.getDivision, this.getLandName, this.getCrop,
    ], (blocks, division, landName, crop) => {
        return lodash.chain(blocks)
            .filter(n => !crop || n.crop.toLocaleUpperCase() === crop.toLocaleUpperCase())
            .filter(n => !division || n.division.toLocaleUpperCase() === division.toLocaleUpperCase())
            .filter(n => !landName || n.landName === landName)
            .uniqBy(n => n.name)
            .map(n => ({
                label: n.name,
                value: n.name,
            }))
            .sortBy(n => n.label)
            .value();
    });

    private getScoutingBlockNameDropdown = createSelector([
        this.getData, this.getRequired,
    ], (blocks, required) => {
        const blocksDrop = blocks.map(x => ({ label: x.label, value: x.value }));

        if (!required) {
            blocksDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return blocksDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getData,
    ], (value, required, blocks) => {
        const values = [];

        blocks
            .slice()
            .filter(x => value.includes(x.value))
            .forEach(n => values.push({
                label: n.label,
                value: n.value,
            }));

        if (!value.length && !required) {
            values.push({
                label: 'ALL',
                value: '',
            });
        }

        return values;
    });

    public render = () => {
        const { required,  fullWidth, isLoading, disabled, single } = this.props;

        const blocks = this.getScoutingBlockNameDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value.length} required={required}>
                    {
                        single &&
                        <Autocomplete
                            id='blockName_select'
                            options={blocks}
                            value={!value ? null : value[0]}
                            getOptionSelected={(option, val) => option.value === val.value}
                            getOptionLabel={option => option.label}
                            onChange={this.onSingleChange}
                            disableClearable={required}
                            openOnFocus
                            disabled={disabled || isLoading}
                            renderInput={params => <TextField error={required && !value} required={required} {...params} fullWidth={fullWidth} label={'Block Name'} />}
                        />
                    }
                    {
                        !single &&
                        <Autocomplete
                            id='blockName_select'
                            options={blocks}
                            value={value}
                            disabled={disabled}
                            multiple
                            getOptionSelected={(option, val) => option.value === val.value}
                            getOptionLabel={option => option.label}
                            onChange={this.onChange}
                            disableClearable={required}
                            openOnFocus
                            disableCloseOnSelect
                            limitTags={3}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon color='primary' fontSize='small' />}
                                        checkedIcon={<CheckBoxIcon  color='primary' fontSize='small' />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.label}
                                </React.Fragment>
                            )}
                            renderInput={params => <TextField maxRows={1} error={required && !value} {...params} fullWidth={fullWidth} label='Block Name' />}
                        />
                    }
                    
                    {
                        required && !value.length &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.general.blocks,
        isLoading: state.general.isLoadingBlocks,
    };
};

export default connect(
    mapStateToProps,
)(ScoutingBlockNameDropdown);
