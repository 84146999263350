
import moment from 'moment';

const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY HH:mm:ss';

export const setArrayElement = <T>(arr : null | Array<T>, index : number, value : T) : Array<T> => {
    if (arr === undefined) return [];
    if (arr === null) return [];
    if (index === -1) return addArrayElement(arr, value);

    return Object.assign([...arr], { [index]: value });
};

export const addArrayElement = <T>(arr : null | Array<T>, value : T, position : 'start' | 'end' = 'start') : Array<T> => {
    if (arr === undefined) return [];
    if (arr === null) return [];

    if (position === 'start') {
        return [value, ...arr];
    } else if (position === 'end') {
        return [...arr, value];
    }
    return arr;
};

export const getIndexOfArrayElement = <T>(arr : null | Array<T> | any, item : any, field : string) => {
    if (arr === undefined) return -1;
    if (arr === null) return -1;
    if (item === undefined || item === null) return -1;

    for (let i = 0; i < arr.length; i++) {
        if (arr[i][field] === item[field]) {
            return i;
        }
    }
    return -1;
};

export const removeArrayElement = <T>(arr : null | Array<T>, index : number) : Array<T> => {
    if (arr === undefined) return [];
    if (arr === null) return [];
    if (index === -1) return arr;

    return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const getNestedObject = (nestedObj : { [key : string] : any }, pathArr : Array<string>) => {
    return pathArr.reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
};

export const getField = <T>(row : T, field : string) => {
    return getNestedObject(row, field.split('.'));
};

export function formatDateTime(dateTime : string | number) : string {
    return moment.utc(dateTime).local().format(DATE_FORMAT_DEFAULT);
}
