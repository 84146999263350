import React from 'react';
import { LatLng } from 'leaflet';
import { CircleMarker, LayerGroup, Polyline, Popup } from 'react-leaflet';

interface IAssignmentFinishedPointMarkerLayerProps {
    finishedPoints : Array<LatLng>;
}

interface IAssignmentFinishedPointMarkerLayerState {}

export default class AssignmentFinishedPointMarkerLayer extends React.PureComponent<IAssignmentFinishedPointMarkerLayerProps, IAssignmentFinishedPointMarkerLayerState> {
    constructor(props : IAssignmentFinishedPointMarkerLayerProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { finishedPoints } = this.props;

        return (
            <LayerGroup>
            {
                finishedPoints.map((n, i) => (
                    <CircleMarker key={`${i}_fin_marker`} center={n} radius={8} weight={1} color='blue' fillOpacity={0} />
                ))
            }
            </LayerGroup>
        );
    };
}
