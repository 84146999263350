import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../@types/redux';
import { IDivision } from '../../../types/model/rights/division';
import DivisionFunctions from '../../../store/rights/division/functions';
import { Card, IconButton, Tooltip } from '@material-ui/core';
import MaterialTable from '../../customComponents/materialTable/Table';
import { Add, Check, Close, Edit } from '@material-ui/icons';
import DivisionEditDialog from './dialog/Edit';
import StandardFab from '../../customComponents/button/StandardFab';
import moment from 'moment';

interface IDivisionListProps {
    divisions : Array<IDivision>;
    isLoading : boolean;
}

interface IDivisionListState {
    selected : IDivision | null;
}

class DivisionListComponent extends React.PureComponent<IDivisionListProps, IDivisionListState> {
    constructor(props : IDivisionListProps) {
        super(props);
        this.state = {
            selected: null,
        };
    }

    public readonly componentDidMount = () => {
        DivisionFunctions.getList();
    };

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const {
            divisions,
        } = this.props;

        const value = divisions.find(x => x.id === event.currentTarget.value);
        if (!value) return;

        this.setState({
            selected: {
                ...value,
            },
        });
    };

    private readonly onEditClose = () => {
        this.setState({
            selected: null,
        });
    };

    public readonly onAddClick = () => {
        this.setState({
            selected: {
                id: '',
                crops: [],
                code: '',
                name: '',
                isActive: true,
                createdBy: '',
                createdByEmployee: '',
                createdByName: '',
                createdOn: moment.utc().valueOf(),
                updatedBy: '',
                updatedByEmployee: '',
                updatedByName: '',
                updatedOn: moment.utc().valueOf(),
            },
        });
    };

    public render = () => {
        const {
            isLoading,
            divisions,
        } = this.props;
        const {
            selected,
        } = this.state;

        return (
            <div className={'fdc hfill mh0 mw0 bcg0'}>
                <div className='fdc flx1 p20'>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<IDivision>
                            id='divisionsTable'
                            data={divisions}
                            rowsPerPage={50}
                            isLoading={isLoading}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 10,
                                renderCell: (row) => (
                                    <div className='aic'>
                                        <Tooltip title='Edit'>
                                            <div>
                                                <IconButton color='primary' value={row.id} disabled={isLoading} onClick={this.onEditClick}>
                                                    <Edit />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Code',
                                field: 'code',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Name',
                                field: 'name',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Crops',
                                field: 'crops',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => n.crops.length,
                            }, {
                                header: 'Is Active?',
                                field: 'isActive',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => !n.isActive ? (<Close className='cpr' />) : (<Check className='cdg' />),
                            }]}
                        />
                    </Card>
                    <DivisionEditDialog
                        value={selected}
                        onClose={this.onEditClose}
                        isLoading={isLoading}
                    />
                </div>
                <div className='fdr aife jcfe'>
                    <Tooltip title='Create'>
                        <div>
                            <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                                <Add />
                            </StandardFab>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        divisions: state.rights.division.divisions,
        isLoading: state.rights.division.isLoading,
    };
};

const DivisionList = connect(
    mapStateToProps,
)(DivisionListComponent);

export default DivisionList;