import { combineReducers } from 'redux';
import trapTypeReducer from './trapType/reducer';
import trapFarmDescriptionReducer from './farmDescription/reducer';
import trapEntryCommentReducer from './trapEntryComment/reducer';

const trapMasterDataReducer = combineReducers({
    trapType: trapTypeReducer,
    farmDescription: trapFarmDescriptionReducer,
    trapEntryComment: trapEntryCommentReducer,
});

export default trapMasterDataReducer;