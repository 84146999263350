import { Card, Icon, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from '../../../@types/redux';
import { CROP } from '../../../appConstants';
import PhenologyFunctions from '../../../store/phenology/general/functions';
import { IPhenology, IPhenologySection } from '../../../types/model/masterData/phenology';
import StandardFab from '../../customComponents/button/StandardFab';
import MaterialTable from '../../customComponents/materialTable/Table';
import PhenologyGeneralEditDialog from './edit/GeneralEditDialog';

interface IPhenologyGeneralListProps {
    crop : CROP;
    phenologies : Array<IPhenology>;
    isLoading : boolean;
}

interface IPhenologyGeneralListState {
    selected ?: IPhenologySection;
}

class PhenologyGeneralListComponent extends React.PureComponent<IPhenologyGeneralListProps, IPhenologyGeneralListState> {
    constructor(props : IPhenologyGeneralListProps) {
        super(props);
        this.state = {};
    }

    private readonly getData = (props : IPhenologyGeneralListProps) => props.phenologies;
    private readonly getCrop = (props : IPhenologyGeneralListProps) => props.crop;

    private readonly getPhenology = createSelector(
        [this.getData, this.getCrop],
        (phenologies, crop) => {
            return phenologies.find(x => x.type === 'general' && x.crop === crop) ?? ({
                crop,
                type: 'general',
                sections: [],
            });
        },
    );

    private readonly onAddClick = () => {
        this.setState({
            selected: {
                name: '',
                options: [],
                optional: false,
                validMonths: [],
            }
        });
    };

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const phenology = {
            ...this.getPhenology(this.props)
        } as IPhenology;

        this.setState({
            selected: phenology.sections.find(x => x.name === event.currentTarget.value),
        });
    };

    private readonly onEditClose = (section ?: IPhenologySection) => {
        if (!section) {
            this.setState({
                selected: undefined,
            });
            return;
        }
        
        const phenology = {
            ...this.getPhenology(this.props)
        } as IPhenology;

        phenology.sections = [...phenology.sections];

        const index = phenology.sections.findIndex(x => x.name === section.name);

        if (index > -1) {
            phenology.sections.splice(index, 1, {
                ...section,
                options: [...section.options],
            });
        } else {
            phenology.sections.push({
                ...section,
                options: [...section.options],
            });
        }

        PhenologyFunctions.save(phenology).then(() => {
            this.setState({
                selected: undefined,
            });
        });
    }

    private readonly onUpClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const phenology = {
            ...this.getPhenology(this.props)
        } as IPhenology;
        
        this.changeOrder(phenology, event.currentTarget.value, true);
    };

    private readonly onDownClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const phenology = {
            ...this.getPhenology(this.props)
        } as IPhenology;
        
        this.changeOrder(phenology, event.currentTarget.value, false);
    };

    private readonly changeOrder = (phenology : IPhenology, name : string, up : boolean) => {
        const sections = [...phenology.sections];

        const index = sections.findIndex(x => x.name === name);

        if (up && index > 0) {
            const section = sections[index];
            const prevSection = sections[index - 1];

            sections.splice(index, 1, prevSection);
            sections.splice(index - 1, 1, section);

            PhenologyFunctions.save({
                ...phenology,
                sections,
            });
        } else if (!up && index < sections.length - 1) {
            const section = sections[index];
            const prevSection = sections[index + 1];

            sections.splice(index, 1, prevSection);
            sections.splice(index + 1, 1, section);

            PhenologyFunctions.save({
                ...phenology,
                sections,
            });
        }
    };

    public readonly render = () => {
        const { selected } = this.state;
        const { isLoading } = this.props;

        const phenology = this.getPhenology(this.props);
        return (
            <div className='fdc flx1'>
                <Card className={'flx1 fdc mb70'}>
                    <MaterialTable<IPhenologySection>
                        id='phenologyTable'
                        data={phenology.sections}
                        rowsPerPage={50}
                        isLoading={isLoading}
                        columns={[{
                            header: '',
                            paddingRight: 4,
                            width: 10,
                            renderCell: row => (
                                <div className='aic'>
                                    <Tooltip title='Edit'>
                                        <div>
                                            <IconButton value={row.name} disabled={isLoading} onClick={this.onEditClick}>
                                                <Icon>edit</Icon>
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </div>
                            ),
                        },{
                            header: '',
                            paddingRight: 4,
                            width: 15,
                            renderCell: row => (
                                <div className='aic'>
                                    <Tooltip title='Up'>
                                        <div>
                                            <IconButton value={row.name} disabled={isLoading} onClick={this.onUpClick}>
                                                <Icon>expand_less</Icon>
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title='Down'>
                                        <div>
                                            <IconButton value={row.name} disabled={isLoading} onClick={this.onDownClick}>
                                                <Icon>expand_more</Icon>
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </div>
                            ),
                        }, {
                            header: 'Name',
                            field: 'name',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Months',
                            field: 'validMonths',
                            width: 145,
                            enableSort: true,
                            renderCell: row => !row.validMonths.length ? 'All' : row.validMonths.map(n => moment().month(n).format('MMMM')).join(),
                        }, {
                            header: 'Options',
                            field: 'options',
                            width: 145,
                            enableSort: true,
                            renderCell: row => (row.options.length),
                        }]}
                    />
                </Card>
                <PhenologyGeneralEditDialog value={selected} fullWidth fullScreen onClose={this.onEditClose} isLoading={isLoading} />
                <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                    <AddIcon />
                </StandardFab>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        phenologies: state.phenology.general.phenologies,
        isLoading: state.phenology.general.isLoading,
    };
};


const PhenologyGeneralList = connect(
    mapStateToProps,
)(PhenologyGeneralListComponent);

export default PhenologyGeneralList;
