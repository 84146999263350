import { getType } from 'typesafe-actions';
import TrapActions from './actions';
import { ITrapState, TrapAction } from '../../@types/redux';

const initialState : ITrapState = {
    traps: [],
    isLoading: false,
};

export default function trapReducer(state : ITrapState = initialState, action : TrapAction) : ITrapState {
    switch (action.type) {
    case getType(TrapActions.setList):
        return { ...state, traps: action.payload };
    case getType(TrapActions.setLoading):
        return { ...state, isLoading: action.payload };
    default:
        return state;
    }
}