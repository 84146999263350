import React from 'react';
import { CROP, IKeyValue } from '../../../appConstants';
import { THRESHOLD_TYPE } from '../../../types/model/masterData/threshold';
import Paper from '@material-ui/core/Paper';
import HeaderButton from '../../customComponents/button/HeaderButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IReportScoutingAssignment } from '../../../types/model/scouting/scoutingAssignment';
import ScoutingReportBodyView from './ScoutingReportBodyView';

interface IScoutingReportViewProps {
    assignments : Array<IReportScoutingAssignment>;
    thresholds : IKeyValue<IKeyValue<number>>;
    isLoading : boolean;
    cropType : CROP;

    section ?: string;
    entity ?: string;
}

interface IScoutingReportViewState {
    reportType : THRESHOLD_TYPE;
}

export default class ScoutingReportView extends React.PureComponent<IScoutingReportViewProps, IScoutingReportViewState> {
    constructor(props : IScoutingReportViewProps) {
        super(props);
        this.state = {
            reportType: 'insects',
        };
    }

    private onReportTypeClick = (reportType : THRESHOLD_TYPE) => {
        this.setState({
            reportType,
        });
    }

    public render = () => {
        const { assignments,  isLoading, thresholds, cropType, section, entity } = this.props;
        const { reportType } = this.state;
        return (
            <Paper className='fdc flx1 ais mt15 mb15'>
                <div className='fdr mnh47'>
                    <div className='fdr aic jcc mnw180 mxw180 brw1 bocg3'>
                        <Typography className='fwn fs16-21'>REPORTING</Typography>
                    </div>
                    <div className='flx1'>
                        <HeaderButton value='insects' selected={reportType === 'insects'} onClick={this.onReportTypeClick} selectedColor='#297D7D'>
                            Insects
                        </HeaderButton>
                        <HeaderButton value='disease' selected={reportType === 'disease'} onClick={this.onReportTypeClick} selectedColor='#99040B'>
                            Diseases
                        </HeaderButton>
                    </div>
                </div>
                <div className='fdc flx1'>
                    {
                        isLoading &&
                        <div className='fdc flx1 aic jcc'>
                            <CircularProgress />
                        </div>
                    }
                    {
                        !isLoading && !!assignments.length &&
                        <ScoutingReportBodyView cropType={cropType} reportType={reportType} assignments={assignments} threshold={thresholds[reportType]} section={section} entity={entity} />
                    }
                </div>
            </Paper>
        );
    }
}
