import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { Add as AddIcon, Close as CloseIcon, Warning } from '@material-ui/icons';
import StandardFab from '../../customComponents/button/StandardFab';
import { DialogActions, FormControl, FormHelperText, Switch, TextField, Typography } from '@material-ui/core';
import { CROP } from '../../../appConstants';
import TrapTypeDropdown from '../../customComponents/dropdowns/trap/TrapTypeDropdown';
import { ITrapType } from '../../../types/model/masterData/trap/trapType';
import DivisionAutocomplete from '../../customComponents/autocomplete/rights/DivisionAutocomplete';
import { IDivision } from '../../../types/model/rights/division';
import FieldAutocomplete from '../../customComponents/autocomplete/block/FieldNameAutocomplete';
import FieldBlockAutocomplete from '../../customComponents/autocomplete/block/FieldBlockAutocomplete';
import { IBlock } from '../../../types/model/masterData/block';
import TrapFarmDescriptionSelector from '../../customComponents/selector/trap/FarmDescriptionSelector';
import StadiumButton from '../../customComponents/button/StadiumButton';
import TrapFunctions from '../../../store/trap/functions';
import GeneralFunctions from '../../../store/general/functions';

interface ITrapCreateDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    disabled ?: boolean;

    crop : CROP;
}

interface ITrapCreateDialogState {
    open : boolean;

    divisionCode : string | null;
    divisionName : string | null;
    trapTypeId : string | null;
    trapTypeName : string | null;
    monitor : boolean;

    block : string | null;
    blockName : string | null;
    landName : string | null;

    description : string | null;
    otherDescription : string | null;

    step : boolean;
}

export default class TrapCreateDialog extends React.PureComponent<ITrapCreateDialogProps, ITrapCreateDialogState> {
    private readonly initState : ITrapCreateDialogState = {
        open: false,
        divisionCode: null,
        divisionName: null,
        trapTypeId: null,
        trapTypeName: null,
        monitor: false,
        block: null,
        blockName: null,
        landName: null,
        description: null,
        otherDescription: null,
        step: false,
    }

    constructor(props : ITrapCreateDialogProps) {
        super(props);
        this.state = {
            ...this.initState,
        };
    }

    public readonly onClick = () => {
        this.setState({
            open: true,
        });
    }

    public readonly onClose = () => {
        this.setState({
            ...this.initState,
        });
    }

    private readonly onDivisionChange = (division ?: IDivision | null) => {
        this.setState({
            divisionCode: division?.id ?? null,
            divisionName: division?.name ?? null,
            landName: null,
            block: null,
            blockName: null,
        });
    }
    
    private readonly onTrapTypeChange = (value ?: ITrapType | null) => {
        this.setState({
            trapTypeId: value?.id ?? null,
            trapTypeName: value?.name ?? null,
        });
    }

    private readonly onMonitorChange = () => {
        this.setState({
            monitor: !this.state.monitor,
            block: null,
            blockName: null,
            landName: null,
            description: null,
            otherDescription: null,
        });
    }

    private readonly onLandNameChange = (landName ?: string | null) => {
        this.setState({
            landName: landName ?? null,
            block: null,
            blockName: null,
        });
    }

    private readonly onBlockChange = (block ?: IBlock | null) => {
        this.setState({
            block: block?.id ?? null,
            blockName: block?.name ?? null,
            landName: block?.landName ?? null,
        });
    }

    private readonly onDescriptionChange = (value ?: string | null) => {
        this.setState({
            description: value ?? null,
            otherDescription: null,
        });
    }

    private readonly onOtherDescriptionChanged = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            otherDescription: event.target.value,
        });
    }

    private readonly onContinueClick = () => {
        const {
            divisionCode,
            trapTypeId,
            monitor,
            landName,
            block,
            blockName,
            divisionName,
            trapTypeName,
            description,
            otherDescription,
        } = this.state;

        if (!divisionCode) return;
        if (!divisionName) return;
        if (!trapTypeId) return;
        if (!trapTypeName) return;

        if (monitor) {
            if (!description) return;
            if (!otherDescription && description.toLocaleLowerCase() === 'other') return;

        } else {
            if (!landName) return;
            if (!block) return;
            if (!blockName) return;
        }

        this.setState({
            step: !this.state.step,
        });
    }

    private readonly save = async () => {
        const {
            divisionCode,
            trapTypeId,
            monitor,
            landName,
            block,
            blockName,
            divisionName,
            trapTypeName,
            description,
            otherDescription,
        } = this.state;

        try {
            await TrapFunctions.save({
                type: trapTypeId ?? '',
                typeName: trapTypeName ?? '',
                block,
                divisionCode: divisionCode ?? '',
                divisionName: divisionName ?? '',
                blockName,
                code: null,
                crop: this.props.crop,
                description,
                otherDescription,
                landName,
                location: null,
                elevation: null,
                locationAccuracy: null,
                monitoring: monitor,
                id: '',
                archived: false,
                createdBy: '',
                createdByEmployee: '',
                createdByName: '',
                createdOn: 0,
                updatedBy: '',
                updatedByEmployee: '',
                updatedByName: '',
                updatedOn: 0,
                isSent: false
            });

            this.onClose();
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error saving trap', ex);
        }
    }

    private readonly onSaveClick = () => {
        this.save();
    }

    public render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            disabled,
            crop,
        } = this.props;

        const {
            open,
            divisionCode,
            trapTypeId,
            monitor,
            landName,
            block,
            description,
            otherDescription,
            step,
        } = this.state;

        return (
            <React.Fragment>
                <div className='fdr'>
                    <Tooltip title='Create'>
                        <div>
                            <StandardFab aria-label='add' disabled={disabled} onClick={this.onClick}>
                                <AddIcon />
                            </StandardFab>
                        </div>
                    </Tooltip>
                </div>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='create-trap-dialog-title'
                    aria-describedby='create-trap-description'>
                    <AppBar className='fdr posr aic jcc bcpd' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography>
                                ADD TRAP
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    {
                        !step &&
                        <DialogContent className='fdc flx1 hfill p0'>
                            <div className='fdr bcp pt15 pb15 pl25'>
                                <Typography
                                    className='fwn cw fs15'
                                >
                                    DIVISION
                                </Typography>
                            </div>
                            <div className='fdc p10'>
                                <div className={'fdc flx1 aic'}>
                                    <DivisionAutocomplete
                                        required
                                        fullWidth
                                        value={divisionCode}
                                        onChange={this.onDivisionChange}
                                        crop={crop}
                                        label={'Select Division'}
                                    />
                                </div>
                            </div>
                            <div className='fdr bcp pt15 pb15 pl25'>
                                <Typography
                                    className='fwn cw fs15'
                                >
                                    TYPE
                                </Typography>
                            </div>
                            <div className='fdc p10'>
                                <div className={'fdc flx1 aic'}>
                                    <TrapTypeDropdown
                                        required
                                        fullWidth
                                        value={trapTypeId}
                                        onChange={this.onTrapTypeChange}
                                        crop={crop}
                                        label='Select Type'
                                    />
                                </div>
                            </div>
                            <div className='fdr aic bcgg pl25'>
                                <Typography
                                    className='fwn flx1 cw fs15'
                                >
                                    FARM MONITORING
                                </Typography>
                                <div className={'fdr'}>
                                    <Switch
                                        id={'monitor'}
                                        name={'monitor'}
                                        checked={monitor}
                                        color='secondary'
                                        onChange={this.onMonitorChange}
                                        inputProps={{
                                            'aria-label': 'Is FARM MONITORING',
                                            id: 'isActive',
                                        }}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                            {
                                monitor &&
                                <>
                                    <div className='fdr bcp pt15 pb15 pl25'>
                                        <Typography
                                            className='fwn cw fs15'
                                        >
                                            DESCRIPTION
                                        </Typography>
                                    </div>
                                    <div className='fdc p10'>
                                        <TrapFarmDescriptionSelector
                                            value={description}
                                            onChange={this.onDescriptionChange}
                                        />
                                    </div>
                                    {
                                        description?.toLocaleLowerCase() === 'other' &&
                                        <div className='fdc p10'>
                                            <FormControl fullWidth required error={!otherDescription}>
                                                <TextField
                                                    id={'otherDescription'}
                                                    autoComplete='off'
                                                    value={otherDescription ?? ''}
                                                    onChange={this.onOtherDescriptionChanged}
                                                    required
                                                    error={!otherDescription}
                                                    label='Add short description'
                                                />
                                                {
                                                    !otherDescription &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                    }
                                </>
                            }
                            {
                                !monitor &&
                                <>
                                    <div className='fdr bcp pt15 pb15 pl25'>
                                        <Typography
                                            className='fwn cw fs15'
                                        >
                                            FIELD
                                        </Typography>
                                    </div>
                                    <div className='fdc p10'>
                                        <div className={'fdc flx1 aic'}>
                                            <FieldAutocomplete
                                                value={landName}
                                                division={divisionCode}
                                                disabled={!divisionCode}
                                                crop={crop}
                                                required
                                                fullWidth
                                                label='Select Field'
                                                onChange={this.onLandNameChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='fdr bcp pt15 pb15 pl25'>
                                        <Typography
                                            className='fwn cw fs15'
                                        >
                                            BLOCK
                                        </Typography>
                                    </div>
                                    <div className='fdc p10'>
                                        <div className={'fdc flx1 aic'}>
                                            <FieldBlockAutocomplete
                                                value={block}
                                                division={divisionCode}
                                                disabled={!divisionCode}
                                                crop={crop}
                                                required
                                                fullWidth
                                                label='Select Block'
                                                onChange={this.onBlockChange}
                                                fieldName={landName}
                                            />
                                            
                                        </div>
                                    </div>
                                </>
                            }
                        </DialogContent>
                    }
                    {
                        step &&
                        <DialogContent className='fdc flx1 hfill'>
                            <div className='fdr aic jcc'>
                                <div className='fdc mr15'>
                                    <Warning className='co h36 w36' />
                                </div>
                                <div className='fdc'>
                                    <Typography
                                        className='co fs24 fwm'
                                        
                                    >
                                        Are you sure you want to add a new trap?
                                    </Typography>
                                </div>
                            </div>
                        </DialogContent>
                    }
                    <DialogActions>
                        {
                            !step &&
                            <div className='fdr'>
                                <StadiumButton className='fw500 fs14 cpd mr15 bw1' variant='text' onClick={this.onClose}>
                                    BACK
                                </StadiumButton>
                                <StadiumButton className='fw500 fs14 cw bcp' onClick={this.onContinueClick}>
                                    CONTINUE
                                </StadiumButton>
                            </div>
                        }
                        {
                            step &&
                            <div className='fdr'>
                                <StadiumButton className='fw500 fs14 cpd mr15 bw1' variant='text' onClick={this.onContinueClick}>
                                        BACK
                                </StadiumButton>
                                <StadiumButton className='fw500 fs14 cw bcp' onClick={this.onSaveClick}>
                                        SAVE
                                </StadiumButton>
                            </div>
                        }
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}
