import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { IPhenologySection, IPhenologySectionOption } from '../../../../types/model/masterData/phenology';
import { Button, Card, Checkbox, DialogActions, FormControl, FormControlLabel, FormHelperText, Switch, TextField, Typography } from '@material-ui/core';
import StandardFab from '../../../customComponents/button/StandardFab';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from '../../../customComponents/materialTable/Table';
import PhenologyGeneralEditOptionDialog from './GeneralEditOption';
import moment from 'moment';

interface IPhenologyGeneralEditDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    value ?: IPhenologySection;

    onClose : (value ?: IPhenologySection) => void;

    isLoading : boolean;
}

interface IPhenologyGeneralEditDialogState {
    open : boolean;

    name : string;
    optional : boolean;
    validMonths : Array<number>;
    options : Array<IPhenologySectionOption>;

    selected ?: IPhenologySectionOption;
}

export default class PhenologyGeneralEditDialog extends React.PureComponent<IPhenologyGeneralEditDialogProps, IPhenologyGeneralEditDialogState> {
    constructor(props : IPhenologyGeneralEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            options: [],
            optional: false,
            validMonths: [],
        };
    }
    
    public readonly componentDidUpdate = (prevProps : IPhenologyGeneralEditDialogProps) => {
        if (this.props.value && !prevProps.value) {
            this.show();

            this.setInitialValues(this.props.value);
        }

        if (!this.props.value && prevProps.value) {
            this.hide();
        }
    }

    public readonly show = () => {
        this.setState({
            open: true,
        });
    }

    public readonly hide = () => {
        this.setState({
            open: false,
        });
    }

    public readonly onClose = () => {
        this.props.onClose();
    }

    private readonly setInitialValues = (value : IPhenologySection) => {
        this.setState({
            name: value.name,
            options: [...value.options],
            optional: value.optional,
            validMonths: [...value.validMonths],
        });
    }

    private readonly onNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: e.target.value,
        });
    }

    private readonly onOptionalChange = () => {
        this.setState({
            optional: !this.state.optional,
        });
    }

    private readonly onSaveClick = () => {
        this.props.onClose({
            name: this.state.name,
            options: [...this.state.options],
            validMonths: [...this.state.validMonths],
            optional: this.state.optional,
        });
    }

    private readonly onUpClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.changeOrder(event.currentTarget.value, true);
    };

    private readonly onDownClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.changeOrder(event.currentTarget.value, false);
    };

    private readonly changeOrder = (name : string, up : boolean) => {
        const options = [...this.state.options];
        const index = options.findIndex(x => x.name === name);

        if (up && index > 0) {
            const option = options[index];
            const prevOption = options[index - 1];

            options.splice(index, 1, prevOption);
            options.splice(index - 1, 1, option);
        } else if (!up && index < options.length - 1) {
            const option = options[index];
            const prevOption = options[index + 1];

            options.splice(index, 1, prevOption);
            options.splice(index + 1, 1, option);
        }

        this.setState({
            options,
        });
    };

    private readonly onFileChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.files || !event.currentTarget.files.length) return;
        const file = event.currentTarget.files[0];

        const options = this.state.options.slice();
        const index = options.findIndex(x => x.name === event.currentTarget.name);
        const option = {
            ...options[index],
            images: options[index].images.slice(),
        };

        option.images.push({
            file,
            name: file.name,
            url: '',
            path: '',
        });

        options.splice(index, 1, option);

        this.setState({
            options,
        });
    };

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const options = this.state.options.slice();
        const index = options.findIndex(x => x.name === event.currentTarget.id);
        const option = {
            ...options[index],
            images: options[index].images.slice(),
        };

        option.images.splice(Number(event.currentTarget.value), 1);
        options.splice(index, 1, option);

        this.setState({
            options,
        });
    };

    private readonly onAddClick = () => {
        this.setState({
            selected: {
                name: '',
                options: [],
                images: [],
                group: '',
                subGroup: '',
                enforceMax: false,
            }
        });
    };

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            selected: this.state.options.slice().find(x => x.name === event.currentTarget.value),
        });
    };
    
    private readonly onEditClose = (option ?: IPhenologySectionOption) => {
        if (!option) {
            this.setState({
                selected: undefined,
            });
            return;
        }
        
        const options = this.state.options.slice();

        const index = options.findIndex(x => x.name === option.name);

        if (index > -1) {
            options.splice(index, 1, {
                ...option,
                options: [...option.options],
            });
        } else {
            options.push({
                ...option,
                options: [...option.options],
            });
        }
        
        this.setState({
            options,
            selected: undefined,
        });
    }

    private readonly onRowDeleteClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const options = this.state.options.slice();
        const index = options.findIndex(x => x.name === event.currentTarget.value);

        options.splice(index, 1);

        this.setState({
            options,
        });
    };

    private readonly onAllMonthChange = () => {
        this.setState({
            validMonths: [],
        });
    }

    private readonly onMonthChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.currentTarget.value);
        
        const validMonths = this.state.validMonths.slice();
        const index = validMonths.indexOf(value);

        if (index > -1) {
            validMonths.splice(index, 1);
        } else {
            validMonths.push(value);
        }
        
        this.setState({
            validMonths,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, value, isLoading } = this.props;
        const { open, name, options, selected, optional, validMonths } = this.state;
        
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='phenology-general-dialog-title'
                aria-describedby='phenology-general-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            {
                                !value?.name ? 'Create' : `Edit ${value.name}`
                            }
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className={'fdr jcfs aifs'}>
                        <div className={'fdc flx1 jcfs p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <FormControl>
                                <TextField
                                    required
                                    error={!name}
                                    autoFocus
                                    margin='dense'
                                    id='name'
                                    label='Name'
                                    type='text'
                                    fullWidth
                                    value={name}
                                    onChange={this.onNameChange}
                                    disabled={!!value?.name}
                                />
                                {
                                    !name &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className={'fdr flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <Typography className='pr6' >Optional?</Typography>
                            <Switch
                                id='optional'
                                type='ant'
                                checked={optional}
                                onClick={this.onOptionalChange}
                                color='primary'
                                inputProps={{
                                    'aria-label': 'Optional Switch',
                                    id: 'optional',
                                }}
                            />
                        </div>
                        <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                        </div>
                    </div>
                    <div className='fdr fww'>
                        <FormControlLabel control={
                            <Checkbox
                                color='primary'
                                value={0}
                                checked={!validMonths.length}
                                onChange={this.onAllMonthChange}
                            />
                        }
                        label={'All'}
                        />
                        {
                            moment.months().map(n => (
                                <FormControlLabel key={n} control={
                                    <Checkbox
                                        color='primary'
                                        value={moment().month(n).month()}
                                        checked={!!validMonths.includes(moment().month(n).month())}
                                        onChange={this.onMonthChange}
                                    />
                                }
                                label={n}
                                />
                            ))
                        }
                    </div>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            OPTIONS
                        </Typography>
                        <span className={'flx1'} />
                        <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                            <AddIcon />
                        </StandardFab>
                    </Toolbar>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<IPhenologySectionOption>
                            id='phenologyOptionTable'
                            data={options}
                            rowsPerPage={50}
                            isLoading={isLoading}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 10,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Edit'>
                                            <div>
                                                <IconButton value={row.name} disabled={isLoading} onClick={this.onEditClick}>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: '',
                                paddingRight: 4,
                                width: 15,
                                renderCell: (row) => (
                                    <div className='aic'>
                                        <Tooltip title='Up'>
                                            <div>
                                                <IconButton value={row.name} disabled={isLoading} onClick={this.onUpClick}>
                                                    <Icon>expand_less</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Down'>
                                            <div>
                                                <IconButton value={row.name} disabled={isLoading} onClick={this.onDownClick}>
                                                    <Icon>expand_more</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Name',
                                field: 'name',
                                width: 70,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Group',
                                field: 'group',
                                width: 70,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Sub Group',
                                field: 'subGroup',
                                width: 70,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Options',
                                field: 'options',
                                width: 75,
                                enableSort: true,
                                align: 'center',
                                renderCell: row => (row.options.length),
                            }, {
                                header: 'Image',
                                field: 'imageName',
                                width: 75,
                                enableSort: true,
                                renderCell: row =>
                                    <div className='fdr'>
                                        <div className='aic'>
                                            {
                                                row.images.map((image, index) =>
                                                    <span key={image.path ? image.path : image.name + index} className='mr15'>
                                                        {
                                                            image.url &&
                                                            <a href={image.url} target='_blank' rel='noreferrer'>{image.name}</a>
                                                        }
                                                        {
                                                            !image.url &&
                                                            image.name
                                                        }
                                                        <IconButton id={row.name} value={index} onClick={this.onDeleteClick}>
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </span>)
                                            }
                                        </div>
                                        <Button component='label'>
                                            <Icon>upload</Icon>
                                            <input
                                                type='file'
                                                accept='image/*'
                                                hidden
                                                name={row.name}
                                                onChange={this.onFileChange}
                                            />
                                        </Button>
                                    </div>,
                            }, {
                                header: '',
                                field: 'name',
                                width: 50,
                                enableSort: true,
                                renderCell: row =>
                                    <div className='fdr'>
                                        <Button className='cpr' value={row.name} onClick={this.onRowDeleteClick}>
                                            <Icon>delete</Icon>
                                        </Button>
                                    </div>,
                            }]}
                        />
                    </Card>
                    <PhenologyGeneralEditOptionDialog value={selected} fullWidth maxWidth='md' onClose={this.onEditClose} isLoading={isLoading} />
                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading} onClick={this.onSaveClick}>
                        <SaveIcon style={{ marginRight: '10px' }} />
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
