import { getBounds } from 'geolib';
import { IGeoServerBlock } from '../../types/model/masterData/geoserver/block';
import { LngLatBoundsLike } from 'react-map-gl';
import { GeolibInputCoordinates } from 'geolib/es/types';

export default class GeoHelper {
    public static getPolygonBounds(blocks : Array<IGeoServerBlock>) : LngLatBoundsLike {
        const polygons : Array<GeolibInputCoordinates> = blocks
            .filter(x => x.geometry.type === 'Polygon')
            .map(x => (x.geometry as GeoJSON.Polygon))
            .map(geometry => geometry.coordinates
                .map(coordinate => coordinate
                    .map(position => ({
                        lat: position[1],
                        lng: position[0],
                    }))
                    .flatMap(position => position))
                .flatMap(coordinate => coordinate)
            )
            .flatMap(geometry => geometry);

        const multiPolygon : Array<GeolibInputCoordinates> = blocks
            .filter(x => x.geometry.type === 'MultiPolygon')
            .map(x => (x.geometry as GeoJSON.MultiPolygon))
            .map(geometry => geometry.coordinates
                .map(coordinate => coordinate
                    .map(positions => positions
                        .map(position => ({
                            lat: position[1],
                            lng: position[0],
                        }))
                        .flatMap(position => position))
                    .flatMap(positions => positions))
                .flatMap(coordinate => coordinate)
            )
            .flatMap(geometry => geometry);


        const bounds = getBounds([
            ...polygons,
            ...multiPolygon,
        ]);

        return [{
            lat: bounds.maxLat,
            lng: bounds.maxLng,
        }, {
            lat: bounds.minLat,
            lng: bounds.minLng,
        }];
    }
}