import React from 'react';
import { LayerGroup, MapLayerProps, LeafletContext, withLeaflet } from 'react-leaflet';
import { LeafletEvent } from 'leaflet';
import BlockPolygonLayer from './BlockPolygonLayer';
import BlockLegendLayer from './BlockLegendLayer';
import { IBlockPolygonLayer } from '../../../types/model/masterData/blockPolygonLayer';

interface IBlockPolygonLayerGroupProps extends MapLayerProps {
    leaflet : LeafletContext;

    blocks : Array<IBlockPolygonLayer>;

    zIndex ?: number;

    showMarkers ?: boolean;
}

interface IBlockPolygonLayerGroupState {
    showMarkers : boolean;
}

class BlockPolygonLayerGroup extends React.PureComponent<IBlockPolygonLayerGroupProps, IBlockPolygonLayerGroupState> {
    constructor(props : IBlockPolygonLayerGroupProps) {
        super(props);
        this.state = {
            showMarkers: !!props.showMarkers,
        };
    }

    public componentDidMount = () => {
        if (!this.props.showMarkers) {
            this.props.leaflet.map?.on('zoomend', this.onZommed);
        }
    }

    public componentWillUnmount = () => {
        if (!this.props.showMarkers) {
            this.props.leaflet.map?.off('zoomend', this.onZommed);
        }
    }

    private onZommed = (event : LeafletEvent) => {
        if (!this.state.showMarkers && event.target.getZoom() > 15) {
            this.setState({
                showMarkers: true,
            });

            return;
        }

        if (this.state.showMarkers && event.target.getZoom() <= 15) {
            this.setState({
                showMarkers: false,
            });

            return;
        }
    }

    public onLayerRef = (ref : LayerGroup | null) => {
        if (ref && this.props.zIndex !== undefined) {
            ref.leafletElement.setZIndex(this.props.zIndex);
        }
    }

    public render = () => {
        const { blocks } = this.props;
        const { showMarkers } = this.state;
        return (
            <LayerGroup ref={this.onLayerRef}>
                {
                    !!blocks.length &&
                    blocks.map((n, i) => (
                        <BlockPolygonLayer key={`land_block_${n.landId}_${i}`} showMarkers={showMarkers} block={n} />
                    ))
                }
                {
                    showMarkers &&
                    <BlockLegendLayer blocks={blocks} />
                }
            </LayerGroup>
        );
    }
}

export default withLeaflet(BlockPolygonLayerGroup);
