import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { IAuthState, IRootState } from '../../@types/redux';
import PrivateRoute from './PrivateRoute';
import NoAccess from './NoAccess';
import UserList from '../right/user/List';
import ScoutingMap from '../scouting/Map';
import ScoutingAssignmentList from '../scouting/assignments/List';
import ScoutingAssignmentCreate from '../scouting/assignments/Create';
import BlockList from '../master/blocks/List';
import ThresholdEdit from '../master/thresholds/Edit';
import ScoutingReport from '../reporting/ScoutingReport';
import PhenologyList from '../master/phenology/List';
import PhenologyAssignmentList from '../phenology/assignment/List';
import PhenologyAssignmentCreate from '../phenology/assignment/Create';
import ScoutingList from '../master/scouting/List';
import InsectList from '../master/insects/List';
import DiseaseList from '../master/disease/List';
import DamageList from '../master/damage/List';
import TrapList from '../trap/List';
import TrapTypeList from '../master/trap/type/List';
import DivisionList from '../right/division/DivisionList';
import TrapFarmDescriptionList from '../master/trap/farmDescription/List';
import TrapEntryCommentList from '../master/trap/trapEntryComment/List';
import TrapsMap from '../trap/Map';
import CultivarList from '../master/cultivars/List';
import PhenologySpecificAssignmentList from '../phenology/specific/List';
import PhenologySpecificsMap from '../phenology/specific/Map';
import NematodeAssignmentList from '../nematode/assignment/List';
import NematodeAssignmentCreate from '../nematode/assignment/Create';
import NematodeSurveyTypeList from '../master/nematode/surveyType/List';
import DashboardMap from '../dashboard/Map';

interface IRoutesProps extends RouteComponentProps {
    auth : IAuthState;
}

class Routes extends React.Component<IRoutesProps> {
    constructor(props : IRoutesProps) {
        super(props);
    }

    public render() {
        const { auth } = this.props;

        return (
            <Switch>
                <Route path={'/'} exact component={DashboardMap} />
                <Route path={'/no-access'} exact component={NoAccess} />
                <PrivateRoute auth={auth} path='/users' exact component={UserList} />
                <PrivateRoute auth={auth} path='/scouting/map' component={ScoutingMap} />
                <PrivateRoute auth={auth} path='/scouting/assignments' exact component={ScoutingAssignmentList} />
                <PrivateRoute auth={auth} path='/scouting/assignments/create' exact component={ScoutingAssignmentCreate} />
                <PrivateRoute auth={auth} path='/block/list' exact component={BlockList} />
                <PrivateRoute auth={auth} path='/masterData/threshold' exact component={ThresholdEdit} />
                <PrivateRoute auth={auth} path='/masterData/phenology' exact component={PhenologyList} />
                <PrivateRoute auth={auth} path='/masterData/scouting' exact component={ScoutingList} />
                <PrivateRoute auth={auth} path='/reporting/scouting' exact component={ScoutingReport} />
                <PrivateRoute auth={auth} path='/phenology/assignments' exact component={PhenologyAssignmentList} />
                <PrivateRoute auth={auth} path='/phenology/assignments/create' exact component={PhenologyAssignmentCreate} />
                <PrivateRoute auth={auth} path='/phenology/specific_assignment' exact component={PhenologySpecificAssignmentList} />
                <PrivateRoute auth={auth} path='/phenology/specific_assignment/map' exact component={PhenologySpecificsMap} />

                <PrivateRoute auth={auth} path='/nematode/assignments' exact component={NematodeAssignmentList} />
                <PrivateRoute auth={auth} path='/nematode/assignments/create' exact component={NematodeAssignmentCreate} />

                <PrivateRoute auth={auth} path='/traps/list' exact component={TrapList} />
                <PrivateRoute auth={auth} path='/traps/map' exact component={TrapsMap} />

                <PrivateRoute auth={auth} path='/masterData/insect' exact component={InsectList} />
                <PrivateRoute auth={auth} path='/masterData/damage' exact component={DamageList} />
                <PrivateRoute auth={auth} path='/masterData/disease' exact component={DiseaseList} />
                <PrivateRoute auth={auth} path='/masterData/cultivars' exact component={CultivarList} />
                <PrivateRoute auth={auth} path='/masterData/nematode/surveyTypes' exact component={NematodeSurveyTypeList} />
                
                <PrivateRoute auth={auth} path='/masterData/traps/farmDescription' exact component={TrapFarmDescriptionList} />
                <PrivateRoute auth={auth} path='/masterData/traps/entryComment' exact component={TrapEntryCommentList} />
                <PrivateRoute auth={auth} path='/masterData/traps/type' exact component={TrapTypeList} />

                <PrivateRoute auth={auth} path='/rights/division' exact component={DivisionList} />
            </Switch>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

/* With router is important because it ensures the component updates with location changes, therefore allowing redirects */
export default withRouter(connect(
    mapStateToProps,
)(Routes));
