import moment from 'moment';
import firebase from 'firebase/app';

export default class FirestoreHelper {

    /**
     * Converts moment date object to firestore timestamp object.
     * Throws error if not UTC.
     * @param momentDate UTC moment Date
     */
    public static momentToTimestamp(momentDate : moment.Moment) : firebase.firestore.Timestamp {
        if (!momentDate.isUTC) throw new Error('Date must be UTC');

        return firebase.firestore.Timestamp.fromMillis(momentDate.valueOf());
    }

    public static millisToTimestamp(unixMillis : number) : firebase.firestore.Timestamp {
        if (unixMillis < 0) throw new Error('Cannot be negative!');

        return firebase.firestore.Timestamp.fromMillis(unixMillis);
    }
}
