import { getType } from 'typesafe-actions';
import CultivarActions from './actions';
import { ICultivar } from '../../../types/model/masterData/cultivars';
import { CultivarAction } from '../../../@types/redux';

export interface ICultivarState {
    cultivars : Array<ICultivar>;
    isLoading : boolean;

}

const initialState : ICultivarState = {
    cultivars: [],
    isLoading: false,
};

export default function cultivarReducer(state : ICultivarState = initialState, action : CultivarAction) : ICultivarState {
    switch (action.type) {
    case getType(CultivarActions.setList):
        return { ...state, cultivars: action.payload };
    case getType(CultivarActions.setLoading):
        return { ...state, isLoading: action.payload };
    default:
        return state;
    }
}