import { CROP } from '../../../../appConstants';
import firebaseApp from '../../../../services/firebaseService';
import { BaseHelper, IBase } from '../../../base';
import firebase from 'firebase/app';

export interface ITrapType extends IBase {
    crop : CROP;
    name : string;

    insects : Array<string>;

    isActive : boolean;
}

export default class TrapTypeHelper extends BaseHelper {
    public static readonly COLLECTION = 'trap_type';

    public static converter : firebase.firestore.FirestoreDataConverter<ITrapType> = {
        fromFirestore: (snapshot) => {
            return TrapTypeHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : ITrapType) => {
            return TrapTypeHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : ITrapType {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as ITrapType,
            ...result,
        };
    }
    
    protected static toFirestore(data : ITrapType) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static doc(id : string) {
        return this.collection().doc(id);
    }

    public static save(trapType : ITrapType) {
        if (trapType.id) {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(trapType.id).set(trapType);
        }

        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc().set(trapType);
    }
}