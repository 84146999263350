export interface IArea {
    id : number;
    name : string;
    bestArea : string;
    area : number;
    length : number;
}

export class AreaHelper {
    public static fromAttributes(attributes : { [key : string] : any}) : IArea {

        return {
            id: attributes['FID'],
            name: attributes['AFDELING'],
            bestArea: attributes['BEST_AREA'],
            area: attributes['Shape__Area'],
            length: attributes['Shape__Length'],
        };
    }
}
