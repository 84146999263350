import React from 'react';
import Paper from '@material-ui/core/Paper';
import { IRootState, RootAction } from '../../../@types/redux';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { createSelector } from 'reselect';
import CheckToggleButton from '../../customComponents/button/CheckToggleButton';
import Typography from '@material-ui/core/Typography';
import { IUser } from '../../../types/model/user';
import EmployeeDropdown from '../../customComponents/dropdowns/EmployeeDropdown';
import { IAssignmentBlock, IBlock } from '../../../types/model/masterData/block';
import { CROP } from '../../../appConstants';

interface IScoutingAssignmentEmployeeSelectorProps {
    division ?: string;
    crop : CROP;
    landName : string;
    employee ?: IUser;
    scoutingBlocks : Array<IAssignmentBlock>;
    onChanged ?: (landName : string, employee : IUser) => void;

    blocks : Array<IBlock>;
}

interface IScoutingAssignmentEmployeeSelectorState {}

class ScoutingAssignmentEmployeeSelectorComponent extends React.PureComponent<IScoutingAssignmentEmployeeSelectorProps, IScoutingAssignmentEmployeeSelectorState> {
    constructor(props : IScoutingAssignmentEmployeeSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly getScoutingBlocks = (state : IScoutingAssignmentEmployeeSelectorState, props : IScoutingAssignmentEmployeeSelectorProps, ) => props.scoutingBlocks;
    private readonly getBlocks = (state : IScoutingAssignmentEmployeeSelectorState, props : IScoutingAssignmentEmployeeSelectorProps) => props.blocks;
    private readonly getLandName = (state : IScoutingAssignmentEmployeeSelectorState, props : IScoutingAssignmentEmployeeSelectorProps) => props.landName;
    private readonly getDivision = (state : IScoutingAssignmentEmployeeSelectorState, props : IScoutingAssignmentEmployeeSelectorProps) => props.division;
    private readonly getCrop = (state : IScoutingAssignmentEmployeeSelectorState, props : IScoutingAssignmentEmployeeSelectorProps) => props.crop;

    private readonly getBlockNames = createSelector([
        this.getBlocks, this.getDivision, this.getLandName, this.getCrop,
    ], (blocks, division, landName, crop) => {
        if (!division) return [];

        return blocks.filter(n =>
            n.crop === crop
            && n.landName === landName
            && division.toLocaleUpperCase() === n.division.toLocaleUpperCase())
            .map(n => n.name).sort();
    });

    private readonly getSelectedBlockNames = createSelector([
        this.getScoutingBlocks,
    ], (blocks) => {
        return blocks.map(n => n.name).sort();
    });

    private onEmployeeChange = (employee ?: IUser) => {
        if (this.props.onChanged && employee) this.props.onChanged(this.props.landName, employee);
    }

    public render = () => {
        const { employee, division, landName } = this.props;

        const blockNames = this.getBlockNames(this.state, this.props);
        const selectedBlockNames = this.getSelectedBlockNames(this.state, this.props);

        return (
            <Paper elevation={3}>
                <div className={'fdc aifs p15'}>
                    <Typography className='fs16 fw700 lh37'>
                        {landName}
                    </Typography>
                    <div className={'fdc aifs jcfs'}>
                        <div className='fdr fww'>
                            {
                                blockNames.map(n => (
                                    <div key={n} className='aic jcc pr15 pb15'>
                                        <CheckToggleButton text={n} value={n} selected={selectedBlockNames.includes(n)} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className={'fdc aifs jcfs w240'}>
                        <EmployeeDropdown value={employee} fullWidth required onChange={this.onEmployeeChange} division={division} disabled={!division} />
                    </div>
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.general.blocks,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators({
}, dispatcher);

const ScoutingAssignmentEmployeeSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ScoutingAssignmentEmployeeSelectorComponent);

export default ScoutingAssignmentEmployeeSelector;
