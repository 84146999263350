import { getType } from 'typesafe-actions';
import { DamageAction } from '../../../@types/redux';
import { IDamage } from '../../../types/model/masterData/damage';
import DamageActions from './actions';

export interface IDamageState {
    damages : Array<IDamage>;
    isLoading : boolean;

}

const initialState : IDamageState = {
    damages: [],
    isLoading: false,
};

export default function damageReducer(state : IDamageState = initialState, action : DamageAction) : IDamageState {
    switch (action.type) {
    case getType(DamageActions.setList):
        return { ...state, damages: action.payload };
    case getType(DamageActions.setLoading):
        return { ...state, isLoading: action.payload };
    default:
        return state;
    }
}