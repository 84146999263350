import TrapFarmDescriptionActions from './actions';
import { getType } from 'typesafe-actions';
import { ITrapFarmDescription } from '../../../../types/model/masterData/trap/farmDescription';
import { TrapFarmDescriptionAction } from '../../../../@types/redux';

export interface ITrapFarmDescriptionState {
    farmDescriptions : Array<ITrapFarmDescription>;
    isLoading : boolean;

}

const initialState : ITrapFarmDescriptionState = {
    farmDescriptions: [],
    isLoading: false,
};

export default function trapFarmDescriptionReducer(state : ITrapFarmDescriptionState = initialState, action : TrapFarmDescriptionAction) : ITrapFarmDescriptionState {
    switch (action.type) {
    case getType(TrapFarmDescriptionActions.setList):
        return { ...state, farmDescriptions: action.payload };
    case getType(TrapFarmDescriptionActions.setLoading):
        return { ...state, isLoading: action.payload };
    default:
        return state;
    }
}