import { getType } from 'typesafe-actions';
import { ITrapType } from '../../../../types/model/masterData/trap/trapType';
import TrapTypeActions from './actions';
import { TrapTypeAction } from '../../../../@types/redux';

export interface ITrapTypeState {
    types : Array<ITrapType>;
    isLoading : boolean;

}

const initialState : ITrapTypeState = {
    types: [],
    isLoading: false,
};

export default function trapTypeReducer(state : ITrapTypeState = initialState, action : TrapTypeAction) : ITrapTypeState {
    switch (action.type) {
    case getType(TrapTypeActions.setList):
        return { ...state, types: action.payload };
    case getType(TrapTypeActions.setLoading):
        return { ...state, isLoading: action.payload };
    default:
        return state;
    }
}