import firebaseApp from '../../services/firebaseService';
import firebase from 'firebase/app';

export interface IUserSession {
    user : IUser;
    firebaseUser : firebase.User;
}

export interface IPermissions {
    isUserAdmin : boolean;
    isScoutingAdmin : boolean;
    isScoutingLocationAdmin : boolean;
    isBlockAdmin : boolean;
    isThresholdAdmin : boolean;
    isPhenologyAdmin : boolean;
    isSuperAdmin : boolean;
    isTrapAdmin : boolean;
    isNematodeAdmin : boolean;
}

export interface IUser {
    /**
     * User document id.
     */
    ref : string;
    name : string;
    email : string;
    lastToken : string;
    employeeNumber : string;
    lastSignIn ?: number;
    createdOn ?: number;
    isActive : boolean;

    crops : Array<string>;

    permissions : IPermissions;

    divisions : Record<string, string>;
}

export default class UserHelper {
    public static readonly USER_COLLECTION = 'users';

    public static converter : firebase.firestore.FirestoreDataConverter<IUser> = {
        fromFirestore: (snapshot) => {
            return UserHelper.fromFirestore(snapshot);
        },
        toFirestore: (user : IUser) => {
            return UserHelper.toObject(user);
        },
    };

    private static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IUser {
        const data = snapshot.data();

        if (!data) throw new Error('Document does not exist!');

        return {
            ref: snapshot.ref.id,
            name: data['name'],
            email: data['email'],
            lastToken: data['lastToken'],
            employeeNumber: data['employeeNumber'],
            lastSignIn: (data['lastSignIn'] as firebase.firestore.Timestamp | null)?.toMillis(),
            createdOn: (data['createdOn'] as firebase.firestore.Timestamp | null)?.toMillis(),
            isActive: data['isActive'],

            crops: data['crops'],
            divisions: data['divisions'],

            permissions: data['permissions'] ? {
                isUserAdmin: data['permissions']['isUserAdmin'],
                isScoutingAdmin: data['permissions']['isScoutingAdmin'] ?? false,
                isScoutingLocationAdmin: data['permissions']['isScoutingLocationAdmin'] ?? false,
                isBlockAdmin: data['permissions']['isBlockAdmin'] ?? false,
                isThresholdAdmin : data['permissions']['isThresholdAdmin'] ?? false,
                isPhenologyAdmin : data['permissions']['isPhenologyAdmin'] ?? false,
                isSuperAdmin : data['permissions']['isSuperAdmin'] ?? false,
                isTrapAdmin : data['permissions']['isTrapAdmin'] ?? false,
                isNematodeAdmin : data['permissions']['isNematodeAdmin'] ?? false,
            } : {
                isUserAdmin: data['isUserAdmin'],
                isScoutingAdmin: data['isScoutingAdmin'] ?? false,
                isScoutingLocationAdmin: data['isScoutingLocationAdmin'] ?? false,
                isBlockAdmin: data['isBlockAdmin'] ?? false,
                isThresholdAdmin : data['isThresholdAdmin'] ?? false,
                isPhenologyAdmin : data['isPhenologyAdmin'] ?? false,
                isSuperAdmin : data['isSuperAdmin'] ?? false,
                isTrapAdmin : data['isTrapAdmin'] ?? false,
                isNematodeAdmin : data['isNematodeAdmin'] ?? false,
            },
        };
    }

    private static toObject(user : IUser) {
        return {
            name: user.name,
            email: user.email,
            lastToken: user.lastToken,
            employeeNumber: user.employeeNumber,
            lastSignIn: !user.lastSignIn ? null : firebase.firestore.Timestamp.fromMillis(user.lastSignIn),
            createdOn: !user.createdOn ? null : firebase.firestore.Timestamp.fromMillis(user.createdOn),
            isActive: user.isActive,
            crops: user.crops.sort((a, b) => a.localeCompare(b)),
            divisions: { ...user.divisions } ,
            permissions: user.permissions,
        };
    }

    public static get(id : string) {
        return firebaseApp.firestore().collection(this.USER_COLLECTION).withConverter(this.converter).doc(id);
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.USER_COLLECTION).withConverter(this.converter);
    }

    public static save(user : IUser) {
        if (user.ref) {
            return firebaseApp.firestore().collection(this.USER_COLLECTION).withConverter(this.converter).doc(user.ref).set(user, {
                // We only want to update certain fields.
                // If we just use merge, divisions do not update when removing one.
                mergeFields: [
                    'name',
                    'email',
                    'employeeNumber',
                    'isActive',
                    'crops',
                    'divisions',
                    'permissions',
                ],
            });
        } else {
            return firebaseApp.firestore().collection(this.USER_COLLECTION).withConverter(this.converter).add(user);
        }
    }
}
