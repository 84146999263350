import { AppBar, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { CROP } from '../../../appConstants';
import PhenologyFunctions from '../../../store/phenology/general/functions';
import TabPanel from '../../customComponents/tab/TabPanel';
import PhenologyGeneralList from './GeneralList';

interface IPhenologyListProps extends RouteComponentProps {}

interface IPhenologyListState {
    tabIndex : number;
}

class PhenologyListComponent extends React.PureComponent<IPhenologyListProps, IPhenologyListState> {
    constructor(props : IPhenologyListProps) {
        super(props);
        this.state = {
            tabIndex: 0,
        };
    }

    public readonly componentDidMount = () => {
        this.load();
    }

    public readonly componentDidUpdate = (prevProps : IPhenologyListProps) => {
        if (prevProps.location.search !== this.props.location.search) {
            this.load();
        }
    }

    public readonly load = () => {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has('crop')) {
            const crop = urlParams.get('crop');

            if (crop) {
                PhenologyFunctions.getList(crop);
            }
        }
    }

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    }
    
    private getSearch = (props : IPhenologyListProps) => props.location.search;

    private getCrop = createSelector(
        [this.getSearch],
        (search) => {
            const urlParams = new URLSearchParams(search);
            if (urlParams.has('crop')) {
                const crop = urlParams.get('crop');
    
                if (crop) {
                    return crop as CROP;
                }
            }

            return null;
        },
    );

    public render = () => {
        const { tabIndex } = this.state;
        const crop = this.getCrop(this.props);
        return (
            <div className={'fdc hfill mh0 mw0'}>
                <AppBar elevation={0} className='fdc posr ais' position='static'>
                    <Tabs value={tabIndex} variant='fullWidth' onChange={this.onTabChange} aria-label='UploadTabs'>
                        <Tab value={0} label='GENERAL' />
                        <Tab value={1} label='SPECIFIC' />
                    </Tabs>
                </AppBar>
                <div className={'flx1 fdc ml15 mr15 mt20'}>
                    <TabPanel value={tabIndex} index={0}>
                        <div className={'flx1 fdc'}>
                            {
                                !!crop &&
                                <PhenologyGeneralList crop={crop} />
                            }
                        </div>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <div className={'flx1 fdc'}>
                        </div>
                    </TabPanel>
                </div>
            </div>
        );
    }
}

const PhenologyList = withRouter(PhenologyListComponent);

export default PhenologyList;