import React from 'react';
import { Paper } from '@material-ui/core';
import VerticalExpander from '../customComponents/expander/VerticalExpander';
import GeoServerDivisionAutocomplete from '../customComponents/autocomplete/masterData/geoserver/DivisionAutocomplete';
import GeoServerSubDivisionAutocomplete from '../customComponents/autocomplete/masterData/geoserver/SubDivisionAutocomplete';
import GeoServerDepartmentAutocomplete from '../customComponents/autocomplete/masterData/geoserver/DepartmentAutocomplete';
import GeoServerFieldAutocomplete from '../customComponents/autocomplete/masterData/geoserver/FieldAutocomplete';
import GeoServerBlockAutocomplete from '../customComponents/autocomplete/masterData/geoserver/BlockAutocomplete';
import { LngLatBoundsLike, MapRef } from 'react-map-gl';
import BasicMap from '../customComponents/mapBox/BasicMap';

interface IDashboardMapProps {}

interface IDashboardMapState {
    divisionGuid : string | null;
    subDivisionGuid : string | null;
    departmentGuid : string | null;
    fieldGuid : string | null;
    blockGuid : string | null;
}

export default class DashboardMap extends React.PureComponent<IDashboardMapProps, IDashboardMapState> {
    private mapRef : MapRef | null = null;

    private readonly divisionZoom = 10;
    private readonly subDivisionZoom = 12;
    private readonly departmentZoom = 14;
    private readonly fieldZoom = 15;
    private readonly blockZoom = 15;

    constructor(props : IDashboardMapProps) {
        super(props);
        this.state = {
            divisionGuid: null,
            subDivisionGuid: null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuid: null,
        };
    }

    private readonly flyToBounds = (bounds : LngLatBoundsLike, zoom ?: number) => {
        this.mapRef?.fitBounds(bounds, {
            minZoom: zoom,
        });
    };

    private readonly onDivisionChange = (value ?: string | null, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            divisionGuid: value ?? null,
            subDivisionGuid: null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuid: null,
        });

        if (bounds) {
            this.flyToBounds(bounds, this.divisionZoom);
        }
    };

    private readonly onSubDivisionChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            subDivisionGuid: value ?? null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuid: null,
        });

        if (bounds) {
            this.flyToBounds(bounds, this.subDivisionZoom);
        }
    };

    private readonly onDepartmentChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            departmentGuid: value ?? null,
            fieldGuid: null,
            blockGuid: null,
        });

        if (bounds) {
            this.flyToBounds(bounds, this.departmentZoom);
        }
    };

    private readonly onFieldChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            fieldGuid: value ?? null,
            blockGuid: null,
        });

        if (bounds) {
            this.flyToBounds(bounds, this.fieldZoom);
        }
    };

    private readonly onBlockChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            blockGuid: value ?? null,
        });

        if (bounds) {
            this.flyToBounds(bounds, this.blockZoom);
        }
    };

    private readonly setMapRef = (mapRef : MapRef) => {
        this.mapRef = mapRef;
    };

    public readonly render = () => {
        const {
            divisionGuid,
            subDivisionGuid,
            blockGuid,
            departmentGuid,
            fieldGuid,
        } = this.state;
        
        return (
            <div className={'fdr hfill mh0'}>
                <Paper className={'flx1 fdr oxh p5 m5'}>
                    <BasicMap
                        id={'farmMap'}
                        onMap={this.setMapRef}
                        divisionGuid={divisionGuid}
                        subDivisionGuid={subDivisionGuid}
                        departmentGuid={departmentGuid}
                        fieldGuid={fieldGuid}
                        blockGuid={blockGuid}
                    />
                </Paper>
                <div className={'fdc mh0'}>
                    <VerticalExpander title='filter' location='right' initiallyExpanded>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerDivisionAutocomplete
                                value={divisionGuid}
                                fullWidth
                                onChange={this.onDivisionChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerSubDivisionAutocomplete
                                value={subDivisionGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                onChange={this.onSubDivisionChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerDepartmentAutocomplete
                                value={departmentGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                subDivisionGuid={subDivisionGuid}
                                onChange={this.onDepartmentChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerFieldAutocomplete
                                value={fieldGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                subDivisionGuid={subDivisionGuid}
                                departmentGuid={departmentGuid}
                                onChange={this.onFieldChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerBlockAutocomplete
                                value={blockGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                subDivisionGuid={subDivisionGuid}
                                departmentGuid={departmentGuid}
                                fieldGuid={fieldGuid}
                                onChange={this.onBlockChange}
                            />
                        </div>
                        <div className={'fdc aife pr20 mt7'}>
                        </div>
                    </VerticalExpander>
                </div>
            </div>
        );
    };
}
