import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import AppFunctionsService from '../../../services/appFunctionServices';
import { IPhenologyAssignment } from '../../../types/model/phenology/assignment';
import { IPhenologyEntry } from '../../../types/model/phenology/entry';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import PhenologyEntrySectionView from './EntrySectionView';

interface IPhenologyEntryViewProps {
    index : number;
    assignment : IPhenologyAssignment;
    value : IPhenologyEntry | null;
}

interface IPhenologyEntryViewState {
    expanded : boolean;
}

export default class PhenologyEntryView extends React.PureComponent<IPhenologyEntryViewProps, IPhenologyEntryViewState> {
    constructor(props : IPhenologyEntryViewProps) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    private onExpansionChange = (event : React.ChangeEvent<unknown>, expanded : boolean) => {
        this.setState({
            expanded,
        });
    }

    private getValue = (props : IPhenologyEntryViewProps) => props.value;
    private getSections = createSelector(
        [this.getValue],
        (value) => {
            if (!value) return [];
            return lodash.chain(value.results).map(x => x.section).uniq().sort().value();
        },
    );

    public render = () => {
        const { expanded } = this.state;
        const { value, index } = this.props;

        const sections = this.getSections(this.props);
        return (
            <Accordion expanded={expanded} onChange={this.onExpansionChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className='fdr aic flx1'>
                        <Typography>
                            Point {index + 1}
                        </Typography>
                        {
                            !!value &&
                            <Typography className='fdr aic'>
                                &nbsp;- {AppFunctionsService.formatDateTime(value.createdOn)}
                            </Typography>
                        }
                        <span className='flx1'></span>
                        {!value ? <CloseIcon className='cpr' /> : <CheckIcon className='cp' />}
                    </div>
                </AccordionSummary>
                <AccordionDetails className={'fdc ais flx1'}>
                    {
                        !value &&
                        <span>No Data</span>
                    }
                    {
                        value &&
                        expanded &&
                        sections.map(s => (
                            <PhenologyEntrySectionView key={`${value.guid}_${s}`} title={s} value={value}
                            />
                        ))
                    }
                </AccordionDetails>
            </Accordion>
        );
    }
}
