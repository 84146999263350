import React from 'react';
import { ITrap } from '../../../types/model/trap/trap';
import { AppBar, Dialog, DialogContent, IconButton, Tab, Tabs, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Transitions } from '../../customComponents/animations/Transitions';
import { Close } from '@material-ui/icons';
import TabPanel from '../../customComponents/tab/TabPanel';
import TrapCommentsView from '../view/CommentsView';
import { connect } from 'react-redux';
import { IRootState } from '../../../@types/redux';
import { IUserSession } from '../../../types/model/user';
import TrapSummaryView from '../view/SummaryView';
import { createSelector } from 'reselect';
import TrapSurveyView from '../view/SurveyView';
import TrapDataView from '../view/DataView';

interface ITrapInfoDialogProps {
    value ?: string;

    onClose ?: () => void;
    
    session ?: IUserSession | null;
    isLoading ?: boolean;

    traps : Array<ITrap>;
}

interface ITrapInfoDialogState {
    open : boolean;

    tabIndex : number;
}

class TrapInfoDialogComponent extends React.PureComponent<ITrapInfoDialogProps, ITrapInfoDialogState> {
    constructor(props : ITrapInfoDialogProps) {
        super(props);
        this.state = {
            open: false,
            tabIndex: 0,
        };
    }

    public componentDidUpdate(prevProps : Readonly<ITrapInfoDialogProps>) : void {
        if (this.props.value && prevProps.value !== this.props.value) {
            this.setState({
                open: true,
            });
        }
    }

    private readonly onClose = () => {
        this.setState({
            open: false,
            tabIndex: 0,
        });

        if (!this.props.onClose) return;
        this.props.onClose();
    }

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    }
    private readonly getValue = (state : ITrapInfoDialogState, props : ITrapInfoDialogProps) => props.value;
    private readonly getTraps = (state : ITrapInfoDialogState, props : ITrapInfoDialogProps) => props.traps;

    private readonly getTrap = createSelector([
        this.getValue,
        this.getTraps,
    ], (value, traps) => traps.find(x => x.id === value));

    public render = () => {
        const {
            session,
            isLoading,
        } = this.props;

        const {
            open,
            tabIndex,
        } = this.state;

        const trap = this.getTrap(this.state, this.props);

        return (
            <Dialog
                open={open}
                fullScreen
                fullWidth
                TransitionComponent={Transitions.ZoomIn}
                transitionDuration={500}
                aria-labelledby='trap-info-dialog-title'
                aria-describedby='trap-info-dialog-description'
                closeAfterTransition
            >
                <AppBar elevation={0} className='fdc posr ais' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit' className='flx1'>
                            {trap?.code} - {trap?.divisionName}
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Close />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                    <Tabs value={tabIndex} variant='scrollable' onChange={this.onTabChange} aria-label='TrapTabs'>
                        <Tab value={0} label='SUMMARY' />
                        <Tab value={1} label='DATA' />
                        <Tab value={2} label='SURVEYS' />
                        <Tab value={3} label='COMMENTS' />
                    </Tabs>
                </AppBar>
                {
                    trap &&
                    <DialogContent className='fdc flx1 bcg0 p0'>
                        <TabPanel value={tabIndex} index={0}>
                            <TrapSummaryView trap={trap} session={session} isLoading={isLoading} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            <TrapDataView trap={trap} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={2}>
                            <TrapSurveyView trap={trap} session={session} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={3}>
                            <TrapCommentsView trapId={trap.id} session={session} />
                        </TabPanel>
                    </DialogContent>
                }
            </Dialog>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
        isLoading: state.trap.isLoading,
        traps: state.trap.traps,
    };
};

const TrapInfoDialog = connect(
    mapStateToProps,
)(TrapInfoDialogComponent);

export default TrapInfoDialog;