import { combineReducers } from 'redux';
import damageReducer from './damage/reducer';
import diseaseReducer from './disease/reducer';
import insectReducer from './insect/reducer';
import trapMasterDataReducer from './trap/main';
import cultivarReducer from './cultivar/reducer';
import masterDataNematodeReducer from './nematode/main';
import masterDataGeoServerReducer from './geoserver/reducer';

const masterDataReducer = combineReducers({
    insect: insectReducer,
    disease: diseaseReducer,
    damage: damageReducer,
    cultivar: cultivarReducer,
    trap: trapMasterDataReducer,
    nematode: masterDataNematodeReducer,
    geoserver: masterDataGeoServerReducer,
});

export default masterDataReducer;
