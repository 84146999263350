import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IDivision } from '../../../../types/model/rights/division';
import DivisionFunctions from '../../../../store/rights/division/functions';
import { CROP } from '../../../../appConstants';

interface IDivisionAutocompleteProps {
    id ?: string;
    value ?: IDivision | string | null;

    onChange : (value ?: IDivision, event ?: React.ChangeEvent<unknown>) => void;

    divisions : Array<IDivision>;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;
    disabled ?: boolean;
    name ?: string;

    label ?: string;

    crop ?: CROP | null;
}

interface IDivisionAutocompleteState {
}

class DivisionAutocompleteComponent extends React.Component<IDivisionAutocompleteProps, IDivisionAutocompleteState> {
    constructor(props : IDivisionAutocompleteProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        DivisionFunctions.getList();
    }

    private onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        const result = this.props.divisions.find(n => n.id === value?.value);

        this.props.onChange(result, event);
    }

    private getData = (state : IDivisionAutocompleteState, props : IDivisionAutocompleteProps) => props.divisions;
    private getValue = (state : IDivisionAutocompleteState, props : IDivisionAutocompleteProps) => props.value;
    private getRequired = (state : IDivisionAutocompleteState, props : IDivisionAutocompleteProps) => props.required;
    private getCrop = (state : IDivisionAutocompleteState, props : IDivisionAutocompleteProps) => props.crop;
    private getDivisions = createSelector([
        this.getData,
        this.getRequired,
        this.getCrop,
    ], (
        divisions,
        required,
        crop,
    ) => {
        const divisionsDrop = divisions
            .filter(x => !crop || x.crops.includes(crop))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(x => ({ label: `${x.name}`, value: x.id }));

        if (!required) {
            divisionsDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return divisionsDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getData,
    ], (value, required, divisions) => {
        if (value && typeof(value) === 'object') {
            return {
                label:  `${value.name}`,
                value: value.id,
            };
        } else if (value && typeof(value) === 'string') {
            const division = divisions.slice().find(x => x.id === value || x.name === value);

            if (division) {
                return {
                    label:  `${division.name}`,
                    value: division.id,
                };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const {
            required,
            fullWidth,
            isLoading,
            disabled,
            label,
        } = this.props;

        const divisions = this.getDivisions(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        disabled={isLoading || disabled}
                        id='division_select'
                        options={divisions}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label.toLocaleUpperCase()}
                        onChange={this.onChange}
                        disableClearable={required}
                        openOnFocus
                        renderInput={params => (
                            <TextField
                                error={required && !value}
                                required={required}
                                {...params}
                                fullWidth={fullWidth}
                                label={label ?? 'Division'}
                            />)}
                    />
                    {
                        required && !value &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        divisions: state.rights.division.divisions.filter(x => x.isActive),
        isLoading: state.rights.division.isLoading,
    };
};

const DivisionAutocomplete = connect(
    mapStateToProps,
)(DivisionAutocompleteComponent);

export default DivisionAutocomplete;
