import firebaseApp from '../../services/firebaseService';
import firebase from 'firebase/app';

export interface IErrorInformation {
    id : string | null;
    version : string | null;
    commitHash : string | null;
    path : string | null;
    environment : string | null;
    name : string | null;
    message : string | null;
    stack : string | null;
    componentStack : string | null;
    userName : string | null;
    userEmail : string | null;
    date : number;
}

export default class ErrorInformationHelper {
    public static readonly COLLECTION_NAME = 'error_information';

    private static converter : firebase.firestore.FirestoreDataConverter<IErrorInformation> = {
        fromFirestore: (snapshot) => {
            return ErrorInformationHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IErrorInformation) : firebase.firestore.DocumentData => {
            return ErrorInformationHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IErrorInformation {
        const data = snapshot.data();

        if (!snapshot.exists || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IErrorInformation,
            id : snapshot.id,
            date: (data['date'] as firebase.firestore.Timestamp).toMillis(),
        };
    }

    public static toFirestoreDocument(data : IErrorInformation) {
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            date: firebase.firestore.Timestamp.fromMillis(data.date),
        };
    }

    public static save(error : IErrorInformation) {
        return ErrorInformationHelper.doc(error.id).set(error, {
            merge: true,
        });
    }

    public static doc(id : string | null) {
        if (!id) {
            return firebaseApp.firestore().collection(ErrorInformationHelper.COLLECTION_NAME).withConverter(ErrorInformationHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(ErrorInformationHelper.COLLECTION_NAME).withConverter(ErrorInformationHelper.converter).doc(id);
    }
}

