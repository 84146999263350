import { createTheme } from '@material-ui/core/styles';

export const themeVariables = {
    colors: {
        white: '#FFFFFF',
        grey0: '#eaeaea',
        grey1: '#B4B4B4',
        grey2: '#F4F4F4',
        grey3: '#DEDEDE',
        orange: '#FF7F00',
        lightgreen: '#76A25B',
        material: {
            primary: {
                main: '#0d703f',
                contrastText: '#fff',
                dark: '#0b4323',
                light: '#098442',
            },
            secondary: {
                main: '#fff',
                contrastText: '#0d703f',
            },
        }
    },
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#0d703f',
            contrastText: '#fff',
            dark: '#0b4323',
            light: '#098442',
        },
        secondary: {
            main: '#fff',
            contrastText: '#0d703f',
        },
    },
});

export default theme;
