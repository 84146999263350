import firebase from 'firebase/app';
import { IGeoPoint } from './geoPoint';

export default class Region {
    public points : Array<firebase.firestore.GeoPoint>;
    public length : number;

    constructor(points : Array<firebase.firestore.GeoPoint>) {
        this.points = points;
        this.length = points.length;
    }

    public area = () => {
        let area = 0;
        let i = 0;
        let j = 0;
        let point1;
        let point2;

        // tslint:disable-next-line: ban-comma-operator
        for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
            point1 = this.points[i];
            point2 = this.points[j];
            area += point1.latitude * point2.longitude;
            area -= point1.longitude * point2.latitude;
        }
        area /= 2;

        return area;
    }

    public centroid = () => {
        let x = 0;
        let y = 0;
        let i = 0;
        let j = 0;
        let f = 0;
        let point1;
        let point2;

        // tslint:disable-next-line: ban-comma-operator
        for (i = 0, j = this.length - 1; i < this.length; j = i, i++) {
            point1 = this.points[i];
            point2 = this.points[j];
            f = point1.latitude * point2.longitude - point2.latitude * point1.longitude;
            x += (point1.latitude + point2.latitude) * f;
            y += (point1.longitude + point2.longitude) * f;
        }

        f = this.area() * 6;

        return {
            latitude: x / f,
            longitude: y / f,
        } as IGeoPoint;
    }
}
