import ReactPDF, { Document, Font, Text , Page, View, Canvas } from '@react-pdf/renderer';
import moment from 'moment';
import React from 'react';
import { createSelector } from 'reselect';
import { ITrap, TrapHelper } from '../../../types/model/trap/trap';
import pdfStyles from '../../../style/pdfStyleSheet';
import { Ecc } from '../../../types/qrcodegen';
import AppFunctionsService from '../../../services/appFunctionServices';
import QrCodeService from '../../../services/qrCodeService';

interface ITrapQrCodeDocumentProps {
    onRender ?: (props : ReactPDF.OnRenderProps) => void;

    traps : Array<ITrap>;
}

interface ITrapQrCodeDocumentState {}

export default class TrapQrCodeDocument extends React.PureComponent<ITrapQrCodeDocumentProps, ITrapQrCodeDocumentState> {
    private readonly documentSize = {
        width: 120,
        height: 135,
    };

    constructor(props : ITrapQrCodeDocumentProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount() {
        Font.register({
            family: 'Roboto',
            src: '/assets/fonts/Roboto-Regular.ttf',
            format: 'truetype',
            fonts: [
                { src: '/assets/fonts/Roboto-Regular.ttf' },
                { src: '/assets/fonts/Roboto-Medium.ttf', fontWeight: 500 },
                { src: '/assets/fonts/Roboto-Bold.ttf', fontWeight: 700 },
                { src: '/assets/fonts/Roboto-Black.ttf', fontWeight: 800 },
            ],
        });
    }

    public getTraps = (state : ITrapQrCodeDocumentState, props : ITrapQrCodeDocumentProps) => props.traps;

    public readonly getFileName = createSelector([],
        () => {
            return `traps-${moment.utc().format('YYYY-MM-DD')}.pdf`;
        },
    );

    public getPages = createSelector([
        this.getTraps,
    ],
    (traps) => {
        return traps.map(trap => (
            <Page
                key={trap.id}
                orientation='portrait'
                break
                size={this.documentSize}
                style={[
                    pdfStyles.body,
                    this.documentSize,
                ]}
            >
                <View style={[
                    pdfStyles.fdc,
                    pdfStyles.flx1,
                    pdfStyles.aic,
                    pdfStyles.jcc,
                ]}>
                    <Canvas paint={(
                        painter,
                    ) => {
                        QrCodeService.paintOnPdfCanvas(
                            painter,
                            {
                                text: TrapHelper.getQrCodeText(trap),
                                ecc: Ecc.HIGH,
                                scale: 1.3,
                                border: 1,
                            }
                        );

                        return null;
                    }} />
                    <View style={[
                        pdfStyles.fdc,
                        pdfStyles.aic,
                        pdfStyles.jcc,
                        pdfStyles.mt15,
                    ]}>
                        <Text
                            style={[
                                pdfStyles.fs8,
                                pdfStyles.cdg,
                                pdfStyles.fwm,
                            ]}
                        >
                            {trap.code} - {trap.typeName}
                        </Text>
                        {
                            trap.monitoring &&
                            <Text
                                style={[
                                    pdfStyles.fs8,
                                    pdfStyles.cdg,
                                    pdfStyles.fwm,
                                ]}
                            >
                                ({AppFunctionsService.toTitleCase(trap.otherDescription ?? trap.description ?? '')} - FM)
                            </Text>
                        }
                        {
                            !trap.monitoring &&
                            <Text
                                style={[
                                    pdfStyles.fs8,
                                    pdfStyles.cdg,
                                    pdfStyles.fwm,
                                ]}
                            >
                                ({AppFunctionsService.toTitleCase(trap.landName ?? '')} - {trap.blockName})
                            </Text>
                        }
                    </View>
                </View>
            </Page>
        ));
    },);

    public render = () => {
        const {
            onRender,
        } = this.props;

        const pages = this.getPages(this.state, this.props);
        return (
            <Document
                title={this.getFileName(this.props)}
                subject={this.getFileName(this.props)}
                onRender={onRender}
            >
                {
                    pages
                }
            </Document>
        );
    }
}
