import React from 'react';
import NotificationFunctions from '../../store/notifications/functions';
import { IAuthState, IRootState } from '../../@types/redux';
import { IFirestoreNotification } from '../../types/model/notification';
import { connect } from 'react-redux';
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import { NotificationImportant } from '@material-ui/icons';
import NotificationDialog from './dialog/NotificationDialog';
import { Transitions } from '../customComponents/animations/Transitions';

interface INotificationsProps {
    auth : IAuthState;

    notifications : Array<IFirestoreNotification>;
}

interface INotificationsState {
    openNotifications : boolean;
}

class NotificationsComponent extends React.PureComponent<INotificationsProps, INotificationsState> {
    constructor(props : INotificationsProps) {
        super(props);
        this.state = {
            openNotifications: false,
        };
    }

    public componentDidMount() : void {
        NotificationFunctions.getList();
    }

    public readonly onToggleNotifications = () => {
        this.setState({
            openNotifications: !this.state.openNotifications,
        });
    }

    public readonly render = () => {
        const {
            notifications,
        } = this.props;
        const {
            openNotifications,
        } = this.state;
        return (
            <div className='mr15'>
                {
                    !!notifications.length &&
                    <Tooltip title='Notifications'>
                        <Badge
                            badgeContent={notifications.length}
                            color='error'
                            overlap='circle'
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <IconButton
                                color='secondary'
                                onClick={this.onToggleNotifications}
                            >
                                <NotificationImportant />
                            </IconButton>
                        </Badge>
                    </Tooltip>
                }
                {
                    !!notifications.length &&
                    <NotificationDialog
                        open={openNotifications}
                        onClose={this.onToggleNotifications}
                        maxWidth='md'
                        fullWidth
                        transition={Transitions.ZoomIn}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        notifications: state.notifications.notifications,
    };
};

const Notifications = connect(
    mapStateToProps,
)(NotificationsComponent);

export default Notifications;