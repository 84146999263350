import React, { ChangeEvent } from 'react';
import { IRootState, RootAction, DispatchCall } from '../../../@types/redux';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import { FirebaseService } from '../../../services/firebaseService';
import { Dispatch, bindActionCreators } from 'redux';
import GeneralActions from '../../../store/general/actions';
import { Transitions } from '../../../modules/customComponents/animations/Transitions';
import GeneralFunctions from '../../../store/general/functions';

interface IEmployeeNrDialogProps {
    open : boolean;

    generalShowErrorSnackbar : DispatchCall<string>;
}

interface IEmployeeNrDialogState {
    isLoading : boolean;
    employeeNr : string;
}

class EmployeeNrDialog extends React.Component<IEmployeeNrDialogProps, IEmployeeNrDialogState> {

    constructor(props : IEmployeeNrDialogProps) {
        super(props);

        this.state = {
            isLoading: false,
            employeeNr: '',
        };
    }

    private onEmployeeNrChange = (e : ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            employeeNr: e.target.value.toLocaleUpperCase(),
        });
    }

    private onSave = async () => {
        this.setState({
            isLoading: true,
        });

        try {
            await FirebaseService.setEmployeeNumber(this.state.employeeNr);

            this.setState({
                isLoading: false,
                employeeNr: '',
            });
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error saving employee', ex);

            this.setState({
                isLoading: false,
            });
        }
    }

    public render() {
        const { isLoading, employeeNr } = this.state;

        return (
            <div>
                <Dialog
                    TransitionComponent={Transitions.Up}
                    open={this.props.open}
                    aria-labelledby='employee-dialog-title'>
                    <DialogTitle id='employee-dialog-title'>Enter Employee Number</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            We need your Employee Number to link you to the mobile app.
                        </DialogContentText>
                        <FormControl>
                            <TextField
                                required
                                error={!employeeNr}
                                autoFocus
                                margin='dense'
                                id='employeeNr'
                                label='Employee Number'
                                type='text'
                                fullWidth
                                value={employeeNr}
                                onChange={this.onEmployeeNrChange}
                            />
                            {
                                !employeeNr &&
                                <FormHelperText error>Required</FormHelperText>
                            }
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' disabled={isLoading} onClick={this.onSave}>
                            {!isLoading && <SaveIcon style={{ marginRight: '10px' }}></SaveIcon>}
                            {isLoading && <CircularProgress color='primary' size={24} style={{ marginRight: '10px' }}></CircularProgress>}
                            SUBMIT
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        open: !!state.auth.session && !state.auth.session.user.employeeNumber,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
    generalShowErrorSnackbar: (message : string) => dispatch(GeneralActions.enqueueSnackbar({
        message,
        options: {
            variant: 'error',
        },
    })),
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EmployeeNrDialog);
