import { CROP } from '../../../appConstants';
import firebaseApp from '../../../services/firebaseService';
import { BaseHelper, IBase } from '../../base';
import { BlockHelper, IAssignmentBlock } from '../masterData/block';
import firebase from 'firebase/app';

export type PhenologySpecificTreeType = IPhenologySpecific & IPhenologySpecificTree;

export interface IPhenologySpecific extends IBase {
    /**
     * Block Id
     */
    block : string;
    blockName : string;
    landName : string;
    division : string;
    crop : CROP;

    divisionName : string;
    cultivar : string;
    cultivarName : string;

    scoutingBlock : IAssignmentBlock;

    trees : Array<IPhenologySpecificTree>;

    isSent : boolean;
}

export interface IPhenologySpecificTree {
    location : firebase.firestore.GeoPoint | null;
    locationAccuracy : number | null;
    elevation : number | null;
    elevationAccuracy : number | null;
    treeNumber : number;
    code : string;
    archived : boolean;
}

export class PhenologySpecificHelper extends BaseHelper {
    public static readonly COLLECTION = 'phenology_specifics';

    public static converter : firebase.firestore.FirestoreDataConverter<IPhenologySpecific> = {
        fromFirestore: (snapshot) => {
            return PhenologySpecificHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IPhenologySpecific) => {
            return PhenologySpecificHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IPhenologySpecific {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as IPhenologySpecific,
            ...result,
            block: (data['block'] as firebase.firestore.DocumentReference).id,
            scoutingBlock: BlockHelper.fromFirestoreData((data['block'] as firebase.firestore.DocumentReference).id, data['scoutingBlock']),
        };
    }

    protected static toFirestore(data : IPhenologySpecific) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
            block: BlockHelper.doc(data.block),
            scoutingBlock: BlockHelper.toFirestoreData(data.scoutingBlock),
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static doc(id ?: string) {
        if (!id) {
            return PhenologySpecificHelper.collection().doc();
        }

        return PhenologySpecificHelper.collection().doc(id);
    }
    public static delete(id : string) {
        return PhenologySpecificHelper.doc(id).delete();
    }

    /**
     * Saves object to firestore using a transaction.
     * @param transaction
     */
    public static saveTransaction(transaction : firebase.firestore.Transaction, data : IPhenologySpecific) {
        if (data.id) {
            transaction.set(this.collection().doc(data.id), data);
        } else {
            transaction.set(this.collection().doc(), data);
        }
    }

    public static save(specific : IPhenologySpecific) {
        if (specific.id) {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(specific.id).set(specific);
        }

        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc().set(specific);
    }

    public static getQrCodeText(tree : PhenologySpecificTreeType) {
        return `1:${tree.id}:${tree.code}`;
    }
}