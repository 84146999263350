import { IScoutingState, ScoutingAction } from '../../@types/redux';
import { getType } from 'typesafe-actions';
import ScoutingActions from './actions';

const initialState = {
    scoutings: [],
    isLoading: false,
    assignments: [],
    isLoadingAssignments: false,
};

export default function authReducer(state : IScoutingState = initialState, action : ScoutingAction) : IScoutingState {
    switch (action.type) {
    case getType(ScoutingActions.setList):
        return { ...state, scoutings: action.payload };
    case getType(ScoutingActions.setLoading):
        return { ...state, isLoading: action.payload };
    case getType(ScoutingActions.setAssignments):
        return { ...state, assignments: action.payload };
    case getType(ScoutingActions.setLoadingAssignments):
        return { ...state, isLoadingAssignments: action.payload };
    default:
        return state;
    }
}
