import React from 'react';
import Typography from '@material-ui/core/Typography';
import { INematodeAssignmentLevelEntry } from '../../../../types/model/nematode/nematodeAssignmentEntry';
import { AppBar, Toolbar } from '@material-ui/core';
import firebaseApp from '../../../../services/firebaseService';

interface INematodeAssignmentEntryTypeViewProps {
    title : string;
    value : INematodeAssignmentLevelEntry;
}

interface INematodeAssignmentEntryTypeViewState {
    imgUrl : string;
}

export default class NematodeAssignmentEntryTypeView extends React.PureComponent<INematodeAssignmentEntryTypeViewProps, INematodeAssignmentEntryTypeViewState> {

    constructor(props : INematodeAssignmentEntryTypeViewProps) {
        super(props);
        this.state = {
            imgUrl: '',
        };
    }

    public componentDidMount() : void {
        this.loadImageUrl();
    }

    private loadImageUrl = async () => {
        const imgUrl = await firebaseApp.storage().ref(this.props.value.imagePath ?? '').getDownloadURL();

        this.setState({
            imgUrl,
        })
    }

    public render = () => {
        const { imgUrl } = this.state;
        const { value, title } = this.props;
        return (
            <div className='fdc ais mb5'>
                <AppBar className='fdc ais' variant='outlined' position='static'>
                    <Toolbar variant='dense' className={'fdr aic jcc'}>
                        <Typography className={'cw'} variant='h6'>
                            {title.toLocaleUpperCase()}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className='fdc aic jcc'>
                    <div className='fdc h500'>
                        <img src={imgUrl} height={'100%'} />
                    </div>
                    <Typography className={'cpd'} variant='h6'>
                        {value.level.toLocaleUpperCase()}
                    </Typography>
                </div>
            </div>
        );
    }
}
