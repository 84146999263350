import firebase from 'firebase/app';
import { dispatch, getState } from '..';
import { CROP } from '../../appConstants';
import { ITrap, TrapHelper } from '../../types/model/trap/trap';
import GeneralFunctions from '../general/functions';
import TrapActions from './actions';
import firebaseApp from '../../services/firebaseService';

export default class TrapFunctions {
    private static listener ?: () => void;

    public static unsubscribe() {
        if (!this.listener) return;

        dispatch(TrapActions.setList([]));
        dispatch(TrapActions.setLoading(false));

        this.listener();
    }
    
    public static getList = (crop ?: CROP | string) => {

        if (TrapFunctions.listener) {
            TrapFunctions.listener();
        }

        dispatch(TrapActions.setLoading(true));

        let query : firebase.firestore.CollectionReference<ITrap> | firebase.firestore.Query<ITrap> =
            TrapHelper.collection();

        if (crop) {
            query = query.where('crop', '==', crop);
        }

        try {
            
            TrapFunctions.listener = query
                .onSnapshot((snapshot) => {
                    const session = getState().auth.session;
                    if (!session) {
                        return;
                    }

                    dispatch(TrapActions.setList(snapshot.docs.map(x => x.data()).filter(x => !!session.user.divisions[x.divisionCode])));
                    dispatch(TrapActions.setLoading(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading traps.', err);
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading traps.', ex);
            dispatch(TrapActions.setLoading(false));
        }
    }

    public static save = async (item : ITrap) => {
        const session = getState().auth.session;

        if (!session) return;

        dispatch(TrapActions.setLoading(true));

        try {
            const save : ITrap = {
                ...item,
                createdBy: item.createdBy ? item.createdBy : session.firebaseUser.uid,
                createdByEmployee: item.createdByEmployee ? item.createdByEmployee : session.user.employeeNumber,
                createdByName: item.createdBy ? item.createdByName : session.user.name,
                createdOn: item.createdOn ? item.createdOn : firebase.firestore.Timestamp.now().toMillis(),
                updatedBy: session.firebaseUser.uid,
                updatedByEmployee: session.user.employeeNumber,
                updatedByName: session.user.name,
                updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                id: item.id ? item.id : '',
                isSent: false,
            };

            await TrapHelper.save(save);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving trap.', ex);
            throw ex;
        } finally {
            dispatch(TrapActions.setLoading(false));
        }
    }

    public static archive = async (items : Array<ITrap>) => {
        const session = getState().auth.session;

        if (!session) return;

        try {
            if (items.length > 100) throw new Error('Selection limit exceeded.');

            await firebaseApp.firestore().runTransaction(async (db) => {
                const snapshots = await Promise.all(items.map(x => db.get(TrapHelper.doc(x.id))));

                snapshots.forEach(snapshot => {
                    const data = snapshot.data();

                    if (data?.archived) return;

                    db.set(snapshot.ref, {
                        ...data,
                        archived: true,
                        isSent: false,
                        updatedBy: session.firebaseUser.uid,
                        updatedByEmployee: session.user.employeeNumber,
                        updatedByName: session.user.name,
                        updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                    }, {
                        mergeFields: [
                            'isSent',
                            'archived',
                            'updatedBy',
                            'updatedByEmployee',
                            'updatedByName',
                            'updatedOn',
                        ]
                    });
                });
            });
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving trap.', ex);
            throw ex;
        }
    }
}