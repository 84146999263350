import React from 'react';

interface ITabPanelProps {
    value : number | string;
    index : number | string;
    children ?: React.ReactNode;
}

interface ITabPanelState {}

export default class TabPanel extends React.PureComponent<ITabPanelProps, ITabPanelState> {
    constructor(props : ITabPanelProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { value, index, children } = this.props;
        return (
            value === index &&
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
}
