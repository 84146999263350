import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Popper from '@material-ui/core/Popper';
import moment from 'moment';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../appConstants';
import StadiumButton from '../button/StadiumButton';

interface IScoutingAssignmentDateFilterProps {
    id? : string;

    value? : moment.Moment;

    onChange? : (date? : moment.Moment) => void;
}

interface IScoutingAssignmentDateFilterState {
    open : boolean;

    currentDate? : moment.Moment | null;
}

export default class ScoutingAssignmentDateFilter extends React.PureComponent<IScoutingAssignmentDateFilterProps, IScoutingAssignmentDateFilterState> {
    private readonly anchorEl : React.RefObject<HTMLButtonElement>;

    constructor(props : IScoutingAssignmentDateFilterProps) {
        super(props);
        this.state = {
            open: false,
            currentDate: null,
        };

        this.anchorEl = React.createRef<HTMLButtonElement>();
    }

    private readonly onClick = (event : React.MouseEvent<HTMLElement, MouseEvent>) => {
        this.setState({ open: !this.state.open });
    }

    private readonly onClose = (event : React.MouseEvent<Document, MouseEvent>) => {
        if (this.anchorEl && this.anchorEl.current?.contains(event.target as any)) {
            return;
        }

        event.preventDefault();

        this.setState({ open: false });
    }

    private onDateChange = (currentDate : moment.Moment | null) => {
        this.setState({
            currentDate: !currentDate ? currentDate : moment.utc(currentDate.valueOf()),
        });
    }

    private onSaveClick = () => {
        if (!this.state.currentDate) return;
        if (!this.props.onChange) return;
        this.props.onChange(this.state.currentDate);
        this.setState({ open: false, currentDate: undefined });
    }

    private onClearClick = () => {
        if (!this.props.onChange) return;
        this.props.onChange(undefined);
        this.setState({ open: false, currentDate: undefined });
    }

    private onCancelClick = () => {
        this.setState({ open: false, currentDate: undefined });
    }

    public readonly render = () => {
        const { id, value } = this.props;
        const { open, currentDate = value ?? null } = this.state;
        return (
            <span>
                <IconButton
                    aria-owns={`filter-menu-${id}`}
                    aria-haspopup='true'
                    onClick={this.onClick}
                    ref={this.anchorEl}
                    style={{
                        color: !value ? '' : '#FEE733',
                    }}
                    color='inherit'>
                    <FilterListIcon fontSize='small' />
                </IconButton>
                <Popper anchorEl={this.anchorEl.current} open={open} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', marginTop: 8 }}
                        >
                            <Paper id={`filter-menu-${id}`}>
                                <ClickAwayListener onClickAway={this.onClose}>
                                    <div className='fdc ais jcfs'>
                                        <div className='fdr aic jcfs pl20 pb5 pt5 w240'>
                                            <KeyboardDatePicker
                                                margin='dense'
                                                id={id}
                                                format={DATEPICKER_FORMAT_DEFAULT}
                                                value={currentDate}
                                                onChange={this.onDateChange}
                                                style={{
                                                    marginTop: 0,
                                                }}
                                                label='Select Date'
                                                inputProps={{
                                                    style: {
                                                        font: '400 14px/37px Roboto',
                                                        color: '#424242',
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div className='fdr aic jcfe pl20 pr20 pb10'>
                                            <StadiumButton style={{
                                                height: 21,
                                            }} className='fw500 fs9 cpd mr15 bw1 h21' variant='text' type='button' onClick={this.onCancelClick}>
                                                    CANCEL
                                            </StadiumButton>
                                            {
                                                value &&
                                                <StadiumButton style={{
                                                    height: 21,
                                                }} className='fw500 fs9 cpd mr15 bw1 h21' variant='text' type='button' onClick={this.onClearClick}>
                                                        CLEAR
                                                </StadiumButton>
                                            }
                                            <StadiumButton style={{
                                                height: 21,
                                            }} className='fw500 fs9 cpd bcy h21' type='button' onClick={this.onSaveClick}>
                                                    APPLY
                                            </StadiumButton>
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </span>
        );
    }
}
