import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { IUser } from '../../../../types/model/user';
import { CROPS } from '../../../../appConstants';

interface IUserCropsEditTabProps {
    user : IUser;

    onChange : (user : IUser) => void;
}

interface IUserCropsEditTabState {}

export default class UserCropsEditTab extends React.PureComponent<IUserCropsEditTabProps, IUserCropsEditTabState> {
    constructor(props : IUserCropsEditTabProps) {
        super(props);
        this.state = {};
    }

    private readonly onCultivarCheck = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const user = {
            ...this.props.user,
            crops: [...this.props.user.crops],
        };

        if (checked) {
            user.crops.push(event.target.value);
        } else {
            const index = user.crops.indexOf(event.target.value);
            user.crops.splice(index, 1);
        }

        this.props.onChange(user);
    }

    public render = () => {
        const {
            crops,
        } = this.props.user;

        return (
            <div className='fdc ais'>
                <div className='fdr fww flx1 m10'>
                    {
                        CROPS.map(n => (
                            <div style={{
                                width: 350,
                            }} key={`cul_${n}`} >
                                <FormControlLabel control={
                                    <Checkbox
                                        color='primary'
                                        value={n}
                                        checked={crops.includes(n)}
                                        onChange={this.onCultivarCheck}
                                    />
                                }
                                label={n.toLocaleUpperCase()}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}
