import React from 'react';
import HorizontalExpander from '../../customComponents/expander/HorizontalExpander';
import {  CROP } from '../../../appConstants';
import DivisionAutocomplete from '../autocomplete/rights/DivisionAutocomplete';
import { IDivision } from '../../../types/model/rights/division';

interface IAssignmentDivisionSelectorProps {
    crop : CROP;
    value ?: IDivision;
    onChanged ?: (value ?: IDivision) => void;
}

interface IAssignmentDivisionSelectorState {}

export default class AssignmentDivisionSelector extends React.PureComponent<IAssignmentDivisionSelectorProps, IAssignmentDivisionSelectorState> {
    constructor(props : IAssignmentDivisionSelectorProps) {
        super(props);
        this.state = {};
    }

    private onDivisionChange = (division ?: IDivision) => {
        if (this.props.onChanged) this.props.onChanged(division);
    }

    public render = () => {
        const { value, crop } = this.props;

        return (
            <HorizontalExpander title={`DIVISION${!value ? '' : ' - '.concat(value.name)}`} color={value ? 'primary' : 'secondary'}>
                <div className='fdc p10'>
                    <div className={'fdc flx1 aic'}>
                        <DivisionAutocomplete required fullWidth value={value} onChange={this.onDivisionChange} crop={crop} />
                    </div>
                </div>
            </HorizontalExpander>
        );
    }
}
