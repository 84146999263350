import React from 'react';
import { connect } from 'react-redux';
import 'leaflet/dist/leaflet.css';
import { IRootState } from '../../../@types/redux';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import MaterialTable from '../../customComponents/table/MaterialTable';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import NavFunctions from '../../../store/nav/functions';
import BlockShapefileUploadDialog from './dialog/BlockShapefileUpload';
import { Transitions } from '../../customComponents/animations/Transitions';
import GeneralFunctions from '../../../store/general/functions';
import { IBlock } from '../../../types/model/masterData/block';

interface IScoutingBlockListProps {
    isLoading : boolean;
    blocks : Array<IBlock>;
}

interface IScoutingBlockListState {}

class BlockList extends React.Component<IScoutingBlockListProps, IScoutingBlockListState> {
    constructor(props : IScoutingBlockListProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount = () => {
        GeneralFunctions.getBlocks();
    }

    public onMapClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        NavFunctions.navScoutingMap(event.currentTarget.value);
    }

    public render = () => {
        const { blocks, isLoading } = this.props;
        return (
            <div className={'fdc flx1 p10 mh0 mw0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            SCOUTING BLOCKS
                        </Typography>
                        <BlockShapefileUploadDialog fullWidth maxWidth='md' fullScreen transition={Transitions.Up} />
                        <span className={'flx1'} />
                        <div className={'fdr pt5 jcfe aic'}>
                            <div className={'fdr pt5'}>
                                <span style={{ paddingRight: '15px' }}><b>Total: </b>{blocks.length}</span>
                            </div>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc'}>
                        <MaterialTable<IBlock>
                            id='blocklsTable'
                            data={blocks}
                            isLoading={isLoading}
                            rowsPerPage={50}
                            columns={[{
                                header: '',
                                minWidth: 50,
                                paddingRight: 4,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Map'>
                                            <div>
                                                <IconButton value={row.id} onClick={this.onMapClick}>
                                                    <Icon>
                                                        map
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            },        {
                                header: 'Division',
                                field: 'division',
                                minWidth: 175,
                                enableSort: true,
                                enableFilter: true,
                            },        {
                                header: 'Land Name',
                                field: 'landName',
                                minWidth: 175,
                                enableSort: true,
                                enableFilter: true,
                            },        {
                                header: 'Name',
                                field: 'name',
                                minWidth: 70,
                                enableSort: true,
                                enableFilter: true,
                            },        {
                                header: 'Crop',
                                field: 'crop',
                                minWidth: 70,
                                enableSort: true,
                                enableFilter: true,
                            }]}
                        />
                    </Card>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.general.isLoadingBlocks,
        blocks: state.general.blocks,
    };
};

export default connect(
    mapStateToProps,
)(BlockList);
