import { CROP } from '../../../../appConstants';
import firebaseApp from '../../../../services/firebaseService';
import { BaseHelper, IBase } from '../../../base';
import TrapTypeHelper from './trapType';
import firebase from 'firebase/app';

export interface ITrapEntryComment extends IBase {
    comment : string;
    trapTypeId : string;
    trapTypeName : string;
    crop : CROP;

    isActive : boolean;
}

export default class TrapEntryCommentHelper extends BaseHelper {
    public static readonly COLLECTION = 'trap_entry_comments';

    public static converter : firebase.firestore.FirestoreDataConverter<ITrapEntryComment> = {
        fromFirestore: (snapshot) => {
            return TrapEntryCommentHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : ITrapEntryComment) => {
            return TrapEntryCommentHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : ITrapEntryComment {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as ITrapEntryComment,
            ...result,
            trapTypeId: (data['trapTypeRef'] as firebase.firestore.DocumentReference).id,
        };
    }
    protected static toFirestore(data : ITrapEntryComment) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
            trapTypeRef: TrapTypeHelper.doc(data.trapTypeId),
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static save(farmDescription : ITrapEntryComment) {
        if (farmDescription.id) {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(farmDescription.id).set(farmDescription);
        }

        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc().set(farmDescription);
    }
}