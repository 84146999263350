import { getType } from 'typesafe-actions';
import { IDivision } from '../../../types/model/rights/division';
import DivisionActions from './actions';
import { DivisionAction } from '../../../@types/redux';

export interface IDivisionState {
    divisions : Array<IDivision>;
    isLoading : boolean;

}

const initialState : IDivisionState = {
    divisions: [],
    isLoading: false,
};

export default function divisionReducer(state : IDivisionState = initialState, action : DivisionAction) : IDivisionState {
    switch (action.type) {
    case getType(DivisionActions.setList):
        return { ...state, divisions: action.payload };
    case getType(DivisionActions.setLoading):
        return { ...state, isLoading: action.payload };
    default:
        return state;
    }
}