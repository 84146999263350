import { CROP } from '../../../../appConstants';
import firebaseApp from '../../../../services/firebaseService';
import { BaseHelper, IBase } from '../../../base';
import { IScoutingFile } from '../../file';
import firebase from 'firebase/app';

export interface INematodeSurveyType extends IBase {
    crop : CROP;
    name : string;

    levels : Array<INematodeSurveyTypeLevel>;

    isActive : boolean;
}

export interface INematodeSurveyTypeLevel {
    name : string;

    images : Array<INematodeSurveyTypeLevelImage>;
}

export interface INematodeSurveyTypeLevelImage {
    file : IScoutingFile;

    description : string;
}

export default class NematodeSurveyTypeHelper extends BaseHelper {
    public static readonly COLLECTION = 'nematode_survey_type';

    public static converter : firebase.firestore.FirestoreDataConverter<INematodeSurveyType> = {
        fromFirestore: (snapshot) => {
            return NematodeSurveyTypeHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : INematodeSurveyType) => {
            return NematodeSurveyTypeHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : INematodeSurveyType {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as INematodeSurveyType,
            ...result,
        };
    }
    
    protected static toFirestore(data : INematodeSurveyType) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static doc(id : string) {
        return this.collection().doc(id);
    }

    public static save(trapType : INematodeSurveyType) {
        if (trapType.id) {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(trapType.id).set(trapType);
        }

        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc().set(trapType);
    }
}