import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AppFunctionsService from '../../../services/appFunctionServices';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import ScoutingEntrySectionView from './ScoutingEntrySectionView';
import { IScoutingEntry } from '../../../types/model/scouting/scoutingEntry';

interface IScoutingEntryViewProps {
    value ?: IScoutingEntry;
    pointNumber : number;
}

interface IScoutingEntryViewState {
    expanded : boolean;
}

export default class ScoutingEntryView extends React.PureComponent<IScoutingEntryViewProps, IScoutingEntryViewState> {

    constructor(props : IScoutingEntryViewProps) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    private getValue = (props : IScoutingEntryViewProps) => props.value;
    private getSections = createSelector(
        [this.getValue],
        (value) => {
            if (!value) return {};

            return lodash.groupBy(value.sections, x => x.section);
        },
    );

    private onExpansionChange = (event : React.ChangeEvent<unknown>, expanded : boolean) => {
        this.setState({
            expanded,
        });
    }

    public render = () => {
        const { expanded } = this.state;
        const { value, pointNumber } = this.props;

        const sections = this.getSections(this.props);
        return (
            <Accordion expanded={expanded} onChange={this.onExpansionChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className='fdr aic flx1'>
                        <Typography>
                            Point {pointNumber}
                        </Typography>
                        {
                            !!value &&
                            <Typography className='fdr aic'>
                                &nbsp;- {AppFunctionsService.formatDateTime(value.createdOn)}
                            </Typography>
                        }
                        <span className='flx1'></span>
                        {!value ? <CloseIcon className='cpr' /> : <CheckIcon className='cp' />}
                    </div>
                </AccordionSummary>
                <AccordionDetails className={'fdc ais flx1'}>
                    {
                        !value &&
                        <span>No Data</span>
                    }
                    {
                        value && expanded &&
                        Object.keys(sections).map(s => (<ScoutingEntrySectionView key={`${value.guid}_${s}`} title={s} values={sections[s]} />))
                    }
                </AccordionDetails>
            </Accordion>
        );
    }
}
