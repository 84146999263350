import * as React from 'react';

import { IRootState, ISnackbarNotification } from '../../@types/redux';
import { connect } from 'react-redux';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import GeneralFunctions from '../../store/general/functions';

interface ISnackbarNotifierProps extends WithSnackbarProps {
    notifications : Array<ISnackbarNotification>;
}

class SnackbarNotifier extends React.Component<ISnackbarNotifierProps> {
    private displayed : Array<number> = [];

    private storeDisplayed = (id : number) => {
        this.displayed = [...this.displayed, id];
    }

    public shouldComponentUpdate = ({ notifications: newSnacks = [] as Array<ISnackbarNotification> }) => {
        const { notifications: currentSnacks } = this.props;
        const notExists = currentSnacks.every(({ key }) => newSnacks.some(n => n.key === key));
        return notExists;
    }

    private onCloseClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.props.closeSnackbar(event.currentTarget.value);
    }

    public componentDidUpdate = () => {
        const { notifications = [] } = this.props;

        notifications.forEach((notification) => {
            // Do nothing if snackbar is already displayed
            if (this.displayed.includes(notification.key)) return;
            // Display snackbar using notistack
            this.props.enqueueSnackbar(notification.message, {
                key: `snackbar_${notification.key}`,
                action: <IconButton value={`snackbar_${notification.key}`} onClick={this.onCloseClick}>
                            <Icon style={{ color: 'white' }}>close</Icon>
                        </IconButton>,
                ...notification.options,
            });
            // Keep track of snackbars that we've displayed
            this.storeDisplayed(notification.key);
            // Dispatch action to remove snackbar from redux store
            GeneralFunctions.generalRemoveSnackbar(notification.key);
        });
    }

    public render = () => {
        return null;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        notifications: state.general.notifications,
    };
};

export default connect(
    mapStateToProps,
)(withSnackbar(SnackbarNotifier));
