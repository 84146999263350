import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const OutlinedStadiumButton = withStyles(() => ({
    root: {
        color: '#0b4323',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: '#00000050 ',
        },
        font: '500 14px Roboto',
        height: 34,
        width: 99,
        borderRadius: 17,
    },
}))(Button);

export default OutlinedStadiumButton;
