import React from 'react';
import { IUser } from '../../../../types/model/user';
import { IDivision } from '../../../../types/model/rights/division';
import { createSelector } from 'reselect';
import DivisionSelectCard from '../../../customComponents/card/DivisionCard';
import lodash from 'lodash';

interface IUserDivisionsEditTabProps {
    user : IUser;

    onChange : (user : IUser) => void;
}

interface IUserDivisionsEditTabState {}

export default class UserDivisionsEditTab extends React.PureComponent<IUserDivisionsEditTabProps, IUserDivisionsEditTabState> {
    constructor(props : IUserDivisionsEditTabProps) {
        super(props);
        this.state = {};
    }

    private readonly getUserDivisions = (state : IUserDivisionsEditTabState, props : IUserDivisionsEditTabProps) => props.user.divisions;
    private getDivisions = createSelector(
        [this.getUserDivisions],
        (divisions) => {
            return lodash.map(divisions, (n, code) => code);
        },
    );

    private readonly onDivisionCheck = (division : IDivision) => {
        const user : IUser = {
            ...this.props.user,
            divisions: {
                ...this.props.user.divisions
            },
            managementLands: {
                ...this.props.user.managementLands
            },
        };

        if (!user.divisions[division.code]) {
            user.divisions[division.code] = division.name;
        } else {
            delete user.divisions[division.code];
            delete user.managementLands[division.code];
        }

        this.props.onChange(user);
    }

    public render = () => {
        const divisions = this.getDivisions(this.state, this.props);

        return (
            <DivisionSelectCard
                value={divisions}
                onDivisionCheckChange={this.onDivisionCheck}
            />
        
        );
    }
}
