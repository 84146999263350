import React from 'react';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { withRouter, RouteComponentProps } from 'react-router';
import ScoutingBlocksMap from '../customComponents/map/ScoutingBlocksMap';
import ScoutingBlockLandNameDropdown from '../customComponents/dropdowns/BlockLandNameDropdown';
import ScoutingBlockNameDropdown from '../customComponents/dropdowns/BlockNameDropdown';
import CropTypeDropdown from '../customComponents/dropdowns/CropTypeDropdown';
import AssignmentDialog from './dialog/AssignmentDialog';
import { Transitions } from '../customComponents/animations/Transitions';
import { CROP } from '../../appConstants';
import VerticalExpander from '../customComponents/expander/VerticalExpander';
import { IBlock } from '../../types/model/masterData/block';
import DivisionAutocomplete from '../customComponents/autocomplete/rights/DivisionAutocomplete';
import { IDivision } from '../../types/model/rights/division';

interface IScoutingMapProps extends RouteComponentProps {
    isLoading : boolean;
    blocks : Array<IBlock>;
}

interface IScoutingMapState {
    division ?: string;

    landName ?: string;
    blockNames : Array<string>;
    cropType ?: CROP;
}

class ScoutingMap extends React.Component<IScoutingMapProps, IScoutingMapState> {

    constructor(props : IScoutingMapProps) {
        super(props);
        this.state = {
            blockNames: [],
        };
    }

    private onDivisionChange = (value ?: IDivision) => {
        this.setState({
            division: value?.code,
            landName: undefined,
            blockNames: [],
        });
    }

    private onBlockLandNameChange = (landName ?: string) => {
        const { division, cropType } = this.state;
        const block = this.props.blocks
            .filter(n => !cropType || n.crop === cropType)
            .filter(n => !division || n.division.toLocaleUpperCase() === division.toLocaleUpperCase() || division.toLocaleUpperCase() === n.division.toLocaleUpperCase())
            .find(x => x.landName === landName);

        this.setState({
            division: division ?? block?.division.toLocaleUpperCase(),
            landName,
            cropType: !block ? this.state.cropType : block.crop as CROP,
            blockNames: [],
        });
    }

    private onBlockNameChange = (blockNames : Array<string>) => {
        this.setState({
            blockNames,
        });
    }

    private onCropTypeChange = (cropType ?: CROP) => {
        this.setState({
            cropType,
        });
    }

    public render = () => {
        const { division, landName, blockNames, cropType } = this.state;
        const { isLoading } = this.props;

        return (
            <div className={'fdr hfill mh0'}>
                <Paper className={'flx1 fdr oxh p5 m5'}>
                    <ScoutingBlocksMap crop={cropType} division={division} landName={landName} blockNames={blockNames} />
                </Paper>
                <div className={'fdc mh0'}>
                    <VerticalExpander title='filter' location='right' initiallyExpanded>
                        <div className={'fdc aic pr20 mt7'}>
                            <DivisionAutocomplete fullWidth value={division} onChange={this.onDivisionChange} />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <CropTypeDropdown fullWidth value={cropType} onChange={this.onCropTypeChange} />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <ScoutingBlockLandNameDropdown value={landName}
                                division={division} disabled={!division} crop={cropType} fullWidth onChange={this.onBlockLandNameChange} />
                        </div>
                        <div className={'fdc aic pr20'}>
                            <ScoutingBlockNameDropdown value={blockNames}
                                landName={landName} disabled={!landName} division={division}
                                fullWidth onChange={this.onBlockNameChange} />
                        </div>
                        <div className={'fdc aife pr20 mt7'}>
                            <AssignmentDialog
                                division={division}
                                landName={landName}
                                crop={!cropType ? '' as CROP : cropType}
                                blockNames={blockNames}
                                fullWidth
                                transition={Transitions.Down}
                                isLoading={isLoading}
                                maxWidth='md'
                                disabled={!landName || !division || !cropType} />
                        </div>
                    </VerticalExpander>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.general.isLoadingBlocks,
        blocks: state.general.blocks,
    };
};

export default connect(
    mapStateToProps,
)(withRouter(ScoutingMap));
