import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IBlock } from '../../../../types/model/masterData/block';
import { CROP } from '../../../../appConstants';
import GeneralFunctions from '../../../../store/general/functions';
import lodash from 'lodash';

interface IFieldNameAutocompleteProps {
    id ?: string;
    value ?: string | null;

    onChange : (value ?: string, event ?: React.ChangeEvent<unknown>) => void;

    blocks : Array<IBlock>;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;
    disabled ?: boolean;
    name ?: string;

    division ?: string | null;

    crop ?: CROP | null;
    label ?: string;
}

interface IFieldNameAutocompleteState {
}

class FieldAutocompleteComponent extends React.Component<IFieldNameAutocompleteProps, IFieldNameAutocompleteState> {
    constructor(props : IFieldNameAutocompleteProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        GeneralFunctions.getBlocks();
    }

    private onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        this.props.onChange(value?.value, event);
    }

    private getData = (state : IFieldNameAutocompleteState, props : IFieldNameAutocompleteProps) => props.blocks;
    private getValue = (state : IFieldNameAutocompleteState, props : IFieldNameAutocompleteProps) => props.value;
    private getRequired = (state : IFieldNameAutocompleteState, props : IFieldNameAutocompleteProps) => props.required;
    private getDivision = (state : IFieldNameAutocompleteState, props : IFieldNameAutocompleteProps) => props.division;
    private getCrop = (state : IFieldNameAutocompleteState, props : IFieldNameAutocompleteProps) => props.crop;

    private getFieldAutocomplete = createSelector([
        this.getData,
        this.getRequired,
        this.getDivision,
        this.getCrop,
    ], (
        blocks,
        required,
        division,
        crop,
    ) => {
        const fieldsDrop = lodash.chain(blocks)
            .filter(n => !division || n.division.toLocaleLowerCase() === division.toLocaleLowerCase())
            .filter(n => !crop || n.crop === crop)
            .uniqBy(n => n.landName)
            .map(n => ({
                label: `${n.landName}`,
                value: n.landName,
            }))
            .sortBy(n => n.label)
            .value();

        if (!required) {
            fieldsDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return fieldsDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getFieldAutocomplete,
    ], (value, required, fields) => {
        if (value && typeof(value) === 'string') {
            const field = fields.slice().find(x => x.value === value);

            if (field) {
                return {
                    ...field,
                };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const {
            required,
            fullWidth,
            isLoading,
            disabled,
            label,
        } = this.props;

        const fields = this.getFieldAutocomplete(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        disabled={isLoading || disabled}
                        id='field_select'
                        options={fields}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChange}
                        disableClearable={required}
                        openOnFocus
                        renderInput={params => (
                            <TextField
                                error={required && !value}
                                required={required}
                                {...params}
                                fullWidth={fullWidth}
                                label={label ?? 'Field'}
                            />)}
                    />
                    {
                        required && !value &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.general.blocks,
        isLoading: state.general.isLoadingBlocks,
    };
};

const FieldAutocomplete = connect(
    mapStateToProps,
)(FieldAutocompleteComponent);

export default FieldAutocomplete;
