import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IDisease } from '../../../types/model/masterData/disease';
import DiseaseFunctions from '../../../store/masterData/disease/functions';

interface IDiseaseDropdownProps {
    id ?: string;
    value ?: IDisease | string;

    onChange : (name ?: string, value ?: IDisease) => void;

    diseases : Array<IDisease>;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;
    disabled ?: boolean;
    name ?: string;
}

interface IDiseaseDropdownState {
}

class DiseaseDropdown extends React.Component<IDiseaseDropdownProps, IDiseaseDropdownState> {
    constructor(props : IDiseaseDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        DiseaseFunctions.getList();
    }

    private onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        const result = this.props.diseases.find(n => n.id === value?.value);

        this.props.onChange(this.props.name, result);
    }

    private getData = (state : IDiseaseDropdownState, props : IDiseaseDropdownProps) => props.diseases;
    private getValue = (state : IDiseaseDropdownState, props : IDiseaseDropdownProps) => props.value;
    private getRequired = (state : IDiseaseDropdownState, props : IDiseaseDropdownProps) => props.required;
    private getDiseaseDropdown = createSelector([
        this.getData, this.getRequired,
    ], (diseases, required) => {
        const diseasesDrop = diseases
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(x => ({ label: `${x.name}`, value: x.id }));

        if (!required) {
            diseasesDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return diseasesDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getData,
    ], (value, required, diseases) => {
        if (typeof(value) === 'object') {
            return {
                label:  `${value.name}`,
                value: value.id,
            };
        } else if (value && typeof(value) === 'string') {
            const disease = diseases.slice().find(x => x.id === value);

            if (disease) {
                return {
                    label:  `${disease.name}`,
                    value: disease.id,
                };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const { required,  fullWidth, isLoading, disabled } = this.props;

        const diseases = this.getDiseaseDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        disabled={isLoading || disabled}
                        id='disease_select'
                        options={diseases}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChange}
                        disableClearable={required}
                        openOnFocus
                        renderInput={params => <TextField error={required && !value} {...params} fullWidth={fullWidth} label='Disease' />}
                    />
                    {
                        required && !value &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        diseases: state.masterData.disease.diseases.filter(x => x.isActive),
        isLoading: state.masterData.disease.isLoading,
    };
};

export default connect(
    mapStateToProps,
)(DiseaseDropdown);
