import firebaseApp from '../../../../services/firebaseService';
import { BaseHelper, IBase } from '../../../base';
import firebase from 'firebase/app';

export interface ITrapFarmDescription extends IBase {
    description : string;

    isActive : boolean;
}

export default class TrapFarmDescriptionHelper extends BaseHelper {
    public static readonly COLLECTION = 'trap_farm_descriptions';

    public static converter : firebase.firestore.FirestoreDataConverter<ITrapFarmDescription> = {
        fromFirestore: (snapshot) => {
            return TrapFarmDescriptionHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : ITrapFarmDescription) => {
            return TrapFarmDescriptionHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : ITrapFarmDescription {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as ITrapFarmDescription,
            ...result,
        };
    }
    protected static toFirestore(data : ITrapFarmDescription) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static save(farmDescription : ITrapFarmDescription) {
        if (farmDescription.id) {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(farmDescription.id).set(farmDescription);
        }

        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc().set(farmDescription);
    }
}