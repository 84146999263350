import React from 'react';
import { LayerGroup, MapLayerProps, Marker, Popup, withLeaflet } from 'react-leaflet';
import { createSelector } from 'reselect';
import { Icon } from 'leaflet';
import { Button, Link } from '@material-ui/core';
import { IPhenologySpecificTree } from '../../../../types/model/phenology/specific';
import { IGeoPoint } from '../../../../types/model/geoPoint';

interface IPhenologySpecificCherriesLocationMarkersProps extends MapLayerProps  {
    trees : Array<IPhenologySpecificTree | { id : string; location : IGeoPoint }>;

    onPhenologySpecificCherriesClick ?: (event : React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

interface IPhenologySpecificCherriesLocationMarkersState {
}

class PhenologySpecificCherriesLocationMarkersComponent extends React.PureComponent<IPhenologySpecificCherriesLocationMarkersProps, IPhenologySpecificCherriesLocationMarkersState> {
    constructor(props : IPhenologySpecificCherriesLocationMarkersProps) {
        super(props);
        this.state = {
        };
    }

    private getPhenologySpecificCherriess = (state : IPhenologySpecificCherriesLocationMarkersState, props : IPhenologySpecificCherriesLocationMarkersProps) => props.trees;
    private getOnPhenologySpecificCherriesClick = (state : IPhenologySpecificCherriesLocationMarkersState, props : IPhenologySpecificCherriesLocationMarkersProps) => props.onPhenologySpecificCherriesClick;
    private getLocationMarkers = createSelector(
        [
            this.getPhenologySpecificCherriess,
            this.getOnPhenologySpecificCherriesClick,
        ],
        (
            trees,
            onPhenologySpecificCherriesClick,
        ) => {
            return trees.map((n) => (
                <Marker
                    key={'code' in n ? n.code : n.id}
                    position={{
                        lat: n.location?.latitude ?? 0,
                        lng: n.location?.longitude ?? 0,
                    }}
                    icon={new Icon({
                        iconUrl: '/assets/images/phenology/cherries.png',
                        iconSize: [44,44],
                        iconAnchor: [22, 22],
                        popupAnchor: [0, -22]
                    })}
                >
                    {
                        'code' in n &&
                        <Popup>
                            {
                                onPhenologySpecificCherriesClick &&
                                <Link component={Button} onClick={onPhenologySpecificCherriesClick} id={n.code}>{n.code}</Link>
                            }
                            {
                                !onPhenologySpecificCherriesClick &&
                                <span>{n.code}</span>
                            }
                        </Popup>
                    }
                </Marker>
            ));
        },
    );

    public onLayerRef = () => {
        // TODO: Add to layer control
    }

    public render = () => {
        const locationMarkers = this.getLocationMarkers(this.state, this.props);
        return (
            <LayerGroup ref={this.onLayerRef}>
                {
                    locationMarkers
                }
            </LayerGroup>
        );
    }
}

const PhenologySpecificCherriesLocationMarkers = withLeaflet(PhenologySpecificCherriesLocationMarkersComponent);

export default PhenologySpecificCherriesLocationMarkers;
