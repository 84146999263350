import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

interface IStringArrayDropdownProps {
    label ?: string;
    values : Array<string>;
    value ?: string;

    onChange ?: (value ?: string) => void;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    disabled ?: boolean;
}

interface IStringArrayDropdownState {}

export default class StringArrayDropdown extends React.PureComponent<IStringArrayDropdownProps, IStringArrayDropdownState> {
    constructor(props : IStringArrayDropdownProps) {
        super(props);
        this.state = {};
    }

    private onChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        if (this.props.onChange && typeof event.target.value === 'string') this.props.onChange(event.target.value);
    }

    public render = () => {
        const { values, value = '', required, autoFocus, fullWidth, label, disabled } = this.props;
        return (
            <FormControl required={required} fullWidth={fullWidth} error={required && !value} disabled={disabled}>
                <InputLabel shrink>{label ?? ''}</InputLabel>
                <Select
                    label={label}
                    required={required}
                    autoFocus={autoFocus}
                    fullWidth={fullWidth}
                    value={value}
                    onChange={this.onChange}
                    displayEmpty={!required}
                    error={required && !value}
                    disabled={disabled}
                >
                    {
                        !required &&
                        <MenuItem value=''>
                            <em>All</em>
                        </MenuItem>
                    }
                    {
                        values.map(n => (
                            <MenuItem key={n} value={n}>{n.toLocaleUpperCase()}</MenuItem>
                        ))
                    }
                </Select>
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    }
}
