import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { ITrapType } from '../../../../types/model/masterData/trap/trapType';
import { CROP } from '../../../../appConstants';
import TrapTypeFunctions from '../../../../store/masterData/trap/trapType/functions';

interface ITrapTypeDropdownProps {
    id ?: string;
    value ?: ITrapType | string | null;

    onChange : (value ?: ITrapType | null, event ?: React.ChangeEvent<unknown>) => void;

    trapTypes : Array<ITrapType>;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;
    disabled ?: boolean;
    name ?: string;

    crop : CROP;
    label ?: string;
}

interface ITrapTypeDropdownState {
}

class TrapTypeDropdown extends React.Component<ITrapTypeDropdownProps, ITrapTypeDropdownState> {
    constructor(props : ITrapTypeDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        TrapTypeFunctions.getList();
    }

    private onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        const result = this.props.trapTypes.find(n => n.id === value?.value);

        this.props.onChange(result, event);
    }

    private getData = (state : ITrapTypeDropdownState, props : ITrapTypeDropdownProps) => props.trapTypes;
    private getCrop = (state : ITrapTypeDropdownState, props : ITrapTypeDropdownProps) => props.crop;
    private getValue = (state : ITrapTypeDropdownState, props : ITrapTypeDropdownProps) => props.value;
    private getRequired = (state : ITrapTypeDropdownState, props : ITrapTypeDropdownProps) => props.required;
    private getTrapTypeDropdown = createSelector([
        this.getData,
        this.getRequired,
        this.getCrop,
    ], (
        trapTypes,
        required,
        crop,
    ) => {
        const trapTypesDrop = trapTypes
            .filter(x => x.crop === crop)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(x => ({ label: `${x.name}`, value: x.id }));

        if (!required) {
            trapTypesDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return trapTypesDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getData,
    ], (value, required, trapTypes) => {
        if (typeof(value) === 'object' && value) {
            return {
                label: `${value.name}`,
                value: value.id,
            };
        } else if (value && typeof(value) === 'string') {
            const insect = trapTypes.slice().find(x => x.id === value || x.name === value);

            if (insect) {
                return {
                    label:  `${insect.name}`,
                    value: insect.id,
                };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const { required,  fullWidth, isLoading, disabled, label } = this.props;

        const trapTypes = this.getTrapTypeDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        disabled={isLoading || disabled}
                        id='trap_type_select'
                        options={trapTypes}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChange}
                        disableClearable={required}
                        openOnFocus
                        renderInput={params => <TextField error={required && !value} required={required} {...params} fullWidth={fullWidth} label={label ?? 'Select Type'} />}
                    />
                    {
                        required && !value &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        trapTypes: state.masterData.trap.trapType.types.filter(x => x.isActive),
        isLoading: state.masterData.trap.trapType.isLoading,
    };
};

export default connect(
    mapStateToProps,
)(TrapTypeDropdown);
