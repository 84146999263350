import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import AppFunctionsService from '../../../services/appFunctionServices';

interface IScoutingStartCellProps {
    taskCompletions? : Array<number>;
}

interface IScoutingStartCellState {
}

export default class ScoutingStartCell extends React.PureComponent<IScoutingStartCellProps, IScoutingStartCellState> {
    private mounted : boolean = true;
    constructor(props : IScoutingStartCellProps) {
        super(props);
        this.state = {
        };
    }

    public render = () => {
        const { taskCompletions } = this.props;
        return (
            <span>
                {
                    !!taskCompletions && !!taskCompletions[0] &&
                    <Tooltip title={AppFunctionsService.formatDateTime(taskCompletions[0])}>
                        <div>
                            {
                                AppFunctionsService.formatDateTimeToDateOnly(taskCompletions[0])
                            }
                        </div>
                    </Tooltip>
                }
            </span>
        );
    }
}
