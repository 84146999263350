import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { IScoutingSectionEntryCheck, IScoutingEntryCheckOption, ScoutingEntryCheckType } from '../../../../types/model/masterData/scouting';
import { Button, DialogActions, FormControl, Switch, TextField, Typography } from '@material-ui/core';
import StandardFab from '../../../customComponents/button/StandardFab';
import AddIcon from '@material-ui/icons/Add';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IDamage } from '../../../../types/model/masterData/damage';
import { IDisease } from '../../../../types/model/masterData/disease';
import { IInsect } from '../../../../types/model/masterData/insect';
import { createSelector } from 'reselect';

interface IScoutingEditOptionDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    value : IScoutingSectionEntryCheck | null;

    onClose : (value : IScoutingSectionEntryCheck | null) => void;

    isLoading : boolean;

    insects : Array<IInsect>;
    diseases : Array<IDisease>;
    damages : Array<IDamage>;

    type : ScoutingEntryCheckType;
}

interface IScoutingEditOptionDialogState {
    open : boolean;

    subName : string;
    options : Array<IScoutingEntryCheckOption>;
}

class ScoutingEditOptionDialog extends React.PureComponent<IScoutingEditOptionDialogProps, IScoutingEditOptionDialogState> {
    constructor(props : IScoutingEditOptionDialogProps) {
        super(props);
        this.state = {
            open: false,
            subName: '',
            options: [],
        };
    }
    
    public readonly componentDidUpdate = (prevProps : IScoutingEditOptionDialogProps) => {
        if (this.props.value && !prevProps.value) {
            this.show();

            this.setInitialValues(this.props.value);
        }

        if (!this.props.value && prevProps.value) {
            this.hide();
        }
    }

    public readonly show = () => {
        this.setState({
            open: true,
        });
    }

    public readonly hide = () => {
        this.setState({
            open: false,
        });
    }

    public readonly onClose = () => {
        this.props.onClose(null);
    }

    private readonly setInitialValues = (value : IScoutingSectionEntryCheck) => {
        this.setState({
            subName: value.subName ?? '',
            options: [...value.options.map(n => ({
                ...n,
            }))],
        });
    }

    private readonly onOptionNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const [ index ] = e.currentTarget.name.split('.');
        const options = this.state.options.slice();
        options[Number(index)].name = e.target.value;

        this.setState({
            options,
        });
    }

    private readonly onOptionLimitChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const [ index ] = e.currentTarget.name.split('.');
        const options = this.state.options.slice();
        options[Number(index)].limit = Number(e.target.value);

        this.setState({
            options,
        });
    }

    private readonly onSaveClick = () => {
        if (!this.props.value) return;
        this.props.onClose({
            ...this.props.value,
            options: this.state.options,
            subName: !this.state.subName ? null : this.state.subName,
        });
    }

    private readonly onAddClick = () => {
        this.setState({
            options: [
                ...this.state.options.slice(), {
                    name: '',
                    counter: false,
                    limit: 0,
                }],
        });
    };

    private readonly onRemoveClick = (e : React.MouseEvent<HTMLButtonElement>) => {
        const options = this.state.options.slice();

        options.splice(Number(e.currentTarget.value), 1);
        this.setState({
            options: [
                ...options,
            ],
        });
    };

    private readonly onUpClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.changeOrder(Number(event.currentTarget.value), true);
    };

    private readonly onDownClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.changeOrder(Number(event.currentTarget.value), false);
    };

    private readonly changeOrder = (index : number, up : boolean) => {
        const options = this.state.options.slice();

        if (up && index > 0) {
            const option = options[index];
            const prevOption = options[index - 1];

            options.splice(index, 1, prevOption);
            options.splice(index - 1, 1, option);
        } else if (!up && index < options.length - 1) {
            const option = options[index];
            const prevOption = options[index + 1];

            options.splice(index, 1, prevOption);
            options.splice(index + 1, 1, option);
        }

        this.setState({
            options,
        });
    };

    private readonly onCounterChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const [ index ] = e.currentTarget.name.split('.');
        const options = this.state.options.slice();
        options[Number(index)].counter = !options[Number(index)].counter;
        options[Number(index)].limit = 0;

        this.setState({
            options,
        });
    }
    private readonly getType = (props : IScoutingEditOptionDialogProps) => props.type;
    private readonly getValue = (props : IScoutingEditOptionDialogProps) => props.value;
    private getInsects = (props : IScoutingEditOptionDialogProps) => props.insects;
    private getDiseases = (props : IScoutingEditOptionDialogProps) => props.diseases;
    private getDamages = (props : IScoutingEditOptionDialogProps) => props.damages;
    private getTitle = createSelector(
        [this.getType, this.getValue, this.getInsects, this.getDiseases, this.getDamages,],
        (type, value, insects, diseases, damages) => {
            switch(type) {
            case 'damage':
                return damages.find(x => x.id === value?.ref?.id)?.name ?? '';
            case 'insects':
                return insects.find(x => x.id === value?.ref?.id)?.name ?? '';
            case 'disease':
                return diseases.find(x => x.id === value?.ref?.id)?.name ?? '';
            }
            
            return '';
        },
    );

    private readonly onSubNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            subName: e.target.value,
        });
    }

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, options, subName } = this.state;
        const title = this.getTitle(this.props);
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='scouting-general-option-dialog-title'
                aria-describedby='scouting-general-option-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            {
                                `Edit ${title}`
                            }
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill mt15'>
                    <div className={'fdr jcfs aifs'}>
                        <div className={'fdc flx1 jcfs p5 mb10 pr20'}>
                            <FormControl>
                                <TextField
                                    margin='dense'
                                    id='subName'
                                    label='Sub Name'
                                    type='text'
                                    fullWidth
                                    value={subName}
                                    onChange={this.onSubNameChange}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            OPTIONS
                        </Typography>
                        <span className={'flx1'} />
                        <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                            <AddIcon />
                        </StandardFab>
                    </Toolbar>
                    <div className='fdc jcfs ais'>
                        {
                            options.map((n, i) =>
                                <div key={i} className='fdr ais jcfs'>
                                    <div className='fdr aic mr15'>
                                        <Tooltip title='Up'>
                                            <div>
                                                <IconButton
                                                    value={i}
                                                    size='small'
                                                    disabled={isLoading || i === 0}
                                                    onClick={this.onUpClick}
                                                >
                                                    <Icon>expand_less</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Down'>
                                            <div>
                                                <IconButton
                                                    value={i}
                                                    size='small'
                                                    disabled={isLoading || i === options.length - 1}
                                                    onClick={this.onDownClick}
                                                >
                                                    <Icon>expand_more</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        
                                    </div>
                                    <div className='fdc flx1 mr15'>
                                        <TextField
                                            required
                                            error={!n.name}
                                            margin='dense'
                                            id={`${i}.name`}
                                            name={`${i}.name`}
                                            label='Name'
                                            type='text'
                                            fullWidth
                                            value={n.name}
                                            onChange={this.onOptionNameChange}
                                        />
                                    </div>
                                    <div className='fdr flx1 mr15 aic'>
                                        <Typography className='pr6' >Counter?</Typography>
                                        <Switch
                                            id={`${i}.counter`}
                                            name={`${i}.counter`}
                                            checked={n.counter}
                                            color='primary'
                                            onChange={this.onCounterChange}
                                            inputProps={{
                                                'aria-label': 'Counter',
                                                id: `${i}.counter`,
                                                
                                            }}
                                        />
                                        
                                    </div>
                                    <div className='fdc flx1 mr15'>
                                        {
                                            n.counter &&
                                            <TextField
                                                required
                                                margin='dense'
                                                id={`${i}.limit`}
                                                name={`${i}.limit`}
                                                label='Limit'
                                                type='number'
                                                fullWidth
                                                value={n.limit}
                                                onChange={this.onOptionLimitChange}
                                            />
                                        }
                                    </div>
                                    <div className='fdr aic'>
                                        <IconButton className='cpr' size='small' value={i} onClick={this.onRemoveClick}>
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading || options.some(n => !n.name)} onClick={this.onSaveClick}>
                        <SaveIcon style={{ marginRight: '10px' }} />
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


const mapStateToProps = (state : IRootState) => {
    return {
        insects: state.masterData.insect.insects,
        diseases: state.masterData.disease.diseases,
        damages: state.masterData.damage.damages,
    };
};

export default connect(
    mapStateToProps,
)(ScoutingEditOptionDialog);