import { withStyles, Fab } from '@material-ui/core';

// tslint:disable-next-line: variable-name
const StandardFab = withStyles(theme => ({
    root: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: theme.palette.primary.dark,
        backgroundColor: '#fd0',
        '&:hover': {
            backgroundColor: '#fd0',
        },
    },
}))(Fab);

export default StandardFab;
