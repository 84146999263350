import { Card, Icon, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { createSelector } from 'reselect';
import { INematodeSurveyType } from '../../../../types/model/masterData/nematode/survey';
import MasterDataNematodeNematodeSurveyTypeFunctions from '../../../../store/masterData/nematode/surveyType/functions';
import GeneralFunctions from '../../../../store/general/functions';
import MaterialTable from '../../../customComponents/materialTable/Table';
import AppFunctionsService from '../../../../services/appFunctionServices';
import StandardFab from '../../../customComponents/button/StandardFab';
import { IRootState } from '../../../../@types/redux';
import NematodeSurveyTypeEditDialog from './dialog/Edit';
import { CROP } from '../../../../appConstants';

interface INematodeSurveyTypeListProps extends RouteComponentProps {
    surveyTypes : Array<INematodeSurveyType>;
    isLoading : boolean;
}

interface INematodeSurveyTypeListState {
    selected : INematodeSurveyType |null;
}

class NematodeSurveyTypeListComponent extends React.PureComponent<INematodeSurveyTypeListProps, INematodeSurveyTypeListState> {
    constructor(props : INematodeSurveyTypeListProps) {
        super(props);
        this.state = {
            selected: null,
        };
    }

    public readonly componentDidMount = () => {
        this.load();
    }

    public readonly load = () => {
        MasterDataNematodeNematodeSurveyTypeFunctions.getList();
    }

    private getSearch = (props : INematodeSurveyTypeListProps) => props.location.search;
    private getCrop = createSelector(
        [this.getSearch],
        (search) => {
            const urlParams = new URLSearchParams(search);
            if (urlParams.has('crop')) {
                const crop = urlParams.get('crop');
    
                if (crop) {
                    return crop as CROP;
                }
            }

            return null;
        },
    );

    private readonly onAddClick = () => {
        this.setState({
            selected: {
                name: '',
                crop: this.getCrop(this.props),
                images: [],
                isActive: true,
            } as INematodeSurveyType,
        });
    };

    private readonly getData = (props : INematodeSurveyTypeListProps) => props.surveyTypes;

    private readonly getNematodeSurveyTypes = createSelector(
        [
            this.getData,
            this.getCrop,
        ],
        (surveyTypes, crop) => surveyTypes
            .filter(x => x.crop === crop)
            .sort((a, b) => a.name.localeCompare(b.name)),
    );

    private save = async (surveyType : INematodeSurveyType) => {
        try {
            await MasterDataNematodeNematodeSurveyTypeFunctions.save(surveyType);

            this.setState({
                selected: null,
            });

            GeneralFunctions.generalShowSuccessSnackbar('Saved.');
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error saving survey type.', ex);
        }
    };

    private readonly onEditClose = (surveyType : INematodeSurveyType | null) => {
        if (surveyType) {
            this.save(surveyType);
            return;
        }

        this.setState({
            selected: null,
        });
    }

    private readonly onRowClick = (e : React.MouseEvent<HTMLButtonElement>) => {
        const surveyType = this.props.surveyTypes.find(x => x.id === e.currentTarget.value);

        if (!surveyType) return;

        this.setState({
            selected: {
                ...surveyType,
            },
        });
    }

    public render = () => {
        const { selected } = this.state;
        const { isLoading } = this.props;
        const surveyTypes = this.getNematodeSurveyTypes(this.props);

        return (
            <div className={'fdc hfill mh0 mw0 bcg0'}>
                <div className={'flx1 fdc ml15 mr15 mt20'}>
                    <Card className={'flx1 fdc mb90'}>
                        <MaterialTable<INematodeSurveyType>
                            id='surveyTypeTable'
                            data={surveyTypes}
                            rowsPerPage={50}
                            isLoading={isLoading}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 25,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Edit'>
                                            <div>
                                                <IconButton value={row.id} color='primary' onClick={this.onRowClick} aria-label='info' disabled={isLoading}>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                paddingRight: 4,
                                header: 'Name',
                                field: 'name',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            },        {
                                header: 'Created By',
                                field: 'createdByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.createdOn)}>
                                        <span>{row.createdByName}</span>
                                    </Tooltip>),
                            },        {
                                header: 'Updated By',
                                field: 'updatedByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.updatedOn)}>
                                        <span>{row.updatedByName}</span>
                                    </Tooltip>),
                            },        {
                                header: 'Is Active?',
                                field: 'isActive',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => !n.isActive ? (<Icon className='cpr'>close</Icon>) : (<Icon className='cdg'>check</Icon>),
                            }]}
                        />
                    </Card>
                    <NematodeSurveyTypeEditDialog value={selected} fullWidth maxWidth='md' onClose={this.onEditClose} isLoading={isLoading} />
                    <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                        <AddIcon />
                    </StandardFab>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        surveyTypes: state.masterData.nematode.surveyType.surveyTypes,
        isLoading: state.masterData.nematode.surveyType.isLoading,
    };
};

const NematodeSurveyTypeList = connect(
    mapStateToProps,
)(NematodeSurveyTypeListComponent);

export default NematodeSurveyTypeList;