import { getType } from 'typesafe-actions';
import NematodeSurveyTypeActions from './actions';
import { INematodeSurveyType } from '../../../../types/model/masterData/nematode/survey';
import { MasterDataNematodeNematodeSurveyTypeAction } from '../../../../@types/redux';

export interface IMasterDataNematodeNematodeSurveyTypeState {
    surveyTypes : Array<INematodeSurveyType>;
    isLoading : boolean;

}

const initialState : IMasterDataNematodeNematodeSurveyTypeState = {
    surveyTypes: [],
    isLoading: false,
};

export default function masterDataNematodeNematodeSurveyTypeReducer(state : IMasterDataNematodeNematodeSurveyTypeState = initialState, action : MasterDataNematodeNematodeSurveyTypeAction) : IMasterDataNematodeNematodeSurveyTypeState {
    switch (action.type) {
    case getType(NematodeSurveyTypeActions.setList):
        return { ...state, surveyTypes: action.payload };
    case getType(NematodeSurveyTypeActions.setLoading):
        return { ...state, isLoading: action.payload };
    default:
        return state;
    }
}