import { motion } from 'framer-motion';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import { createSelector } from 'reselect';

interface ICheckToggleButtonProps {
    text : string;
    value : string;

    selected ?: boolean;

    onToggle ?: (value : string) => void;

    warning ?: boolean;
}

interface ICheckToggleButtonState {

}

export default class CheckToggleButton extends React.PureComponent<ICheckToggleButtonProps, ICheckToggleButtonState> {
    constructor(props : ICheckToggleButtonProps) {
        super(props);
        this.state = {};
    }

    private readonly getSelected = (props : ICheckToggleButtonProps) => props.selected;
    private readonly getWarning = (props : ICheckToggleButtonProps) => props.warning;
    private readonly getBackgroundColour = createSelector([
        this.getSelected, this.getWarning,
    ], (selected, warning) => {
        if (warning) return '#F8AE49';
        if (selected) return '#0D703F';
        return '#E0E0E0';
    });
    private readonly getColour = createSelector([
        this.getSelected, this.getWarning,
    ], (selected, warning) => {
        if (warning) return '#FFFFFF';
        if (selected) return '#FFFFFF';
        return '#A7A7A7';
    });

    private readonly onClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        if (this.props.onToggle) this.props.onToggle(this.props.value);
    }

    public readonly render = () => {
        const { text, selected, warning } = this.props;

        const colour = this.getColour(this.props);
        const backgroundColour = this.getBackgroundColour(this.props);
        return (
            <motion.button style={{
                outline: 'none',
                border: 'none',
                cursor: !this.props.onToggle ? '' : 'pointer',
            }}
            initial={{
                color: '#A7A7A7',
                backgroundColor: '#E0E0E0',
            }}
            animate={{
                color: colour,
                backgroundColor: backgroundColour,
            }}
            className='fdr aic fwm fs12 h28 br16 ' type='button' onClick={this.onClick}>
                {
                    !warning &&
                    <div className='aic jcc w17 h17 br16 mr7'style={{
                        color: !selected ? '#E0E0E0' : '#0D703F',
                        backgroundColor: !selected ? '#A7A7A7' : '#FFFFFF',
                    }}>
                        <CheckIcon className='fs12' fontSize='small' />
                    </div>
                }
                <span className={`${!warning ? 'mr7' : 'mr15 ml15'}`}>
                    {
                        text
                    }
                </span>
            </motion.button>
        );
    }
}
