import firebaseApp from '../../../services/firebaseService';
import { BaseHelper, IBase } from '../../base';
import { IScoutingFile } from '../file';
import firebase from 'firebase/app';

export interface IInsect extends IBase {
    name : string;

    images : Array<IScoutingFile> | null;

    subTrapTypes : Array<string>;

    isActive : boolean;
}

export default class InsectHelper extends BaseHelper {
    public static readonly COLLECTION = 'insects';

    public static converter : firebase.firestore.FirestoreDataConverter<IInsect> = {
        fromFirestore: (snapshot) => {
            return InsectHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IInsect) => {
            return InsectHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IInsect {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as IInsect,
            ...result,
            isActive: data.isActive ?? true,
            subTrapTypes: data.subTrapTypes ?? [],
        };
    }
    protected static toFirestore(data : IInsect) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;

        return {
            ...rest,
            ...result,
        };
    }

    public static doc(id ?: string) {
        if (!id) {
            return this.collection().doc();
        }

        return this.collection().doc(id);
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static save(insect : IInsect) {
        if (insect.id) {
            return this.collection().doc(insect.id).set(insect);
        }

        return this.collection().doc().set(insect);
    }
}