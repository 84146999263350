import GeneralFunctions from '../store/general/functions';

export default class NotificationService {
    public static readonly subscribePush = async () => {
        try {
            const registration = await NotificationService.registerServiceWorker();

            return registration;
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error registering notifications', ex);
        }

        return null;
    }

    private static readonly registerServiceWorker = async () => {
        if ('serviceWorker' in navigator) {
            try {
                let serviceWorker = await navigator.serviceWorker.getRegistration('/firebaseMessagingSw.js');

                if (!serviceWorker) {
                    serviceWorker = await navigator.serviceWorker.register('/firebaseMessagingSw.js');
                }

                await serviceWorker.update();
                
                return serviceWorker;
            } catch (ex) {
                console.error('Service Worker Error', ex);
                NotificationService.unregisterServiceWorker();
            }
        }

        return null;
    }

    private static readonly unregisterServiceWorker = async () => {
        if ('serviceWorker' in navigator) {
            try {
                const serviceWorker = await navigator.serviceWorker.getRegistration('./firebaseMessagingSw.js');

                if (serviceWorker) {
                    await serviceWorker.unregister();
                }
            } catch (ex) {
                console.error('Service Worker Error', ex);
            }
        }
    }
}