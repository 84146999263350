import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { Add as AddIcon, Close as CloseIcon, Warning } from '@material-ui/icons';
import { DialogActions, Typography } from '@material-ui/core';
import { CROP } from '../../../../appConstants';
import { IDivision } from '../../../../types/model/rights/division';
import GeneralFunctions from '../../../../store/general/functions';
import { IAssignmentBlock, IBlock } from '../../../../types/model/masterData/block';
import StandardFab from '../../../customComponents/button/StandardFab';
import DivisionAutocomplete from '../../../customComponents/autocomplete/rights/DivisionAutocomplete';
import CultivarAutocomplete from '../../../customComponents/autocomplete/masterData/CultivarAutocomplete';
import FieldAutocomplete from '../../../customComponents/autocomplete/block/FieldNameAutocomplete';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import { createSelector } from 'reselect';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import CheckToggleButton from '../../../customComponents/button/CheckToggleButton';
import { ICultivar } from '../../../../types/model/masterData/cultivars';
import CounterTextField from '../../../customComponents/input/CounterInput';
import PhenologySpecificFunctions from '../../../../store/phenology/specific/functions';

interface IPhenologySpecificAssignmentCreateDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    disabled ?: boolean;

    crop : CROP;

    blocks : Array<IBlock>;

    isLoading : boolean;
}

interface IPhenologySpecificAssignmentCreateDialogState {
    open : boolean;

    divisionCode : string | null;
    divisionName : string | null;
    cultivar : string | null;
    cultivarName : string | null;

    landName : string | null;

    step : boolean;

    selectedBlocks : Array<IAssignmentBlock>;

    trees : Record<string, number>;
}

class PhenologySpecificAssignmentCreateDialogComponent extends React.PureComponent<IPhenologySpecificAssignmentCreateDialogProps, IPhenologySpecificAssignmentCreateDialogState> {
    private readonly initState : IPhenologySpecificAssignmentCreateDialogState = {
        open: false,
        divisionCode: null,
        divisionName: null,
        cultivar: null,
        cultivarName: null,
        landName: null,
        step: false,
        selectedBlocks: [],
        trees: {},
    }

    constructor(props : IPhenologySpecificAssignmentCreateDialogProps) {
        super(props);
        this.state = {
            ...this.initState,
        };
    }

    public readonly onClick = () => {
        this.setState({
            open: true,
        });
    }

    public readonly onClose = () => {
        this.setState({
            ...this.initState,
        });
    }

    private readonly onDivisionChange = (division ?: IDivision | null) => {
        this.setState({
            divisionCode: division?.id ?? null,
            divisionName: division?.name ?? null,
            landName: null,
            trees: {},
        });
    }
    
    private readonly onCultivarChange = (values : Array<ICultivar>) => {
        this.setState({
            cultivar: values[0]?.id ?? null,
            cultivarName: values[0]?.name ?? null,
        });
    }

    private readonly onLandNameChange = (landName ?: string | null) => {
        this.setState({
            landName: landName ?? null,
            trees: {},
        });
    }

    private readonly save = async () => {
        const {
            divisionCode,
            cultivar,
            cultivarName,
            divisionName,
            selectedBlocks,
            trees,
        } = this.state;

        if (!divisionCode
            || !divisionName
            || !cultivar
            || !cultivarName
            || !selectedBlocks.length) return;
        try {

            await PhenologySpecificFunctions.createTrees(
                divisionName,
                cultivar,
                cultivarName,
                selectedBlocks,
                trees,
            );

            this.onClose();
        } catch (_) {
            // Ignore
        }
    }

    private readonly isValid = () => {
        const {
            divisionCode,
            divisionName,
            cultivar,
            cultivarName,
            landName,
            selectedBlocks,
        } = this.state;

        return !!divisionCode
            && !!divisionName
            && !!cultivar
            && !!cultivarName
            && !!landName
            && !!selectedBlocks.length;
    }

    private readonly onSaveClick = () => {
        this.save();
    }

    private readonly onContinueClick = () => {
        if (!this.isValid()) return;

        this.setState({
            step: !this.state.step,
        });
    }

    private readonly onTreeCountChange = (value : number, id ?: string) => {
        if (!id) return;

        this.setState({
            trees: {
                ...this.state.trees,
                [id]: value,
            }
        });
    }

    private readonly getCrop = (props : IPhenologySpecificAssignmentCreateDialogProps, state : IPhenologySpecificAssignmentCreateDialogState) => props.crop;
    private readonly getLandName = (props : IPhenologySpecificAssignmentCreateDialogProps, state : IPhenologySpecificAssignmentCreateDialogState) => state.landName;
    private readonly getDivision = (props : IPhenologySpecificAssignmentCreateDialogProps, state : IPhenologySpecificAssignmentCreateDialogState) => state.divisionCode;
    private readonly getBlocks = (props : IPhenologySpecificAssignmentCreateDialogProps, state : IPhenologySpecificAssignmentCreateDialogState) => props.blocks;
    private readonly getScoutingBlocks = (props : IPhenologySpecificAssignmentCreateDialogProps, state : IPhenologySpecificAssignmentCreateDialogState) => state.selectedBlocks;

    private readonly getBlockNames = createSelector([
        this.getBlocks,
        this.getLandName,
        this.getDivision,
        this.getCrop,
    ], (blocks, landName, division, crop) => {

        if (!division) return [];

        return blocks
            .filter(n => crop === n.crop)
            .filter(n => n.landName === landName)
            .filter(n => division.toLocaleUpperCase() === n.division.toLocaleUpperCase())
            .map(n => n.name)
            .sort();
    });

    private readonly getSelectedBlockNames = createSelector([
        this.getScoutingBlocks,
    ], (blocks) => {
        return blocks
            .map(n => n.name)
            .sort();
    });

    private readonly onBlockNameClick = (blockName : string) => {
        const { blocks, crop } = this.props;
        const { divisionCode, landName } = this.state;

        if (!divisionCode || !landName) return;

        const selectedBlocks = this.state.selectedBlocks.slice();

        const index = selectedBlocks.findIndex(x => x.name === blockName);

        if (index > -1) {
            selectedBlocks.splice(index, 1);
        } else {
            const block = blocks
                .find(x =>
                    x.crop === crop &&
                    x.landName === landName &&
                    x.name === blockName &&
                    divisionCode.toLocaleUpperCase() === x.division.toLocaleUpperCase());

            if (block) selectedBlocks.push(block);
        }

        this.setState({
            selectedBlocks,
        });
    }

    public render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            disabled,
            crop,
            isLoading,
        } = this.props;

        const {
            open,
            divisionCode,
            cultivar,
            cultivarName,
            landName,
            step,
            divisionName,
            trees,
        } = this.state;

        const blockNames = this.getBlockNames(this.props, this.state);
        const selectedBlockNames = this.getSelectedBlockNames(this.props, this.state);
        
        return (
            <React.Fragment>
                <div className='fdr'>
                    <Tooltip title='Create'>
                        <div>
                            <StandardFab aria-label='add' disabled={disabled} onClick={this.onClick}>
                                <AddIcon />
                            </StandardFab>
                        </div>
                    </Tooltip>
                </div>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='create-PhenologySpecificAssignment-dialog-title'
                    aria-describedby='create-PhenologySpecificAssignment-description'>
                    <AppBar className='fdr posr aic jcc bcpd' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography>
                                ADD TREE
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton disabled={isLoading} color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill p0'>
                        {
                            !step &&
                            <>
                                <div className='fdr bcp pt15 pb15 pl25'>
                                    <Typography
                                        className='fwn cw fs15'
                                    >
                                        DIVISION
                                    </Typography>
                                </div>
                                <div className='fdc p10'>
                                    <div className={'fdc flx1 aic'}>
                                        <DivisionAutocomplete
                                            required
                                            fullWidth
                                            value={divisionCode}
                                            onChange={this.onDivisionChange}
                                            crop={crop}
                                            label={'Select Division'}
                                        />
                                    </div>
                                </div>
                                <div className='fdr bcp pt15 pb15 pl25'>
                                    <Typography
                                        className='fwn cw fs15'
                                    >
                                        KULTIVARS
                                    </Typography>
                                </div>
                                <div className='fdc p10'>
                                    <div className={'fdc flx1 aic'}>
                                        <CultivarAutocomplete
                                            required
                                            fullWidth
                                            value={!cultivar ? [] : [cultivar]}
                                            onChange={this.onCultivarChange}
                                            crop={crop}
                                            label='Select Cultivars'
                                            single
                                        />
                                    </div>
                                </div>
                                <div className='fdr bcp pt15 pb15 pl25'>
                                    <Typography
                                        className='fwn cw fs15'
                                    >
                                        ADD FIELDS
                                    </Typography>
                                </div>
                                <div className='fdc p10'>
                                    <div className={'fdc flx1 aic'}>
                                        <FieldAutocomplete
                                            value={landName}
                                            division={divisionCode}
                                            disabled={!divisionCode}
                                            crop={crop}
                                            required
                                            fullWidth
                                            label='Land Name'
                                            onChange={this.onLandNameChange}
                                        />
                                    </div>
                                </div>
                                <div className={'fdc aifs jcc p10 mt5'}>
                                    <Typography className='fs17 fwb cp'>
                                        BLOCK
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 p10'}>
                                    <div className='fdr fww'>
                                        {
                                            blockNames.map(n => (
                                                <div key={n} className='aic jcc pr15 pb15'>
                                                    <CheckToggleButton
                                                        text={n}
                                                        value={n}
                                                        selected={selectedBlockNames.includes(n)}
                                                        onToggle={this.onBlockNameClick}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        {
                            step &&
                            <>
                                <div className='fdr'>
                                    <div className='fdc flx1'>
                                        <Typography className='jcc cw fs14 lh37 bclg'>
                                            DIVISION
                                        </Typography>
                                        <Typography className='jcc fs14 lh37'>
                                            {
                                                divisionName
                                            }
                                        </Typography>
                                    </div>
                                    <div className='fdc flx1'>
                                        <Typography className='jcc cw fs14 lh37 bcig'>
                                            KULTIVAR
                                        </Typography>
                                        <Typography className='jcc fs14 lh37'>
                                            {
                                                cultivarName
                                            }
                                        </Typography>
                                    </div>
                                    <div className='fdc flx1'>
                                        <Typography className='jcc cw fs14 lh37 bcdg'>
                                            FIELD
                                        </Typography>
                                        <Typography className='jcc fs14 lh37'>
                                            {
                                                landName
                                            }
                                        </Typography>
                                    </div>
                                </div>
                                <div className='fdr bcp pt15 pb15 pl25'>
                                    <Typography
                                        className='fwn cw fs15'
                                    >
                                        SELECT TREES PER BLOCK
                                    </Typography>
                                </div>
                                <div className='fdr pt15 pb15 ml5 mr10'>
                                    <div style={{
                                        width: '100%',
                                        display: 'grid',
                                        gridTemplateColumns: '50% 50%',
                                        gridRowGap: 5,
                                        gridColumnGap: 5,
                                    }}>
                                        {
                                            selectedBlockNames.map((block) => (
                                                <div key={`${block}`} className='fdr aic jcc h60 bcg0 pl5 pr5'>
                                                    <div>
                                                        <CheckToggleButton
                                                            text={block}
                                                            value={block}
                                                            selected
                                                        />
                                                    </div>
                                                    <div className='flx1'>
                                                        <CounterTextField
                                                            id={block}
                                                            value={trees[block] ?? 0}
                                                            min={1}
                                                            onChange={this.onTreeCountChange}
                                                            label='Tree Amount'
                                                            disabled={isLoading}
                                                        />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </DialogContent>
                    <DialogActions>
                        {
                            !step &&
                            <div className='fdr'>
                                <StadiumButton className='fw500 fs14 cpd mr15 bw1' variant='text' onClick={this.onClose}>
                                    BACK
                                </StadiumButton>
                                <StadiumButton disabled={!this.isValid()} className='fw500 fs14 cw bcp' onClick={this.onContinueClick}>
                                    CONTINUE
                                </StadiumButton>
                            </div>
                        }
                        {
                            step &&
                            <div className='fdr'>
                                <StadiumButton disabled={isLoading} className='fw500 fs14 cpd mr15 bw1' variant='text' onClick={this.onContinueClick}>
                                        BACK
                                </StadiumButton>
                                <StadiumButton disabled={isLoading} className='fw500 fs14 cw bcp' onClick={this.onSaveClick}>
                                        SAVE
                                </StadiumButton>
                            </div>
                        }
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.general.blocks,
        isLoading: state.phenology.specific.isLoading,
    };
};

const PhenologySpecificAssignmentCreateDialog = connect(
    mapStateToProps,
)(PhenologySpecificAssignmentCreateDialogComponent);

export default PhenologySpecificAssignmentCreateDialog;