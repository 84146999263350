import ListItemText from '@material-ui/core/ListItemText';
import { styled } from '@material-ui/core/styles';

// tslint:disable-next-line: variable-name
const ListItemTextStyled = styled(ListItemText)({
    '& span': {
        font: '400 16px/37px Roboto',
        color: '#0F4323',
        letterSpacing: 0.32,
    },
});

export default ListItemTextStyled;
