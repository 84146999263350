import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { THRESHOLD_TYPE } from '../../../types/model/masterData/threshold';

const useStyles = makeStyles<Theme, IHeaderButtonProps>({
    root: {
        borderRadius: 0,
        background: props => !props.selected || !props.selectedColor ? 'white' : `linear-gradient(45deg, ${props.selectedColor} 30%, ${props.selectedColor} 90%)`,
        color: props => !props.selected || !props.selectedColor ? '#424242' : 'white',
    },
});

interface IHeaderButtonProps {
    children : React.ReactNode;
    selected ?: boolean;
    selectedColor ?: string;

    value ?: THRESHOLD_TYPE;
    onClick ?: (type : THRESHOLD_TYPE) => void;
}

const HeaderButton = (props : IHeaderButtonProps) => {
    const classes = useStyles(props);

    const onClick = useCallback((event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!props.onClick || !props.value) return;

        props.onClick(event.currentTarget.value as THRESHOLD_TYPE);
    }, []);

    return (
        <Button value={props.value} onClick={onClick} className={`flx1 br0 ${classes.root}`} variant='text'>
            {
                props.children
            }
        </Button>
    );
};

export default HeaderButton;
