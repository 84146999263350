import React from 'react';
import { LatLng } from 'leaflet';
import { Map } from 'react-leaflet';
import BlockPolygonLayerGroup from '../../../customComponents/map/BlockPolygonLayerGroup';
import StandardLayerControl from '../../../customComponents/map/StandardLayerControl';
import randomColor from 'randomcolor';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IBlock } from '../../../../types/model/masterData/block';
import { IBlockPolygonLayer } from '../../../../types/model/masterData/blockPolygonLayer';

interface IShapefileUploadMapProps {
    blocks : Array<IBlock>;
}

interface IShapefileUploadMapState {
}

export default class ShapefileUploadMap extends React.PureComponent<IShapefileUploadMapProps, IShapefileUploadMapState> {
    private mapZoom = 8;
    private mapCenter = new LatLng(-23.5520414, 30.1148622);

    constructor(props : IShapefileUploadMapProps) {
        super(props);
        this.state = {
            blocks: [],
        };
    }

    private getPropBlock = (props : IShapefileUploadMapProps) => props.blocks;
    private getBlocks = createSelector(
        [this.getPropBlock],
        (blocks) => {
            const resultList : Array<IBlockPolygonLayer> = [];

            blocks.forEach((b) => {
                const block : IBlockPolygonLayer = {
                    blockName: b.name,
                    color: randomColor({ seed: b.landName }),
                    crop: b.crop,
                    landId: b.landId,
                    id: b.id,
                    landName: b.landName,
                    points: lodash.map(b.points, point => ({
                        color: randomColor({ seed: b.landName }),
                        center: new LatLng(point.latitude, point.longitude),
                    })),
                    positions: [],
                };

                lodash.forEach(b.polygons, (poly, i) => {
                    block.positions[Number(i)] = [];
                    poly.forEach(polyPoint => block.positions[Number(i)].push(new LatLng(polyPoint.latitude, polyPoint.longitude)));
                });

                resultList.push(block);
            });

            return resultList;
        },
    );

    public render = () => {
        const blocks = this.getBlocks(this.props);
        return (
            <Map maxZoom={20} className={'flx1 bcw'} center={this.mapCenter} zoom={this.mapZoom} preferCanvas>
                <StandardLayerControl managementAreas={false} polygonOpacity={0.2} polygonWidth={5} />
                <BlockPolygonLayerGroup blocks={blocks} showMarkers />
            </Map>
        );
    }
}
