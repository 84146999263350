import { IGeneralState, GeneralAction } from '../../@types/redux';
import Actions from './actions';
import { getType } from 'typesafe-actions';

const initialState : IGeneralState = {
    notifications : [],
    isLoadingAreas: false,
    areas: [],
    blocks: [],
    isLoadingBlocks: false,
};

export default function generalReducer(state : IGeneralState = initialState, action : GeneralAction) : IGeneralState {
    switch (action.type) {
    case getType(Actions.enqueueSnackbar):
        return { ...state, notifications: [
            ...state.notifications,
            {
                ...action.payload,
            },
        ] };
    case getType(Actions.removeSnackbar):
        return { ...state,
            notifications: state.notifications.filter(
                notification => notification.key !== action.payload,
            ) };
    case getType(Actions.setAreas):
        return { ...state, areas: action.payload };
    case getType(Actions.setLoadingAreas):
        return { ...state, isLoadingAreas: action.payload };
    case getType(Actions.setBlocks):
        return { ...state, blocks: action.payload };
    case getType(Actions.setLoadingBlocks):
        return { ...state, isLoadingBlocks: action.payload };
    default:
        return state;
    }
}
