import React from 'react';
import { CROP, IKeyValue } from '../../../appConstants';
import { THRESHOLD_TYPE } from '../../../types/model/masterData/threshold';
import { IReportScoutingAssignment } from '../../../types/model/scouting/scoutingAssignment';
import lodash from 'lodash';
import Typography from '@material-ui/core/Typography';
import ScoutingReportHeader from '../components/Header';
import { createSelector } from 'reselect';
import ReportingHelper from '../../../services/helper/reportingHelper';
import ScoutingReportRowView from './ScoutingReportRowView';
import ScoutingReportTotalRow from './ScoutingReportTotalRow';
import ScoutingReportAverageRow from './ScoutingReportAverageRow';
import ScoutingReportPercentageRow from './ScoutingReportPercentageRow';
import ScoutingReportTotalPointsRow from './ScoutingReportTotalPointsRow';
import AssignmentInfoDialog from '../../scouting/dialog/AssignmentInfoDialog';
import { Transitions } from '../../customComponents/animations/Transitions';

interface IScoutingReportBodyViewProps {
    cropType : CROP;
    reportType : THRESHOLD_TYPE;
    assignments : Array<IReportScoutingAssignment>;
    threshold : IKeyValue<number>;
    section ?: string;
    entity ?: string;
}

interface IScoutingReportBodyViewState {
    selectedAssignment ?: IReportScoutingAssignment;
}

export default class ScoutingReportBodyView extends React.PureComponent<IScoutingReportBodyViewProps, IScoutingReportBodyViewState> {
    constructor(props : IScoutingReportBodyViewProps) {
        super(props);
        this.state = {
        };
    }

    private readonly getAssignments = (props : IScoutingReportBodyViewProps) => props.assignments;
    private readonly getReportType = (props : IScoutingReportBodyViewProps) => props.reportType;
    private readonly getSection = (props : IScoutingReportBodyViewProps) => props.section;
    private readonly getEntity = (props : IScoutingReportBodyViewProps) => props.entity;

    private readonly getHeaders = createSelector(
        [this.getAssignments, this.getReportType, this.getSection, this.getEntity],
        (assignments, reportType, sectionString, entity) => {
            return ReportingHelper.getHeaders(assignments, reportType, sectionString, entity);
        },
    );

    private readonly getData = createSelector(
        [this.getHeaders, this.getReportType, this.getAssignments, this.getSection, this.getEntity],
        (headers, reportType, assignments, sectionString, entity) => {
            return ReportingHelper.getReportData(assignments, headers, reportType, sectionString, entity);
        },
    );

    private readonly getTotalData = createSelector(
        [this.getData],
        (rowData) => {
            const result = ReportingHelper.getTotalData(rowData);

            return result;
        },
    );

    private readonly getAverageData = createSelector(
        [this.getData],
        (rowData) => {
            const result = ReportingHelper.getAverageData(rowData);

            return result;
        },
    );

    private readonly getTotalPoints = createSelector(
        [this.getData],
        (rowData) => {
            const result = ReportingHelper.getTotalPoints(rowData);

            return result;
        },
    );

    public onPointClick = (assignmentId : string) => {
        const selectedAssignment = this.props.assignments.slice().find(x => x.id === assignmentId);
        this.setState({
            selectedAssignment,
        });
    }

    private readonly onAssignmentInfoClose = () => {
        this.setState({
            selectedAssignment: undefined,
        });
    }

    public render = () => {
        const { cropType, reportType, threshold } = this.props;
        const { selectedAssignment } = this.state;
        const headers = this.getHeaders(this.props);
        const rowData = this.getData(this.props);
        const totalData = this.getTotalData(this.props);
        const totalPoints = this.getTotalPoints(this.props);
        const averageData = this.getAverageData(this.props);
        return (
            <div className='fdc flx1'>
                <div className='fdr mnh47 bnl'>
                    <div className='fdr aic jcc mnw180 mxw180 bclg'>
                        <Typography className='fw500 cw ttu fs16-21'>{cropType}</Typography>
                    </div>
                    {
                        Object.keys(headers).map((n) => (
                            <ScoutingReportHeader type={reportType} key={`header_${n}`}>
                                {n}
                            </ScoutingReportHeader>
                        ))
                    }
                </div>
                <div className='fdr mnh32 bnl bbw1 bocg5'>
                    <div className='fdr aic jcc mnw180 mxw180'>
                    </div>
                    {
                        lodash.map(headers, (subs, header) => (
                            <div style={{
                                backgroundColor: '#C0DADB',
                            }} className='flx1 fwn fs13-18 lsp02 tac brw1 bocg4' key={`sub_header_${header}`}>
                                {
                                    subs.map(s => (
                                        <div className='flx1 aic jcc brw1 bocg3' key={`sub_header_${header}_${s}`}>
                                            {
                                                header === 'Mite' &&
                                                s[0]

                                            }
                                            {
                                                header !== 'Mite' &&
                                                (s.split(' ')[1] ?? s[0])
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
                <div className='oyo report'>
                    <div>
                        {
                            Object.keys(rowData).map(d => (
                                <ScoutingReportRowView key={`row_${d}`}
                                    title={d}
                                    rowData={rowData[d]}
                                    threshold={threshold}
                                    reportType={reportType}
                                    loopCount={0}
                                    cropType={cropType}
                                    onPointClick={this.onPointClick} />
                            ))
                        }
                    </div>
                </div>
                <span className='flx1' />
                <ScoutingReportTotalRow rowData={totalData} headers={headers} />
                <ScoutingReportTotalPointsRow rowData={totalPoints} headers={headers} />
                <ScoutingReportAverageRow rowData={averageData} headers={headers} />
                <ScoutingReportPercentageRow rowData={averageData} headers={headers} />
                {
                    !!selectedAssignment &&
                    <AssignmentInfoDialog value={selectedAssignment} fullWidth
                        transition={Transitions.Up} maxWidth='md' fullScreen
                        defaultOpen onClosed={this.onAssignmentInfoClose} />
                }
            </div>
        );
    }
}
