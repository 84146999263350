import React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import 'leaflet/dist/leaflet.css';
import { LatLng, polygon } from 'leaflet';
import { Map as LMap } from 'react-leaflet';
import { createSelector } from 'reselect';
import { BlockHelper, IBlock } from '../../../../types/model/masterData/block';
import { DispatchCall, IRootState, RootAction } from '../../../../@types/redux';
import { CROP, DEFAULT_MAP_CENTER } from '../../../../appConstants';
import { IPhenologySpecific, PhenologySpecificTreeType } from '../../../../types/model/phenology/specific';
import PhenologySpecificFunctions from '../../../../store/phenology/specific/functions';
import GeneralFunctions from '../../../../store/general/functions';
import { IBlockPolygonLayer } from '../../../../types/model/masterData/blockPolygonLayer';
import StandardLayerControl from '../StandardLayerControl';
import BlockPolygonLayerGroup from '../BlockPolygonLayerGroup';
import PhenologySpecificCherriesLocationMarkers from './CherriesSpecificMarkersLayer';
import PhenologySpecificActions from '../../../../store/phenology/specific/actions';

interface IPhenologySpecificBlocksMapProps {
    isLoading : boolean;
    specifics : Array<IPhenologySpecific>;
    blocks : Array<IBlock>;

    setLoading : DispatchCall<boolean>;

    cropType ?: CROP | null;
    selectedCode ?: string | null;
    divisionCode ?: string | null;
    blockId ?: string | null;
    landName ?: string | null;
    
    onPhenologySpecificClick ?: (tree : PhenologySpecificTreeType, event : React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

interface IPhenologySpecificBlocksMapState {}

class PhenologySpecificBlocksMapComponent extends React.PureComponent<IPhenologySpecificBlocksMapProps, IPhenologySpecificBlocksMapState> {
    private readonly mapRef ?: React.RefObject<LMap>;

    private readonly mapZoom = 8;
    private readonly mapCenter : LatLng = DEFAULT_MAP_CENTER;

    constructor(props : IPhenologySpecificBlocksMapProps) {
        super(props);
        this.state = {};

        this.mapRef = React.createRef();
    }
    
    public readonly componentDidMount = () => {
        PhenologySpecificFunctions.listen();
        GeneralFunctions.getBlocks();
    }
    
    public readonly componentDidUpdate = (prevProps : Readonly<IPhenologySpecificBlocksMapProps>) => {
        if (prevProps.isLoading && !this.props.isLoading) {
            this.onInit();
        }

        if (!this.props.isLoading && prevProps.divisionCode !== this.props.divisionCode) {
            this.onDivisionBlockLandChange();
        }

        if (!this.props.isLoading && prevProps.landName !== this.props.landName) {
            this.onDivisionBlockLandChange();
        }

        if (!this.props.isLoading && prevProps.blockId !== this.props.blockId) {
            this.onDivisionBlockLandChange();
        }
    }

    public readonly componentWillUnmount = () => {
        PhenologySpecificFunctions.unsubscribe();
    }

    private readonly flyToPhenologySpecific = (tree ?: PhenologySpecificTreeType, zoom ?: number) => {
        if (this.mapRef?.current && tree?.location) {
            this.mapRef.current.leafletElement.flyTo(
                new LatLng(tree.location.latitude, tree.location.longitude),
                zoom,
            );
        }
    }

    private readonly flyToBlock = (block ?: IBlock) => {
        if (this.mapRef?.current && block?.polygons) {
            this.mapRef.current.leafletElement.flyToBounds(
                polygon(BlockHelper.toLayer(block).positions).getBounds(),
            );
        }
    }

    private readonly onDivisionBlockLandChange = () => {
        this.flyToBlock(this.props.blocks.find(x =>
            (!this.props.blockId || x.id === this.props.blockId)
            && (!this.props.cropType || x.crop === this.props.cropType)
            && (!this.props.landName || x.landName === this.props.landName)
            && (!this.props.divisionCode || x.division.toLocaleLowerCase() === this.props.divisionCode.toLocaleLowerCase())
        ));
    }

    private readonly getData = (state : IPhenologySpecificBlocksMapState, props : IPhenologySpecificBlocksMapProps) => props.specifics;
    private readonly getDataBlocks = (state : IPhenologySpecificBlocksMapState, props : IPhenologySpecificBlocksMapProps) => props.blocks;
    private readonly getCropType = (state : IPhenologySpecificBlocksMapState, props : IPhenologySpecificBlocksMapProps) => props.cropType;
    private readonly getDivision = (state : IPhenologySpecificBlocksMapState, props : IPhenologySpecificBlocksMapProps) => props.divisionCode;
    private readonly getLandName = (state : IPhenologySpecificBlocksMapState, props : IPhenologySpecificBlocksMapProps) => props.landName;
    private readonly getBlockId = (state : IPhenologySpecificBlocksMapState, props : IPhenologySpecificBlocksMapProps) => props.blockId;

    private readonly getTrees = createSelector([
        this.getData,
    ], (
        specifics,
    ) => {
        return specifics.map(x => x.trees.map(z => ({
            ...x,
            ...z,
        })) ?? []).flatMap(x => x);
    });

    private readonly getPhenologySpecifics = createSelector([
        this.getTrees,
        this.getCropType,
        this.getDivision,
        this.getLandName,
        this.getBlockId,
    ], (
        trees,
        cropType,
        divisionCode,
        landName,
        blockId,
    ) => {
        return trees
            .filter(x => !!x.location)
            .filter(x => !cropType || cropType === x.crop)
            .filter(x => !divisionCode || divisionCode === x.division)
            .filter(x => !landName || landName === x.landName)
            .filter(x => !blockId || blockId === x.block);
    });

    private readonly getBlocks = createSelector([
        this.getDataBlocks,
        this.getDivision,
        this.getLandName,
        this.getBlockId,
        this.getCropType,
    ],
    (
        blocks,
        division,
        landName,
        blockNames,
        crop,
    ) => {
        const layer : Array<IBlockPolygonLayer> = blocks
            .filter(n => !division || division.toLocaleLowerCase() === n.division.toLocaleLowerCase())
            .filter(n => !landName || n.landName === landName)
            .filter(n => !blockNames || !blockNames.length || blockNames.includes(n.name))
            .filter(n => !crop || n.crop === crop)
            .map(n => BlockHelper.toLayer(n));

        return layer;
    },
    );

    private readonly onInit = () => {
        this.flyToPhenologySpecific(this.getTrees(this.state, this.props).find(x => x.code === this.props.selectedCode), 18);
    }

    private readonly onPhenologySpecificClick = (event : React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (!this.props.onPhenologySpecificClick) return;
        const tree = this.getTrees(this.state, this.props).find(x => x.id === event.currentTarget.id);
        if (!tree) return;

        this.props.onPhenologySpecificClick(tree, event);
    }

    public readonly render = () => {
        const crop = this.getCropType(this.state, this.props);
        const filteredPhenologySpecifics = this.getPhenologySpecifics(this.state, this.props);
        const blocks = this.getBlocks(this.state, this.props);

        return (
            <LMap
                ref={this.mapRef}
                maxZoom={20}
                className={'flx1 bcw'}
                center={this.mapCenter}
                zoom={this.mapZoom}
                preferCanvas
            >
                <StandardLayerControl
                    polygonOpacity={0.2}
                    polygonWidth={5}
                />
                <BlockPolygonLayerGroup zIndex={5} blocks={blocks} />
                {
                    crop === 'cherries' &&
                    <PhenologySpecificCherriesLocationMarkers
                        trees={filteredPhenologySpecifics}
                        onPhenologySpecificCherriesClick={this.onPhenologySpecificClick}
                    />
                }
            </LMap>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.general.blocks,
        isLoading: state.phenology.specific.isLoading || state.general.isLoadingBlocks,
        specifics: state.phenology.specific.specifics,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators({
    setLoading: (isLoading : boolean) => dispatcher(PhenologySpecificActions.setLoading(isLoading)),
}, dispatcher);

const PhenologySpecificBlocksMap = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PhenologySpecificBlocksMapComponent);

export default PhenologySpecificBlocksMap;