import { ActionType, getType } from 'typesafe-actions';
import MasterDataGeoServerActions from './actions';
import { IGeoServerBlock } from '../../../types/model/masterData/geoserver/block';

export interface IMasterDataGeoServerState {
    blocks : Array<IGeoServerBlock>;
    isLoadingBlocks : boolean;

}

const initialState : IMasterDataGeoServerState = {
    blocks: [],
    isLoadingBlocks: false,
};

export default function masterDataGeoServerReducer(state : IMasterDataGeoServerState = initialState, action : ActionType<typeof MasterDataGeoServerActions>) : IMasterDataGeoServerState {
    switch (action.type) {
        case getType(MasterDataGeoServerActions.setBlockList):
            return { ...state, blocks: action.payload };
        case getType(MasterDataGeoServerActions.setLoadingBlockList):
            return { ...state, isLoadingBlocks: action.payload };
        default:
            return state;
    }
}