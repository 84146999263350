import React from 'react';
import { IAuthState } from '../../../@types/redux';
import { Avatar, Link } from '@material-ui/core';
import UserNotificationSettingsDialog from '../../right/user/dialog/NotificationSettings';
import { Transitions } from '../animations/Transitions';

interface IUserAvatarProps {
    auth : IAuthState;
}

interface IUserAvatarState {
    notificationSettingsOpen : boolean;
}

export default class UserAvatar extends React.PureComponent<IUserAvatarProps, IUserAvatarState> {
    constructor(props : IUserAvatarProps) {
        super(props);
        this.state = {
            notificationSettingsOpen: false,
        };
    }

    public readonly onToggleNotificationSettings = () => {
        this.setState({
            notificationSettingsOpen: !this.state.notificationSettingsOpen,
        });
    }

    public readonly render = () => {
        const { auth } = this.props;
        const {
            notificationSettingsOpen,
        } = this.state;

        return (
            <div className={'fdr aic'}>
                <Link component='button'
                    variant='h6'
                    color='inherit'
                    onClick={this.onToggleNotificationSettings}
                >
                    {auth.session?.user.name}
                </Link>
                <Avatar
                    className='cursorPointer ml20'
                    alt={auth.session?.firebaseUser.displayName ?? ''}
                    src={auth.session?.firebaseUser.photoURL ?? '/broken-image.jpg'}
                    onClick={this.onToggleNotificationSettings}
                />
                {
                    !!auth.session &&
                    <UserNotificationSettingsDialog
                        open={notificationSettingsOpen}
                        onClose={this.onToggleNotificationSettings}
                        fullWidth
                        maxWidth='md'
                        transition={Transitions.ZoomIn}
                        user={auth.session.firebaseUser}
                    />
                }
            </div>
        );
    }
}
