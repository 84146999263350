import { Accordion, AccordionDetails, AccordionSummary, AppBar, ButtonBase, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import { ExpandMore, Warning } from '@material-ui/icons';
import React from 'react';
import getPreciseDistance from 'geolib/es/getPreciseDistance';
import { PhenologySpecificTreeType } from '../../../../types/model/phenology/specific';
import { IPhenologySpecificTreeEntry, PhenologySpecificTreeEntryHelper } from '../../../../types/model/phenology/specificEntry';
import { IUserSession } from '../../../../types/model/user';
import { IGeoPoint } from '../../../../types/model/geoPoint';
import { createSelector } from 'reselect';
import AppFunctionsService from '../../../../services/appFunctionServices';
import UseLocationDialog from '../../../trap/dialog/UseLocationDialog';
import StadiumDiv from '../../../customComponents/div/Stadium';
import PhenologySpecificFunctions from '../../../../store/phenology/specific/functions';
import TabPanel from '../../../customComponents/tab/TabPanel';
import PhenologySpecificTreeEntryBranchView from './SurveyEntryBranchView';

interface IPhenologySpecificTreeEntryViewProps {
    tree : PhenologySpecificTreeType;
    value : IPhenologySpecificTreeEntry;
    
    session ?: IUserSession | null;
}

interface IPhenologySpecificTreeEntryViewState {
    expanded : boolean;

    selectedTab : string;
}

export default class PhenologySpecificTreeEntryView extends React.PureComponent<IPhenologySpecificTreeEntryViewProps, IPhenologySpecificTreeEntryViewState> {
    private readonly accuracyLimitMeters = 15;

    constructor(props : IPhenologySpecificTreeEntryViewProps) {
        super(props);
        this.state = {
            expanded: false,
            selectedTab: PhenologySpecificTreeEntryHelper.sections[0],
        };
    }

    private readonly saveLocation = async (location : IGeoPoint, elevation : number) => {
        const { tree } = this.props;
        
        try {
            await PhenologySpecificFunctions.updateLocation(tree, location, elevation);
        } catch (ex) {
            // Ignore
        }
    }

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : string) => {
        this.setState({
            selectedTab: value,
        });
    }

    private readonly onLocationEditClose = (location : IGeoPoint | null, elevation : number | null) => {
        if (!location) return;
        if (!elevation) return;

        this.saveLocation(location, elevation);
    }

    private readonly onExpansionChange = (event : React.ChangeEvent<unknown>, expanded : boolean) => {
        this.setState({
            expanded,
        });
    }

    private readonly getTree = (state : IPhenologySpecificTreeEntryViewState, props : IPhenologySpecificTreeEntryViewProps) => props.tree;
    private readonly getValue = (state : IPhenologySpecificTreeEntryViewState, props : IPhenologySpecificTreeEntryViewProps) => props.value;

    private readonly getDistance = createSelector(
        [
            this.getTree,
            this.getValue,
        ],
        (
            trap,
            value,
        ) => getPreciseDistance({
            latitude: trap.location?.latitude ?? 0,
            longitude: trap.location?.longitude ?? 0,
        },{
            latitude: value.location.latitude,
            longitude: value.location.longitude,
        },
        ),
    );

    private readonly getCenter = createSelector(
        [this.getValue],
        (value) => {
            return {
                lat: value.location.latitude,
                lng: value.location.longitude,
            };
        },
    );

    public readonly render = () => {
        const { value, tree, session } = this.props;
        const { expanded, selectedTab, } = this.state;

        const distance =  this.getDistance(this.state, this.props);

        const center = this.getCenter(this.state, this.props);

        return (
            <Accordion expanded={expanded} onChange={this.onExpansionChange}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    className={`${expanded ? 'bcp' : ''}`}
                    IconButtonProps={{
                        className: `${expanded ? 'cw' : 'cg3'}`,
                    }}
                >
                    <div className='fdr aic flx1'>
                        <Typography className={`fwm fs16 ${expanded ? 'cw' : 'cg3'}`}>
                            {
                                AppFunctionsService.formatDateTimeToDateOnly(value.date)
                            }
                        </Typography>
                        <span className='flx1'></span>
                        {
                            session?.user.permissions.isTrapAdmin &&
                            tree.location &&
                            distance > 0 &&
                            <div className='fdc mr10'>
                                <UseLocationDialog
                                    oldLocation={tree.location}
                                    oldElevation={tree.elevation ?? 0}
                                    newLocation={value.location}
                                    newElevation={value.elevation}
                                    onClose={this.onLocationEditClose}
                                    buttonClassName={expanded ? 'cp bcw' : 'cw bcp'}
                                />
                            </div>
                        }
                        <StadiumDiv className={expanded ? 'cg3 bcw' : 'cg3 bcg1'}>
                            <div className='fdr'>
                                <Typography className='fwr fs15'>
                                    Location difference:
                                </Typography>
                                <Typography className={`fwm fs15 ${expanded ? 'cpr' : ''}`}>
                                    &nbsp;&nbsp;
                                    {
                                        `${distance.toFixed(0)} m`
                                    }
                                </Typography>
                            </div>
                        </StadiumDiv>
                        {
                            value.locationAccuracy > this.accuracyLimitMeters &&
                            <div className='ml10'>
                                <Tooltip title={`Location accuracy low! ${value.locationAccuracy.toFixed(0)}m`}>
                                    <Warning className={`${expanded ? 'cy' : 'cpr'}`} />
                                </Tooltip>
                            </div>
                        }
                    </div>
                </AccordionSummary>
                <AccordionDetails className={'fdc p0'}>
                    <AppBar elevation={0} className='fdc posr ais bcpd' position='static'>
                        <Tabs value={selectedTab} variant='fullWidth' onChange={this.onTabChange} aria-label='SectionTabs'>
                            {
                                PhenologySpecificTreeEntryHelper.sections.map(section => (
                                    <Tab key={section} value={section} label={section.toLocaleUpperCase()} />
                                ))
                            }
                        </Tabs>
                    </AppBar>
                    <div className='fdc flx1 bcg0 p15'>
                        {
                            PhenologySpecificTreeEntryHelper.sections.map(section => (
                                <TabPanel key={section} value={selectedTab} index={section}>
                                    <PhenologySpecificTreeEntryBranchView
                                        branches={value.branches[section] ?? []}
                                    />
                                </TabPanel>
                            ))
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }
}
