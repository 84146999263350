import React from 'react';
import L from 'leaflet';
import { withLeaflet, LeafletContext } from 'react-leaflet';
interface ILegendLayerProps {
    leaflet : LeafletContext;

    legends : Array<{
        color : string;
        text : string;
    }>;
}

interface ILegendLayerState {}

class LegendLayer extends React.PureComponent<ILegendLayerProps, ILegendLayerState> {
    private legend : L.Control;
    constructor(props : ILegendLayerProps) {
        super(props);

        this.state = {};
        this.legend = new L.Control({ position: 'bottomright' });
    }

    public componentWillUnmount() {
        this.props.leaflet.map?.removeControl(this.legend);
    }

    public componentDidUpdate() {
        this.renderLegend();
    }

    private renderLegend() {
        this.props.leaflet.map?.removeControl(this.legend);

        this.legend.onAdd = () => {
            const div = L.DomUtil.create('div', 'info legend');
            div.onwheel = (event) => {
                event.stopPropagation();
            };

            this.props.legends.forEach((n) => {
                const legendDiv = document.createElement('div');
                legendDiv.setAttribute('class', 'fdr aic');

                const legendColour = document.createElement('div');
                legendColour.setAttribute('class', 'square mr5');
                legendColour.style.backgroundColor = n.color;
                legendDiv.appendChild(legendColour);

                const legendText = document.createElement('div');
                legendText.append(`${n.text}`);
                legendDiv.appendChild(legendText);
                div.appendChild(legendDiv);
            });

            if (!this.props.legends.length) {
                div.setAttribute('class', 'dn');
            }

            return div;
        };

        this.props.leaflet.map?.addControl(this.legend);
    }

    public render = () => {
        return (<div></div>);
    }
}

export default withLeaflet(LegendLayer);
