import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Popper } from '@material-ui/core';
import { CROP } from '../../../appConstants';
import TrapTypeDropdown from '../dropdowns/trap/TrapTypeDropdown';
import { ITrapType } from '../../../types/model/masterData/trap/trapType';

interface IScoutingTrapTypeFilterProps {
    id ?: string;

    value ? : ITrapType | null;

    onChange : (value ?: ITrapType | null) => void;

    cropType : CROP;
}

interface IScoutingTrapTypeFilterState {
    open : boolean;
}

export default class ScoutingTrapTypeFilter extends React.PureComponent<IScoutingTrapTypeFilterProps, IScoutingTrapTypeFilterState> {
    private readonly anchorEl : React.RefObject<HTMLButtonElement>;

    constructor(props : IScoutingTrapTypeFilterProps) {
        super(props);
        this.state = {
            open: false,
        };

        this.anchorEl = React.createRef<HTMLButtonElement>();
    }

    private readonly onClick = () => {
        this.setState({ open: !this.state.open });
    }

    private readonly onClose = (event : React.MouseEvent<Document, MouseEvent>) => {
        if (this.anchorEl.current?.contains(event.target as any)) {
            return;
        }

        event.preventDefault();

        this.setState({ open: false });
    }

    public readonly render = () => {
        const { id, value, cropType, onChange } = this.props;
        const { open } = this.state;
        return (
            <span>
                <IconButton
                    aria-owns={`filter-menu-${id}`}
                    aria-haspopup='true'
                    onClick={this.onClick}
                    ref={this.anchorEl}
                    style={{
                        color: !value ? '' : '#FEE733',
                    }}
                    color='inherit'>
                    <FilterListIcon fontSize='small' />
                </IconButton>
                <Popper anchorEl={this.anchorEl.current} open={open} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', marginTop: 8 }}
                        >
                            <Paper id={`filter-menu-${id}`}>
                                <ClickAwayListener onClickAway={this.onClose}>
                                    <div className='fdc ais jcfs'>
                                        <div className='fdc aic jcfs pl20 pr20 pb5 pt5 w240'>
                                        <TrapTypeDropdown
                                            value={value}
                                            crop={cropType}
                                            fullWidth
                                            onChange={onChange} />
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </span>
        );
    }
}
