import { StyleSheet } from '@react-pdf/renderer';

const pdfStyles = StyleSheet.create({
    body: {
        fontFamily: 'Roboto',
        overflow: 'hidden',
    },

    /* Flex */
    flx1: { flexGrow: 1, display: 'flex' },

    /* Flex Direction */
    fdc: { flexDirection: 'column', display: 'flex' },

    /* Flex Wrap */
    jcc: { justifyContent: 'center' },

    /* Align */
    aic: { alignItems: 'center' },

    /* Height */

    /* Width */

    /* Margin */
    m0: { margin: 0 },

    /* Margin Top */
    mt15: { marginTop: 15 },

    /* FontSize */
    fs8: { fontSize: 8 },

    /* Font Weight */
    fwm: { fontWeight: 500 },

    /* COLOR */
    cdg: { color: '#424242', }
});

export default pdfStyles;

export const table = StyleSheet.create({
    container: {
        display: 'flex',
        width: 'auto',
        borderStyle: 'solid',
        borderColor: '#000',
        borderWidth: 1,
        borderRightWidth: 0,
        borderLeftWidth: 0,
    },
    row: {
        // margin: 'auto',
        flexDirection: 'row',
    },
    colHeader: {
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    col: {
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    cellHeader: {
        fontSize: 12,
        fontWeight: 700,
        paddingHorizontal: 3,
        paddingVertical: 3,
    },
    cell: {
        fontSize: 8,
        borderStyle: 'solid',
        borderColor: '#000',
        borderRightWidth: 1,
        justifyContent: 'center',
    },
    numberCell: {
        fontSize: 8,
        borderStyle: 'solid',
        borderColor: '#000',
        borderRightWidth: 1,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    cellContent: {
        marginVertical: 3,
        marginHorizontal: 3,
    },
});
