import React from 'react';
import HorizontalExpander from '../../customComponents/expander/HorizontalExpander';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import { createSelector } from 'reselect';
import { IAssignmentBlock } from '../../../types/model/masterData/block';
import AssignmentFieldView from '../../assignment/AssignmentFieldView';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IPhenologyAssignment, PHENOLOGY_ASSIGNMENT_TYPE } from '../../../types/model/phenology/assignment';

interface IPhenologyAssignmentFieldsViewProps {
    isLoading ?: boolean;
    date ?: moment.Moment;
    landName ?: string;
    division ?: string;
    type ?: PHENOLOGY_ASSIGNMENT_TYPE | null;
    assignmentBlocks : Record<string, Array<IAssignmentBlock>>;

    onAddFieldsClick ?: () => void;
    onDeleteClick ?: (landName : string) => void;
    onZoomClick ?: (landName : string) => void;
    onEditClick ?: (landName : string) => void;

    assignments : Array<IPhenologyAssignment>;
}

interface IPhenologyAssignmentFieldsViewState {
}

class PhenologyAssignmentFieldsViewComponent extends React.PureComponent<IPhenologyAssignmentFieldsViewProps, IPhenologyAssignmentFieldsViewState> {
    constructor(props : IPhenologyAssignmentFieldsViewProps) {
        super(props);
        this.state = {
        };
    }

    private readonly getPhenologyBlocks = (props : IPhenologyAssignmentFieldsViewProps) => props.assignmentBlocks;
    private readonly getAssignment = (props : IPhenologyAssignmentFieldsViewProps) => props.assignments;
    private readonly getDate = (props : IPhenologyAssignmentFieldsViewProps) => props.date;

    private readonly getPhenologyFieldNames = createSelector([
        this.getPhenologyBlocks,
    ], (scoutingBlocks) => {
        return Object.keys(scoutingBlocks).sort();
    });

    private readonly getDatedAssignment = createSelector([
        this.getAssignment, this.getDate,
    ], (assignments, date) => {
        return assignments
            .filter(x => moment.utc(x.date).isSame(date));
    });

    private readonly getAlreadyScoutedBlockNames =
    (landName : string, blocks : Array<IAssignmentBlock>) => {
        const assignments = this.getDatedAssignment(this.props);
        return assignments
            .filter(x => x.landName === landName)
            .filter(x => blocks.some(n => n.name === x.blockName))
            .map(n => n.blockName).sort();
    };

    public render = () => {
        const { division, date, type, isLoading, assignmentBlocks,
            onDeleteClick, onZoomClick, onEditClick, onAddFieldsClick } = this.props;

        const scoutingFields = this.getPhenologyFieldNames(this.props);

        return (
            <HorizontalExpander initiallyExpanded title='FIELDS' color={date && division && type ? 'primary' : 'secondary'}
                disabled={!date || !division || !type || isLoading}>
                <div className='fdc pt5 pb5 pl15 pr15'>
                    <div className='fdr aic jcfe'>
                        <Button
                            onClick={onAddFieldsClick}
                            className='cp fwm fs14'
                            startIcon={
                                <div className='h24 w24 bcp brh'>
                                    <AddIcon color='secondary' />
                                </div>
                            }
                        >
                            ADD
                        </Button>
                    </div>
                    {
                        scoutingFields.map(fieldName => (
                            <div key={`${fieldName}_field`} className='mb10'>
                                <AssignmentFieldView
                                    name={fieldName}
                                    scoutingBlocks={assignmentBlocks[fieldName]}
                                    onDeleteClick={onDeleteClick}
                                    onZoomClick={onZoomClick}
                                    onEditClick={onEditClick}
                                    excludedBlocks={this.getAlreadyScoutedBlockNames(fieldName, assignmentBlocks[fieldName])} />
                            </div>
                        ))
                    }
                </div>
            </HorizontalExpander>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        assignments: state.phenology.general.assignments,
    };
};

const PhenologyAssignmentFieldsView = connect(
    mapStateToProps,
)(PhenologyAssignmentFieldsViewComponent);

export default PhenologyAssignmentFieldsView;
