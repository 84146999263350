import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createSelector } from 'reselect';
import { LatLng } from 'leaflet';
import { INematodeAssignment } from '../../../../types/model/nematode/nematodeAssignment';
import BlockMap from '../../../customComponents/map/BlockMap';
import AppFunctionsService from '../../../../services/appFunctionServices';
import { INematodeAssignmentLocationEntry, NematodeAssignmentLocationEntryHelper } from '../../../../types/model/nematode/nematodeAssignmentLocationEntry';
import GeneralFunctions from '../../../../store/general/functions';
import AssignmentLocationMarkerLayer from '../../../customComponents/map/assignment/LocationEntriesMarkersLayer';
import AssignmentFinishedPointMarkerLayer from '../../../customComponents/map/assignment/FinishedPointsMarkerLayer';

interface INematodeAssignmentInfoViewProps {
    value : INematodeAssignment;
}

interface INematodeAssignmentInfoViewState {
    isLoading : boolean;
    locationEntries : Array<INematodeAssignmentLocationEntry>;
}

export default class NematodeAssignmentInfoView extends React.PureComponent<INematodeAssignmentInfoViewProps, INematodeAssignmentInfoViewState> {
    private listener ?: () => void;

    constructor(props : INematodeAssignmentInfoViewProps) {
        super(props);
        this.state = {
            locationEntries: [],
            isLoading: true,
        };
    }
    
    public componentDidMount(): void {
        this.load();
    }
    
    public componentWillUnmount(): void {
        if (this.listener) this.listener();
    }

    private readonly load = () => {
        this.setState({
            isLoading: true,
        });

        this.listener = NematodeAssignmentLocationEntryHelper.collection(this.props.value.id).orderBy('createdOn', 'asc').onSnapshot((snapshot) => {
            const locationEntries = this.state.locationEntries.slice();

            // "added" | "removed" | "modified"
            snapshot.docChanges().forEach((f) => {
                const entry = f.doc.data();

                switch (f.type) {
                case 'added':
                    locationEntries.splice(f.newIndex, 0, entry);
                    break;
                case 'modified':
                    locationEntries.splice(f.oldIndex, 1);
                    locationEntries.splice(f.newIndex, 1, entry);
                    break;
                case 'removed':
                    locationEntries.splice(f.oldIndex, 1);
                    break;
                }
            });

            this.setState({
                locationEntries,
                isLoading: false,
            });
        }, (ex) => {
            GeneralFunctions.generalShowErrorSnackbar('Error loading location entries', ex);
        });
    }
    
    private getScoutingBlock = (props : INematodeAssignmentInfoViewProps) => props.value.scoutingBlock;
    private getFinishedGuids = (props : INematodeAssignmentInfoViewProps) => props.value.finishedPoints;
    private getFinishedPoints = createSelector(
        [this.getFinishedGuids, this.getScoutingBlock],
        (guids, block) => {
            return guids.map(n => new LatLng(block.points[n].latitude, block.points[n].longitude));
        },
    );

    public render = () => {
        const {
            value,
        } = this.props;
        const {
            locationEntries,
        } = this.state;

        const finishedPoints = this.getFinishedPoints(this.props);
        return (
            <div className='fdr flx1 ais'>
                <div className='fdc flx1 ais mr5'>
                    <AppBar className='fdc ais' variant='outlined' position='static'>
                        <Toolbar variant='dense' className={'fdr aic jcc'}>
                            <Typography className={'cw'} variant='h6'>
                                BLOCK - {value.blockName}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdc flx1'>
                        <BlockMap block={this.props.value.scoutingBlock}>
                            <AssignmentLocationMarkerLayer locationEntries={locationEntries} />
                            <AssignmentFinishedPointMarkerLayer finishedPoints={finishedPoints} />
                        </BlockMap>
                    </div>
                </div>
                <div className='fdc flx1 ais ml5'>
                    <AppBar className='fdc ais' variant='outlined' position='static'>
                        <Toolbar variant='dense' className={'fdr aic jcc'}>
                            <Typography className={'cw'} variant='h6'>
                                ASSIGNMENT
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                DATE
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {AppFunctionsService.formatDateTimeToDateOnly(value.date)}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                COMPLETED POINTS
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {`${value.finishedPoints.length} / ${Object.keys(value.scoutingBlock.points).length}`}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                SCOUT
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {`${value.employeeNumber} - ${value.employeeName}`}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                DIVISION
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.division.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                LAND NAME
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.landName.toLocaleUpperCase()}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                BLOCK NAME
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.blockName.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                LAND DESCRIPTION
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.scoutingBlock.description.toLocaleUpperCase()}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                CROP
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.crop.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                AGE
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.scoutingBlock.age.toFixed(0)}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                AREA
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    !value.scoutingBlock.ha &&
                                    '-'
                                }
                                {
                                    !!value.scoutingBlock.ha &&
                                    `${value.scoutingBlock.ha.toFixed(2)} Ha`
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
