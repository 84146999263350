import { Checkbox, FormControl, FormHelperText, LinearProgress, TextField } from '@material-ui/core';
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import lodash from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { IRootState } from '../../../../@types/redux';
import { CROP } from '../../../../appConstants';
import AppFunctionsService from '../../../../services/appFunctionServices';
import ScoutingFunctions from '../../../../store/scouting/functions';
import { IScouting, IScoutingSection } from '../../../../types/model/masterData/scouting';
import ScoutingListbox from '../../listbox/ScoutingListbox';

interface IScoutingComponentMultiselectProps extends RouteComponentProps {
    scoutings : Array<IScouting>;
    isLoading : boolean;

    value : Array<string>;
    
    sections ?: Array<string> | null;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    disabled ?: boolean;
    name ?: string;

    onChange ?: (value : Array<string>, name ?: string) => void;
    onClose ?: () => void;
}

interface IScoutingComponentMultiselectState {}

class ScoutingComponentMultiselectComponent extends React.PureComponent<IScoutingComponentMultiselectProps, IScoutingComponentMultiselectState> {
    constructor(props : IScoutingComponentMultiselectProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        this.load();
    }

    public readonly componentDidUpdate = (prevProps : IScoutingComponentMultiselectProps) => {
        if (prevProps.location.search !== this.props.location.search) {
            this.load();
        }
    }

    public readonly load = () => {
        const crop = this.getCrop(this.props);

        if (crop) {
            ScoutingFunctions.getList(crop);
        }
    }

    private readonly getData = (props : IScoutingComponentMultiselectProps) => props.scoutings;
    private getSearch = (props : IScoutingComponentMultiselectProps) => props.location.search;
    private getSections = (props : IScoutingComponentMultiselectProps) => props.sections;
    private getValue = (props : IScoutingComponentMultiselectProps) => props.value;

    private getCrop = createSelector(
        [this.getSearch],
        (search) => {
            const urlParams = new URLSearchParams(search);
            if (urlParams.has('crop')) {
                const crop = urlParams.get('crop');
    
                if (crop) {
                    return crop as CROP;
                }
            }

            return null;
        },
    );

    private readonly getComponents = createSelector(
        [this.getData, this.getCrop, this.getSections],
        (scoutings, crop, selectedSections) => {
            const sections : Array<IScoutingSection> = scoutings
                .find(x => x.crop === crop)?.sections
                .filter(x => !selectedSections || selectedSections.includes(x.name)) ?? [];

            return lodash
                .chain(sections)
                .flatMap(x => x.entries)
                .uniqBy(x => x.name)
                .map(n => ({
                    value: n.name,
                    label: AppFunctionsService.toTitleCase(n.name),
                }))
                .value();
        },
    );

    private onChange = (event : React.ChangeEvent<unknown>, value : Array<{
        label : string;
        value : string;
    }>) => {
        if (!this.props.onChange) return;
        this.props.onChange(value.map(x => x.value).sort(), this.props.name);
    }

    private getSelectedValue = createSelector([
        this.getValue,
    ], (value) => {
        return lodash.chain(value)
            .map(n => ({
                value: n,
                label: AppFunctionsService.toTitleCase(n),
            })).value();
    });


    public render = () => {
        const { required,  fullWidth, isLoading, disabled, onClose } = this.props;

        const components = this.getComponents(this.props);

        const value = this.getSelectedValue(this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        multiple
                        disabled={isLoading || disabled}
                        options={components}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChange}
                        onClose={onClose}
                        disableClearable={required}
                        openOnFocus
                        disableCloseOnSelect
                        ListboxProps={{
                            title: '(Select what needs to be scouted)',
                        }}
                        ListboxComponent={ScoutingListbox}
                        renderOption={(option, { selected }) => (
                            <>
                                <Checkbox
                                    color='primary'
                                    icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                                    checkedIcon={<CheckBoxIcon fontSize='small' />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.label}
                            </>
                        )}
                        renderInput={params => <TextField error={required && !value} {...params} fullWidth={fullWidth} label='Select Components' />}
                    />
                    {
                        required && !value.length &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        scoutings: state.scouting.scoutings,
        isLoading: state.scouting.isLoading,
    };
};

const ScoutingComponentMultiselect = connect(
    mapStateToProps,
)(withRouter(ScoutingComponentMultiselectComponent));

export default ScoutingComponentMultiselect;
