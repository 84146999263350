import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { IPhenologySectionOption, IPhenologySectionOptionsOption } from '../../../../types/model/masterData/phenology';
import { Button, DialogActions, FormControl, FormHelperText, Switch, TextField, Typography } from '@material-ui/core';
import StandardFab from '../../../customComponents/button/StandardFab';
import AddIcon from '@material-ui/icons/Add';

interface IPhenologyGeneralEditOptionDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    value ?: IPhenologySectionOption;

    onClose : (value ?: IPhenologySectionOption) => void;

    isLoading : boolean;
}

interface IPhenologyGeneralEditOptionDialogState {
    open : boolean;

    name : string;
    group : string;
    subGroup : string;
    enforceMax : boolean;
    options : Array<IPhenologySectionOptionsOption>;
}

export default class PhenologyGeneralEditOptionDialog extends React.PureComponent<IPhenologyGeneralEditOptionDialogProps, IPhenologyGeneralEditOptionDialogState> {
    constructor(props : IPhenologyGeneralEditOptionDialogProps) {
        super(props);
        this.state = {
            open: false,
            enforceMax: false,
            name: '',
            group: '',
            subGroup: '',
            options: [],
        };
    }
    
    public readonly componentDidUpdate = (prevProps : IPhenologyGeneralEditOptionDialogProps) => {
        if (this.props.value && !prevProps.value) {
            this.show();

            this.setInitialValues(this.props.value);
        }

        if (!this.props.value && prevProps.value) {
            this.hide();
        }
    }

    public readonly show = () => {
        this.setState({
            open: true,
        });
    }

    public readonly hide = () => {
        this.setState({
            open: false,
        });
    }

    public readonly onClose = () => {
        this.props.onClose();
    }

    private readonly setInitialValues = (value : IPhenologySectionOption) => {
        this.setState({
            name: value.name,
            group: !value.group ? '' : value.group,
            enforceMax: !!value.enforceMax, // Can be null
            subGroup: !value.subGroup ? '' : value.subGroup,
            options: [...value.options.map(n => ({
                ...n,
            }))],
        });
    }

    private readonly onNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: e.target.value,
        });
    }

    private readonly onGroupChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            group: e.target.value,
        });
    }

    private readonly onSubGroupChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            subGroup: e.target.value,
        });
    }

    private readonly onEnforceMaxChange = () => {
        this.setState({
            enforceMax: !this.state.enforceMax,
        });
    }

    private readonly onOptionNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const [ index ] = e.currentTarget.name.split('.');
        const options = this.state.options.slice();
        options[Number(index)].name = e.target.value;

        this.setState({
            options,
        });
    }

    private readonly onSaveClick = () => {
        this.props.onClose({
            name: this.state.name,
            group: this.state.group,
            subGroup: this.state.subGroup,
            options: this.state.options,
            images: this.props.value?.images ?? [],
            enforceMax: this.state.enforceMax,
        });
    }

    private readonly onAddClick = () => {
        this.setState({
            options: [
                ...this.state.options.slice(), {
                    name: '',
                    value: 0,
                }],
        });
    };

    private readonly onRemoveClick = (e : React.MouseEvent<HTMLButtonElement>) => {
        const options = this.state.options.slice();

        options.splice(Number(e.currentTarget.value), 1);
        this.setState({
            options: [
                ...options
            ],
        });
    };

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, value, isLoading } = this.props;
        const { open, name, options, group, subGroup, enforceMax } = this.state;
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='phenology-general-option-dialog-title'
                aria-describedby='phenology-general-option-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            {
                                !value?.name ? 'Create' : `Edit ${value.name}`
                            }
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className={'fdr jcfs aifs'}>
                        <div className={'fdc flx1 jcfs p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <FormControl>
                                <TextField
                                    required
                                    error={!name}
                                    autoFocus
                                    margin='dense'
                                    id='name'
                                    label='Name'
                                    type='text'
                                    fullWidth
                                    value={name}
                                    onChange={this.onNameChange}
                                    disabled={!!value?.name}
                                />
                                {
                                    !name &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <FormControl>
                                <TextField
                                    margin='dense'
                                    id='group'
                                    label='Group'
                                    type='text'
                                    fullWidth
                                    value={group ? group : ''}
                                    onChange={this.onGroupChange}
                                />
                            </FormControl>
                        </div>
                        <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <FormControl>
                                <TextField
                                    margin='dense'
                                    id='subGroup'
                                    label='Sub Group'
                                    type='text'
                                    fullWidth
                                    value={subGroup ? subGroup : ''}
                                    onChange={this.onSubGroupChange}
                                />
                            </FormControl>
                        </div>
                        <div className={'fdr flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <Typography className='pr6' >Enforce Max?</Typography>
                            <Switch
                                id='optional'
                                type='ant'
                                checked={enforceMax}
                                onClick={this.onEnforceMaxChange}
                                color='primary'
                                inputProps={{
                                    'aria-label': 'Enforce Max Switch',
                                    id: 'optional',
                                }}
                            />
                        </div>
                    </div>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            OPTIONS
                        </Typography>
                        <span className={'flx1'} />
                        <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                            <AddIcon />
                        </StandardFab>
                    </Toolbar>
                    <div className='fdc jcfs ais'>
                        {
                            options.map((option, index) =>
                                <div key={`${index}`} className='fdr aic'>
                                    <TextField
                                        key={`${index}`}
                                        required
                                        error={!name}
                                        autoFocus
                                        margin='dense'
                                        id={`${index}.name`}
                                        name={`${index}.name`}
                                        label='Name'
                                        type='text'
                                        fullWidth
                                        value={option.name}
                                        onChange={this.onOptionNameChange}
                                    />
                                    <Tooltip title='Remove'>
                                        <div>
                                            <IconButton value={index} onClick={this.onRemoveClick}>
                                                <Icon className='cpr'>delete</Icon>
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </div>
                            )
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading} onClick={this.onSaveClick}>
                        <SaveIcon style={{ marginRight: '10px' }} />
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
