import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, DialogActions, FormControl, FormHelperText, Switch, TextField, Typography } from '@material-ui/core';
import { IInsect } from '../../../../types/model/masterData/insect';
import { IScoutingFile } from '../../../../types/model/file';
import StandardFab from '../../../customComponents/button/StandardFab';
import { Add } from '@material-ui/icons';

interface IInsectEditDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    value : IInsect | null;

    onClose : (value : IInsect | null) => void;

    isLoading : boolean;
}

interface IInsectEditDialogState {
    open : boolean;

    name : string;

    images : Array<IScoutingFile>;

    subTrapTypes : Array<string>;
    
    isActive : boolean;
}

export default class InsectEditDialog extends React.PureComponent<IInsectEditDialogProps, IInsectEditDialogState> {
    constructor(props : IInsectEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            isActive: true,
            images: [],
            subTrapTypes: [],
        };
    }
    
    public readonly componentDidUpdate = (prevProps : IInsectEditDialogProps) => {
        if (this.props.value && !prevProps.value) {
            this.show();

            this.setInitialValues(this.props.value);
        }

        if (!this.props.value && prevProps.value) {
            this.hide();
        }
    }

    public readonly show = () => {
        this.setState({
            open: true,
        });
    }

    public readonly hide = () => {
        this.setState({
            open: false,
        });
    }

    public readonly onClose = () => {
        this.props.onClose(null);
    }

    private readonly setInitialValues = (value : IInsect) => {
        this.setState({
            name: value.name,
            images: value.images ?? [],
            subTrapTypes: value.subTrapTypes,
            isActive: value.isActive,
        });
    }

    private readonly onNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: e.target.value,
        });
    }

    private readonly onIsActiveChange = () => {
        this.setState({
            isActive: !this.state.isActive,
        });
    }

    private readonly onFileChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.files || !event.currentTarget.files.length) return;
        const file = event.currentTarget.files[0];

        const images = this.state.images.slice();

        images.push({
            file,
            name: file.name,
            url: '',
            path: '',
            thumbnailUrl: null,
        });

        this.setState({
            images,
        });
    };

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const images = this.state.images.slice();

        images.splice(Number(event.currentTarget.value), 1);

        this.setState({
            images,
        });
    };

    private readonly onSaveClick = () => {
        if (!this.props.value) return;
        this.props.onClose({
            ...this.props.value,
            name: this.state.name,
            images: this.state.images.map((image) => ({
                ...image,
            })),
            subTrapTypes: this.state.subTrapTypes.slice(),
            isActive: this.state.isActive,
        });
    }

    private readonly onOptionNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const [ index ] = e.currentTarget.name.split('.');
        const subTrapTypes = this.state.subTrapTypes.slice();
        subTrapTypes[Number(index)] = e.target.value;

        this.setState({
            subTrapTypes,
        });
    }

    private readonly onAddClick = () => {
        this.setState({
            subTrapTypes: [
                ...this.state.subTrapTypes.slice(),
                '',
            ],
        });
    };

    private readonly onRemoveClick = (e : React.MouseEvent<HTMLButtonElement>) => {
        const subTrapTypes = this.state.subTrapTypes.slice();

        subTrapTypes.splice(Number(e.currentTarget.value), 1);
        this.setState({
            subTrapTypes: [
                ...subTrapTypes
            ],
        });
    };

    public render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            value,
            isLoading,
        } = this.props;

        const {
            open,
            name,
            images,
            isActive,
            subTrapTypes,
        } = this.state;
        
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='insect-general-dialog-title'
                aria-describedby='insect-general-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            {
                                !value?.name ? 'Create' : `Edit ${value.name}`
                            }
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className={'fdr jcfs aifs'}>
                        <div className={'fdc flx1 jcfs mr10'}>
                            <FormControl>
                                <TextField
                                    required
                                    error={!name}
                                    autoFocus
                                    id='name'
                                    label='Name'
                                    type='text'
                                    fullWidth
                                    value={name}
                                    onChange={this.onNameChange}
                                />
                                {
                                    !name &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className={'fdr flx1 aic ml10'}>
                            <Typography className='pr6' >Is Active?</Typography>
                            <Switch
                                id={'isActive'}
                                name={'isActive'}
                                checked={isActive}
                                color='primary'
                                onChange={this.onIsActiveChange}
                                inputProps={{
                                    'aria-label': 'Is Active',
                                    id: 'isActive',
                                }}
                            />
                        </div>
                    </div>
                    <div  className={'fdc jcc aifs mt15'}>
                        {
                            images.map((image, index) => (
                                <span key={image.path ? image.path : image.name + index} className='mr15 mb10'>
                                    {
                                        image.url &&
                                        <a href={image.url} target='_blank' rel='noreferrer'>{image.name}</a>
                                    }
                                    {
                                        !image.url &&
                                        image.name
                                    }
                                    <IconButton id={image.name} value={index} onClick={this.onDeleteClick}>
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </span>
                            ))
                        }
                    </div>
                    <div  className={'fdr jcfs aic mt15'}>
                        <div className='fdr flx1 jcfe'>
                            <Button component='label' color='primary'>
                                <Icon>upload</Icon>
                                <input
                                    type='file'
                                    accept='image/*'
                                    hidden
                                    name={name}
                                    onChange={this.onFileChange}
                                />
                                <span className='ml15'>Upload Image</span>
                            </Button>
                        </div>
                    </div>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            SUB TRAP TYPES
                        </Typography>
                        <span className={'flx1'} />
                        <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                            <Add />
                        </StandardFab>
                    </Toolbar>
                    <div className='fdc jcfs ais'>
                        {
                            subTrapTypes.map((option, index) =>
                                <div key={`${index}`} className='fdr aic'>
                                    <TextField
                                        key={`${index}`}
                                        required
                                        error={!name}
                                        autoFocus
                                        margin='dense'
                                        id={`${index}`}
                                        name={`${index}`}
                                        label='Name'
                                        type='text'
                                        fullWidth
                                        value={option}
                                        onChange={this.onOptionNameChange}
                                    />
                                    <Tooltip title='Remove'>
                                        <div>
                                            <IconButton value={index} onClick={this.onRemoveClick}>
                                                <Icon className='cpr'>delete</Icon>
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </div>
                            )
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading} onClick={this.onSaveClick}>
                        <SaveIcon style={{ marginRight: '10px' }} />
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
