
import { Route, RouteComponentProps, RouteProps } from 'react-router';
import React from 'react';
import { IAuthState } from '../../@types/redux';
import NoAccess from './NoAccess';
import AuthFunctions from '../../store/auth/functions';

interface IPrivateRouteProps extends RouteProps {
    auth : IAuthState;
}

export default class PrivateRoute extends React.Component<IPrivateRouteProps> {
    public render () {
        const { component, auth, ...rest } = this.props;
        if (!component) {
            return;
        }
        
        const Component = component;
        const render = (props : RouteComponentProps) : React.ReactNode => {
            if (auth.session) {
                if (!AuthFunctions.hasRight(props.match.path)) {
                    return <NoAccess />;
                }

                return <Component {...props} />;
            }
        };

        return (<Route {...rest} render={render} />);
    }
}
