import { AppBar, Dialog, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import StadiumButton from '../../customComponents/button/StadiumButton';
import { Transitions } from '../../customComponents/animations/Transitions';
import { Close } from '@material-ui/icons';
import { ITrap } from '../../../types/model/trap/trap';
import TrapQrCodeDocument from '../pdf/QrCodeDocument';
import { PDFViewer } from '@react-pdf/renderer';

interface ITrapsPrintQrCodeButtonProps {
    selected : Array<ITrap>;

    onClose ?: () => void;
}

interface ITrapsPrintQrCodeButtonState {
    open : boolean;
    isLoading : boolean;
}

export default class TrapsPrintQrCodeButton extends React.PureComponent<ITrapsPrintQrCodeButtonProps, ITrapsPrintQrCodeButtonState> {
    constructor(props : ITrapsPrintQrCodeButtonProps) {
        super(props);
        this.state = {
            open: false,
            isLoading: false,
        };
    }
    public readonly onClick = () => {
        this.setState({
            open: true,
            isLoading: false,
        });
    }

    private readonly onClose = () => {
        this.setState({
            open: false,
        });
    }

    public render = () => {
        const {
            selected,
        } = this.props;

        const {
            open,
            isLoading,
        } = this.state;

        return (
            <React.Fragment>
                {
                    !!selected.length &&
                    <div className='fdr'>
                        <Tooltip title='PrintQrCode'>
                            <div>
                                <StadiumButton className='fw500 fs14 cw bcp'  onClick={this.onClick}>
                                    PRINT QR CODE
                                </StadiumButton>
                            </div>
                        </Tooltip>
                    </div>
                }
                <Dialog
                    open={open}
                    TransitionComponent={Transitions.ZoomIn}
                    transitionDuration={500}
                    maxWidth={'lg'}
                    fullScreen
                    fullWidth
                    aria-labelledby='print-qr-code-trap-dialog-title'
                    aria-describedby='print-qr-code-trap-description'>
                    <AppBar className='fdr posr aic jcc bcpd' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography>
                                PRINT QR CODES
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close' disabled={isLoading}>
                                        <Close />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    {
                        open &&
                        selected.length &&
                        <div className='hfill posr'>
                            <PDFViewer style={{
                                flexGrow: 1,
                                borderWidth: 0,
                                flexDirection: 'column',
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                            }}>
                                <TrapQrCodeDocument
                                    traps={selected}
                                />
                            </PDFViewer>
                        </div>
                    }
                </Dialog>
            </React.Fragment>
        );
    }
}
