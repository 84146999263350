// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.odd-row {
    background-color: ghostwhite;
}

tr.table-header th{
    background-color: lightgrey !important;
    position: sticky !important;
    top: 0 !important;
    z-index: 1;
}

tr.table-padding th {
    padding: 4px 4px 4px 4px;
}

tr.table-padding td {
    padding: 4px 4px 4px 4px;
}

/* Dimensions */
.wfill {width: 100% !important;}
.hfill {height: 100% !important;}

/* Flexbox */

.flx1 { display: flex !important; flex: 1 !important; }
.fdc {
    display: flex !important;
    flex-direction: column !important;
}


.oxs {overflow-x: scroll !important;}
.oys {overflow-y: scroll !important;}

.mh0 { min-height: 0 !important;}`, "",{"version":3,"sources":["webpack://./src/modules/customComponents/table/materialTable.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;IACI,sCAAsC;IACtC,2BAA2B;IAC3B,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA,eAAe;AACf,QAAQ,sBAAsB,CAAC;AAC/B,QAAQ,uBAAuB,CAAC;;AAEhC,YAAY;;AAEZ,QAAQ,wBAAwB,EAAE,kBAAkB,EAAE;AACtD;IACI,wBAAwB;IACxB,iCAAiC;AACrC;;;AAGA,MAAM,6BAA6B,CAAC;AACpC,MAAM,6BAA6B,CAAC;;AAEpC,OAAO,wBAAwB,CAAC","sourcesContent":[".odd-row {\n    background-color: ghostwhite;\n}\n\ntr.table-header th{\n    background-color: lightgrey !important;\n    position: sticky !important;\n    top: 0 !important;\n    z-index: 1;\n}\n\ntr.table-padding th {\n    padding: 4px 4px 4px 4px;\n}\n\ntr.table-padding td {\n    padding: 4px 4px 4px 4px;\n}\n\n/* Dimensions */\n.wfill {width: 100% !important;}\n.hfill {height: 100% !important;}\n\n/* Flexbox */\n\n.flx1 { display: flex !important; flex: 1 !important; }\n.fdc {\n    display: flex !important;\n    flex-direction: column !important;\n}\n\n\n.oxs {overflow-x: scroll !important;}\n.oys {overflow-y: scroll !important;}\n\n.mh0 { min-height: 0 !important;}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
