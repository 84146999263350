import firebase from 'firebase/app';
import firebaseApp from '../../../services/firebaseService';
import { BaseHelper, IBase } from '../../base';
import { TrapHelper } from './trap';

export type TrapCommentType = 'website' | 'survey';
export interface ITrapComment extends IBase {
    trapId : string;
    comment : string;

    type : TrapCommentType | string;

    date : number;
}

export class TrapCommentHelper extends BaseHelper {
    public static readonly COLLECTION = (trapId : string) => `traps/${trapId}/trap_comments`;

    public static converter : firebase.firestore.FirestoreDataConverter<ITrapComment> = {
        fromFirestore: (snapshot) => {
            return TrapCommentHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : ITrapComment) => {
            return TrapCommentHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : ITrapComment {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as ITrapComment,
            ...result,
            trapId: (data['trapRef'] as firebase.firestore.DocumentReference).id,
            date: (data['date'] as firebase.firestore.Timestamp).toMillis(),
        };
    }

    protected static toFirestore(data : ITrapComment) {
        const result = super.toFirestore(data);

        const { id : _, ...rest } = data;
        return {
            ...rest,
            ...result,
            trapRef: TrapHelper.doc(data.trapId),
            date: firebase.firestore.Timestamp.fromMillis(data.date),
        };
    }

    public static collection(trapId : string) {
        return firebaseApp.firestore().collection(this.COLLECTION(trapId)).withConverter(this.converter);
    }

    public static doc(trapId : string, id ?: string) {
        if (!id) {
            return TrapCommentHelper.collection(trapId).doc();
        }

        return TrapCommentHelper.collection(trapId).doc(id);
    }

    public static delete(id : string) {
        return TrapCommentHelper.doc(id).update({
            archived: false,
        });
    }

    public static save(trapComment : ITrapComment) {
        if (trapComment.id) {
            return this.collection(trapComment.trapId).doc(trapComment.id).set(trapComment);
        }

        return this.collection(trapComment.trapId).doc().set(trapComment);
    }

}