import firebaseApp from '../../../services/firebaseService';
import lodash from 'lodash';
import { BaseHelper, IBase } from '../../base';
import { GeoPointHelper, IGeoPoint } from '../geoPoint';
import { LatLng } from 'leaflet';
import { IBlockPolygonLayer } from './blockPolygonLayer';
import firebase from 'firebase/app';
import randomColor from 'randomcolor';

export interface IAssignmentBlock extends Omit<IBlock, 'createdBy'
| 'createdByName'
| 'createdByEmployee'
| 'createdOn'
| 'updatedBy'
| 'updatedByName'
| 'updatedByEmployee'
| 'updatedOn'> {}

export interface IBlock extends IBase {
    landId ?: string;
    fatherId ?: number;
    name : string;
    area : number;
    division : string;
    description : string;
    landName : string;
    ha : number | null;
    age : number;
    polygons : Record<number, Array<IGeoPoint>>;
    points : Record<string, IGeoPoint>;
    pointsArray : Array<string>;
    phenologyPoints : Record<string, IGeoPoint>;
    phenologyPointsArray : Array<string>;
    center : IGeoPoint;
    crop : string;
}

export class BlockHelper extends BaseHelper {
    public static readonly COLLECTION = 'scouting_blocks';

    public static converter : firebase.firestore.FirestoreDataConverter<IBlock | null> = {
        fromFirestore: (snapshot) => {
            return BlockHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IBlock) => {
            return BlockHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IBlock | null {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data || !result) return null;

        const pointGuids = Object.keys(data['points'] ?? {});
        pointGuids.sort();

        const phenologyPointGuids = Object.keys(data['phenologyPoints'] ?? {});
        phenologyPointGuids.sort();
        return {
            ...result,
            landId: data['landId'],
            fatherId: data['fatherId'],
            name: data['name'],
            area: data['area'],
            division: data['division'],
            description: data['description'],
            landName: data['landName'],
            ha: data['ha'],
            age: data['age'],
            polygons: lodash.mapValues(data['polygons'], geos => lodash.map(geos, geo => (GeoPointHelper.fromFirestore(geo)))),
            points: lodash.mapValues(data['points'], geo => (GeoPointHelper.fromFirestore(geo))),
            pointsArray: pointGuids,
            phenologyPoints: lodash.mapValues(data['phenologyPoints'] ?? {}, geo => (GeoPointHelper.fromFirestore(geo))),
            phenologyPointsArray: phenologyPointGuids,
            center: GeoPointHelper.fromFirestore(data['center']),
            crop: data['crop'],
        };
    }

    protected static toFirestore(data : IBlock) {

        const result = super.toFirestore(data);
        return {
            ...result,
            landId: data.landId ?? '0',
            fatherId: data.fatherId ?? 0,
            name: data.name,
            area: data.area,
            division: data.division,
            description: data.description,
            landName: data.landName,
            ha: data.ha,
            age: data.age,
            crop: data.crop,
            center: GeoPointHelper.toFirestore(data.center),
            polygons: lodash.mapValues(data.polygons, geos => lodash.map(geos, geo => (GeoPointHelper.toFirestore(geo)))),
            points: lodash.mapValues(data.points, geo => (GeoPointHelper.toFirestore(geo))),
        };
    }

    public static fromFirestoreData(blockId : string, data : firebase.firestore.DocumentData) : IAssignmentBlock {
        const scoutingPointGuids = Object.keys(data['points'] ?? {});
        scoutingPointGuids.sort();

        const phenologyPointGuids = Object.keys(data['phenologyPoints'] ?? {});
        phenologyPointGuids.sort();

        return {
            id: data['ref'].id,
            landId: data['landId'],
            fatherId: data['fatherId'],
            name: data['name'],
            area: data['area'],
            division: data['division'],
            description: data['description'],
            landName: data['landName'],
            ha: data['ha'],
            age: data['age'],
            polygons: lodash.mapValues(data['polygons'], geos => lodash.map(geos, geo => (GeoPointHelper.fromFirestore(geo)))),
            points: lodash.mapValues(data['points'], geo => (GeoPointHelper.fromFirestore(geo))),
            pointsArray: scoutingPointGuids,
            phenologyPoints: lodash.mapValues(data['phenologyPoints'], geo => (GeoPointHelper.fromFirestore(geo))),
            phenologyPointsArray: phenologyPointGuids,
            center: GeoPointHelper.fromFirestore(data['center']),
            crop: data['crop'],
        };
    }

    public static toFirestoreData(data : IBlock | IAssignmentBlock) : firebase.firestore.DocumentData {
        return {
            ref: BlockHelper.doc(data.id),
            landId: data['landId'],
            fatherId: data['fatherId'],
            name: data['name'],
            area: data['area'],
            division: data['division'],
            description: data['description'],
            landName: data['landName'],
            ha: data['ha'],
            age: data['age'],
            polygons: lodash.mapValues(data['polygons'], geos => lodash.map(geos, geo => (GeoPointHelper.toFirestore(geo)))),
            points: lodash.mapValues(data['points'], geo => (GeoPointHelper.toFirestore(geo))),
            phenologyPoints: lodash.mapValues(data['phenologyPoints'], geo => (GeoPointHelper.toFirestore(geo))),
            center: GeoPointHelper.toFirestore(data['center']),
            crop: data['crop'],
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    private static validate(data : IBlock) {
        if (!Object.keys(data.points).length) {
            throw Error(`${data.landName} - ${this.name}: points required.`);
        }

        if (!Object.keys(data.polygons).length) {
            throw Error(`${data.landName} - ${this.name}: polygons required.`);
        }

        if (!data.crop) {
            throw Error(`${data.landName} - ${this.name}: crop required.`);
        }
    }

    public static save(data : IBlock) {
        this.validate(data);
        if (data.id) {
            return firebaseApp.firestore().collection(this.COLLECTION).doc(data.id).withConverter(this.converter).set(data, {
                merge: true,
            });
        } else {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).add(data);
        }
    }

    public static batchSave(batch : firebase.firestore.WriteBatch, data : IBlock) {
        if (data.id) {
            this.validate(data);
            return batch.set(this.collection().withConverter(this.converter).doc(data.id), data, {
                merge: true,
            });
        }
    }

    public static batchSavePoints(batch : firebase.firestore.WriteBatch, data : IBlock) {
        if (data.id) {
            return batch.update(firebaseApp.firestore().collection(this.COLLECTION).doc(data.id), 'points', data.points);
        }
    }

    public static batchSavePhenologyPoints(batch : firebase.firestore.WriteBatch, data : IBlock) {
        if (data.id) {
            return batch.update(firebaseApp.firestore().collection(this.COLLECTION).doc(data.id), 'phenologyPoints', data.phenologyPoints);
        }
    }

    /**
     * Returns document snapshot.
     * @param id Document Path or Id
     */
    public static doc(id : string) {
        if (id.includes('/')) {
            return firebaseApp.firestore().doc(id).withConverter(BlockHelper.converter);
        }

        return this.collection().doc(id);
    }

    public static toLayer(n : IBlock | IAssignmentBlock, phenology ?: boolean) : IBlockPolygonLayer {
        const blockLayer : IBlockPolygonLayer = {
            positions: [] as Array<Array<LatLng>>,
            color: randomColor({ seed: n.landName }),
            landId: n.landId,
            landName: n.landName,
            blockName: n.name,
            crop: n.crop,
            id: n.id,
            division: n.division,
            points: lodash.map(!phenology ? n.points : n.phenologyPoints, x => ({
                color: randomColor({ seed: n.landName }),
                center: new LatLng(x.latitude, x.longitude),
            })),
        };

        lodash.forEach(n.polygons, (poly, i) => {
            blockLayer.positions[Number(i)] = [];
            poly.forEach(polyPoint => blockLayer.positions[Number(i)].push(new LatLng(polyPoint.latitude, polyPoint.longitude)));
        });

        return blockLayer;
    }
}
