import { getType } from 'typesafe-actions';
import { IPhynologyState, PhenologyAction } from '../../../@types/redux';
import PhenologyActions from './actions';

const initialState : IPhynologyState = {
    phenologies: [],
    isLoading: false,
    assignments: [],
    isLoadingAssignments: false,
};

export default function phenologyGeneralReducer(state : IPhynologyState = initialState, action : PhenologyAction) : IPhynologyState {
    switch (action.type) {
    case getType(PhenologyActions.setList):
        return { ...state, phenologies: action.payload };
    case getType(PhenologyActions.setLoading):
        return { ...state, isLoading: action.payload };
    case getType(PhenologyActions.setAssignments):
        return { ...state, assignments: action.payload };
    case getType(PhenologyActions.setLoadingAssignments):
        return { ...state, isLoadingAssignments: action.payload };
    default:
        return state;
    }
}