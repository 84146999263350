import React from 'react';
import { IRootState, RootAction } from '../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Paper } from '@material-ui/core';
import VerticalExpander from '../customComponents/expander/VerticalExpander';
import { CROP } from '../../appConstants';
import DivisionAutocomplete from '../customComponents/autocomplete/rights/DivisionAutocomplete';
import { IDivision } from '../../types/model/rights/division';
import CropAutocomplete from '../customComponents/autocomplete/masterData/CropAutocomplete';
import FieldAutocomplete from '../customComponents/autocomplete/block/FieldNameAutocomplete';
import FieldBlockAutocomplete from '../customComponents/autocomplete/block/FieldBlockAutocomplete';
import { IBlock } from '../../types/model/masterData/block';
import { IUserSession } from '../../types/model/user';
import TrapBlocksMap from '../customComponents/map/TrapBlocksMap';
import TrapInfoDialog from './dialog/ViewDialog';
import { ITrap } from '../../types/model/trap/trap';

interface ITrapsMapProps extends RouteComponentProps {
    session ?: IUserSession | null;
}

interface ITrapsMapState {
    division : IDivision | null;

    landName : string | null;
    block : string | null;
    cropType : CROP | null;
    selectedCode : string | null;
    selected ?: ITrap;
}

class TrapsMapComponent extends React.PureComponent<ITrapsMapProps, ITrapsMapState> {
    constructor(props : ITrapsMapProps) {
        super(props);
        this.state = {
            block: null,
            division: null,
            landName: null,
            cropType: null,
            selectedCode: null,
        };
    }

    public readonly componentDidMount = () => {
        this.setCropType();
        this.setCode();
    }

    public componentDidUpdate(prevProps : Readonly<ITrapsMapProps>) : void {
        if (prevProps.location !== this.props.location) {
            this.setCropType();
            this.setCode();
        }
    }


    public readonly setCropType = () => {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has('crop')) {
            const crop = urlParams.get('crop') as CROP | null;

            if (crop) {
                this.setState({
                    cropType: crop,
                });
            }
        } else {
            this.setState({
                cropType: null,
            });
        }
    }

    public readonly setCode = () => {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has('code')) {
            const code = urlParams.get('code') as string | null;

            if (code) {
                this.setState({
                    selectedCode: code,
                });
            }
        } else {
            this.setState({
                selectedCode: null,
            });
        }
    }

    private readonly onDivisionChange = (value ?: IDivision) => {
        this.setState({
            division: value ?? null,
            landName: null,
            block: null,
        });
    }

    private readonly onCropTypeChange = (value ?: CROP) => {
        this.setState({
            cropType: value ?? null,
            division: null,
            landName: null,
            block: null,
        });
    }

    private readonly onLandNameChange = (landName ?: string | null) => {
        this.setState({
            landName: landName ?? null,
            block: null,
        });
    }

    private readonly onBlockChange = (block ?: IBlock | null) => {
        this.setState({
            block: block?.id ?? null,
            landName: block?.landName ?? null,
        });
    }

    private readonly onTrapClick = (trap : ITrap) => {
        this.setState({
            selected: trap,
        });
    }

    private readonly onInfoClose = () => {
        this.setState({
            selected: undefined,
        });
    }

    public render = () => {
        const {
            selectedCode,
            division,
            landName,
            block,
            cropType,
            selected,
        } = this.state;
        return (
            <div className={'fdr hfill mh0'}>
                <Paper className={'flx1 fdr oxh p5 m5'}>
                    <TrapBlocksMap
                        selectedCode={selectedCode}
                        divisionCode={division?.code ?? null}
                        landName={landName}
                        blockId={block}
                        cropType={cropType}
                        onTrapClick={this.onTrapClick}
                    />
                </Paper>
                <div className={'fdc mh0'}>
                    <VerticalExpander title='filter' location='right' initiallyExpanded>
                        <div className={'fdc aic pr20 mt7'}>
                            <DivisionAutocomplete
                                fullWidth
                                value={division}
                                onChange={this.onDivisionChange}
                                crop={cropType}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <CropAutocomplete
                                fullWidth
                                value={cropType}
                                onChange={this.onCropTypeChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <FieldAutocomplete
                                value={landName}
                                division={division?.code}
                                crop={cropType}
                                fullWidth
                                onChange={this.onLandNameChange}
                            />
                        </div>
                        <div className={'fdc aic pr20'}>
                            <FieldBlockAutocomplete
                                value={block}
                                division={division?.code ?? null}
                                crop={cropType ?? null}
                                fullWidth
                                onChange={this.onBlockChange}
                                fieldName={landName}
                            />
                        </div>
                    </VerticalExpander>
                </div>
                <TrapInfoDialog
                    value={selected?.id}
                    onClose={this.onInfoClose}
                />
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators({
}, dispatcher);

const TrapsMap = connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(TrapsMapComponent));

export default TrapsMap;