import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { CROP } from '../../../../appConstants';
import { IBlock } from '../../../../types/model/masterData/block';
import GeneralFunctions from '../../../../store/general/functions';
import lodash from 'lodash';

interface IFieldBlockAutocompleteProps {
    id ?: string;
    value ?: IBlock | string | null;

    onChange : (value ?: IBlock, event ?: React.ChangeEvent<unknown>) => void;

    blocks : Array<IBlock>;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;
    disabled ?: boolean;
    name ?: string;

    label ?: string;

    crop ?: CROP | null;
    division ?: string | null;
    fieldName ?: string | null;
}

interface IFieldBlockAutocompleteState {
}

class FieldBlockAutocompleteComponent extends React.Component<IFieldBlockAutocompleteProps, IFieldBlockAutocompleteState> {
    constructor(props : IFieldBlockAutocompleteProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        GeneralFunctions.getBlocks();
    }

    private onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        const result = this.props.blocks.find(n => n.id === value?.value);

        this.props.onChange(result, event);
    }

    private getData = (state : IFieldBlockAutocompleteState, props : IFieldBlockAutocompleteProps) => props.blocks;
    private getValue = (state : IFieldBlockAutocompleteState, props : IFieldBlockAutocompleteProps) => props.value;
    private getRequired = (state : IFieldBlockAutocompleteState, props : IFieldBlockAutocompleteProps) => props.required;
    private getDivision = (state : IFieldBlockAutocompleteState, props : IFieldBlockAutocompleteProps) => props.division;
    private getCrop = (state : IFieldBlockAutocompleteState, props : IFieldBlockAutocompleteProps) => props.crop;
    private getFieldName = (state : IFieldBlockAutocompleteState, props : IFieldBlockAutocompleteProps) => props.fieldName;
    private getBlockAutocomplete = createSelector([
        this.getData,
        this.getRequired,
        this.getDivision,
        this.getFieldName,
        this.getCrop,
    ], (
        blocks,
        required,
        division,
        fieldName,
        crop,
    ) => {
        const blocksDrop = lodash.chain(blocks)
            .filter(n => !division || n.division.toLocaleLowerCase() === division.toLocaleLowerCase())
            .filter(n => !crop || n.crop === crop)
            .filter(n => !fieldName || n.landName === fieldName)
            .uniqBy(n => n.name)
            .map(n => ({
                label: !fieldName ? `${n.landName} - ${n.name}` : `${n.name}`,
                value: n.id,
            }))
            .sortBy(n => n.label)
            .value();

        if (!required) {
            blocksDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return blocksDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue,
        this.getRequired,
        this.getData,
        this.getFieldName,
    ], (
        value,
        required,
        blocks,
        fieldName,
    ) => {
        if (value && typeof(value) === 'object') {
            return {
                label: !fieldName ? `${value.landName} - ${value.name}` : `${value.name}`,
                value: value.id,
            };
        } else if (value && typeof(value) === 'string') {
            const block = blocks.slice().find(x => x.id === value || x.name === value);

            if (block) {
                return {
                    label:  !fieldName ? `${block.landName} - ${block.name}` : `${block.name}`,
                    value: block.id,
                };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const {
            required,
            fullWidth,
            isLoading,
            disabled,
            label,
        } = this.props;

        const blocks = this.getBlockAutocomplete(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        disabled={isLoading || disabled}
                        id='block_select'
                        options={blocks}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChange}
                        disableClearable={required}
                        openOnFocus
                        renderInput={params => (
                            <TextField
                                error={required && !value}
                                required={required}
                                {...params}
                                fullWidth={fullWidth}
                                label={label ?? 'Block'}
                            />)}
                    />
                    {
                        required && !value &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.general.blocks,
        isLoading: state.general.isLoadingBlocks,
    };
};

const FieldBlockAutocomplete = connect(
    mapStateToProps,
)(FieldBlockAutocompleteComponent);

export default FieldBlockAutocomplete;
