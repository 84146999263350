import { Checkbox, FormControl, FormHelperText, LinearProgress, TextField } from '@material-ui/core';
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import lodash from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { IRootState } from '../../../../@types/redux';
import { CROP } from '../../../../appConstants';
import AppFunctionsService from '../../../../services/appFunctionServices';
import DamageFunctions from '../../../../store/masterData/damage/functions';
import { IDamage } from '../../../../types/model/masterData/damage';
import { IScouting, IScoutingSection } from '../../../../types/model/masterData/scouting';
import ScoutingListbox from '../../listbox/ScoutingListbox';

interface IScoutingDamageMultiselectProps extends RouteComponentProps {
    scoutings : Array<IScouting>;
    damages : Array<IDamage>;
    isLoading : boolean;

    value : Array<string>;
    
    sections ?: Array<string> | null;
    components ?: Array<string> | null;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    disabled ?: boolean;
    name ?: string;

    onChange ?: (value : Array<string>, name ?: string) => void;
    onClose ?: () => void;
}

interface IScoutingDamageMultiselectState {}

class ScoutingDamageMultiselectComponent extends React.PureComponent<IScoutingDamageMultiselectProps, IScoutingDamageMultiselectState> {
    constructor(props : IScoutingDamageMultiselectProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = async () => {
        DamageFunctions.getList();
    }

    private readonly getData = (props : IScoutingDamageMultiselectProps) => props.scoutings;
    private readonly getDamages = (props : IScoutingDamageMultiselectProps) => props.damages;
    private getSearch = (props : IScoutingDamageMultiselectProps) => props.location.search;
    private getSections = (props : IScoutingDamageMultiselectProps) => props.sections;
    private getComponents = (props : IScoutingDamageMultiselectProps) => props.components;
    private getValue = (props : IScoutingDamageMultiselectProps) => props.value;

    private getCrop = createSelector(
        [this.getSearch],
        (search) => {
            const urlParams = new URLSearchParams(search);
            if (urlParams.has('crop')) {
                const crop = urlParams.get('crop');
    
                if (crop) {
                    return crop as CROP;
                }
            }

            return null;
        },
    );

    private readonly getDropdownDamages = createSelector(
        [
            this.getDamages,
            this.getData,
            this.getCrop,
            this.getSections,
            this.getComponents,
        ],
        (
            damages,
            scoutings,
            crop,
            selectedSections,
            selectedComponents
        ) => {
            const sections : Array<IScoutingSection> = scoutings
                .find(x => x.crop === crop)?.sections
                .filter(x => !selectedSections || selectedSections.includes(x.name)) ?? [];

            const ids : Array<string> = lodash
                .chain(sections)
                .flatMap(x => x.entries)
                .filter(x => !selectedComponents || selectedComponents.includes(x.name))
                .flatMap(x => x.checks.damage)
                .map(x => x.ref?.id ?? '')
                .uniq()
                .value();

            return damages.filter(x => ids.includes(x.id)).map(x => ({
                value: x.name,
                label: AppFunctionsService.toTitleCase(x.name),
            })).sort((a, b) => a.label.localeCompare(b.label));
        },
    );

    private onChange = (event : React.ChangeEvent<unknown>, value : Array<{
        label : string;
        value : string;
    }>) => {
        if (!this.props.onChange) return;
        this.props.onChange(value.map(x => x.value).sort(), this.props.name);
    }

    private getSelectedValue = createSelector([
        this.getValue,
    ], (value) => {
        return lodash.chain(value)
            .map(n => ({
                value: n,
                label: AppFunctionsService.toTitleCase(n),
            })).value();
    });


    public render = () => {
        const { required,  fullWidth, isLoading, disabled, onClose } = this.props;

        const components = this.getDropdownDamages(this.props);

        const value = this.getSelectedValue(this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        multiple
                        disabled={isLoading || disabled}
                        options={components}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChange}
                        onClose={onClose}
                        disableClearable={required}
                        openOnFocus
                        disableCloseOnSelect
                        ListboxProps={{
                            title: '(Select what needs to be scouted)',
                        }}
                        ListboxComponent={ScoutingListbox}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    color='primary'
                                    icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                                    checkedIcon={<CheckBoxIcon fontSize='small' />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.label}
                            </React.Fragment>
                        )}
                        renderInput={params => <TextField error={required && !value} {...params} fullWidth={fullWidth} label='Select Damages' />}
                    />
                    {
                        required && !value.length &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        scoutings: state.scouting.scoutings,
        isLoading: state.scouting.isLoading || state.masterData.damage.isLoading,
        damages: state.masterData.damage.damages,
    };
};

const ScoutingDamageMultiselect = connect(
    mapStateToProps,
)(withRouter(ScoutingDamageMultiselectComponent));

export default ScoutingDamageMultiselect;
