import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/messaging';
import { IKeyValue, CROP } from '../appConstants';
import { IReportRow } from '../types/reportRow';
import HttpService from './httpService';
import { IAssignmentBlock } from '../types/model/masterData/block';

const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);

export default firebaseApp;
export const firebaseGoogleProvider = new firebase.auth.GoogleAuthProvider();

export const firebaseMessaging = firebaseApp.messaging();

export class FirebaseService {
    private static registerUserCloud = firebaseApp.functions(CLOUD_FUNCTIONS_REGION).httpsCallable('registerUser');
    private static setEmployeeNumberCloud = firebaseApp.functions(CLOUD_FUNCTIONS_REGION).httpsCallable('setEmployeeNumber');
    private static getArcGisTokenCloud = firebaseApp.functions(CLOUD_FUNCTIONS_REGION).httpsCallable('getArcGisToken');
    private static createPhenologySpecificTreesCloud = firebaseApp.functions(CLOUD_FUNCTIONS_REGION).httpsCallable('createPhenologySpecificTrees');

    /**
     * Uploads file to Firebase and then updates appropriate entity
     * field with new file name.
     * @param id ID of entity to update
     * @param fieldName field of entity to update
     * @param collectionName collection name of entity
     * @param file file to upload
     * @param path path of file to upload
     * @static
     * @memberof FirebaseService
     */
    public static uploadFileMetaData = (id : string, fieldName : string, collectionName : string, file : File, path : string) => {
        return firebaseApp.storage().ref(path).child(file.name).put(file, {
            customMetadata: {
                fieldName,
                collectionName,
                id,
            },
        });
    }

    public static registerUser = async (employeeNumber : string, name : string) => {
        try {
            const result = await FirebaseService.registerUserCloud({
                employeeNumber,
                name,
            });

            return result.data.message as string;
        } catch (ex) {
            if (typeof(ex) === 'object' && ex instanceof Error) {
                throw Error(ex.message);
            }

            throw Error('Error');
        }
    }

    public static setEmployeeNumber = async (employeeNumber : string) => {
        try {
            const result = await FirebaseService.setEmployeeNumberCloud({
                employeeNumber,
            });

            return result.data as string;
        } catch (ex) {
            if (typeof(ex) === 'object' && ex instanceof Error) {
                throw Error(ex.message);
            }

            throw Error('Error');
        }
    }

    public static getArcGisToken = async () => {
        try {
            const result = await FirebaseService.getArcGisTokenCloud();

            return result.data as {
                access_token : string;
                expires_in : number;
            };
        } catch (ex : Error | unknown) {
            if (typeof(ex) === 'object' && ex instanceof Error) {
                throw Error(ex.message);
            }

            throw Error('Error');
        }
    }

    public static createPhenologySpecificTrees = async (
        divisionName : string,
        cultivar : string,
        cultivarName : string,
        selectedBlocks : Array<IAssignmentBlock>,
        trees : Record<string, number>,
    ) => {
        try {
            await FirebaseService.createPhenologySpecificTreesCloud({
                divisionName,
                cultivar,
                cultivarName,
                selectedBlocks,
                trees,
            });
        } catch (ex) {
            if (typeof(ex) === 'object' && ex instanceof Error) {
                throw Error(ex.message);
            }

            throw Error('Error');
        }
    }

    public static getReportExcel = async (exportData : IKeyValue<{
        crop : CROP;
        headers : IKeyValue<Array<string>>;
        data : IKeyValue<IReportRow>;
    }>) => {
        try {
            await HttpService.getCloudFunctionFile('requestReport', exportData);
        } catch (ex) {
            if (typeof(ex) === 'object' && ex instanceof Error) {
                throw Error(ex.message);
            }

            throw Error('An Error Occurred');
        }
    }
}
