import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { IRootState } from '../../../../../@types/redux';
import { IGeoServerBlock } from '../../../../../types/model/masterData/geoserver/block';
import MasterDataGeoServerFunctions from '../../../../../store/masterData/geoserver/functions';
import { LngLatBoundsLike } from 'react-map-gl';
import GeoHelper from '../../../../../services/helper/geoHelper';

interface IGeoServerDivisionAutocompleteProps {
    id ?: string;
    value ?: string | null;

    onChange : (value ?: string, bounds ?: LngLatBoundsLike | null, event ?: React.ChangeEvent<unknown>) => void;

    blocks : Array<IGeoServerBlock>;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;
    disabled ?: boolean;
    name ?: string;

    label ?: string;
}

interface IGeoServerDivisionAutocompleteState {
}

class GeoServerDivisionAutocompleteComponent extends React.Component<IGeoServerDivisionAutocompleteProps, IGeoServerDivisionAutocompleteState> {
    constructor(props : IGeoServerDivisionAutocompleteProps) {
        super(props);

        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    public readonly loadData = async () => {
        MasterDataGeoServerFunctions.getBlockList();
    };

    private readonly getData = (state : IGeoServerDivisionAutocompleteState, props : IGeoServerDivisionAutocompleteProps) => props.blocks;
    private readonly getValue = (state : IGeoServerDivisionAutocompleteState, props : IGeoServerDivisionAutocompleteProps) => props.value;
    private readonly getRequired = (state : IGeoServerDivisionAutocompleteState, props : IGeoServerDivisionAutocompleteProps) => props.required;
    
    private readonly getBlockAutocomplete = createSelector([
        this.getData,
        this.getRequired,
    ], (
        blocks,
        required,
    ) => {
        const blocksDrop = lodash.chain(blocks)
            .uniqBy(n => n.divisionGuid)
            .map(n => ({
                label: n.divisionName,
                value: n.divisionGuid,
            }))
            .sortBy(n => n.label)
            .value();

        if (!required) {
            blocksDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return blocksDrop;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue,
        this.getRequired,
        this.getBlockAutocomplete,
    ], (
        value,
        required,
        blocks,
    ) => {
        if (value && typeof(value) === 'string') {
            const block = blocks
                .find(x => x.value === value);

            return block ?? null;
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });



    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        const bounds = !value?.value ? null : GeoHelper.getPolygonBounds(this.props.blocks.filter(x => x.divisionGuid === value.value));

        this.props.onChange(value?.value, bounds, event);
    };

    public readonly render = () => {
        const {
            required,
            fullWidth,
            isLoading,
            disabled,
            label,
        } = this.props;

        const blocks = this.getBlockAutocomplete(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        disabled={isLoading || disabled}
                        id='block_select'
                        options={blocks}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChange}
                        disableClearable={required}
                        openOnFocus
                        renderInput={params => (
                            <TextField
                                error={required && !value}
                                required={required}
                                {...params}
                                fullWidth={fullWidth}
                                label={label ?? 'Division'}
                            />)}
                    />
                    {
                        required && !value &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.masterData.geoserver.blocks,
        isLoading: state.masterData.geoserver.isLoadingBlocks,
    };
};

const GeoServerDivisionAutocomplete = connect(
    mapStateToProps,
)(GeoServerDivisionAutocompleteComponent);

export default GeoServerDivisionAutocomplete;
