import React from 'react';
import { IPhenologySpecificTreeEntryBranch, PhenologySpecificTreeEntryHelper } from '../../../../types/model/phenology/specificEntry';
import { Box, styled } from '@material-ui/core';
import { themeVariables } from '../../../../material';

interface IPhenologySpecificTreeEntryBranchViewProps {
    branches : Array<IPhenologySpecificTreeEntryBranch>;
}

interface IPhenologySpecificTreeEntryBranchViewState {}

export default class PhenologySpecificTreeEntryBranchView extends React.PureComponent<IPhenologySpecificTreeEntryBranchViewProps, IPhenologySpecificTreeEntryBranchViewState> {
    constructor(props : IPhenologySpecificTreeEntryBranchViewProps) {
        super(props);
        this.state = {};
    }

    private readonly StyledBox = styled(Box)({
        maxHeight: 480,
        overflowY: 'auto',
        '& > div': {
            marginBottom: 15,
        },
        '& .header div': {
            height: 38,
            backgroundColor: themeVariables.colors.material.primary.dark,
            color: themeVariables.colors.white,
            fontWeight: 400,
            fontSize: 14,
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& .header div:not(:nth-last-child(1))': {
            marginRight: 20,
        },
        '& .row div': {
            height: 38,
            color: themeVariables.colors.material.primary.dark,
            backgroundColor: themeVariables.colors.grey3,
            fontWeight: 700,
            fontSize: 18,
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& .row div:not(:nth-last-child(1))': {
            marginRight: 20,
        },
        '& .row div:first-child': {
            color: themeVariables.colors.white,
            fontWeight: 700,
            fontSize: 18,
        },
        '& .row:nth-child(even) div:first-child': {
            backgroundColor: themeVariables.colors.material.primary.dark,
        },
        '& .row:nth-child(even) div:not(:first-child)': {
            backgroundColor: themeVariables.colors.lightgreen,
        },
        '& .row:nth-child(odd) div:first-child': {
            backgroundColor: themeVariables.colors.grey1,
        },
    });

    public readonly render = () => {
        const {
            branches,
        } = this.props;

        return (
            <this.StyledBox className='fdc'>
                <div className='fdr header'>
                    <div className='flx1'>
                        BRANCH NO
                    </div>
                    {
                        PhenologySpecificTreeEntryHelper.spurs.map((spur, i) => (
                            <div key={spur} className='flx1'>
                                {
                                    spur
                                }
                                {
                                    i == (PhenologySpecificTreeEntryHelper.spurs.length - 1) &&
                                    ' +'
                                }
                                &nbsp;POINTER
                            </div>
                        ))
                    }
                </div>
                {
                    branches.map((branch, i) => (
                        <div key={i} className='fdr row'>
                            <div className='flx1'>
                                {i + 1}
                            </div>
                            {
                                PhenologySpecificTreeEntryHelper.spurs.map((spur) => (
                                    <div key={spur} className='flx1'>
                                        {
                                            !!branch.spurs[spur] &&
                                            branch.spurs[spur]
                                        }
                                        {
                                            !branch.spurs[spur] &&
                                            '-'
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </this.StyledBox>
        );
    };
}
