import TrapEntryCommentActions from './actions';
import { getType } from 'typesafe-actions';
import { ITrapEntryComment } from '../../../../types/model/masterData/trap/trapEntryComment';
import { TrapEntryCommentAction } from '../../../../@types/redux';

export interface ITrapEntryCommentState {
    trapEntryComments : Array<ITrapEntryComment>;
    isLoading : boolean;

}

const initialState : ITrapEntryCommentState = {
    trapEntryComments: [],
    isLoading: false,
};

export default function trapEntryCommentReducer(state : ITrapEntryCommentState = initialState, action : TrapEntryCommentAction) : ITrapEntryCommentState {
    switch (action.type) {
    case getType(TrapEntryCommentActions.setList):
        return { ...state, trapEntryComments: action.payload };
    case getType(TrapEntryCommentActions.setLoading):
        return { ...state, isLoading: action.payload };
    default:
        return state;
    }
}