import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { IAuthState, IRootState } from './@types/redux';
import authFunctions from './store/auth/functions';
import NavBar from './modules/root/NavBar';
import Login from './modules/root/Login';
import Routes from './modules/root/Routes';
import EmployeeNumberDialog from './modules/right/dialog/EmployeeNumberDialog';
import NavDrawer from './modules/root/NavDrawer';
import SnackbarNotifier from './modules/root/SnackbarNotifier';
import ErrorBoundary from './modules/error/ErrorBoundary';

interface IAppProps extends RouteComponentProps {
    auth : IAuthState;
}

interface IAppState {
    leftDrawerOpen : boolean;
    rightDrawerOpen : boolean;
}

class App extends React.Component<IAppProps, IAppState> {
    constructor(props : IAppProps) {
        super(props);

        this.state = {
            leftDrawerOpen: false,
            rightDrawerOpen: false,
        };
    }

    public componentDidMount() {
        authFunctions.setAuthState();
    }

    private readonly toggleLeftDrawer = () => {
        this.setState({ leftDrawerOpen: !this.state.leftDrawerOpen });
    };

    private readonly toggleRightDrawer = () => {
        this.setState({ rightDrawerOpen: !this.state.rightDrawerOpen });
    };

    public render() {
        return (
            <>
                <div className={'fdc hfill mnh0'}>
                    <NavBar toggleLeftDrawer={this.toggleLeftDrawer} toggleRightDrawer={this.toggleRightDrawer} />
                    <div className={'fdc hfill'}>
                        <ErrorBoundary
                            auth={this.props.auth}
                        >
                            <NavDrawer key='leftmenu' toggleDrawer={this.toggleLeftDrawer} openLeft={this.state.leftDrawerOpen} openRight={false} /> {/* For Navigation */}
                            {
                                this.props.auth.session && !this.props.auth.isLoggingIn ? (
                                    <Routes />
                                ) : (
                                    <Login />
                                )
                            }
                            <EmployeeNumberDialog />
                        </ErrorBoundary>
                    </div>
                </div>
                <SnackbarNotifier />
            </>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

export default withRouter(connect(
    mapStateToProps,
)(App));
