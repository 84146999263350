import AppBar from '@material-ui/core/AppBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { connect } from 'react-redux';
import { IAuthState, IGeneralState, IRootState } from '../../@types/redux';
import { VERSION } from '../../version';
import { withRouter, RouteComponentProps } from 'react-router';
import RouteBreadCrumbs from '../customComponents/breadCrumb/RouteBreadCrumb';
import UserAvatar from '../customComponents/avatar/UserAvatar';
import Notifications from './Notifications';

interface INavBarProps extends RouteComponentProps {
    toggleLeftDrawer : () => void;
    toggleRightDrawer : () => void;
    auth : IAuthState;

    general : IGeneralState;
}

interface INavBarStates {
}

class NavBar extends React.Component<INavBarProps, INavBarStates> {
    constructor(props : INavBarProps) {
        super(props);

        this.state = {
        };
    }

    public render() {
        const auth = this.props.auth;
        return (
            <AppBar position='static'>
                <Toolbar variant='dense'>
                    {auth.isLoggedIn && auth.session && <IconButton color={'inherit'} className={'mr20'} onClick={this.props.toggleLeftDrawer}>
                        <Icon>menu</Icon>
                    </IconButton>}
                    <img height={50} src={`${ASSET_BASE}/assets/images/logo.png`} className={'mr10 mb5 mt5'} />
                    <Typography color='inherit' variant='h6'>
                        Scouting
                    </Typography>
                    <sub className={'ml10 pt5'}>v{VERSION.version}</sub>
                    <div className='pl15 aic jcc'>
                        <RouteBreadCrumbs />
                    </div>
                    <span className={'flx1'} />
                    {
                        auth.isLoggedIn && auth.session &&
                        <Notifications auth={auth} />
                    }
                    {
                        auth.isLoggedIn && auth.session &&
                        <UserAvatar auth={auth} />
                    }
                </Toolbar>
            </AppBar>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        general: state.general,
    };
};

export default withRouter(connect(
    mapStateToProps,
)(NavBar));
