import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

interface IStadiumButtonProps extends ButtonProps {
    className ?: string;
}

// tslint:disable-next-line: variable-name
const StadiumButton = (props : IStadiumButtonProps) => {
    const {
        className = '',
    } = props;
    return (
        <Button { ...props } className={`${className} pl25 pr25 br25 bsb ${!props.style ? 'h36' : ''} fwb`}>
        </Button>
    );
};

export default StadiumButton;
