import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { StyledComponentProps } from '@material-ui/core/styles/withStyles';
import { THRESHOLD_TYPE } from '../../../types/model/masterData/threshold';

interface IScoutingReportCellProps extends StyledComponentProps {
    values : Array<number>;

    thresholdValue ?: number;

    type : THRESHOLD_TYPE | string;

    loopCount : number;
}

interface IScoutingReportCellState {}

class ScoutingReportCell extends React.PureComponent<IScoutingReportCellProps, IScoutingReportCellState> {
    constructor(props : IScoutingReportCellProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { values, classes, loopCount } = this.props;
        const greyValue = 237 - (237 * 0.1 * loopCount);
        return (
            <div className='flx1 fwn fs13-18 lsp02 tac brw1 bocg4 bnl'>
                {
                    values.map((n, i) => (
                        <div className={`flx1 aic jcc bocg3 brw1 ${classes?.root}`} key={`value_sub_${i}`}
                            style={{
                                backgroundColor: n ? '' : `rgb(${greyValue}, ${greyValue}, ${greyValue})`,
                            }}>
                            {
                                !!n &&
                                n
                            }
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default withStyles<'root', never, IScoutingReportCellProps>({
    root: {
        backgroundColor: (props) => {
            switch (props.type) {
            case 'insects':
                if (props.thresholdValue && props.values.some(n => n >= props.thresholdValue!)) return '#297D7D';
                if (props.thresholdValue && props.values.some(n => n >= (props.thresholdValue! / 2))) return '#5AACAC';
                break;
            case 'disease':
                if (props.thresholdValue && props.values.some(n => n >= props.thresholdValue!)) return '#CC2B29';
                if (props.thresholdValue && props.values.some(n => n >= (props.thresholdValue! / 2))) return '#E25A58';
            }
            return '';
        },
        color: (props) => {
            switch (props.type) {
            case 'insects':
                if (props.thresholdValue && props.values.some(n => n >= props.thresholdValue!)) return '#FFFFFF';
                if (props.thresholdValue && props.values.some(n => n >= (props.thresholdValue! / 2))) return '#FFFFFF';
                break;
            case 'disease':
                if (props.thresholdValue && props.values.some(n => n >= props.thresholdValue!)) return '#FFFFFF';
                if (props.thresholdValue && props.values.some(n => n >= (props.thresholdValue! / 2))) return '#FFFFFF';
            }
            return '';
        },
    },
})(ScoutingReportCell);
