import { Icon, IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import ToggleButton from '../../../customComponents/button/ToggleButton';
import ScoutingComponentMultiselect from '../../../customComponents/dropdowns/scouting/ComponentMultiselect';

interface IScoutingComponentSelectorProps {
    sections ?: Array<string>;
    components ?: Array<string>;
    onComponentsChange ?: (sections ?: Array<string> | null) => void;
}

interface IScoutingComponentSelectorState {
    edit : boolean;
}

class ScoutingComponentSelector extends React.PureComponent<IScoutingComponentSelectorProps, IScoutingComponentSelectorState> {
    constructor(props : IScoutingComponentSelectorProps) {
        super(props);
        this.state = {
            edit: true,
        };
    }

    private readonly onClose = () => {
        if (!this.props.components?.length) return;
        this.setState({
            edit: false,
        });
    }

    private readonly onEditClick = () => {
        this.setState({
            edit: !this.state.edit,
        });
    }

    public render = () => {
        const { sections, components, onComponentsChange } = this.props;
        const { edit } = this.state;
        return (
            <>
                <List className='mt15' disablePadding dense>
                    <ListItem className={'bclg cw'} key={'SPECIFIC - Component'}>
                        <ListItemText primary={'SPECIFIC - Component'} title={'SPECIFIC - Component'} />
                        {
                            !edit &&
                            <IconButton onClick={this.onEditClick} color='secondary' size='small'>
                                <Icon>edit</Icon>
                            </IconButton>
                        }
                    </ListItem>
                </List>
                {
                    !edit &&
                    components &&
                    <div className='fdc aic p10'>
                        <div className='fdr jcsb fww'>
                            {
                                components.map((n, i) => (
                                    <ToggleButton
                                        key={n}
                                        className={`${i !== (components.length - 1) ? 'mr15' : ''}`}
                                        value={`${n}`}
                                        selected
                                        disabled
                                    >
                                        {`${n}`.toLocaleUpperCase()}
                                    </ToggleButton>
                                ))
                            }
                        </div>
                    </div>
                }
                {
                    edit &&
                    <div className='fdc aifs p10'>
                        <ScoutingComponentMultiselect
                            value={components ?? []}
                            onChange={onComponentsChange}
                            fullWidth
                            sections={sections}
                            onClose={this.onClose}
                        />
                    </div>
                }
            </>
        );
    }
}

export default ScoutingComponentSelector;
