import NematodeActions from './actions';
import { dispatch, getState } from '..';
import firebaseApp from '../../services/firebaseService';
import GeneralFunctions from '../general/functions';
import lodash from 'lodash';
import firebase from 'firebase/app';
import { Moment } from 'moment';
import { NematodeAssignmentHelper, INematodeAssignment, NematodeAssignmentType } from '../../types/model/nematode/nematodeAssignment';
import { IUser } from '../../types/model/user';
import { IAssignmentBlock } from '../../types/model/masterData/block';

export default class NematodeFunctions {
    private static assignmentListener ?: () => void;

    public static getAssignments = (crop : string, unfinishedOnly : boolean, refresh ?: boolean) => {
        if (!refresh && NematodeFunctions.assignmentListener) return;

        if (NematodeFunctions.assignmentListener) {
            NematodeFunctions.assignmentListener();
        }

        dispatch(NematodeActions.setLoadingAssignments(true));
        dispatch(NematodeActions.setAssignments([]));
        try {
            const session = getState().auth.session;
            if (!session) return;
            const divisions = Object.keys(session.user.divisions).map(n => n.toLocaleLowerCase());

            let query = NematodeAssignmentHelper
                .collection()
                .where('crop', '==', crop);

            if (unfinishedOnly) {
                query = query.where('finished', '==', false);
            }

            NematodeFunctions.assignmentListener = query
                .orderBy('date', 'desc')
                .limit(1000)
                .onSnapshot((snapshot) => {
                    const nematodeState = getState().nematode;

                    const assignments = lodash.cloneDeep(nematodeState.assignments);

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const assignment = f.doc.data();

                        if (!assignment || !divisions.includes(assignment.division)) return;

                        const index = lodash.findIndex(assignments, n => n.id === assignment.id);

                        switch (f.type) {
                        case 'added':
                            assignments.push(assignment);
                            break;
                        case 'modified':
                            assignments.splice(index, 1, assignment);
                            break;
                        case 'removed':
                            assignments.splice(index, 1);
                            break;
                        }
                    });

                    dispatch(NematodeActions.setAssignments(assignments.sort((a, b) => b.date - a.date)));
                    dispatch(NematodeActions.setLoadingAssignments(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading assignments.', err);
                    dispatch(NematodeActions.setLoadingAssignments(false));
                }, () => {
                    dispatch(NematodeActions.setAssignments([]));
                    dispatch(NematodeActions.setLoadingAssignments(false));
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading assignments.', ex);
        }
    }

    /**
     * Validates an assignment.
     * Throws `Error` if invalid.
     * @param assignment
     */
    private static validateAssignment(assignment : INematodeAssignment) {

        if (!assignment.date) {
            throw new Error('Date required.');
        }

        if (!assignment.block) {
            throw new Error('Block required.');
        }

        if (!assignment.division) {
            throw new Error('Division required.');
        }

        if (!assignment.blockName) {
            throw new Error(`${assignment.scoutingBlock.landName} required a Block.`);
        }

        if (!Object.keys(assignment.scoutingBlock.points).length) {
            throw new Error(`Block ${assignment.scoutingBlock.name} has no points.`);
        }
    }

    /**
     * Creates/Saves assignment.
     * If `saveItem` is not undefined it makes changes to that assignment and
     * saves it, if it is undefined a new assignment is create using the list
     * of nematode blocks. If multiple blocks is provided and `saveItem` is not undefined
     * the last block will be used.
     * This uses a firestore transaction to create/save multiple assignments.
     * @param date
     * @param employee Employee document path
     * @param employeeNumber
     * @param employeeName
     * @param nematodeBlocks
     * @param saveItem
     */
    public static saveAssignment = async (
        date : Moment,
        employee : string,
        employeeNumber : string,
        employeeName : string,
        scoutingBlocks : Array<IAssignmentBlock>,
        saveItem ?: INematodeAssignment,
    ) => {
        dispatch(NematodeActions.setLoadingAssignments(true));

        try {
            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const authState = getState().auth;

                if (!authState.session) return;

                for (const block of scoutingBlocks) {
                    const assignment = {
                        id: saveItem?.id ?? '',
                        createdBy: saveItem?.createdBy ?? authState.session.user.ref,
                        createdByName: saveItem?.createdByName ?? authState.session.user.name,
                        createdByEmployee: saveItem?.createdByEmployee ?? authState.session.user.employeeNumber,
                        createdOn: saveItem?.createdOn ?? firebase.firestore.Timestamp.now().toMillis(),

                        updatedBy: authState.session.user.ref,
                        updatedByName: authState.session.user.name,
                        updatedByEmployee: authState.session.user.employeeNumber,
                        updatedOn: firebase.firestore.Timestamp.now().toMillis(),

                        date: date.valueOf(),
                        employee,
                        employeeNumber,
                        employeeName,

                        block: block.id,
                        blockName: block.name,
                        landName: block.landName,
                        division: block.division,
                        crop: block.crop,
                        scoutingBlock: block,
                        finished: false,
                        finishedPoints: [],

                        assignmentType: saveItem?.assignmentType ?? 'general',
                        isSent: false,

                    } as INematodeAssignment;

                    NematodeFunctions.validateAssignment(assignment);
                    NematodeAssignmentHelper.saveTransaction(transaction, assignment);
                }
            });

            GeneralFunctions.generalShowSuccessSnackbar(`Assignment${scoutingBlocks.length > 1 ? 's' : ''} saved.`);
            return true;
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving assignment.', ex);
        } finally {
            dispatch(NematodeActions.setLoadingAssignments(false));
        }
        return false;
    }

    public static createAssignments = async (
        date : Moment,
        scoutingBlocks : Record<string, Array<IAssignmentBlock>>,
        employees : Record<string, IUser | null>,
        nematodeAssignmentType : NematodeAssignmentType,
    ) => {
        dispatch(NematodeActions.setLoadingAssignments(true));

        try {
            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const authState = getState().auth;

                if (!authState.session) return;

                for (const landName of Object.keys(scoutingBlocks)) {
                    for (const block of scoutingBlocks[landName]) {
                        const employee = employees[landName];

                        if (!employee) throw new Error(`Land ${landName} has no Scout.`);
                        const assignment = {
                            id: '',
                            createdBy: authState.session.user.ref,
                            createdByName: authState.session.user.name,
                            createdByEmployee: authState.session.user.employeeNumber,
                            createdOn: firebase.firestore.Timestamp.now().toMillis(),

                            date: date.valueOf(),
                            employee: employee.ref,
                            employeeNumber: employee.employeeNumber,
                            employeeName: employee.name,

                            block: block.id,
                            blockName: block.name,
                            landName: block.landName,
                            division: block.division,
                            crop: block.crop,
                            scoutingBlock: block,
                            finished: false,
                            finishedPoints: [],

                            updatedBy: authState.session.user.ref,
                            updatedByName: authState.session.user.name,
                            updatedByEmployee: authState.session.user.employeeNumber,
                            updatedOn: firebase.firestore.Timestamp.now().toMillis(),

                            assignmentType: nematodeAssignmentType,
                            isSent: false,
                            
                        } as INematodeAssignment;

                        NematodeFunctions.validateAssignment(assignment);
                        NematodeAssignmentHelper.saveTransaction(transaction, assignment);
                    }

                }
            });

            GeneralFunctions.generalShowSuccessSnackbar(`Assignment${Object.keys(scoutingBlocks).length > 1 ? 's' : ''} created.`);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while creating assignment.', ex);

            throw ex;
        } finally {
            dispatch(NematodeActions.setLoadingAssignments(false));
        }
    }
}
