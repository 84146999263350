import firebase from 'firebase/app';
import { dispatch, getState } from '../..';
import DivisionHelper, { IDivision } from '../../../types/model/rights/division';
import GeneralFunctions from '../../general/functions';
import DivisionActions from './actions';

export default class DivisionFunctions {
    private static listener ?: () => void;

    public static readonly getList = (refresh ?: boolean) => {
        if (!refresh && DivisionFunctions.listener) {
            return;
        }

        if (DivisionFunctions.listener) {
            DivisionFunctions.listener();
        }

        dispatch(DivisionActions.setLoading(true));
        dispatch(DivisionActions.setList([]));

        try {
            DivisionFunctions.listener = DivisionHelper
                .collection()
                .onSnapshot((snapshot) => {
                    const session = getState().auth.session;
                    if (!session) {
                        return;
                    }

                    dispatch(DivisionActions.setList(snapshot.docs.map(x => x.data()).filter(x => !!session.user.divisions[x.code])));
                    dispatch(DivisionActions.setLoading(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading divisions.', err);
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading divisions.', ex);
            dispatch(DivisionActions.setLoading(false));
        }
    }

    public static save = async (item : IDivision) => {
        const session = getState().auth.session;

        if (!session) return;
        
        const divisions = getState().rights.division.divisions;

        if (divisions.some(x => (x.name === item.name || x.code === item.code)&& x.id !== item.id)) {
            throw new Error('Division already exists.');
        }

        dispatch(DivisionActions.setLoading(true));

        try {
            const save : IDivision = {
                ...item,
                createdBy: item.createdBy ? item.createdBy : session.firebaseUser.uid,
                createdByEmployee: item.createdByEmployee ? item.createdByEmployee : session.user.employeeNumber,
                createdByName: item.createdBy ? item.createdByName : session.user.name,
                createdOn: item.createdOn ? item.createdOn : firebase.firestore.Timestamp.now().toMillis(),
                updatedBy: session.firebaseUser.uid,
                updatedByEmployee: session.user.employeeNumber,
                updatedByName: session.user.name,
                updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                id: item.id ? item.id : item.code.toUpperCase(),
            };

            await DivisionHelper.save(save);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving trap type.', ex);
            throw ex;
        } finally {
            dispatch(DivisionActions.setLoading(false));
        }
    }
}