import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import { createSelector } from 'reselect';
import moment from 'moment';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { INematodeAssignment, NematodeAssignmentType } from '../../../../types/model/nematode/nematodeAssignment';
import { CROP, DATE_FORMAT_DEFAULT_NO_TIME } from '../../../../appConstants';
import { IAssignmentBlock, IBlock } from '../../../../types/model/masterData/block';
import { IRootState } from '../../../../@types/redux';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import ScoutingBlockLandNameDropdown from '../../../customComponents/dropdowns/BlockLandNameDropdown';
import CheckToggleButton from '../../../customComponents/button/CheckToggleButton';
import { Transitions } from '../../../customComponents/animations/Transitions';

interface INematodeAssignmentFieldSelectorProps {
    date ?: moment.Moment;
    division ?: string;
    assignmentType ?: NematodeAssignmentType;
    crop : CROP;

    onBackClick ?: () => void;
    onAddClick ?: () => void;

    blocks : Array<IBlock>;
    assignments : Array<INematodeAssignment>;
    selectedScoutingBlocks : Array<IAssignmentBlock>;

    landName ?: string;
    onChange ?: (landName : string, blocks : Array<IAssignmentBlock>) => void;
}

interface INematodeAssignmentFieldSelectorState {
    showInfo : boolean;
}

class NematodeAssignmentFieldSelectorComponent extends React.PureComponent<INematodeAssignmentFieldSelectorProps, INematodeAssignmentFieldSelectorState> {
    constructor(props : INematodeAssignmentFieldSelectorProps) {
        super(props);
        this.state = {
            showInfo: false,
        };
    }

    private readonly getScoutedBlocks = (
        assignments : Array<INematodeAssignment>,
        crop : CROP,
        date ?: moment.Moment,
        landName ?: string
    ) => {
        return assignments
            .filter(x => x.crop === crop)
            .filter(x => x.landName === landName)
            .filter(x => moment.utc(x.date).isSame(date))
            .map(n => n.blockName)
            .sort();
    }

    private readonly getCrop = (props : INematodeAssignmentFieldSelectorProps) => props.crop;
    private readonly getLandName = (props : INematodeAssignmentFieldSelectorProps) => props.landName;
    private readonly getDivision = (props : INematodeAssignmentFieldSelectorProps) => props.division;
    private readonly getScoutingBlocks = (props : INematodeAssignmentFieldSelectorProps) => props.selectedScoutingBlocks;
    private readonly getBlocks = (props : INematodeAssignmentFieldSelectorProps) => props.blocks;
    private readonly getAssignment = (props : INematodeAssignmentFieldSelectorProps) => props.assignments;
    private readonly getDate = (props : INematodeAssignmentFieldSelectorProps) => props.date;

    private readonly getBlockNames = createSelector([
        this.getBlocks,
        this.getLandName,
        this.getDivision,
        this.getCrop,
    ], (
        blocks,
        landName,
        division,
        crop,
    ) => {

        if (!division) return [];

        return blocks
            .filter(n => crop === n.crop)
            .filter(n => n.landName === landName)
            .map(n => n.name)
            .sort();
    });

    private readonly getSelectedBlockNames = createSelector([
        this.getScoutingBlocks,
    ], (blocks) => {
        return blocks
            .map(n => n.name)
            .sort();
    });

    private readonly getAlreadyScoutedBlockNames = createSelector([
        this.getAssignment,
        this.getDate,
        this.getLandName,
        this.getCrop,
    ], (
        assignments,
        date,
        landName,
        crop,
    ) => {
        return this.getScoutedBlocks(assignments, crop, date, landName);
    });

    private readonly onBlockLandNameChange = (landName ?: string) => {
        const { division } = this.props;
        if (!division || !landName) return;

        const alreadyScoutedBlockNames = this.getScoutedBlocks(this.props.assignments, this.props.crop, this.props.date, landName);

        const scoutingBlocks = this.props.blocks
            .filter(n => n.crop === this.props.crop)
            .filter(n => n.landName === landName)
            .filter(n => !alreadyScoutedBlockNames.includes(n.name))
            .filter(n => division.toLocaleUpperCase() === n.division.toLocaleUpperCase());

        if (this.props.onChange) this.props.onChange(landName, scoutingBlocks);
    }

    private readonly onBlockNameClick = (blockName : string) => {
        const { division, blocks, landName, crop } = this.props;

        if (!division || !landName) return;

        const scoutingBlocks = this.props.selectedScoutingBlocks.slice();

        const index = scoutingBlocks.findIndex(x => x.name === blockName);

        if (index > -1) {
            scoutingBlocks.splice(index, 1);
        } else {
            const block = blocks
                .find(x =>
                    x.crop === crop &&
                    x.landName === landName &&
                    x.name === blockName &&
                    division.toLocaleUpperCase() === x.division.toLocaleUpperCase());

            if (block) scoutingBlocks.push(block);
        }

        if (this.props.onChange) this.props.onChange(landName, scoutingBlocks);
    }

    private readonly onInfoClick = () => {
        this.setState({
            showInfo: !this.state.showInfo,
        });
    }

    public readonly render = () => {
        const {
            division,
            crop,
            onBackClick,
            landName,
            selectedScoutingBlocks,
            onAddClick,
            date,
            assignmentType,
        } = this.props;

        const { showInfo } = this.state;

        const blockNames = this.getBlockNames(this.props);
        const selectedBlockNames = this.getSelectedBlockNames(this.props);
        const alreadyScoutedBlockNames = this.getAlreadyScoutedBlockNames(this.props);

        return (
            <Paper className={'flx1 fdc m5'}>
                <AppBar position='static' className='bcdg pbr' elevation={0}>
                    <Toolbar variant='dense'>
                        {
                            !!onBackClick &&
                            <Tooltip title='Back'>
                                <div>
                                    <IconButton size='small' onClick={onBackClick}>
                                        <ArrowBackIcon color='secondary' />
                                    </IconButton>
                                </div>
                            </Tooltip>

                        }
                        <Typography className='fs17'>
                            ADD FIELDS
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Info'>
                            <div>
                                <IconButton size='small' onClick={this.onInfoClick}>
                                    <InfoRoundedIcon className='cy' />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <div className='fdc hfill oya drawer'>
                    <div className={'fdc aic p10'}>
                        <ScoutingBlockLandNameDropdown
                            crop={crop}
                            value={landName}
                            disabled={!division}
                            division={division}
                            fullWidth
                            required
                            onChange={this.onBlockLandNameChange}
                        />
                    </div>
                    <div className={'fdc aifs jcc p10 mt5'}>
                        <Typography className='fs17 fwb cp'>
                            Blocks
                        </Typography>
                    </div>
                    <div className={'fdc flx1 p10'}>
                        <div className='fdr fww'>
                            {
                                blockNames.map(n => (
                                    <div key={n} className='aic jcc pr15 pb15'>
                                        <CheckToggleButton text={n} value={n} selected={selectedBlockNames.includes(n)}
                                            onToggle={this.onBlockNameClick} warning={!selectedBlockNames.includes(n) && alreadyScoutedBlockNames.includes(n)} />
                                    </div>
                                ))
                            }
                        </div>
                        {
                            !!alreadyScoutedBlockNames.filter(x => !selectedBlockNames.includes(x)).length &&
                            <div className='fdc mb10'>
                                <Typography className='fdr aifs co fs12 fwm'>
                                    <WarningIcon className='co mr7' />
                                    Block {alreadyScoutedBlockNames.filter(x => !selectedBlockNames.includes(x)).join(', ')} has already been scouted, if you want to scout it again please select it.
                                </Typography>
                            </div>
                        }
                        {
                            !!alreadyScoutedBlockNames.length &&
                            !!alreadyScoutedBlockNames.filter(x => selectedBlockNames.includes(x)).length &&
                            <div className='fdc mb10'>
                                <Typography className='fdr aifs co fs12 fwm'>
                                    <WarningIcon className='co mr7' />
                                    You have selected block {alreadyScoutedBlockNames.filter(x => selectedBlockNames.includes(x)).join(', ')} for scouting even though it has been scouted this week.
                                </Typography>
                            </div>
                        }
                    </div>
                    <div className={'fdc aife jcc p10 mt5'}>
                        <StadiumButton variant='contained' className='bcy cpd dbcg dcg bsd' disabled={!selectedScoutingBlocks.length} onClick={onAddClick}>
                            ADD FIELD
                        </StadiumButton>
                    </div>
                </div>
                <Dialog
                    open={showInfo}
                    TransitionComponent={Transitions.Down}
                    transitionDuration={500}
                    onClose={this.onInfoClick}
                    maxWidth='sm'
                    fullWidth
                    aria-labelledby='nematode-assignment-info-dialog-title'
                    aria-describedby='nematode-assignment-info-dialog-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                View Assignment
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onInfoClick} aria-label='Close'>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc ais flx1'>
                        <Typography className='cp fs18 lh37 fwm'>
                            Assignment Details
                        </Typography>
                        <div className='fdr'>
                            <div className='fdc flx1'>
                                <Typography className='fs13 lh37'>
                                    WEEK
                                </Typography>
                                <Typography className='fs16 fw700 lh37'>
                                    Week {date?.week()}
                                </Typography>
                            </div>
                            <div className='fdc flx1'>
                                <Typography className='fs13 lh37'>
                                    DATE
                                </Typography>
                                <Typography className='fs16 fw700 lh37'>
                                    {date?.format(DATE_FORMAT_DEFAULT_NO_TIME)}
                                </Typography>
                            </div>
                            <div className='fdc flx1'>
                                <Typography className='fs13 lh37'>
                                    ASSIGNMENT TYPE
                                </Typography>
                                <Typography className='fs16 fw700 lh37'>
                                    {assignmentType}
                                </Typography>
                            </div>
                            <div className='fdc flx1'>
                                <Typography className='fs13 lh37'>
                                    DIVISION
                                </Typography>
                                <Typography className='fs16 fw700 lh37'>
                                    {division}
                                </Typography>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </Paper>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        assignments: state.nematode.assignments,
        blocks: state.general.blocks,
    };
};

const NematodeAssignmentFieldSelector =  connect(
    mapStateToProps,
)(NematodeAssignmentFieldSelectorComponent);

export default NematodeAssignmentFieldSelector;
